import logoImage from "../../assets/img/login_logo.png";
import { createRef, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "components/Loader/Loader";
import { useLocation, useHistory } from "react-router-dom";
import API from "api";
import Icon from "../../components/Icon";
import "../../../src/tableStyling.css";
import viewIcon from "../../assets/img/eye-open-icon.png";
import hideIcon from "../../assets/img/eye-not-open-icon.png";

const ResetPassword = () => {
  const location = useLocation();
  let history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const inputRefs = useRef([createRef(), createRef(), createRef(), createRef(), createRef(), createRef()]);

  const [digits, setDigits] = useState(Array(6).fill(""));
  const [resetPassError, setResetPassError] = useState(null);

  const [loading, setLoading] = useState(false);

  const [passwordData, setpasswordData] = useState({
    password: "",
    confirmPassword: "",
  });

  const { password, confirmPassword } = passwordData;

  // const handleChange = (value, index) => {
  //   if (value !== "" && !/^\d$/.test(value)) {
  //     return;
  //   }
  //   const newDigits = [...digits];
  //   newDigits[index] = value;
  //   setDigits(newDigits);

  //   if (value) {
  //     if (index < 5) {
  //       inputRefs.current[index + 1].focus();
  //     }
  //   } else if (index > 0) {
  //     inputRefs.current[index - 1].focus();
  //   }
  // };
  const handleChange = (value) => {
    if (value !== "" && !/^\d+$/.test(value)) {
      return;
    }

    if (value.length <= 6) {
      const newDigits = value.split("");
      setDigits(newDigits);
    }
  };

  const handleKeyUp = (e, index) => {
    if (e.key === "Backspace" && !digits[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handelResetPassword = async () => {
    const code = digits.join("");
    setLoading(true);
    try {
      if (password !== confirmPassword) {
        setResetPassError({ message: "Password not match.", error: true });
        setLoading(false);
        return;
      }

      if (!location?.state || !location.state?.username) {
        setLoading(false);

        setResetPassError({ message: "Username not found.", error: true });

        return;
      }

      const path = "/user/verifyCode";

      const payload = {
        phone: location.state?.username,
        code,
        password,
      };

      const { verified } = await API.callPublicAPI({ path, payload });

      // await Auth.forgotPasswordSubmit(location.state?.username, code, password);

      setLoading(false);
      if (verified && history) history.push("/");
    } catch (error) {
      console.log("Error", { error });
      setLoading(false);
      setResetPassError({ message: "Incorrect OTP.", error: true });
    }
  };

  const handleResendCode = async () => {
    if (!location?.state || !location.state?.username) {
      setResetPassError({ message: "Username not found.", error: true });
      return;
    }
    try {
      const path = "/user/sendCode";
      const response = await API.callPublicAPI({ path, payload: { phone: location?.state?.username } });
      if (response?.send) {
        setResetPassError({ message: "OTP Sent.", success: true });
        return;
      } else {
        setResetPassError({ message: "Record not found" });
      }
    } catch (error) {
      setResetPassError({ message: error?.message });
    }
    // await Auth.forgotPassword(location?.state?.username);
  };

  const backToLoginPage = () => {
    if (history) history.push("/");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="loginWrapper">
      <div className="login-left-side">
        <div className="text-center">{<img src={logoImage} alt="logo" className="logo-width" />}</div>
      </div>

      <SweetAlert
        show={resetPassError ? true : false}
        error={resetPassError?.error || false}
        success={resetPassError?.success || false}
        title={resetPassError?.success ? "Success" : "Error"}
        onConfirm={() => setResetPassError(null)}
      >
        {resetPassError?.message}
      </SweetAlert>
      {loading && <Loader />}
      <div className="login-right-side max-w-100">
        <div className="registerFormWrapper">
          <div className="registerWrapper">
            <h3 className="w-100 text-center mt-2">Enter New Password</h3>
            <p className="tag-line text-center mt-4">
              To change your password, please fill in the field below.
              <br /> Your password must contain at least 8 charactors, it must also include at least <br />
              one upper case letter, one lower case letter, one number and one special charater
            </p>
            <div className="codeScannerWrapper codeScannerPhoneInput mt-4">
              <div className="registered-successful">
                {/* <div className="custom-radio-btn-wrapper mb-4"> */}
                {/* {Array.from({ length: 6 }).map((_, index) => (
                    <input
                      key={index}
                      ref={(el) => (inputRefs.current[index] = el)}
                      type="text"
                      maxLength="1"
                      value={digits[index]}
                      onKeyUp={(e) => handleKeyUp(e, index)}
                      onChange={(e) => handleChange(e.target.value, index)}
                      style={{ width: "40px", textAlign: "center", margin: "0 5px" }}
                    />
                  ))}
                </div> */}

                <div className="login-inner-right-side text-left">
                  <div className="text-left w-100 mb-3">
                    <label className="login-input-label">
                      OTP <span className="text-danger"> *</span>
                    </label>
                    <div>
                      <input
                        style={{ width: "100%", paddingRight: "40px", borderRadius: "2px" }}
                        type="text"
                        maxLength="6"
                        value={digits.join("")}
                        onChange={(e) => handleChange(e.target.value)}
                      />
                    </div>
                  </div>
                  <label className="login-input-label mb-2">
                    Enter New Password<span className="text-danger"> *</span>
                  </label>
                  <div className="input-group mb-3">
                    <div style={{ position: "relative", width: "100%" }}>
                      <input
                        style={{ width: "100%", paddingRight: "40px", borderRadius: "2px" }}
                        placeholder="Enter New Password"
                        minLength={8}
                        value={passwordData.password}
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => setpasswordData({ ...passwordData, password: e.target.value })}
                      />
                      <span
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        {showPassword ? (
                          <img className="header-btn-img" width={20} height={20} src={viewIcon} alt={`viewIcon`} />
                        ) : (
                          <img className="header-btn-img" width={20} height={20} src={hideIcon} alt={`hideIcon`} />
                        )}
                      </span>
                    </div>
                  </div>

                  <label className="login-input-label mb-2">
                    Re Enter New Password <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <div className="" style={{ position: "relative", width: "100%" }}>
                      <input
                        style={{ width: "100%", paddingRight: "40px", borderRadius: "2px" }}
                        placeholder="Re Enter New Password"
                        minLength={8}
                        value={confirmPassword}
                        type={showConfirmPassword ? "text" : "password"}
                        onChange={(e) => setpasswordData({ ...passwordData, confirmPassword: e.target.value })}
                      />
                      <span
                        onClick={toggleConfirmPasswordVisibility}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        {showConfirmPassword ? (
                          <img className="header-btn-img" width={20} height={20} src={viewIcon} alt={`viewIcon`} />
                        ) : (
                          <img className="header-btn-img" width={20} height={20} src={hideIcon} alt={`hideIcon`} />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="mt-4">
                    <button
                      type="submit"
                      disabled={digits.join("").length !== 6 || password === "" || confirmPassword === ""}
                      className="text-uppercase w-50 btn signin-btn"
                      onClick={handelResetPassword}
                    >
                      Change Password
                    </button>
                    <p
                      className="mt-3 text-center"
                      style={{ cursor: "pointer", fontSize: "14px", color: "##26335a" }}
                      onClick={handleResendCode}
                    >
                      Resend Code?
                    </p>
                    <p
                      className="text-center"
                      style={{ cursor: "pointer", fontSize: "14px", color: "#26335a" }}
                      onClick={backToLoginPage}
                    >
                      Back to login page
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
