import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import ColorLabel from "components/ColorLabel";
import Status from "components/Status";
import moment from "moment";
import notesOnIcon from "../src/assets/img/file-icon.png";
import cptCodesAndDesc from "./Jsons/CPTCodesAndDescription.json";
import eraDenialsReasons from "./Jsons/DenialReasonGroupCode.json";
import eraDenialsRemarks from "./Jsons/DenialRemarkCode.json";
import placeOfServices from "./Jsons/POS.json";
import payerlist from "./Jsons/payer-list.json";
import insuranceList from "./Jsons/InsuranceData.json";

export const PAGE_LIMIT = 20;
export const CE_PAGE_LIMIT = 10;
export const MIN_TEST_QUANTITY = 2;
export const MAX_TEST_QUANTITY = 8;
export const MIN_TEST_LIMIT = 8;
export const ATTACHMENT_FILES_LIMIT = 5;
export const NOTES_MAX_CHARACTER_LENGTH = 1500;
export const ALERT_MSG_MAX_CHARACTER_LENGTH = 4000;
export const UNPAID_CLAIMS_STATS_DAYS_GAP = 30;

export const DEFAULT_MEDICARE_PRO_CODE = [{ label: "K1034", value: "K1034", diagnosis: ["Z20.822"], modifire: [] }];

export const CONFIG = {
  env: process.env.REACT_APP_ENV,
  isLive: process.env.REACT_APP_ENV === "live",
  isLabType: process.env.REACT_APP_COMPANY_TYPE === "LAB",
  isBioLab: process.env.REACT_APP_BIO_LAB === "biolab",
  generalClient: process.env.REACT_APP_GENERAL_CLIENT,
  generalFacility: process.env.REACT_APP_GENERAL_FACILITY,
  generalSubAgent: process.env.REACT_APP_GENERAL_SUBAGENT,
  appURL: process.env.REACT_APP_API_URL,
  eligibilityBucket: process.env.REACT_APP_ELIGIBILITY_BUCKET,
  updateBucket: process.env.REACT_APP_ELIGIBILITY_RESULT_BUCKET,
  updateBackupBucket: process.env.REACT_APP_ELIGIBILITY_RESULT_BACKUP_BUCKET,
  appCodeName: process.env.REACT_APP_CODE_NAME,
  documentBucket: process.env.REACT_APP_DOCUMENT_BUCKET,
  DEFAULT_NPI: process.env.REACT_APP_LAB_NPI,
  envType: process.env.REACT_APP_ENV === "live" && !window.location.hostname.includes("localhost") ? "live" : "test",
  labName: process.env.REACT_APP_BIO_LAB,
};

export const filterRoutesPath = ["/clients", "/facilities", "/subagents", "/users"];

export const LTC_UPDATE_BUCKET = "ltcs-update-record";
export const LTC_ELIGIBILITY_BUCKET = "ltcs-eligibility";

export const DIAG_REF_CODE = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
  5: "F",
  6: "G",
  7: "H",
};

export const MEDICATRE_IDS = [
  "00831",
  "02001",
  "10111",
  "10112",
  "26119",
  "ISA01",
  "07101",
  "07102",
  "03101",
  "03102",
  "BTHS1",
  "01911",
  "01111",
  "01102",
  "01192",
  "12302",
  "33070",
  "04111",
  "04102",
  "ISA02",
  "ISA03",
  "ISA04",
  "ISA05",
  "13102",
  "12201",
  "12202",
  "12301",
  "12101",
  "12102",
  "16003",
  "17003",
  "18003",
  "19003",
  "ISA06",
  "ISA07",
  "09101",
  "09102",
  "10211",
  "10212",
  "ISA08",
  "15004",
  "06014",
  "1100H",
  "01211",
  "01202",
  "05101",
  "05102",
  "02202",
  "06101",
  "00952",
  "08101",
  "08102",
  "04901",
  "ISA09",
  "05201",
  "05202",
  "ISA10",
  "15101",
  "15102",
  "07201",
  "07202",
  "ISA11",
  "ISA12",
  "14211",
  "14212",
  "12302",
  "14011",
  "14112",
  "ISA13",
  "05901",
  "12M65",
  "08201",
  "08202",
  "06201",
  "06202",
  "05301",
  "05302",
  "07301",
  "00512",
  "03201",
  "03001",
  "11501",
  "11502",
  "03302",
  "05401",
  "05402",
  "14312",
  "14013",
  "12401",
  "12402",
  "04211",
  "04202",
  "12M07",
  "12M82",
  "12M83",
  "12M45",
  "12M30",
  "01312",
  "01302",
  "13201",
  "13202",
  "13292",
  "13282",
  "15201",
  "15202",
  "04311",
  "04302",
  "02302",
  "12501",
  "12502",
  "09202",
  "ISA14",
  "ISA15",
  "00882",
  "14411",
  "14412",
  "ISA16",
  "45552",
  "11001",
  "11202",
  "03402",
  "ISA17",
  "ISA18",
  "ISA19",
  "MCAMA",
  "10311",
  "10312",
  "04411",
  "04402",
  "ISA20",
  "09302",
  "03501",
  "03502",
  "12004",
  "11302",
  "14512",
  "02402",
  "06001",
  "06302",
  "11402",
  "11003",
  "03602",
];

export const PROFILE_IMAGE_PATH = "https://medflow-images.s3.eu-west-1.amazonaws.com/public/profile/";
export const LOGO_IMAGE_PATH = "https://medflow-images.s3.eu-west-1.amazonaws.com/public/logoImages/";
export const EXTRERNAL_IMAGE_PATH = "https://employee-external-tests.s3.eu-west-1.amazonaws.com/public/";

export const MESSAGE_TYPE = {
  default: "default",
  info: "info",
  success: "success",
  warning: "warning",
  danger: "danger",
  error: "error",
  input: "input",
  custom: "custom",
  controlled: "controlled",
};

export const API_ACTIONS = {
  fulfilled: "fulfilled",
  rejected: "rejected",
  pending: "pending",
};

export const MESSAGE_MODES = {
  info: "info",
  error: "error",
  success: "success",
};

export const TEST_HEADER_TYPE = {
  unprocessed: "unprocessed",
  completed: "completed",
  pending: "pending",
};

export const TEST_STATUS = {
  Sent: "Sent",
  Processing: "Processing",
  Pending: "Pending",
  Processed: "Processed",
};

export const EMPLYEE_STATUS = {
  active: "Active",
  inactive: "In Active",
};

export const NPI_TYPE_OPTIONS = [
  {
    label: "NPI-1",
    value: "NPI-1",
  },
  {
    label: "NPI-2",
    value: "NPI-2",
  },
];

export const newRoleOptions = [
  {
    value: "Admins",
    label: "Medflow Admin",
  },
  {
    value: "Clients",
    label: "Client Admin",
  },
  {
    value: "Employers",
    label: "Facility/Agent Admin",
  },
  {
    value: "SubAgents",
    label: "Sub Agent Admin",
  },
];

export const newRoleOptionsForLab = [
  {
    value: "Admins",
    label: "Medflow Admin",
  },
  {
    value: "Clients",
    label: "Client Admin",
  },
];

export const claimActions = {
  receive: "receive",
  return: "return",
};

export const CLAIM_SUBMIT_STATUS = {
  draft: "draft",
  submitted: "submitted",
  rejected: "rejected",
  approved: "approved",
  paid: "paid",
  denied: "denied",
  cancelled: "cancelled",
  partial: "partial",
  rejectedanddenied: "rejectedanddenied",
  unpaid: "unpaid",
  sent: "sent",
};

export const CLAIM_EDIT_STATUS = {
  draft: "draft",
  submitted: "submitted",
  rejected: "rejected",
  approved: "approved",
  paid: "paid",
  denied: "denied",
  cancelled: "cancelled",
  partial: "partial",
  rejectedanddenied: "rejectedanddenied",
  unpaid: "unpaid",
  sent: "submitted",
};

export const CLAIM_STATUS_OPT = [
  {
    value: "draft",
    label: "Draft",
  },
  { value: "submitted", label: "Sent" },
  { value: "rejected", label: "Rejected" },
  { value: "approved", label: "Approved" },
  { value: "paid", label: "Paid" },
  { value: "denied", label: "Denied" },
  { value: "cancelled", label: "Cancelled" },
];

export const INVOICE_STATUS_OPT = [
  { value: "pending", label: "Pending" },
  { value: "1st Attempt", label: "1st Attempt" },
  { value: "2nd Attempt", label: "2nd Attempt" },
  { value: "3rd Attempt", label: "3rd Attempt" },
  { value: "collection", label: "collection" },
  //{ value: "sent", label: "Sent" },
  //{ value: "paid", label: "Paid" },
  //{ value: "partial", label: "Partial" },
  { value: "cancelled", label: "Cancelled" },
];

export const claimSubmitStatusColorOpts = {
  open: "gray",
  draft: "gray",
  submitted: "#3939ff",
  "1st Attempt": "#3939ff",
  "2nd Attempt": "#3939ff",
  "3rd Attempt": "#3939ff",
  partial: "green",
  rejected: "red",
  collection: "#3939ff",
  approved: "green",
  sent: "green",
  paid: "green",
  denied: "#ff5f5f",
  cancelled: "#95614c",
  sent: "#3939ff",
  pending: "orange",
};

export const EMP_CLAIM_BLANK_OPTS = {
  "First Name": "pat_name_f",
  "Last Name": "pat_name_l",
  Email: "pat_email",
  "Phone Number": "pat_phone",
  Address: "pat_addr_1",
  "Apt/Unit#": "pat_addr_2",
  City: "pat_city",
  State: "pat_state",
  "Zip Code": "pat_zip",
  "Date Of Birth": "pat_dob",
  Gender: "pat_sex",
  Insurance: "payer_name",
  "Insurance #": "ins_number",
  "Claim ID": "claimNo",
  "Claim Amount": "total_charge",
  "Adjusted Amount": "adjustedAmount",
  "Paid Amount": "paidAmount",
  DOS: "from_date_1",
  Status: "status",
  "LTCS ID": "pcn",
  "B-ID": "remote_claimid",
  "Processed By": "paid_insurance",
};

export const claimActionOptions = [
  { value: "receive", label: "Receive" },
  { value: "return", label: "Refund" },
];

export const USER_ROLE_TYPE = {
  Admin: "Admin",
  Coordinator: "Coordinator",
  Staff: "Staff",
  Employee: "Employee",
};

export const USER_ROLE_TITLE = {
  Admins: "Medflow Admin",
  Employers: "Facility/Agent Admin",
  Clients: "Client Admin",
  SubAgents: "Sub Agent Admin",
};

export const FILTER_USER_ROLE = {
  Admins: ["Admins", "Clients", "Employers", "SubAgents"],
  Clients: ["Clients", "Employers", "SubAgents"],
  Employers: ["Employers", "SubAgents"],
};

export const HEALTH_AND_SAFTEY = "Health & Safety";

export const EmptyUserObj = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  isNew: true,
  countryCode: "",
  autoShipment: false,
  isMemberLogin: "N",
  locationID: "",
  picture: "",
  dob: "",
  medicalNo: "",
  insruanceProvider: "",
  country: "United States",
  isoCode: "US",
  insuranceDetails: [],
  note: "",
  status: "active",
  isSecondary: "N",
  primaryInsurance: {
    copay: 0,
    deductible: 0,
  },
  connectedTo: [],
};

export const NEW_PROVIDER_OBJECT = {
  name: "",
  firstName: "",
  middleName: "",
  lastName: "",
  code: "",
  npi: "",
  taxid: "",
  speciality: "",
  email: "",
  phoneNumber: "",
  street: "",
  street2: "",
  city: "",
  state: "",
  isActive: true,
};
export const TEST_TYPE_VALUE = {
  Lucira: "Lucira",
  PCR: "PCR",
  Antigen: "Rapid Antigen",
  Other: "Rapid PCR",
  Molecular: "Cue",
};

export const AMAZON_CLIENT_RESULT = {
  Positive: "Fail",
  Negative: "Pass",
  Expired: "Expired",
  positive: "Fail",
  negative: "Pass",
  expired: "Expired",
  Pass: "Pass",
  Fail: "Fail",
  pass: "Pass",
  fail: "Fail",
};

export const RESULT_COLOR = {
  positive: "red",
  negative: "green",
  expired: "orange",
  pass: "green",
  fail: "red",
};
export const customData = [
  {
    value: "custom",
    label: "custom",
  },
  {
    value: "custom1",
    label: "custom1",
  },
];

export const CLI_DEFAULT_OPTIONS = {
  yes: "Yes",
  no: "No",
};

export const NEW_CLI_ITEM = {
  cliCode: "",
  isDefault: CLI_DEFAULT_OPTIONS.no,
};

export const CLI_ACTIONS = {
  add: "add",
  delete: "delete",
  update: "update",
};

export const weekDaysOptions = [
  {
    value: "0",
    label: "SUN",
  },
  {
    value: "1",
    label: "MON",
  },
  {
    value: "2",
    label: "TUE",
  },
  {
    value: "3",
    label: "WED",
  },
  {
    value: "4",
    label: "THU",
  },
  {
    value: "5",
    label: "FRI",
  },
  {
    value: "6",
    label: "SAT",
  },
];

export const TestTypeOptions = [
  {
    value: "Antigen",
    label: "Antigen",
  },
  {
    value: "Molecular",
    label: "Molecular",
  },
];
export const AUTO_SHIP_OPTIONS = [
  {
    value: "1",
    label: "Yes",
  },
  {
    value: "0",
    label: "No",
  },
];

export const WEEK_DAYS = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export const SUPER_ADMIN_ID = "20cafd4b-2671-4854-8389-4f185d60c69b";

export const EmptyEmployeeObj = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  isNew: true,
};

export const QuestionStatus = {
  new: 1,
  edit: 2,
  delete: 3,
};

export const INSURANCE_TYPE = {
  primary: "primary",
  secondary: "secondary",
};

export const SCHEDULE_TYPES = {
  everyday: "Every Day",
  selecteddays: "Selected Days",
  untilcancel: "Until Cancel",
};

export const QUESTION_GROUP_TYPES = {
  questionandtesting: {
    value: 1,
    label: "Question/Testing",
    shortCode: "QT",
  },
  question: { value: 2, label: "Question", shortCode: "Q" },
  testing: { value: 3, label: "Testing", shortCode: "T" },
};

export const LOCATION_OPTIONS = [
  { value: "Cupertino", label: "Cupertino" },
  { value: "Los Angeles", label: "Los Angeles" },
  { value: "Austin", label: "Austin" },
  { value: "Nashville", label: "Nashville" },
  { value: "New York", label: "New York" },
  { value: "London", label: "London" },
  { value: "Singapore", label: "Singapore" },
  { value: "Tokyo", label: "Tokyo" },
];

export const REGION_OPTIONS = [
  { value: "Americas", label: "Americas" },
  { value: "EMEA", label: "EMEA" },
  { value: "Asia Pac", label: "Asia Pac" },
  { value: "Japan", label: "Japan" },
];

export const TAX_IDS = [
  { value: "E", label: "EIN" },
  { value: "S", label: "SSN" },
];

export const GROUP_TYPES = {
  1: { value: 1, label: "Question/Testing", shortCode: "QT" },
  2: { value: 2, label: "Question", shortCode: "Q" },
  3: { value: 3, label: "Testing", shortCode: "T" },
};
export const CLAIM_SUBMIT = [
  {
    value: "1",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon />
      </div>
    ),
  },
  {
    value: "0",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon />
      </div>
    ),
  },
];
export const CLAIM_STATUS = [
  {
    value: "Accepted",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon />
      </div>
    ),
  },
  {
    value: "Rejected",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon />
      </div>
    ),
  },
];

export const ELIGIBILITY_STATUS = [
  {
    value: "eligibile",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon />
      </div>
    ),
  },
  {
    value: "invalid",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon />
      </div>
    ),
  },
  {
    value: "not checked",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="yellow" questionIcon />
      </div>
    ),
  },
];

export const NOTE_FILTER = [
  {
    value: "0",
    label: "Empty",
  },
  {
    value: "1",
    label: (
      <div className="action-exclamation">
        <span
          className="cursor-pointer"
          style={{
            borderColor: "rgb(83 83 232)",
          }}
        >
          <img className="notesIcon" src={notesOnIcon} alt="notes-on-img" />
        </span>
      </div>
    ),
  },
];

export const INSURANCE_STATUS = [
  {
    value: "Pending",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" crossIcon />
      </div>
    ),
  },
  {
    value: "Approved",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="lightgreen" crossIcon />
      </div>
    ),
  },
  {
    value: "Paid",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon />
      </div>
    ),
  },
  {
    value: "Denied",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon />
      </div>
    ),
  },
];

export const SENT_INVOICE_OPT = [
  {
    value: "",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="gray" crossIcon />
      </div>
    ),
  },
  {
    value: "pending",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="orange" crossIcon />
      </div>
    ),
  },
  {
    value: "sent",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon />
      </div>
    ),
  },
];

export const STATUS_OPTIONS = [
  {
    value: "inactive",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon />
      </div>
    ),
  },
  {
    value: "active",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon />
      </div>
    ),
  },
];
export const ZONE_STATUS_OPTIONS = [
  { value: "0", label: "Empty" },
  {
    value: "3",
    label: <ViewCheckBox id="filter" bgColor="green" />,
  },
];

export const QA_OPTIONS = [
  { value: "0", label: "Empty" },
  { value: "1", label: <ViewCheckBox id="filter" bgColor="green" /> },
  { value: "M", label: <ViewCheckBox id="filter" bgColor="blue" /> },
  { value: "X", label: <div style={{ color: "red" }}>X</div> },
];
export const TEST_OPTIONS = [
  { value: "0", label: "Empty" },
  { value: "1", label: <ViewCheckBox id="filter" bgColor="green" /> },
  { value: "X", label: <div style={{ color: "red" }}>X</div> },
];
export const CHECKIN_OPTIONS = [
  { value: "0", label: "Empty" },
  { value: "1", label: <ViewCheckBox id="filter" bgColor="green" /> },
];

export const TEST_TYPES = [
  { value: "Molecular", label: "Cue" },
  { value: "PCR", label: "PCR" },
  { value: "Antigen", label: "Rapid Antigen" },
  { value: "Other", label: "Rapid PCR" },
  { value: "Lucira", label: "Lucira" },
];

export const ORDER_STATUS_OPT = [
  { value: "Pending", label: "Pending" },
  { value: "Sent", label: "Sent" },
  { value: "Delivered", label: "Delivered" },
  { value: "Returned", label: "Returned" },
];

export const VACCINATED_OPTIONS = [
  { value: "0", label: "No" },
  { value: "1", label: "Yes" },
];

export const ELIGIBLE_STATUS = [
  {
    value: "1",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon />
      </div>
    ),
  },
  {
    value: "0",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon />
      </div>
    ),
  },
];
export const INJURY_OPTIONS = [
  {
    value: "y",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" tickIcon /> Yes
      </div>
    ),
  },
  {
    value: "n",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon /> No
      </div>
    ),
  },
];

export const ON_LOCATION_OPT = [
  { value: "onLocation", label: "onLocation" },
  { value: "At Home", label: "At Home" },
];
export const MESSAGE_SEND_TYPE = [
  { value: "E", label: "Email" },
  { value: "M", label: "Text Message" },
];
export const MESSAGE_TEMPLATE_CATGORY = [
  { value: "Appeal", label: "Appeal" },
  { value: "Invoice", label: "Invoice" },
];
export const SCHEDULE_TYPE_EVERY_DAY = "everyday";
export const SCHEDULE_TYPE_SELECTED_DAYS = "selecteddays";
export const SCHEDULE_TYPE_UNTIL_CANCEL = "untilcancel";

export const AssignGroup_Type_Question_Testing = "questionandtesting";
export const AssignGroup_Type_Question_Only = "question";
export const AssignGroup_Type_testing_Only = "testing";

export const USER_TYPE_USER = "user";
export const USER_TYPE_ADMIN = "admin";
export const USER_TYPE_CLIENT = "client";
export const USER_TYPE_SUBAGENT = "subagent";

export const DAYS_LABEL = {
  0: "SUN",
  1: "MON",
  2: "TUE",
  3: "WED",
  4: "THU",
  5: "FRI",
  6: "SAT",
};
export const COLOR_OPT = [
  { value: "Black", label: <ColorLabel color={"Black"} /> },
  { value: "Green", label: <ColorLabel color={"Green"} /> },
  { value: "Blue", label: <ColorLabel color={"Blue"} /> },
  { value: "Gray", label: <ColorLabel color={"Gray"} /> },
  { value: "Brown", label: <ColorLabel color={"Brown"} /> },
  { value: "Deep Pink", label: <ColorLabel color={"Deep Pink"} /> },
  { value: "Purple", label: <ColorLabel color={"Purple"} /> },
  { value: "Orange", label: <ColorLabel color={"Orange"} /> },
];

export const COLOR_VALUE = {
  black: "Black",
  green: "Green",
  blue: "Blue",
  gray: "Gray",
  pink: "Deep Pink",
  purple: "Purple",
  orange: "Orange",
};

export const EMPLOYEE_HEADERS = ["firstName", "lastName", "phoneNumber"];

export const objTypes = {
  isVaccinated: "BOOL",
  isNew: "BOOL",
  vaccinationCardUpload: "BOOL",
  isBooster: "BOOL",
  isExternalTest: "BOOL",
  unionNo: "BOOL",
  testOrdered: "N",
  totalOrderd: "N",
  testAvailable: "N",
  isSignUp: "N",
  paidAmount: "N",
  units: "N",
  autoShipment: "BOOL",
  allowedPayment: "N",
  total_paid: "N",
  status_code: "N",
  adjustedAmount: "N",
  eligibilityStatus: "M",
};

export const AdditionalKeys = [
  "_lastChangedAt",
  "_version",
  "updatedAt",
  "createdAt",
  "updatedBy",
  "updatedByName",
  "clientID",
  "SubAgentID",
  "locationID",
  "claimId",
  "userId",
  "seenById",
  "SubmittedBy",
  "diag_1",
  "diag_2",
  "diag_3",
  "diag_4",
  "diag_5",
  "diag_6",
  "diag_7",
  "diag_8",
  "status_code",
  "adjustedAmount",
  "submissionDate",
  "acknowledgeBy",
  "submittedBy",
  "reSubmissionDate",
  "submittedByName",
  "status",
  "note",
  "id",
  "ref_id",
  "ref_npi",
  "ref_name_l",
  "ref_name_f",
  "ref_name_m",
  "prov_id",
  "prov_name_f",
  "prov_name_l",
  "prov_name_m",
  "ord_prov_id",
  "ord_prov_name_f",
  "ord_prov_npi",
  "ord_prov_name_l",
  "ord_prov_name_m",
  "bill_addr_1",
  "bill_addr_1",
  "bill_addr_2",
  "bill_city",
  "bill_id",
  "bill_name",
  "bill_npi",
  "bill_phone",
  "bill_state",
  "bill_taxid",
  "bill_taxid_type",
  "bill_zip",
  "narrative_1",
  "narrative_2",
  "employment_related",
  "payer_order",
  "remote_claimid",
  "remote_fileid",
  "remote_batchid",
  "charge_1",
  "diag_ref_1",
  "claimNo",
  "insuranceProcessDate",
  "plan_type",
  "patient_responsibility",
  "crossover_id",
  "status_code",
  "filing_code",
  "requestedBy",
  "requestedByName",
  "proc_array",
  "provider",
  "eraIds",
  "paid_insurance",
  "assign_details",
  "updatedSource",
  "referringProvider",
  "renderingProvider",
];

export const fieldLabel = {
  firstName: "First Name",
  lastName: "Last Name",
  phoneNumber: "Phone Number",
  email: "Email",
  sex: "Gender",
  jobTitle: "Job Title",
  picture: "Picture",
  dob: "DOB",
  street: "Address",
  street2: "Apt/Unit #",
  city: "City",
  state: "State",
  country: "Country",
  zip: "Zip Code",
  insuranceCompany: "Insurance Provider",
  medicalNo: "Insurance Number",
  medicareNo: "Medicare Number",
  insuranceGroupId: "Insurance Group ID",
  testOrdered: "Test Ordered",
  totalOrderd: "Total Test Ordered",
  testAvailable: "Test Available",
  autoShipment: "Auto Shipment",
  ClientName: "Client",
  AgentName: "Facility/Agent",
  SubAgentName: "SubAgent",
  note: "Note",
  status: "Status",
  eligibilityStatus: "Eligibility Status",
  pat_name_f: "First Name",
  pat_name_l: "Last Name",
  pat_name_m: "Middle Name",
  pat_name_phone: "Phone Number",
  pat_email: "Email",
  pat_sex: "Gender",
  pat_state: "State",
  pat_city: "City",
  pat_addr_1: "Patient Address",
  pat_addr_2: "Patien Apt/Unit",
  pat_addr: "Patient Address",
  pat_zip: "zip",
  payer_name: "Insurance",
  payerid: "Insurnace",
  ins_number: "Insurance Number",
  clia_number: "CLIA Number",
  diag_1: "Diag 1",
  diag_2: "Diag 2",
  diag_3: "Diag 3",
  diag_4: "Diag 4",
  diag_5: "Diag 5",
  diag_6: "Diag 6",
  diag_7: "Diag 7",
  diag_8: "Diag 8",
  mod1: "Modifier 1",
  mod2: "Modifier 2",
  mod3: "Modifier 3",
  mod4: "Modifier 4",
  place_of_service: "POS",
  prov_name: "Rendering Provider",
  ord_prov_name: "Ordering Provider",
  ref_name: "Referring Physician",
  message: "Message",
  from_date_1: "Date of Services",
  payer_icn: "Claim No",
  total_charge: "Total Charge",
  units: "Units",
  paymentCheckNo: "Payment Cheque No",
  checkDate: "Cheque Date",
  referringPyhsician: "Refferring Physician",
  insuranceCompanyCode: "Insurance Name",
  insuranceGroupId: "Group ID",
  medicareNo: "Medicare No",
  seenByName: "Acknowledge By",
  acknowledgeBy: "Acknowledge By",
  orderDate: "Claim Date",
  name: "User Name",
  posDesc: "POS Desc",
  place_of_service: "POS Code",
  proc_code: "CPT Code",
  reSubmissionDate: "Re Submission Date",
  proc_desc: "CPT Desc",
  pat_country: "Country",
  prov_taxid: "Provider TaxID",
  submittedByName: "Submitted By Name",
  paidAmount: "Paid Amount",
  adjustedAmount: "Adjusted Amount",
  total_paid: "Total Paid",
  allowedPayment: "Allowed Payment",
  charge: "Charge",
  from_date: "DOS",
  fileid: "Fileid",
  claimmd_id: "Claim ID",
  accept_assign: "Accept Assign",
  paymentMethod: "Payment Method",
  paymentCheckNo: "Payment Cheque No",
  checkDate: "Cheque Date",
  allowedPayment: "Allowed Payment",
  paidAmount: "Paid Amount",
  adjustedAmount: "Adjusted Amount",
  overPaidAmount: "Over Paid Amount",
  total_paid: "Total Paid",
  crossover_carrier: "CrossOver Carrier",
  claim_received_date: "Claim Received Date",
  era: "ERA",
};

export const TABLE_COLUMN_TYPES = {
  shortDate: "shortDate",
  text: "text",
  upperCaseText: "upperCaseText",
  email: "email",
  phone: "phone",
  currency: "currency",
  image: "image",
  insuranceProvider: "insuranceProvider",
  number: "number",
  percentage: "percentage",
  labelID: "labelID",
  link: "link",
  statusFormatter: "statusFormatter",
};

export const CHECKBOX_ICON_OPTIONS = {
  cross: "crossIcon",
  tick: "tickIcon",
  question: "questionIcon",
};

export const CHECKBOX_SHAPES = {
  square: "square",
  circle: "circle",
};

export const CHECKBOX_SIZES = {
  sm: "sm",
  md: "md",
  lg: "lg",
};

export const CHECKBOX_COLORS = {
  maroon: "maroon",
  green: "green",
  lightgreen: "lightgreen",
  blue: "blue",
  yellow: "yellow",
  orange: "orange",
  gray: "gray",
};

export const TABLE_QUICK_TOOLS = {
  checkbox: "checkbox",
  edit: "edit",
  notes: "notes",
  delete: "delete",
};

export const applyCustomOrder = (arr, desiredOrder) => {
  const orderForIndexVals = desiredOrder.reverse();
  return arr.sort((a, b) => {
    const aIndex = -orderForIndexVals.indexOf(a);
    const bIndex = -orderForIndexVals.indexOf(b);
    return aIndex - bIndex;
  });
};

export const LOCATION_HEADERS = [
  "name",
  // "contact_name",
  "contact_first_name",
  "contact_last_name",
  // "phone_number",
  "main_phone_number",
  "contact_email",
];

export const ELIGIBILITY_HEADERS = ["firstName", "lastName", "dob", "medicareNo", "medicareAgent"];

export const LOCATION = ["cupertino", "los angeles", "austin", "nashville", "new york", "london", "singapore", "tokyo"];
export const REGION = ["americas", "emea", "asia pac", "japan"];

export const TIMEZONE_OPT = [
  { label: "Eastern Standard Time", value: "Eastern Standard Time" },
  { label: "Central Standard Time", value: "Central Standard Time" },
  { label: "Mountain Standard Time", value: "Mountain Standard Time" },
  { label: "Pacific Standard Time", value: "Pacific Standard Time" },
  { label: "Pakistan Standard Time", value: "Pakistan Standard Time" },
];

export const ALERT_SETTINGS_TRIGGER_OPTIONS = [
  { value: "Daily", label: "Daily" },
  { value: "Weekly", label: "Weekly" },
];

export const IMAGE_UPLOAD_TYPE = {
  frontCard: "front_card",
  backCard: "back_card",
};

export const TIMEZONE_VALUE = {
  est: "Eastern Standard Time",
  cst: "Central Standard Time",
  mst: "Mountain Standard Time",
  pst: "Pacific Standard Time",
  pkt: "Pakistan Standard Time",
};

export const SCHEDULE_CATEGORY = {
  TESTING: "Testing",
  QUESTIONS: "Question",
};

export const TEST_ORDER_LIMIT_CATEGORY = [
  { label: "Per Week", value: "0" },
  { label: "Per Month", value: "1" },
  { label: "Per Year", value: "2" },
];

export const GENDER_OPTIONS = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
];

export const REPORT_TYPES_OPTIONS = [
  { value: "transaction", label: "Transaction Report" },
  { value: "sale", label: "Sales Report" },
  { value: "payment", label: "Payment Report" },
  { value: "daily", label: "Daily Report" },
];

export const REPORT_NAME = {
  transaction: "Transaction Report",
  sale: "Sales Report",
  payment: "Payment Report",
  daily: "Daily Report",
};

export const REPORT_CLAIM_STATUS_OPTIONS = [
  { value: "posted", label: "Posted" },
  { value: "open", label: "Open" },
  { value: "cancelled", label: "Cancelled" },
  { value: "draft", label: "Draft" },
];

export const BILLING_ENTITY_REPORT = [
  { value: "all", label: "All" },
  { value: "1043366164", label: "Vista" },
  { value: "1700913118", label: "Bio" },
];

export const PAYMENT_TYPE_REPORT = [
  { value: "all", label: "All" },
  { value: "ERA", label: "EFT" },
  { value: "EOB", label: "EOB" },
  { value: "cash", label: "Cash" },
  { value: "credit_card", label: "Credit Card" },
  { value: "other", label: "Other" },
];

export const SORTING_REPORT = [
  { value: "clientID", label: "Client ID" },
  { value: "clientName", label: "Client Name" },
  { value: "claimDate", label: "DOC" },
  { value: "paymentDate", label: "DOP" },
  { value: "serviceDate", label: "DOS" },
  { value: "salePersonId", label: "Sale Person ID" },
  { value: "firstName", label: "Sale Person Name" },
];

// SUM(CASE WHEN cab.payerid = '01192' OR cab.payerid = '00882' THEN cab.${amountColumn} ELSE 0 END) AS medicare,
// SUM(CASE WHEN cab.payerid = '57016' THEN cab.${amountColumn} ELSE 0 END) AS medical,
// SUM(CASE WHEN cab.payerid NOT in ('00001','00002','01192','57016','00882')  THEN cab.${amountColumn} ELSE 0 END) AS private,
// SUM(CASE WHEN cab.payerid = '00001' THEN cab.${amountColumn} ELSE 0 END) AS patient,
// SUM(CASE WHEN cab.payerid = '00002' THEN cab.${amountColumn} ELSE 0 END) AS client

export const BILL_TYPE = [
  { label: "All", value: "all" },
  { value: "Medicare", label: "Medicare" },
  { value: "Medical", label: "Medical" },
  { value: "Private", label: "Private" },
  { value: "BillClient", label: "Bill Client" },
  { value: "BillPatient", label: "Bill Patient/Cash" },
];

export const CLAIM_STATUS_REPORT = [
  { label: "All", value: "all" },
  { value: "approved", label: "Approved" },
  { value: "cancelled", label: "Cancelled" },
  { value: "denied", label: "Denied" },
  { value: "draft", label: "Draft" },
  { value: "paid", label: "Paid" },
  { value: "rejected", label: "Rejected" },
  { value: "sent", label: "Sent" },
];

export const OPEN_STATUS_REPORT_AMOUNT_OPTIONS = [
  { value: "expected", label: "Expected Amount" },
  { value: "billed", label: "Billed Amount" },
];

export const TEST_ORDER_LIMIT_CATEGORY_VALUE = {
  0: "Per Week",
  1: "Per Month",
  2: "Per Year",
};

export const SCHEDULE_OPTIONS = [
  {
    id: 0,
    name: "Sunday",
    isChecked: false,
    testType: [
      {
        value: "",
        label: "",
        location: "",
        isDone: false,
        scheduleCategory: "Testing",
        result: "",
      },
    ],
  },
  {
    id: 1,
    name: "Monday",
    isChecked: false,
    testType: [
      {
        value: "",
        label: "",
        location: "",
        isDone: false,
        scheduleCategory: "Testing",
        result: "",
      },
    ],
  },
  {
    id: 2,
    name: "Tuesday",
    isChecked: false,
    testType: [
      {
        value: "",
        label: "",
        location: "",
        isDone: false,
        scheduleCategory: "Testing",
        result: "",
      },
    ],
  },
  {
    id: 3,
    name: "Wednesday",
    isChecked: false,
    testType: [
      {
        value: "",
        label: "",
        location: "",
        isDone: false,
        scheduleCategory: "Testing",
        result: "",
      },
    ],
  },
  {
    id: 4,
    name: "Thursday",
    isChecked: false,
    testType: [
      {
        value: "",
        label: "",
        location: "",
        isDone: false,
        scheduleCategory: "Testing",
        result: "",
      },
    ],
  },
  {
    id: 5,
    name: "Friday",
    isChecked: false,
    testType: [
      {
        value: "",
        label: "",
        location: "",
        isDone: false,
        scheduleCategory: "Testing",
        result: "",
      },
    ],
  },
  {
    id: 6,
    name: "Saturday",
    isChecked: false,
    testType: [
      {
        value: "",
        label: "",
        location: "",
        testNow: false,
        isDone: false,
        scheduleCategory: "Testing",
        result: "",
      },
    ],
  },
];

export const DAYS = [0, 1, 2, 3, 4, 5, 6];

export const CONFIRMATION_TYPE = {
  DELETE: "delete",
  PROGRAM_START: "programstart",
  ASSIGN_PROGRAM: "assignprogram",
  UPDATE_TIME: "updateTime",
  AUTO_SHIPMENT: "autoshipment",
  PUBLIC_ORDER: "publicOrder",
  SUBMIT_CLAIM: "submitClaim",
  RESUBMIT_CLAIM: "reSubmitClaim",
  UPDATE_PATIENT: "updatePatient",
  CREATE_PATIENT: "createPatient",
  RESTORE_CLAIM: "restoreClaim",
  ELIGIBILITY_FAIL: "eligibilityFail",
  DRAFT_SAVE: "draftSave",
  CANCELLED_INVOICE: "cancelledInvoice",
  INVOICE_UPDATE_SINGLE: "invoiceUpdateSingle",
  INVOICE_UPDATE: "invoiceUpdate",
  updateERA: "updateERA",
};

export const DAILY_QA_STATUS = {
  X: false,
  1: true,
  M: true,
  0: null,
};

export const Audit_Entries = [
  "Member Profile Created",
  "Member Profile Edited",
  "Eligibility",
  "Test Ordered",
  "Order Limit Reset",
  "Insurance Status",
  "Claim Status",
  "Claim",
  "Status",
  "Acknowledge",
  "Document",
];

export const FILE_TYPES_OPT = [
  { value: "check", label: "Check Image" },
  { value: "EOB", label: "EOB" },
];

export const Audit_Types = {
  OrderPlaced: "Test Ordered",
  OrderShipped: "Test Ordered",
  OrderDelivered: "Test Ordered",
  OrderReturn: "Test Ordered",
  Eligibility: "Eligibility",
  MemberUpdated: "Member Profile Edited",
  MemberCreated: "Member Profile Created",
  OrderLimitReset: "Order Limit Reset",
  insuranceStatus: "Insurance Status",
  claimStatus: "Claim Status",
  ClaimPartiallyProcessed: "Claim",
  ERAUpdate: "Claim",
  SubmissionFailed: "Claim",
  SendToInsurance: "Claim",
  ClaimCreated: "Claim",
  ClaimUpdated: "Claim",
  SubmitForBill: "Claim",
  RESubmitForBill: "Claim",
  ClaimRejected: "Claim",
  ClaimCancelled: "Claim",
  ClaimRestored: "Claim",
  ClaimDenied: "Claim",
  ClaimWriteOff: "Claim",
  ClaimInvoice: "Claim",
  InvoiceSent: "Claim",
  OrderUpdate: "Order Update",
  UpdateStatus: "Status",
  isAcknowledge: "Acknowledge",
  isAssigned: "Assigned",
  CreatedDocument: "Document",
  UpdateDocument: "Document",
  DeletedDocument: "Document",
};

export const Audit_Parse = {
  OrderPlaced: "Order Placed",
  OrderShipped: "Order Shipped",
  OrderReturned: "Order Returned",
  OrderOrderReturn: "Order Returned",
  OrderDelivered: "Order Delivered",
  Eligibility: "Eligibility Checked",
  MemberUpdated: "Member Edited",
  MemberCreated: "New Patient",
  SubmitForBill: "Claim Submitted",
  RESubmitForBill: "Claim Re-Submitted",
  ERAUpdate: "ERA Update",
  ClaimDenied: "Claim Denied",
  OrderLimitReset: "Order Limit Reset",
  insuranceStatus: "Insurance Status",
  claimStatus: "Claim Status",
  claimStatusRejected: "Claim Rejected",
  claimRejected: "Claim Rejected",
  ClaimCancelled: "Claim Cancelled",
  ClaimRestored: "Claim Restored",
  ClaimRejected: "Claim Rejected",
  claimApproved: "Claim Approved",
  SendToInsurance: "Send To Insurance",
  SubmissionFailed: "Submission Failed",
  ClaimDenied: "Claim Denied",
  ClaimWriteOff: "Write Off",
  ClaimInvoice: "Invoice Generated",
  InvoiceSent: "Invoice Sent",
  InvoiceCancel: "Invoice Cancelled",
  ClaimPartiallyProcessed: "Claim Partially Processed",
  claimAmountReceived: "Claim Paid",
  claimAmountReturned: "Claim Returned",
  ClaimCreated: "Claim Created",
  ClaimUpdated: "Claim Updated",
  CreatedDocument: "Document Created",
  UpdateDocument: "Document Updated",
  DeletedDocument: "Document Deleted",
  OrderUpdate: "Order Update",
  Note: "Note",
  isAcknowledge: "Acknowledge",
  UpdateStatus: "Status",
  isAssigned: "Assigned",
  ClientUpdated: "Client Updated",
  ClientCreated: "Client Created",
  PayerCreated: "Payer Created",
  PayerUpdated: "Payer Updated",
  ProviderUpdated: "Provider Updated",
  ProviderCreated: "Provider Created",
};

export const OrderStatusEvents = {
  Sent: "Shipped",
  Delivered: "Delivered",
  Returned: "Returned",
};
export const HISTORY_TEST = [
  {
    date: "2022-09-12",
    id: "3031b1ba-7101-4ff7-b5e1-e2c01768426d",
    isDone: false,
    label: "PCR",
    location: "On Location",
    lucira: false,
    result: "Positive",
    scheduleCategory: "Testing",
    value: "PCR",
  },
  {
    date: "2022-09-06",
    id: "31ebc832-6f1a-4cd3-aa94-4fbf9d87c41f",
    isDone: false,
    label: "Rapid PCR",
    location: "On Location",
    lucira: false,
    result: "Negative",
    scheduleCategory: "Testing",
    value: "Other",
  },
  {
    date: "2022-09-12",
    id: "c4cbb706-8a15-4996-9790-91fb9cd48cb1",
    isDone: false,
    label: "Cue",
    location: "On Location",
    lucira: false,
    result: "Invlaid",
    scheduleCategory: "Testing",
    value: "Molecular",
  },
];

export const DAYS_NAMES = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const STATUS = {
  assignProgram: 2,
  startProgram: 1,
  zoneAssign: 3,
  removeProgram: 0,
};

export const AltaSetting = {
  name: "ALTA DIAGNOSTIC LABORATORIES, INC",
  npi: "1063180354",
  city: "ALTADENA",
  state: "CA",
  street: "2268 LINCOLN AVE",
  street2: "",
  taxid: "871701212",
  zip: "910015432",
};

export const countryListAllIsoData = [
  { code: "AF", code3: "AFG", name: "Afghanistan", number: "004" },
  { code: "AL", code3: "ALB", name: "Albania", number: "008" },
  { code: "DZ", code3: "DZA", name: "Algeria", number: "012" },
  { code: "AS", code3: "ASM", name: "American Samoa", number: "016" },
  { code: "AD", code3: "AND", name: "Andorra", number: "020" },
  { code: "AO", code3: "AGO", name: "Angola", number: "024" },
  { code: "AI", code3: "AIA", name: "Anguilla", number: "660" },
  { code: "AQ", code3: "ATA", name: "Antarctica", number: "010" },
  { code: "AG", code3: "ATG", name: "Antigua and Barbuda", number: "028" },
  { code: "AR", code3: "ARG", name: "Argentina", number: "032" },
  { code: "AM", code3: "ARM", name: "Armenia", number: "051" },
  { code: "AW", code3: "ABW", name: "Aruba", number: "533" },
  { code: "AU", code3: "AUS", name: "Australia", number: "036" },
  { code: "AT", code3: "AUT", name: "Austria", number: "040" },
  { code: "AZ", code3: "AZE", name: "Azerbaijan", number: "031" },
  { code: "BS", code3: "BHS", name: "Bahamas", number: "044" },
  { code: "BH", code3: "BHR", name: "Bahrain", number: "048" },
  { code: "BD", code3: "BGD", name: "Bangladesh", number: "050" },
  { code: "BB", code3: "BRB", name: "Barbados", number: "052" },
  { code: "BY", code3: "BLR", name: "Belarus", number: "112" },
  { code: "BE", code3: "BEL", name: "Belgium", number: "056" },
  { code: "BZ", code3: "BLZ", name: "Belize", number: "084" },
  { code: "BJ", code3: "BEN", name: "Benin", number: "204" },
  { code: "BM", code3: "BMU", name: "Bermuda", number: "060" },
  { code: "BT", code3: "BTN", name: "Bhutan", number: "064" },
  { code: "BO", code3: "BOL", name: "Bolivia", number: "068" },
  {
    code: "BQ",
    code3: "BES",
    name: "Bonaire, Sint Eustatius and Saba",
    number: "535",
  },
  { code: "BA", code3: "BIH", name: "Bosnia and Herzegovina", number: "070" },
  { code: "BW", code3: "BWA", name: "Botswana", number: "072" },
  { code: "BV", code3: "BVT", name: "Bouvet Island", number: "074" },
  { code: "BR", code3: "BRA", name: "Brazil", number: "076" },
  {
    code: "IO",
    code3: "IOT",
    name: "British Indian Ocean Territory",
    number: "086",
  },
  { code: "BN", code3: "BRN", name: "Brunei Darussalam", number: "096" },
  { code: "BG", code3: "BGR", name: "Bulgaria", number: "100" },
  { code: "BF", code3: "BFA", name: "Burkina Faso", number: "854" },
  { code: "BI", code3: "BDI", name: "Burundi", number: "108" },
  { code: "CV", code3: "CPV", name: "Cabo Verde", number: "132" },
  { code: "KH", code3: "KHM", name: "Cambodia", number: "116" },
  { code: "CM", code3: "CMR", name: "Cameroon", number: "120" },
  { code: "CA", code3: "CAN", name: "Canada", number: "124" },
  { code: "KY", code3: "CYM", name: "Cayman Islands", number: "136" },
  { code: "CF", code3: "CAF", name: "Central African Republic", number: "140" },
  { code: "TD", code3: "TCD", name: "Chad", number: "148" },
  { code: "CL", code3: "CHL", name: "Chile", number: "152" },
  { code: "CN", code3: "CHN", name: "China", number: "156" },
  { code: "CX", code3: "CXR", name: "Christmas Island", number: "162" },
  { code: "CC", code3: "CCK", name: "Cocos (Keeling) Islands", number: "166" },
  { code: "CO", code3: "COL", name: "Colombia", number: "170" },
  { code: "KM", code3: "COM", name: "Comoros", number: "174" },
  {
    code: "CD",
    code3: "COD",
    name: "Congo, The Democratic Republic of the",
    number: "180",
  },
  { code: "CG", code3: "COG", name: "Congo", number: "178" },
  { code: "CK", code3: "COK", name: "Cook Islands", number: "184" },
  { code: "CR", code3: "CRI", name: "Costa Rica", number: "188" },
  { code: "HR", code3: "HRV", name: "Croatia", number: "191" },
  { code: "CU", code3: "CUB", name: "Cuba", number: "192" },
  { code: "CW", code3: "CUW", name: "Curaçao", number: "531" },
  { code: "CY", code3: "CYP", name: "Cyprus", number: "196" },
  { code: "CZ", code3: "CZE", name: "Czechia", number: "203" },
  { code: "CI", code3: "CIV", name: "Côte d'Ivoire", number: "384" },
  { code: "DK", code3: "DNK", name: "Denmark", number: "208" },
  { code: "DJ", code3: "DJI", name: "Djibouti", number: "262" },
  { code: "DM", code3: "DMA", name: "Dominica", number: "212" },
  { code: "DO", code3: "DOM", name: "Dominican Republic", number: "214" },
  { code: "EC", code3: "ECU", name: "Ecuador", number: "218" },
  { code: "EG", code3: "EGY", name: "Egypt", number: "818" },
  { code: "SV", code3: "SLV", name: "El Salvador", number: "222" },
  { code: "GQ", code3: "GNQ", name: "Equatorial Guinea", number: "226" },
  { code: "ER", code3: "ERI", name: "Eritrea", number: "232" },
  { code: "EE", code3: "EST", name: "Estonia", number: "233" },
  { code: "SZ", code3: "SWZ", name: "Eswatini", number: "748" },
  { code: "ET", code3: "ETH", name: "Ethiopia", number: "231" },
  {
    code: "FK",
    code3: "FLK",
    name: "Falkland Islands (Malvinas)",
    number: "238",
  },
  { code: "FO", code3: "FRO", name: "Faroe Islands", number: "234" },
  { code: "FJ", code3: "FJI", name: "Fiji", number: "242" },
  { code: "FI", code3: "FIN", name: "Finland", number: "246" },
  { code: "FR", code3: "FRA", name: "France", number: "250" },
  { code: "GF", code3: "GUF", name: "French Guiana", number: "254" },
  { code: "PF", code3: "PYF", name: "French Polynesia", number: "258" },
  {
    code: "TF",
    code3: "ATF",
    name: "French Southern Territories",
    number: "260",
  },
  { code: "GA", code3: "GAB", name: "Gabon", number: "266" },
  { code: "GM", code3: "GMB", name: "Gambia", number: "270" },
  { code: "GE", code3: "GEO", name: "Georgia", number: "268" },
  { code: "DE", code3: "DEU", name: "Germany", number: "276" },
  { code: "GH", code3: "GHA", name: "Ghana", number: "288" },
  { code: "GI", code3: "GIB", name: "Gibraltar", number: "292" },
  { code: "GR", code3: "GRC", name: "Greece", number: "300" },
  { code: "GL", code3: "GRL", name: "Greenland", number: "304" },
  { code: "GD", code3: "GRD", name: "Grenada", number: "308" },
  { code: "GP", code3: "GLP", name: "Guadeloupe", number: "312" },
  { code: "GU", code3: "GUM", name: "Guam", number: "316" },
  { code: "GT", code3: "GTM", name: "Guatemala", number: "320" },
  { code: "GG", code3: "GGY", name: "Guernsey", number: "831" },
  { code: "GN", code3: "GIN", name: "Guinea", number: "324" },
  { code: "GW", code3: "GNB", name: "Guinea-Bissau", number: "624" },
  { code: "GY", code3: "GUY", name: "Guyana", number: "328" },
  { code: "HT", code3: "HTI", name: "Haiti", number: "332" },
  {
    code: "HM",
    code3: "HMD",
    name: "Heard Island and McDonald Islands",
    number: "334",
  },
  {
    code: "VA",
    code3: "VAT",
    name: "Holy See (Vatican City State)",
    number: "336",
  },
  { code: "HN", code3: "HND", name: "Honduras", number: "340" },
  { code: "HK", code3: "HKG", name: "Hong Kong", number: "344" },
  { code: "HU", code3: "HUN", name: "Hungary", number: "348" },
  { code: "IS", code3: "ISL", name: "Iceland", number: "352" },
  { code: "IN", code3: "IND", name: "India", number: "356" },
  { code: "ID", code3: "IDN", name: "Indonesia", number: "360" },
  {
    code: "IR",
    code3: "IRN",
    name: "Iran, Islamic Republic Of",
    number: "364",
  },
  { code: "IQ", code3: "IRQ", name: "Iraq", number: "368" },
  { code: "IE", code3: "IRL", name: "Ireland", number: "372" },
  { code: "IM", code3: "IMN", name: "Isle of Man", number: "833" },
  { code: "IL", code3: "ISR", name: "Israel", number: "376" },
  { code: "IT", code3: "ITA", name: "Italy", number: "380" },
  { code: "JM", code3: "JAM", name: "Jamaica", number: "388" },
  { code: "JP", code3: "JPN", name: "Japan", number: "392" },
  { code: "JE", code3: "JEY", name: "Jersey", number: "832" },
  { code: "JO", code3: "JOR", name: "Jordan", number: "400" },
  { code: "KZ", code3: "KAZ", name: "Kazakhstan", number: "398" },
  { code: "KE", code3: "KEN", name: "Kenya", number: "404" },
  { code: "KI", code3: "KIR", name: "Kiribati", number: "296" },
  {
    code: "KP",
    code3: "PRK",
    name: "Korea, Democratic People's Republic of",
    number: "408",
  },
  { code: "KR", code3: "KOR", name: "Korea, Republic of", number: "410" },
  { code: "KW", code3: "KWT", name: "Kuwait", number: "414" },
  { code: "KG", code3: "KGZ", name: "Kyrgyzstan", number: "417" },
  {
    code: "LA",
    code3: "LAO",
    name: "Lao People's Democratic Republic",
    number: "418",
  },
  { code: "LV", code3: "LVA", name: "Latvia", number: "428" },
  { code: "LB", code3: "LBN", name: "Lebanon", number: "422" },
  { code: "LS", code3: "LSO", name: "Lesotho", number: "426" },
  { code: "LR", code3: "LBR", name: "Liberia", number: "430" },
  { code: "LY", code3: "LBY", name: "Libya", number: "434" },
  { code: "LI", code3: "LIE", name: "Liechtenstein", number: "438" },
  { code: "LT", code3: "LTU", name: "Lithuania", number: "440" },
  { code: "LU", code3: "LUX", name: "Luxembourg", number: "442" },
  { code: "MO", code3: "MAC", name: "Macao", number: "446" },
  { code: "MG", code3: "MDG", name: "Madagascar", number: "450" },
  { code: "MW", code3: "MWI", name: "Malawi", number: "454" },
  { code: "MY", code3: "MYS", name: "Malaysia", number: "458" },
  { code: "MV", code3: "MDV", name: "Maldives", number: "462" },
  { code: "ML", code3: "MLI", name: "Mali", number: "466" },
  { code: "MT", code3: "MLT", name: "Malta", number: "470" },
  { code: "MH", code3: "MHL", name: "Marshall Islands", number: "584" },
  { code: "MQ", code3: "MTQ", name: "Martinique", number: "474" },
  { code: "MR", code3: "MRT", name: "Mauritania", number: "478" },
  { code: "MU", code3: "MUS", name: "Mauritius", number: "480" },
  { code: "YT", code3: "MYT", name: "Mayotte", number: "175" },
  { code: "MX", code3: "MEX", name: "Mexico", number: "484" },
  {
    code: "FM",
    code3: "FSM",
    name: "Micronesia, Federated States of",
    number: "583",
  },
  { code: "MD", code3: "MDA", name: "Moldova, Republic of", number: "498" },
  { code: "MC", code3: "MCO", name: "Monaco", number: "492" },
  { code: "MN", code3: "MNG", name: "Mongolia", number: "496" },
  { code: "ME", code3: "MNE", name: "Montenegro", number: "499" },
  { code: "MS", code3: "MSR", name: "Montserrat", number: "500" },
  { code: "MA", code3: "MAR", name: "Morocco", number: "504" },
  { code: "MZ", code3: "MOZ", name: "Mozambique", number: "508" },
  { code: "MM", code3: "MMR", name: "Myanmar", number: "104" },
  { code: "NA", code3: "NAM", name: "Namibia", number: "516" },
  { code: "NR", code3: "NRU", name: "Nauru", number: "520" },
  { code: "NP", code3: "NPL", name: "Nepal", number: "524" },
  { code: "NL", code3: "NLD", name: "Netherlands", number: "528" },
  { code: "NC", code3: "NCL", name: "New Caledonia", number: "540" },
  { code: "NZ", code3: "NZL", name: "New Zealand", number: "554" },
  { code: "NI", code3: "NIC", name: "Nicaragua", number: "558" },
  { code: "NE", code3: "NER", name: "Niger", number: "562" },
  { code: "NG", code3: "NGA", name: "Nigeria", number: "566" },
  { code: "NU", code3: "NIU", name: "Niue", number: "570" },
  { code: "NF", code3: "NFK", name: "Norfolk Island", number: "574" },
  { code: "MP", code3: "MNP", name: "Northern Mariana Islands", number: "580" },
  { code: "NO", code3: "NOR", name: "Norway", number: "578" },
  { code: "OM", code3: "OMN", name: "Oman", number: "512" },
  { code: "PK", code3: "PAK", name: "Pakistan", number: "586" },
  { code: "PW", code3: "PLW", name: "Palau", number: "585" },
  {
    code: "PS",
    code3: "PSE",
    name: "Palestinian Territory, Occupied",
    number: "275",
  },
  { code: "PA", code3: "PAN", name: "Panama", number: "591" },
  { code: "PG", code3: "PNG", name: "Papua New Guinea", number: "598" },
  { code: "PY", code3: "PRY", name: "Paraguay", number: "600" },
  { code: "PE", code3: "PER", name: "Peru", number: "604" },
  { code: "PH", code3: "PHL", name: "Philippines", number: "608" },
  { code: "PN", code3: "PCN", name: "Pitcairn", number: "612" },
  { code: "PL", code3: "POL", name: "Poland", number: "616" },
  { code: "PT", code3: "PRT", name: "Portugal", number: "620" },
  { code: "PR", code3: "PRI", name: "Puerto Rico", number: "630" },
  { code: "QA", code3: "QAT", name: "Qatar", number: "634" },
  {
    code: "MK",
    code3: "MKD",
    name: "Macedonia, The Former Yugoslav Republic of",
    number: "807",
  },
  { code: "RO", code3: "ROU", name: "Romania", number: "642" },
  { code: "RU", code3: "RUS", name: "Russian Federation", number: "643" },
  { code: "RW", code3: "RWA", name: "Rwanda", number: "646" },
  { code: "RE", code3: "REU", name: "Reunion", number: "638" },
  { code: "BL", code3: "BLM", name: "Saint Barthélemy", number: "652" },
  { code: "SH", code3: "SHN", name: "Saint Helena", number: "654" },
  { code: "KN", code3: "KNA", name: "Saint Kitts and Nevis", number: "659" },
  { code: "LC", code3: "LCA", name: "Saint Lucia", number: "662" },
  {
    code: "MF",
    code3: "MAF",
    name: "Saint Martin (French part)",
    number: "663",
  },
  {
    code: "PM",
    code3: "SPM",
    name: "Saint Pierre and Miquelon",
    number: "666",
  },
  {
    code: "VC",
    code3: "VCT",
    name: "Saint Vincent and the Grenadines",
    number: "670",
  },
  { code: "WS", code3: "WSM", name: "Samoa", number: "882" },
  { code: "SM", code3: "SMR", name: "San Marino", number: "674" },
  { code: "ST", code3: "STP", name: "Sao Tome and Principe", number: "678" },
  { code: "SA", code3: "SAU", name: "Saudi Arabia", number: "682" },
  { code: "SN", code3: "SEN", name: "Senegal", number: "686" },
  { code: "RS", code3: "SRB", name: "Serbia", number: "688" },
  { code: "SC", code3: "SYC", name: "Seychelles", number: "690" },
  { code: "SL", code3: "SLE", name: "Sierra Leone", number: "694" },
  { code: "SG", code3: "SGP", name: "Singapore", number: "702" },
  {
    code: "SX",
    code3: "SXM",
    name: "Sint Maarten (Dutch part)",
    number: "534",
  },
  { code: "SK", code3: "SVK", name: "Slovakia", number: "703" },
  { code: "SI", code3: "SVN", name: "Slovenia", number: "705" },
  { code: "SB", code3: "SLB", name: "Solomon Islands", number: "090" },
  { code: "SO", code3: "SOM", name: "Somalia", number: "706" },
  { code: "ZA", code3: "ZAF", name: "South Africa", number: "710" },
  {
    code: "GS",
    code3: "SGS",
    name: "South Georgia and the South Sandwich Islands",
    number: "239",
  },
  { code: "SS", code3: "SSD", name: "South Sudan", number: "728" },
  { code: "ES", code3: "ESP", name: "Spain", number: "724" },
  { code: "LK", code3: "LKA", name: "Sri Lanka", number: "144" },
  { code: "SD", code3: "SDN", name: "Sudan", number: "729" },
  { code: "SR", code3: "SUR", name: "Suriname", number: "740" },
  { code: "SJ", code3: "SJM", name: "Svalbard and Jan Mayen", number: "744" },
  { code: "SE", code3: "SWE", name: "Sweden", number: "752" },
  { code: "CH", code3: "CHE", name: "Switzerland", number: "756" },
  { code: "SY", code3: "SYR", name: "Syrian Arab Republic", number: "760" },
  { code: "TW", code3: "TWN", name: "Taiwan", number: "158" },
  { code: "TJ", code3: "TJK", name: "Tajikistan", number: "762" },
  {
    code: "TZ",
    code3: "TZA",
    name: "Tanzania, United Republic of",
    number: "834",
  },
  { code: "TH", code3: "THA", name: "Thailand", number: "764" },
  { code: "TL", code3: "TLS", name: "Timor-Leste", number: "626" },
  { code: "TG", code3: "TGO", name: "Togo", number: "768" },
  { code: "TK", code3: "TKL", name: "Tokelau", number: "772" },
  { code: "TO", code3: "TON", name: "Tonga", number: "776" },
  { code: "TT", code3: "TTO", name: "Trinidad and Tobago", number: "780" },
  { code: "TN", code3: "TUN", name: "Tunisia", number: "788" },
  { code: "TR", code3: "TUR", name: "Turkey", number: "792" },
  { code: "TM", code3: "TKM", name: "Turkmenistan", number: "795" },
  { code: "TC", code3: "TCA", name: "Turks and Caicos Islands", number: "796" },
  { code: "TV", code3: "TUV", name: "Tuvalu", number: "798" },
  { code: "UG", code3: "UGA", name: "Uganda", number: "800" },
  { code: "UA", code3: "UKR", name: "Ukraine", number: "804" },
  { code: "AE", code3: "ARE", name: "United Arab Emirates", number: "784" },
  { code: "GB", code3: "GBR", name: "United Kingdom", number: "826" },
  {
    code: "UM",
    code3: "UMI",
    name: "United States Minor Outlying Islands",
    number: "581",
  },
  { code: "US", code3: "USA", name: "United States", number: "840" },
  { code: "UY", code3: "URY", name: "Uruguay", number: "858" },
  { code: "UZ", code3: "UZB", name: "Uzbekistan", number: "860" },
  { code: "VU", code3: "VUT", name: "Vanuatu", number: "548" },
  { code: "VE", code3: "VEN", name: "Venezuela", number: "862" },
  { code: "VN", code3: "VNM", name: "Vietnam", number: "704" },
  { code: "VG", code3: "VGB", name: "Virgin Islands, British", number: "092" },
  { code: "VI", code3: "VIR", name: "Virgin Islands, U.S.", number: "850" },
  { code: "WF", code3: "WLF", name: "Wallis and Futuna", number: "876" },
  { code: "EH", code3: "ESH", name: "Western Sahara", number: "732" },
  { code: "YE", code3: "YEM", name: "Yemen", number: "887" },
  { code: "ZM", code3: "ZMB", name: "Zambia", number: "894" },
  { code: "ZW", code3: "ZWE", name: "Zimbabwe", number: "716" },
  { code: "AX", code3: "ALA", name: "Åland Islands", number: "248" },
];

export const newRegisterObj = {
  firstName: "",
  lastName: "",
  dob: null,
  idNumber: "",
  medicalNo: "",
  street: "",
  city: "",
  state: "",
  phoneNumber: "",
  sex: "",
  email: "",
  isVaccinated: null,
  country: "United States",
  street2: "",
  countryCode: "",
  zip: "",
  testSendQty: MIN_TEST_QUANTITY,
  isNew: true,
  isSecondary: "N",
};

export const newLocationObject = {
  name: "",
  primary_contact: "",
  contact_email: "",
  street: "",
  city: "",
  state: "",
  phoneNumber: "",
  email: "",
  street2: "",
  countryCode: "",
  zip: "",
  preRegistration: false,
  isNew: true,
  testOrderCategory: "1",
  testLimit: 6,
  country: "United States",
};
export const newLabClientObject = {
  name: "",
  practiceId: "",
  address: "",
  phone_number: "",
  fax: "",
  email: "",
  contact_name: "",
  contactName: "",
  contactFax: "",
  contact_email: "",
  active: false,
  isActive: true,
  isArchive: false,
  discount: "",
  commission: "",
  isNew: true,
};

export const TEST_ORDER_QUANTITY = [
  { label: 2, value: 2 },
  { label: 4, value: 4 },
  { label: 6, value: 6 },
  { label: 8, value: 8 },
];

export const personalizationObj = {
  employeeClaim: {
    draft: [
      { id: 1, width: "100%" },
      { id: 2, width: "100%" },
      { id: 3, width: "100%" },
      { id: 4, width: "100%" },
      { id: 5, width: "100%" },
      { id: 6, width: "100%" },
      { id: 7, width: "100%" },
      { id: 8, width: "100%" },
      { id: 9, width: "100%" },
      { id: 10, width: "100%" },
      { id: 11, width: "100%" },
      { id: 12, width: "100%" },
      { id: 13, width: "100%" },
      { id: 14, width: "100%" },
      { id: 15, width: "100%" },
      { id: 16, width: "100%" },
      { id: 17, width: "100%" },
      { id: 18, width: "100%" },
      { id: 19, width: "100%" },
      { id: 20, width: "100%" },
      { id: 21, width: "100%" },
      { id: 22, width: "100%" },
      { id: 23, width: "100%" },
      { id: 24, width: "100%" },
      { id: 25, width: "100%" },
      { id: 26, width: "100%" },
      { id: 27, width: "100%" },
      { id: 28, width: "100%" },
      { id: 29, width: "100%" },
    ],
    submitted: [
      { id: 1, width: "100%" },
      { id: 2, width: "100%" },
      { id: 3, width: "100%" },
      { id: 4, width: "100%" },
      { id: 5, width: "100%" },
      { id: 6, width: "100%" },
      { id: 7, width: "100%" },
      { id: 8, width: "100%" },
      { id: 9, width: "100%" },
      { id: 10, width: "100%" },
      { id: 11, width: "100%" },
      { id: 12, width: "100%" },
      { id: 13, width: "100%" },
      { id: 14, width: "100%" },
      { id: 15, width: "100%" },
      { id: 16, width: "100%" },
      { id: 17, width: "100%" },
      { id: 18, width: "100%" },
      { id: 19, width: "100%" },
      { id: 20, width: "100%" },
      { id: 21, width: "100%" },
      { id: 22, width: "100%" },
      { id: 23, width: "100%" },
      { id: 24, width: "100%" },
      { id: 25, width: "100%" },
      { id: 26, width: "100%" },
      { id: 27, width: "100%" },
      { id: 28, width: "100%" },
      { id: 29, width: "100%" },
    ],
    rejected: [
      { id: 1, width: "100%" },
      { id: 2, width: "100%" },
      { id: 3, width: "100%" },
      { id: 4, width: "100%" },
      { id: 5, width: "100%" },
      { id: 6, width: "100%" },
      { id: 7, width: "100%" },
      { id: 8, width: "100%" },
      { id: 9, width: "100%" },
      { id: 10, width: "100%" },
      { id: 11, width: "100%" },
      { id: 12, width: "100%" },
      { id: 13, width: "100%" },
      { id: 14, width: "100%" },
      { id: 15, width: "100%" },
      { id: 16, width: "100%" },
      { id: 17, width: "100%" },
      { id: 18, width: "100%" },
      { id: 19, width: "100%" },
      { id: 20, width: "100%" },
      { id: 21, width: "100%" },
      { id: 22, width: "100%" },
      { id: 23, width: "100%" },
      { id: 24, width: "100%" },
      { id: 25, width: "100%" },
      { id: 26, width: "100%" },
      { id: 27, width: "100%" },
      { id: 28, width: "100%" },
      { id: 29, width: "100%" },
    ],
    paid: [
      { id: 1, width: "100%" },
      { id: 2, width: "100%" },
      { id: 3, width: "100%" },
      { id: 4, width: "100%" },
      { id: 5, width: "100%" },
      { id: 6, width: "100%" },
      { id: 7, width: "100%" },
      { id: 8, width: "100%" },
      { id: 9, width: "100%" },
      { id: 10, width: "100%" },
      { id: 11, width: "100%" },
      { id: 12, width: "100%" },
      { id: 13, width: "100%" },
      { id: 14, width: "100%" },
      { id: 15, width: "100%" },
      { id: 16, width: "100%" },
      { id: 17, width: "100%" },
      { id: 18, width: "100%" },
      { id: 19, width: "100%" },
      { id: 20, width: "100%" },
      { id: 21, width: "100%" },
      { id: 22, width: "100%" },
      { id: 23, width: "100%" },
      { id: 24, width: "100%" },
      { id: 25, width: "100%" },
      { id: 26, width: "100%" },
      { id: 27, width: "100%" },
      { id: 28, width: "100%" },
      { id: 29, width: "100%" },
    ],
    denied: [
      { id: 1, width: "100%" },
      { id: 2, width: "100%" },
      { id: 3, width: "100%" },
      { id: 4, width: "100%" },
      { id: 5, width: "100%" },
      { id: 6, width: "100%" },
      { id: 7, width: "100%" },
      { id: 8, width: "100%" },
      { id: 9, width: "100%" },
      { id: 10, width: "100%" },
      { id: 11, width: "100%" },
      { id: 12, width: "100%" },
      { id: 13, width: "100%" },
      { id: 14, width: "100%" },
      { id: 15, width: "100%" },
      { id: 16, width: "100%" },
      { id: 17, width: "100%" },
      { id: 18, width: "100%" },
      { id: 19, width: "100%" },
      { id: 20, width: "100%" },
      { id: 21, width: "100%" },
      { id: 22, width: "100%" },
      { id: 23, width: "100%" },
      { id: 24, width: "100%" },
      { id: 25, width: "100%" },
      { id: 26, width: "100%" },
      { id: 27, width: "100%" },
      { id: 28, width: "100%" },
      { id: 29, width: "100%" },
    ],
    cancel: [
      { id: 1, width: "100%" },
      { id: 2, width: "100%" },
      { id: 3, width: "100%" },
      { id: 4, width: "100%" },
      { id: 5, width: "100%" },
      { id: 6, width: "100%" },
      { id: 7, width: "100%" },
      { id: 8, width: "100%" },
      { id: 9, width: "100%" },
      { id: 10, width: "100%" },
      { id: 11, width: "100%" },
      { id: 12, width: "100%" },
      { id: 13, width: "100%" },
      { id: 14, width: "100%" },
      { id: 15, width: "100%" },
      { id: 16, width: "100%" },
      { id: 17, width: "100%" },
      { id: 18, width: "100%" },
      { id: 19, width: "100%" },
      { id: 20, width: "100%" },
      { id: 21, width: "100%" },
      { id: 22, width: "100%" },
      { id: 23, width: "100%" },
      { id: 24, width: "100%" },
      { id: 25, width: "100%" },
      { id: 26, width: "100%" },
      { id: 27, width: "100%" },
      { id: 28, width: "100%" },
      { id: 29, width: "100%" },
    ],
    patientClient: [
      { id: 1, width: "100%" },
      { id: 2, width: "100%" },
      { id: 3, width: "100%" },
      { id: 4, width: "100%" },
      { id: 5, width: "100%" },
      { id: 6, width: "100%" },
      { id: 7, width: "100%" },
      { id: 8, width: "100%" },
      { id: 9, width: "100%" },
      { id: 10, width: "100%" },
      { id: 11, width: "100%" },
      { id: 12, width: "100%" },
      { id: 13, width: "100%" },
      { id: 14, width: "100%" },
      { id: 15, width: "100%" },
      { id: 16, width: "100%" },
      { id: 17, width: "100%" },
      { id: 18, width: "100%" },
      { id: 19, width: "100%" },
      { id: 20, width: "100%" },
      { id: 21, width: "100%" },
      { id: 22, width: "100%" },
      { id: 23, width: "100%" },
      { id: 24, width: "100%" },
      { id: 25, width: "100%" },
      { id: 26, width: "100%" },
      { id: 27, width: "100%" },
      { id: 28, width: "100%" },
      { id: 29, width: "100%" },
    ],
  },
};

export const PERSONALIZATION = {
  member: "1,2,3,4,14,15,20,21,23,24",
  client: "1,6,7,8,12,13,14",
  brmCompany: "1,2,3,4,5,6,7,8,9,10",
  brmfacility: "1,2,3,4,5,6,7,8,9",
  facility: "1,6,7,8,12,13,14",
  labClient: "1,2,13,14,15,16",
  subAgent: "1,6,7,8,12,13,14",
  orders: "1,2,3,12,13,14,20",
  memberOrders: "1,2,3,4,5,6,7",
  employeeClaim: JSON.stringify(personalizationObj.employeeClaim),
  insurance: "1,2,3,4,5,6,7,8,9,10,11,12",
  document: "1,2,3,4,5,6",
  checkEligibility: "1,2,3,4,5,6,7,8,9,10,11",
  billing: "2,3,4,5,6,14,15,16,17,18,21,22",
  provider: "1,8,12,13,14,15,16",
  systemUser: "1,2,3,4,5,6",
};

export const DEFAULT_USER_PERSONALIZATION = {
  billed:
    '[{"id":1,"width":149},{"id":2,"width":150},{"id":3,"width":146},{"id":10,"width":134},{"id":11,"width":103},{"id":12,"width":127},{"id":13,"width":148},{"id":19,"width":102},{"id":21,"width":124}]',
  billing: "2,3,4,5,6,14,15,16,17,18,21,22",
  brmCompany: "1,2,3,4,5,6,7,8,9,10",
  brmfacility: "1,2,3,4,5,6,7,8,9",
  cancelled:
    '[{"id":1,"width":97},{"id":2,"width":124},{"id":3,"width":130},{"id":11,"width":118},{"id":12,"width":142},{"id":13,"width":163},{"id":14,"width":137},{"id":20,"width":117},{"id":22,"width":136}]',
  checkEligibility:
    '[{"id":1,"width":170},{"id":2,"width":150},{"id":3,"width":137},{"id":4,"width":237},{"id":5,"width":121},{"id":6,"width":219},{"id":7,"width":84},{"id":8,"width":188}]',
  claimInvoice:
    '[{"id":1,"width":122},{"id":2,"width":112},{"id":6,"width":66},{"id":7,"width":92},{"id":8,"width":102},{"id":9,"width":66},{"id":10,"width":98},{"id":12,"width":83},{"id":13,"width":109},{"id":14,"width":70},{"id":15,"width":90}]',
  client: "1,6,7,8,12,13,14",
  denied:
    '[{"id":1,"width":115},{"id":2,"width":116},{"id":3,"width":112},{"id":11,"width":69},{"id":12,"width":93},{"id":13,"width":114},{"id":14,"width":124},{"id":20,"width":68},{"id":22,"width":129},{"id":19,"width":68},{"id":25,"width":141}]',
  document: "1,2,3,4,5,6",
  draft:
    '[{"id":1,"width":125},{"id":2,"width":98},{"id":3,"width":108},{"id":11,"width":95},{"id":12,"width":112},{"id":13,"width":146},{"id":14,"width":148},{"id":20,"width":112},{"id":22,"width":98},{"id":16,"width":118}]',
  employeeClaim:
    '[{"id":1,"width":133},{"id":2,"width":123},{"id":6,"width":77},{"id":7,"width":103},{"id":8,"width":113},{"id":9,"width":77},{"id":10,"width":109},{"id":12,"width":94},{"id":13,"width":120},{"id":14,"width":81},{"id":15,"width":101}]',
  facility: "1,6,7,8,12,13,14",
  insurance:
    '[{"id":1,"width":134},{"id":2,"width":123},{"id":8,"width":65},{"id":9,"width":132},{"id":10,"width":172},{"id":11,"width":163},{"id":12,"width":112},{"id":13,"width":128},{"id":15,"width":189}]',
  labClient: "1,2,13,14,15,16,18",
  member:
    '[{"id":1,"width":120},{"id":3,"width":116},{"id":11,"width":138},{"id":12,"width":113},{"id":13,"width":121},{"id":14,"width":131},{"id":17,"width":128},{"id":18,"width":126},{"id":19,"width":186}]',
  memberOrders: "1,2,3,4,5,6,7",
  orders: "1,2,3,12,13,14,20",
  paid: '[{"id":1,"width":107},{"id":2,"width":106},{"id":3,"width":93},{"id":11,"width":84},{"id":12,"width":84},{"id":13,"width":117},{"id":20,"width":99},{"id":22,"width":106},{"id":16,"width":134},{"id":18,"width":111},{"id":26,"width":107}]',
  patientClient:
    '[{"id":1,"width":126},{"id":2,"width":116},{"id":3,"width":108},{"id":11,"width":78},{"id":12,"width":135},{"id":13,"width":133},{"id":20,"width":91},{"id":22,"width":243},{"id":29,"width":155}]',
  provider: "1,8,12,13,14,15,16,17",
  rejected:
    '[{"id":1,"width":146},{"id":2,"width":147},{"id":3,"width":143},{"id":11,"width":100},{"id":12,"width":124},{"id":13,"width":145},{"id":14,"width":155},{"id":20,"width":99},{"id":22,"width":118}]',
  subAgent: "1,6,7,8,12,13,14",
  submitted:
    '[{"id":1,"width":86},{"id":2,"width":109},{"id":3,"width":96},{"id":11,"width":103},{"id":12,"width":94},{"id":13,"width":99},{"id":20,"width":88},{"id":22,"width":145},{"id":16,"width":124},{"id":30,"width":106},{"id":31,"width":102}]',
  systemUser: "1,2,3,4,5,6",
};

export const INSURANCE_PROVIDER = payerlist.filter(
  (p) => p.payer_state === process.env.REACT_APP_STATE || !p.payer_state
);
export const INSURANCE_LIST = insuranceList;
export const POS_OPT = placeOfServices;
export const PROC_CODE_OPT = cptCodesAndDesc.PROCEDURE_CODE;
export const ERA_DENAIEL_REASONS = eraDenialsReasons;
export const ERA_DENAIEL_REMARKS = eraDenialsRemarks;

export const PARSE_BENEFIT_CODE = {
  1: "Active Coverage",
  2: "Active - Full Risk Capitation",
  3: "Active - Services Capitated",
  4: "Active - Services Capitated to Primary Care Physician",
  5: "Active - Pending Investigation",
  6: "Inactive",
  7: "Inactive - Pending Eligibility Update",
  8: "Inactive - Pending Investigation",
  A: "Co-Insurance",
  B: "Co-Payment",
  C: "Deductible",
  CB: "Coverage Basis",
  D: "Benefit Description",
  E: "Exclusions",
  F: "Limitations",
  G: "Out of Pocket (Stop Loss)",
  H: "Unlimited",
  I: "Non-Covered",
  J: "Cost Containment",
  K: "Reserve",
  L: "Primary Care Provider",
  M: "Pre-Existing Condition",
  MC: "Managed Care Coordinator",
  N: "Services Restricted to Following Provider",
  O: "Not Deemed a Medical Necessity",
  P: "Benefit Disclaimer",
  Q: "Second Surgical Opinion Required",
  R: "Other or Additional Payor",
  S: "Prior Year(s) History",
  T: "Card(s) Reported Lost/Stolen",
  U: "Contact Following Entity for Eligibility or Benefit Information",
  V: "Cannot Process",
  W: "Other Source of Data",
  X: "Health Care Facility",
  Y: "Spend Dow",
};

export const currencyformatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const SUB_AGENT_HEADERS = [
  "name",
  "street",
  "street2",
  "city",
  "state",
  "zip",
  "phoneNumber",
  "webSite",
  "contact_first_name",
  "contact_last_name",
  "contact_email",
  "main_phone_number",
];

export const COLUMNS = {
  1: "Primary",
  2: "Secondary",
  3: "Third",
  4: "Fourth",
  5: "Fifth",
  6: "Sixth",
  7: "Seventh",
  8: "Eighth",
  9: "Ninth",
  10: "Tenth",
  11: "Eleventh",
  12: "Twelfth",
};

export const ORDER_FILTER_CAPS = {
  orderStatus: true,
  firstName: true,
  lastName: true,
  requestedByName: true,
};

export const MEDICARE_ID = ["01192", "00882", "12302"];

export const TEST_QUANTITY = [
  { value: 0, label: 0 },
  { value: 8, label: 8 },
  { value: 16, label: 16 },
  { value: 24, label: 24 },
  { value: 32, label: 32 },
  { value: 40, label: 40 },
];

export const ORDER_QUANTITY = [
  { value: 0, label: 0 },
  { value: 8, label: 8 },
];

export const STATS_OBJECT = {
  approved: 0,
  approvedAmount: 0,
  denied: 0,
  deniedAmount: 0,
  draft: 0,
  draftAmount: 0,
  newPatients: 0,
  paid: 0,
  paidAmount: 0,
  partialProcessed: 0,
  partialProcessedAmount: 0,
  refundAmount: 0,
  rejectted: 0,
  rejecttedAmount: 0,
  submitted: 0,
  submittedAmount: 0,
};

export const ActionEventType = ["Eligibility", "OrderShipped"];

export const PROCEDURE_CODE_HEADER = [
  "Date of Service",
  "Place of Service",
  "Procedure",
  "M1",
  "M2",
  "M3",
  "M4",
  "Diag1",
  "Diag2",
  "Diag3",
  "Diag4",
  "Billed",
  "Units",
];

export const ERA_PROCEDURE_CODE_HEADER = [
  { title: "Paid Date", width: 10 },
  { title: "Claim Number", width: 10 },
  { title: "Provider Name", width: 10 },
  { title: "Process As", width: 10 },
  { title: "Cheque Number", width: 10 },
  { title: "Status", width: 10 },
  { title: "Paid By", width: 10 },
  { title: "Total Paid", width: 10 },

  // "DOS",

  // "Proc code",
  // "Units",
  // "Modifiers",
  // "Billed",
  // "Allowed",
  // "Deduct",
  // "Co-Ins",
  // "Co-Pay",
  // "Write Off",
];

export const ERA_PROCEDURE_CODE_DETAIL_HEADER = [
  // "Units",
  { item: "Date of Service", width: 15 },
  { item: "CPT", width: 10 },
  { item: "Desc", width: 10 },
  { item: "Billed", width: 10 },
  { item: "Allowed", width: 10 },
  { item: "Deduct", width: 10 },
  { item: "Co-Ins", width: 10 },
  { item: "Co-Pay", width: 10 },
  { item: "Write Off", width: 10 },
  { item: "Paid", width: 10 },
];

export const PREV_CLAIM_TABLE = [
  "Date of Service",
  "Procedure Code",
  "Payer Name",
  "Amount Billed",
  "Rendering Provider",
  "Status",
];

export const PREV_CLAIM_ACTIONS = {
  auto: "auto",
  update: "update",
  new: "new",
};

export const CLAIM_CHANGE_OPTS = [
  { label: "Draft", value: CLAIM_SUBMIT_STATUS.draft },
  { label: "Paid", value: CLAIM_SUBMIT_STATUS.paid },
  { label: "Submitted", value: CLAIM_SUBMIT_STATUS.submitted },
  { label: "Denied", value: CLAIM_SUBMIT_STATUS.denied },
  { label: "Rejected", value: CLAIM_SUBMIT_STATUS.rejected },
];

export const BILL_TO_OPTIONS = [
  { label: "Bill Client", value: "billClient" },
  { label: "Bill Patient", value: "billPatient" },
  { label: "Secondary", value: "secondary" },
];
export const CLAIM_STATUS_LOG_OBJ = {
  submitted: "SubmitForBill",
  denied: "ClaimDenied",
  rejected: "ClaimRejected",
  cancelled: "ClaimCancelled",
  draft: "ClaimRestored",
  paid: "Paid",
};

export const DIAGNOSIS_ITEMS = ["A", "B", "C", "D", "E", "F", "G", "H"];

export const PARSE_ALPHA_INTO_NUM = {
  A: "1",
  B: "2",
  C: "3",
  D: "4",
  E: "5",
  F: "6",
  G: "7",
  H: "8",
};

export const CHECK_ELIGIBILITY_OPTIONS = [{ value: "logs", title: "Logs" }];
export const PATIENTS_DROPDOWN_OPTIONS = [
  { value: "logs", title: "Audit Trail" },
  { value: "delete", title: "Archive" },
];

export const CPT_STATUS_OPT = [
  { value: "", label: "Select" },
  { value: "open", label: "Open" },
  { value: "paid", label: "Paid" },
  { value: "denied", label: "Denied" },
  { value: "refund", label: "Refund" },
  { value: "pr", label: "PR" },
];

export const RESUBMIT_CLAIM_OPT = [
  {
    value: "",
    label: "All",
  },
  { value: "y", label: "Yes" },
  { value: "n", label: "No" },
];

export const LAB_CLIENT_DROPDOWN_OPTIONS = [
  { value: "generateInvoice", title: "Generate Invoice" },
  { value: "sendInvoice", title: "Send Invoice" },
  { value: "processPayment", title: "Process Payment" },
  { value: "postPayment", title: "Post Payment" },
  { value: "delete", title: "Archive" },
  { value: "logs", title: "Audit Trail" },
];
export const PROVIDER_DROPDOWN_OPTIONS = [
  { value: "delete", title: "Archive" },
  { value: "logs", title: "Audit Trail" },
];
export const PAYER_DROPDOWN_OPTIONS = [{ value: "logs", title: "Audit Trail" }];

export const ADMIN_ORDERS_DROPDOWN_OPTIONS = [{ value: "view", title: "View" }];
export const BILLING_ORDERS_DROPDOWN_OPTIONS = [{ value: "view", title: "View" }];
export const LOCATION_DROPDOWN_OPTIONS = [{ value: "delete", title: "Delete" }];
export const SUBAGENT_DROPDOWN_OPTIONS = [{ value: "delete", title: "Delete" }];
export const USER_DROPDOWN_OPTIONS = [{ value: "delete", title: "Delete" }];
export const CLIENTS_DROPDOWN_OPTIONS = [{ value: "delete", title: "Delete" }];
export const DASHBOARD_CLAIMS_DROPDOWN_OPTIONS = [
  { value: "logs", title: "Logs" },
  { value: "view", title: "View" },
];
export const ClaimDropDownOptions = [
  { value: "logs", title: "Logs" },
  {
    value: "delete",
    title: "Cancel",
    // title: (
    //   <img
    //     src={TrashIcon}
    //     alt="trash icon"
    //     width="15"
    //     onMouseLeave={(e) => (e.target.style.color = "black")}
    //     onMouseOver={(e) => {
    //       e.target.style.cursor = "pointer";
    //     }}
    //     aria-hidden="true"
    //   />
    // ),
  },
  {
    value: "restore",
    title: "Restore",
  },
  window.location.hostname.includes("localhost") && { value: "sendInvoice", title: "Send Invoice" },
];
export const ClaimInvoiceOptions = [
  {
    value: "viewInvoice",
    title: "View Invoice",
  },
];

export const LAB_CLAIM_STATUS_OPT = [
  {
    value: "submitted",
    label: "Submitted",
  },
  { value: "denied", label: "Denied" },
  { value: "approved", label: "Approved" },
];

export const PAID_CLAIM_FILTERING_SPANS_OPTS = [
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "yearly", label: "Yearly" },
];

export const PAID_CLAIM_FILTERING_SPANS = {
  weekly: "weekly",
  monthly: "monthly",
  yearly: "yearly",
};

export const PAID_CLAIM_SPANS_FORMAT = {
  weekly: "DDMMYYYY",
  monthly: "MMYYYY",
  yearly: "YYYY",
};

export const PAID_CLAIM_SPANS_MODAL_FORMAT = {
  weekly: {
    datePickerFormat: "MM/dd/y",
    datePickerView: "month",
    momentFormat: "YYYY-MM-DD",
  },
  monthly: {
    datePickerFormat: "yyyy-MM",
    datePickerView: "year",
    momentFormat: "YYYY-MM",
  },
  yearly: {
    datePickerFormat: "yyyy",
    datePickerView: "decade",
    momentFormat: "YYYY",
  },
};

export const CLAIMS_STATS_DETAILS = {
  selectedSpan: PAID_CLAIM_FILTERING_SPANS.yearly,
  date: moment().format(PAID_CLAIM_SPANS_MODAL_FORMAT.yearly.momentFormat),
  payerId: "",
  rows: [],
  total: 0,
};

export const CLAIM_STATUS_DETAILS_VIEW_AS = [
  { value: "chart", label: "Chart" },
  { value: "list", label: "List" },
];
export const DRAFT_INVOICE_VIEW_AS = [
  { value: "billClient", label: "Bill Client" },
  { value: "billPatient", label: "Bill Patient" },
  { value: "additionalInfo", label: "Additional Info" },
];
export const SALE_PERSON_ACTIVE_OPTS = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

export const CLAIM_STATUS_DETAILS_VIEW_AS_OPTS = {
  chart: "chart",
  list: "list",
};

export const INSURANCE_PAID_CLAIM_DETAILS_TABLE_HEADERS = ["DOS", "Claim Date", "Total Charge", "Paid Amount"];

export const HOSPICE_MODFIRE = {
  GW: "GW",
  GV: "GV",
};

export const MOD_KEYS = ["mod1", "mod2", "mod3", "mod4"];

export const usersNo = {
  ibrahim: "+923234217605",
};

export const TEST_TYPE_OPT = [{ value: "PCR", label: "PCR" }];

export const ProcTooltipHeader = ["Code", "Description"];
export const labClientTooltipHeader = ["name"];

export const ERA_VIEW_STATUS = {
  paid: "paid",
  denied: "denied",
  partial: "partial",
};

export const ORDER_BY_DIRECTION = {
  ASCEDNING: "asc",
  DESCEDNING: "desc",
};

export const EMP_CLAIM_REARRANGE_OPTS = {
  delete: "delete",
  update: "update",
  notesUpdate: "notesUpdate",
};

export const DEFAULT_PROVIDERS = [
  {
    value: "renderingProvider",
    label: "Rendering Provider",
  },
  { value: "referringProvider", label: "Referring Physician" },
  { value: "orderingProvider", label: "Ordering Provider" },
  { value: "referringPyhsician", label: "Referring Pyhsician" },
  { value: "primaryCarePhysician", label: "Primary Care Physician" },
];
export const PARSE_PROVIDER = {
  renderingProvider: "Rendering Provider",
  referringProvider: "Referring Physician",
  orderingProvider: "Ordering Provider",
  referringPyhsician: "Referring Pyhsician",
  primaryCarePhysician: "Primary Care Physician",
};

export const DEFAULT_PROVIDERS_ARR = [
  "renderingProvider",
  "referringProvider",
  "orderingProvider",
  "referringPyhsician",
  "primaryCarePhysician",
];

export const EMP_CLAIM_MODAL = {
  clientID: "clientID",
  locationID: "locationID",
  subAgentID: "subAgentID",
  employeeID: "employeeID",
  clientName: "clientName",
  locationName: "locationName",
  subAgentName: "subAgentName",
  status: "status",
  message: "message",
  orderId: "orderId",
  orderDate: "orderDate",
  localOrderId: "localOrderId",
  fileid: "fileid",
  claimmd_id: "claimmd_id",
  accept_assign: "accept_assign",
  bill_addr_1: "bill_addr_1",
  bill_addr_2: "bill_addr_2",
  bill_city: "bill_city",
  bill_id: "bill_id",
  bill_name: "bill_name",
  bill_npi: "bill_npi",
  bill_phone: "bill_phone",
  bill_state: "bill_state",
  bill_taxid: "bill_taxid",
  bill_taxid_type: "bill_taxid_type",
  bill_zip: "bill_zip",
  diag_1: "diag_1",
  diag_2: "diag_2",
  diag_3: "diag_3",
  diag_4: "diag_4",
  diag_5: "diag_5",
  diag_6: "diag_6",
  diag_7: "diag_7",
  diag_8: "diag_8",
  mod1_1: "mod1_1",
  mod2_1: "mod2_1",
  mod3_1: "mod3_1",
  mod4_1: "mod4_1",
  narrative_1: "narrative_1",
  mod1_2: "mod1_2",
  mod2_2: "mod2_2",
  mod3_2: "mod3_2",
  mod4_2: "mod4_2",
  narrative_2: "narrative_2",
  employment_related: "employment_related",
  isInjury: "isInjury",
  isAccident: "isAccident",
  ins_addr_1: "ins_addr_1",
  ins_addr_2: "ins_addr_2",
  ins_city: "ins_city",
  ins_dob: "ins_dob",
  injuryDate: "injuryDate",
  stateOfInjury: "stateOfInjury",
  ins_name_f: "ins_name_f",
  ins_name_l: "ins_name_l",
  ins_name_m: "ins_name_m",
  ins_number: "ins_number",
  ins_sex: "ins_sex",
  ins_state: "ins_state",
  ins_zip: "ins_zip",
  pat_addr_1: "pat_addr_1",
  pat_addr_2: "pat_addr_2",
  pat_city: "pat_city",
  pat_country: "pat_country",
  pat_dob: "pat_dob",
  pat_name_f: "pat_name_f",
  pat_name_l: "pat_name_l",
  pat_name_m: "pat_name_m",
  pat_phone: "pat_phone",
  pat_rel: "pat_rel",
  pat_sex: "pat_sex",
  pat_ssn: "pat_ssn",
  pat_state: "pat_state",
  pat_zip: "pat_zip",
  payer_name: "payer_name",
  payer_order: "payer_order",
  payerid: "payerid",
  pcn: "pcn",
  remote_claimid: "remote_claimid",
  remote_fileid: "remote_fileid",
  remote_batchid: "remote_batchid",
  total_charge: "total_charge",
  charge_1: "charge_1",
  diag_ref_1: "diag_ref_1",
  from_date_1: "from_date_1",
  place_of_service_1: "place_of_service_1",
  place_of_service_2: "place_of_service_2",
  proc_code_1: "proc_code_1",
  proc_code_2: "proc_code_2",
  thru_date_1: "thru_date_1",
  units_1: "units_1",
  remote_chgid_1: "remote_chgid_1",
  remote_chgid_2: "remote_chgid_2",
  thru_date_2: "thru_date_2",
  units_2: "units_2",
  paymentMethod: "paymentMethod",
  paymentCheckNo: "paymentCheckNo",
  checkDate: "checkDate",
  claimNo: "claimNo",
  allowedPayment: "allowedPayment",
  paidAmount: "paidAmount",
  adjustedAmount: "adjustedAmount",
  submissionDate: "submissionDate",
  reSubmissionDate: "reSubmissionDate",
  insuranceProcessDate: "insuranceProcessDate",
  isPost: "isPost",
  plan_type: "plan_type",
  patient_responsibility: "patient_responsibility",
  total_paid: "total_paid",
  crossover_carrier: "crossover_carrier",
  crossover_id: "crossover_id",
  prov_npi: "prov_npi",
  prov_id: "prov_id",
  claim_received_date: "claim_received_date",
  status_code: "status_code",
  payer_icn: "payer_icn",
  filing_code: "filing_code",
  requestedBy: "requestedBy",
  requestedByName: "requestedByName",
  updatedBy: "updatedBy",
  updatedByName: "updatedByName",
  createdAt: "createdAt",
  proc_array: "proc_array",
  provider: "provider",
  era: "era",
  clia_number: "clia_number",
  note: "note",
  eraIds: "eraIds",
  isNewLogs: "isNewLogs",
  acknowledgeBy: "acknowledgeBy",
  pat_email: "pat_email",
  paid_insurance: "paid_insurance",
  assign_details: "assign_details",
  accNo: "accNo",
  toData: "toData",
  write_off: "write_off",
  homeBound: "homeBound",
  snf: "snf",
  isHospice: "isHospice",
  isInsHousing: "isInsHousing",
  updatedAt: "updatedAt",
  _version: "_version",
};

export const PARSE_FILTER_KEY_NAME = {
  First_Name: "firstName",
  Last_Name: "lastName",
  Email: "email",
  Phone: "phoneNumber",
  Gender: "sex",
  Zip_Code: "zip",
  Date_of_Birth: "dob",
  Primary_Insurance: "insuranceCompany",
  Primary_Insurance_Number: "insuranceCompany",
  Secondary_Insurance: "secondaryInsurance",
  Updated_At: "updatedAt",
  Note: "note",
  Status: "status",
};
export const PROVIDER_URL = "https://npiregistry.cms.hhs.gov/api/";

export const LAB_URL_CODE = {
  "www.alta.safecampltc.com": "004",
  localhost: "004",
};

export const DEFAULT_USERS = ["+18189031049", "+923234217605", "+18185730434"];

export const ADMIN_USERS = ["+17777777777", "+18888888888"];

export const PARSE_CLAIM_ERROR_KEY = {
  prov_npi: "Rendering NPI",
  ref_npi: "Referring NPI",
  ord_prov_npi: "Ordering NPIp",
  ins_number: "Medicare policy number",
};

export const altaIDS = ["7430ab67-1538-4088-93e1-b7c46a20a216", "bc3bcb3d-56b0-46e4-afc3-231a09749a2e"];

export const localClaimId = ["ee55bdd6-017a-43f2-ae77-d7c5f0722876", "2cf6e154-3297-4a11-8f5c-bcd185df98ae"];

export const localClaimIdsERA = [
  { id: "8499768b-7058-4563-8275-f028497b86b1", era: "28201331", pcn: "LTCS-001-0001540" },
  { id: "d2eb9df1-1d13-47a5-8205-0e483f19364d", era: "28201331", pcn: "LTCS-001-0001654" },
  { id: "4bba708f-216d-4e58-b286-882897f92b6c", era: "28088744", pcn: "LTCS-001-0001320" },
  { id: "ac4e0942-ebed-47eb-8d05-7d0da15da46a", era: "28658548", pcn: "LTCS-001-0001366" },
  { id: "a20aea22-f1a9-4205-80c5-64481d61635e", era: "28658548", pcn: "LTCS-001-0001770" },
  { id: "6001d699-fdfe-4974-9990-72ce8109f348", era: "28610907", pcn: "LTCS-001-0001651" },
  { id: "76151c75-cc08-4feb-8a36-85d85331b9bc", era: "28340999", pcn: "LTCS-001-0001636" },
  { id: "7eb4aa16-0333-4458-8736-6ae653ee7777", era: "28088744", pcn: "LTCS-001-0001557" },
];

export const ALTA_URL = "www.altalab.safecampltc.com";

export const DEFAULT_USRERS_PERMISSIONS = {
  checkEligibility: { access: true },
  claiminvoice: { access: true, editInvoices: true, generateInvoices: true, sendInvoices: true },
  claims: {
    access: true,
    createDraftClaims: true,
    delete: true,
    editClaims: true,
    editEra: true,
    submitAppeal: true,
    submitClaims: true,
    write: true,
  },
  dashboard: { access: true },
  employee: {
    access: true,
    archivePatients: true,
    createPatient: true,
    delete: true,
    editPatient: true,
    editPatientRecord: true,
    write: true,
  },
  users: {
    access: true,
    archiveUser: true,
    createNewUser: true,
    delete: true,
    editUserCredentials: true,
    write: true,
  },
  settings: { access: true },
};

export const LAB_ROTUES = {
  "/dashboard": "dashboard",
  "/members": "employee",
  "/checkeligibility": "checkEligibility",
  "/employeeclaim": "claims",
  "/users": "users",
  "/settings": "settings",
  "/claiminvoice": "claiminvoice",
};

export const PSERMISSIONS_LIST = [
  { label: "Patient ", value: "employee", advance: true },
  { label: "Check Eligibility ", value: "checkEligibility", advance: true },
  { label: "Claims ", value: "claims", advance: true },
  { label: "System Users", value: "users", advance: true },
  { label: "Setting ", value: "settings", advance: false },
  { label: "Claim Invoice ", value: "claiminvoice", advance: false },
];

export const notForClientsPermission = ["users"];

export const EMPLOYEE = "employee";

export const CHECKELIGIBILITY = "checkEligibility";

export const EMPLOYEE_CLAIM = "claims";

export const USERS = "users";

export const STATUS_KEY = {
  draft: "draft",
  submitted: "submitted",
  rejected: "rejectted",
  approved: "approved",
  denied: "denied",
  paid: "paid",
  partial: "partialProcessed",
  cancelled: "cancelled",
};
export const STATUS_KEYS_RE_SUBMIT = {
  draft: "draft",
  rejected: "rejectted",
  denied: "denied",
  sent: "sent",
  submitted: "submitted",
};

export const CLAIMS_KEYS = [
  "clientID",
  "locationID",
  "subAgentID",
  "employeeID",
  "clientName",
  "locationName",
  "subAgentName",
  "orderId",
  "orderDate",
  "localOrderId",
  "fileid",
  "paymentMethod",
  "paymentCheckNo",
  "checkDate",
  "claimNo",
  "allowedPayment",
  "paidAmount",
  "adjustedAmount",
  "overPaidAmount",
  "submissionDate",
  "reSubmissionDate",
  "insuranceProcessDate",
  "isPost",
  "plan_type",
  "total_paid",
  "crossover_carrier",
  "crossover_id",
  "claim_received_date",
  "status_code",
  "filing_code",
  "requestedBy",
  "requestedByName",
  "updatedBy",
  "updatedByName",
  "createdAt",
  "proc_array",
  "provider",
  "era",
  "eraIds",
  "isNewLogs",
  "acknowledgeBy",
  "paid_insurance",
  "assign_details",
  "toData",
  "updatedAt",
  "_version",
  "_deleted",
  "_lastChangedAt",
  "partialAmount",
  "employee",
  "eligibility",
  "openBalance",
  "openStatus",
  "billTo",
  "write_off",
  "invoiceID",
];
export const EMPLOYEE_STATUS = [
  {
    value: "active",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="green" crossIcon /> Active
      </div>
    ),
  },
  {
    value: "inactive",
    label: (
      <div className="star-icon-wrapper">
        <Status type="circle" size="md" color="maroon" crossIcon /> InActive
      </div>
    ),
  },
];

export const INSURANCE_EMPTY_OBJECT = {
  clientID: "",
  payerId: "",
  Name: "",
  address: "",
  city: "",
  state: "",
  phone: "",
  fax: "",
  eClaim: true,
  eFax: "",
  manualTerm: "",
  manualTerm: "",
  manualAmount: 0,
  manualMail: "",
  eEligibility: true,
  EDI: true,
  ERA: true,
  eraMail: false,
  claimAddress: "",
  claimCity: "",
  claimState: "",
  claimPhone: "",
  claimFax: "",
  isNew: true,
  isActive: true,
};

export const EMP_INS_OBJECT = {
  insuranceCompany: "",
  insuranceCompanyCode: "",
  medicalNo: "",
  insuranceType: "",
  insuranceGroupId: "",
  medicareNo: "",
  deductible: 0,
  copay: 0,
  isActive: true,
  isNew: true,
};

export const PROCEDURE_CODE_STATES = {
  PLUS: "pluc",
  ADD: "add",
  UPDATE: "update",
};

export const FILE_TYPES = {
  png: "image",
  jpg: "image",
  jpeg: "image",
  pdf: "pdf",
  docx: "word",
  xlsx: "excel",
  pptx: "powerpoint",
  txt: "alt",
};

export const BCC_VALUE = {
  DEDUCTIBLE: "C",
  OUT_OF_POCKET: "G",
};

export const CUSTOM_INS = ["00000", "00001", "00002", "00003"];

export const BILL_PATIENT_INS = ["00001", "00002", "00003"];

export const ON_HOLD_CPT = "11111";

export const DUMMY_ELIG_RES = {
  benefit: [{ benefit_code: "30", benefit_coverage_code: "1", benefit_description: "Dummy" }],
};

export const CLAIM_SUBMIT_STATUS_LABELS = {
  submitted: "Sent",
  partial: "Partial",
  paid: "Paid",
  denied: "Denied",
};

export const CLAIM_STATUS_LABELS = {
  submitted: "Sent",
  partial: "Partial",
  paid: "Paid",
  denied: "Denied",
  cancelled: "Cancelled",
  draft: "Draft",
  rejected: "Rejected",
  approved: "Approved",
};

export const acceptedBulkInvoiceStatus = ["draft", "sent"];

export const CLAIM_CODE_HEADER = [
  { title: "DOS", width: 8 },
  { title: "First Name", width: 8 },
  { title: "Last Name", width: 8 },
  { title: "Claim ID", width: 9 },
  { title: "Claim Received Date", width: 13 },
  { title: "Processed Type", width: 9 },
  { title: "Billed Party", width: 9 },
  { title: "Amount Billed", width: 9 },
  { title: "Amount Received", width: 13 },
  { title: "Bal Amount", width: 13 },
  { title: "Status", width: 10 },

  // "Proc code",
  // "Units",
  // "Modifiers",
  // "Billed",
  // "Allowed",
  // "Deduct",
  // "Co-Ins",
  // "Co-Pay",
  // "Write Off",
];

export const CLAIM_CODE_DETAIL_HEADER = [
  // "Units",

  { itemKey: "proc_code", title: "CPT", sort: true, filter: true },
  { itemKey: "proc_desc", title: "CPT DESC" },
  { itemKey: "charge", title: "Amount Billed" },
  { itemKey: "allowed", title: "Allowed" },
  { itemKey: "deductible", title: "Deduct" },
  { itemKey: "co_insurance", title: "Co-Ins" },
  { itemKey: "copay", title: "Co-Pay" },
  { itemKey: "write_off", title: "Write Off" },
  { itemKey: "paid", title: "Paid Amount" },
  { itemKey: "open", title: "Bal" },
  { itemKey: "notes", title: "Note" },
  { itemKey: "status", title: "Status" },
];

export const PAYMENT_TYPES = [
  { value: "CHK", label: "Check", isDisabled: false },
  { value: "ERA", label: "ERA", isDisabled: false },
  { value: "CC", label: "Credit Card", isDisabled: false },
  { value: "cash", label: "Cash", isDisabled: true },
  { value: "other", label: "Other", isDisabled: true },
];

export const IS_PAYMENT_TYPE = {
  check: "EOB",
  credit: "CC",
};

export const STATUS_CODE_METHOD = {
  1: "Primary",
  2: "Secondary",
  8: "Bill Patient",
  9: "Bll Client",
};

export const CLAIM_INSURANCE_TYPE = [
  { label: "Primary", value: "primary" },
  { label: "Seconday", value: "secondary" },
  { label: "Tertiary", value: "tertiary" },
  { label: "Bill Patient", value: "billPatient" },
];
export const INSURANCE_TYPES = [
  { value: 1, label: "Primary" },
  { value: 2, label: "Secondary" },
  { value: 8, label: "Bill Patient" },
  { value: 9, label: "Bill Client" },
];

export const BILLING_TYPES_OPT = [
  { value: "mail", label: "Mail" },
  { value: "email", label: "Email" },
  { value: "fax", label: "Fax" },
];

export const INVOICE_DEFAULT_MESSAGE =
  "According to your insurance, our charges were applied against your yearly deductible. It is now your responsibility to pay the amount due. PLEASE PUT YOUR ACCOUNT/ INVOICE # ON YOUR CHECK OR MONEY ORDER. <br/> THANK YOU.";

export const discountRadioButtonValues = [
  { value: "overall", label: "Overall" },
  { value: "line-item", label: "Line Item" },
];

export const invoicePreviewTypes = [
  { value: "summary", label: "Summary" },
  { value: "detail", label: "Detail" },
];

export const InvoiceEditDetailsSearch = [
  "Patient Name",
  "Reffering Provider",
  "CPT",
  "Date Of Service",
  "Accession No",
];

export const PAYER_NETWORK_OPTIONS = [
  { value: "In Network", label: "In Network" },
  { value: "Out of Network", label: "Out of Network" },
];

export const COMBINE_PROFILE = "Combine Profile";
export const IMPORT_TYPE = {
  labClient: "labClient",
  associatedClient: "associatedClient",
};

export const DEFAULT_TAB_INDEX_EMPLOYEE_CLAIM = {
  draft: 0,
  submitted: 1,
  rejected: 2,
  paid: 3,
  denied: 4,
  cancelled: 5,
  patientClient: 6,
};

export const SYSTEM_USER_PSERMISSIONS_LIST = [
  {
    label: "Patient ",
    value: "employee",
    subObject: [
      {
        label: "Create Patients",
        value: "createPatient",
      },
      {
        label: "Edit Patients",
        value: "editPatient",
      },
      {
        label: "Archive Patients",
        value: "archivePatients",
      },
    ],
  },

  {
    label: "Claims",
    value: "claims",
    subObject: [
      {
        label: "Create Draft Claims",
        value: "createDraftClaims",
      },
      {
        label: "Submit Claims",
        value: "submitClaims",
      },
      {
        label: "Edit/Update Claims",
        value: "editClaims",
      },
      {
        label: "Edit ERA",
        value: "editEra",
      },
      {
        label: "Submit Appeal",
        value: "submitAppeal",
      },
      {
        label: "Cancel Claims",
        value: "delete",
      },
    ],
  },

  {
    label: "Invoices",
    value: "claiminvoice",
    subObject: [
      {
        label: "Generate Invoices",
        value: "generateInvoices",
      },
      {
        label: "Edit Invoices",
        value: "editInvoices",
      },
      {
        label: "Send Invoices",
        value: "sendInvoices",
      },
    ],
  },

  { label: "Check Eligibility", value: "checkEligibility" },
  { label: "Settings", value: "settings" },
  {
    label: "System Users",
    value: "users",
    subObject: [
      {
        label: "Create New User",
        value: "createNewUser",
        checked: true,
      },
      {
        label: " Archive Users",
        value: "archiveUser",
        checked: true,
      },
      {
        label: "Edit User Credentials and Access",
        value: "editUserCredentials",
        checked: true,
      },
    ],
  },
];

export const dependentPermissionsList = [
  "checkEligibility",
  "claiminvoice",
  "claims",
  "dashboard",
  "employee",
  "settings",
  "users",
];

export const CUSTOM_INS_ADV_PLAN = ["00001", "00002", "00003", "01192", "00882", "57016", "12302"];


export const IGNORE_INS = ["00000", "00001", "00002", "00003"];
