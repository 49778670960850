import api from "api";
import { Storage } from "aws-amplify";
import ExportToExcel from "components/ExportToExcel";
import Filter from "components/Filter";
import FilterSelect from "components/FilterSelect";
import Icon from "components/Icon";
import Loader from "components/Loader/Loader";
import AssignClaimModal from "components/Modal/AssignClaimModal/AssignClaimModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import ErrorMessageModal from "components/Modal/ErrorMessageModal";
import NotesModal from "components/Modal/NotesModal";
import EraModal from "components/Modal/Order/EraModal";
import OrderImportModal from "components/Modal/Order/OrderImportModal";
import OrderLogsModal from "components/Modal/Order/OrderLogsModal";
import ClaimImportModal from "components/Claim/Modal/ClaimImportModal";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import MFPagination from "components/Pagination/MFPagination";
import SingleStatsCard from "components/SingleStatsCard";
import MainTable from "components/Table/MainTable";
import TimeFilter from "components/TimeFilter";
import {
  AltaSetting,
  CLAIM_SUBMIT_STATUS,
  CLAIMS_KEYS,
  CONFIG,
  currencyformatter,
  EMPLOYEE_CLAIM,
  ERA_VIEW_STATUS,
  INSURANCE_PROVIDER,
  MESSAGE_MODES,
  PAGE_LIMIT,
  STATUS_KEYS_RE_SUBMIT,
  TABLE_QUICK_TOOLS,
  USERS,
  usersNo,
  TABLE_COLUMN_TYPES,
} from "constant";
import { CLAIM_PERSONALIZATION } from "constants/claimPersonalization";
import { AppContext } from "context/app-context";
import { debounce } from "debounce";
import graphqlApi from "graphqlApi";
import moment from "moment";
import { useCallback, useContext, useEffect, useState, useRef, useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { authSelector, selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { selectedClients } from "store/features/clients/clientsSelectors";
import { selectedCompanySetting } from "store/features/companySetting/companySettingSelectors";
import { acknowledgeClaim, fetchAllEmployeeClaims } from "store/features/employeeClaims/employeeClaimsActions";
import {
  claimFilterSelector,
  claimSelector,
  claimTimeFilterSelector,
} from "store/features/employeeClaims/employeeClaimsSelectors";
import {
  assignEmployeeClaimAsync,
  setClaimReSubmissionModal,
  setCurrentPage,
  setClaimsFilter as setFilter,
  setFilteredEmployeeClaims,
  setPageNo,
  setStatsObj,
  setClaimTimeFilter as setTimeFilter,
  updateBulkSubmitEmployeeClaimAsync,
  updateNotes,
  updateStatusEmployeeClaimAsync,
} from "store/features/employeeClaims/employeeClaimsSlice";
import { employeesSelector } from "store/features/employees/employeesSelectors";
import { fetchAllEmployees } from "store/features/employees/employeesSlice";
import { setMessage } from "store/features/general/generalAction";
import { fetchAllLocations } from "store/features/locations/locationsSlice";
import {
  setClaimsIDs,
  setOpenPaymentProcess,
  setShowFromBarCode,
  setShowFromClientSetting,
} from "store/features/processPayment/processPaymentSlice";
import { selectedProvider } from "store/features/providers/providersSelectors";
import { t } from "stringConstants";
import "tableStyling.css";
import {
  CapsFirstLetter,
  downloadDataAsCSV,
  draggablePersonalizationLocalStorage,
  formatCurrency,
  formatEmployeeClaim,
  getValidDep,
  isMatchString,
  parseClaimDate,
  phoneFormatter,
  removeKeysFromObject,
  RemoveSpaceAndChar,
  trimObject,
  formatNumber,
  StatusFormatter,
  userCompanyID,
} from "utils";
import ChangeClaimStatusModal from "./ChangeClaimStatusModal";
import { CLAIM_EDIT_STATUS, CONFIRMATION_TYPE, CUSTOM_INS } from "constant";
import { addSelectedClaim } from "store/features/bulkInvoices/bulkInvoicesAction";
import { addSelectedInvoice } from "store/features/invoicePreview/invoicePreviewAction";
import { getFloatVal, LSProcessPaymentData, parseInvoiceData } from "utils";
import ReportFilterModal from "views/ReportFilterModal";
import { exportClamDataToExcel } from "api";
import { downloadFileFromS3 } from "utils";
import { exportClaimDataToExcel } from "store/features/employeeClaims/employeeClaimsActions";
import { selectedLocation } from "store/features/locations/locationsSelectors";
import { splitClientName } from "utils";
import { getCompanySetting } from "api";
import PersonalizationModalEmployeeClaim from "components/Modal/Personalization/PersonalizationModalEmployeeClaim";
import { customIsEmpty } from "util/customLodash";
import { updateEmployeeClaimPersonalization } from "utils";
import { customIsEqual } from "util/customLodash";
import { getLoginUserInfoFromLocal } from "utils";
import { getERAUniqueIds } from "utils";
import { cancelEmployeeClaimAsync } from "store/features/employeeClaims/employeeClaimsSlice";
import { setLoader } from "store/features/general/generalSlice";

import { toTitleCase } from "utils";
import { EMP_CLAIM_PERSONALIZE } from "constants/personalization";
import { DEFAULT_PERSONALIZE } from "constants/claimPersonalization";
import { fetchInsurancesAsync } from "store/features/insurances/insuranceSlice";

const EmployeeClaimAPI = (props) => {
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState(false);

  const { locations } = useSelector(selectedLocation);

  const initialized = useRef(false);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openClaimSubmitConfirmation, setOpenClaimSubmitConfirmation] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [userToDelete, setUserToDelete] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const appContext = useContext(AppContext);
  const [allSelected, setAllSelected] = useState(false);
  const [personalize, setPersonalize] = useState([]);

  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);

  const [openImportModal, setOpenImportModal] = useState(false);
  const [openClaimImport, setOpenClaimImport] = useState(false);

  const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [openEraModal, setOpenEraModal] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [openReportModal, setOpenReportModal] = useState(false);
  const [selectedBucket, setSelectedBucket] = useState("draft");
  const [claimNote, setClaimNote] = useState(null);
  const [changeClaimStatusModal, setChangeClaimStatusModal] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { employeeClaims, pageNo, filteredEmployeeClaims, totalRecord, statsObj, currentPage } =
    useSelector(claimSelector);

  const setting = useSelector(selectedCompanySetting);
  const { clients } = useSelector(selectedClients);
  const { providers } = useSelector(selectedProvider);
  const loginUser = useSelector(selectLoginUser);
  const { employees } = useSelector(employeesSelector);
  const { permission } = useSelector(authSelector);

  const [showAssignModal, setShowAssignModal] = useState(false);

  const [bulkInvoiceConfirmation, setBulkInvoiceConfirmation] = useState(false);

  const filter = useSelector(claimFilterSelector);
  const timeFilter = useSelector(claimTimeFilterSelector);

  const quickTools = [
    TABLE_QUICK_TOOLS.checkbox,
    (permission[EMPLOYEE_CLAIM]?.editClaims || !CONFIG.isLabType) && TABLE_QUICK_TOOLS.edit,
    (permission[EMPLOYEE_CLAIM]?.editClaims || !CONFIG.isLabType) && TABLE_QUICK_TOOLS.notes,
  ];
  const { filterDates } = TimeFilter;

  useEffect(() => {
    if (location.state && !initialized.current) {
      setShowFilter(true);
      const filterTerm = location.state.term;
      const filterTermName = location.state.name;
      const nextTerm = location.state.filterTerm;
      const nextTermName = location.state.filterName;
      // const sortObj = {
      //   orderBy: sortBy,
      //   orderByDirection: sortDescending ? "desc" : "asc",
      // };
      if (filterTerm && filterTermName) {
        dispatch(setFilter({ [filterTerm]: filterTermName }));
        setTimeout(() => setShowFilter(false), 1);
      }
      if (nextTerm && nextTermName) {
        dispatch(
          setFilter({
            [nextTerm]: nextTermName,
            [filterTerm]: filterTermName,
          })
        );
        setTimeout(() => setShowFilter(false), 1);
      }
      initialized.current = true;
    }
  }, [location.state]);

  const searchTerms = [
    "First Name",
    "Last Name",
    "Email",
    "Phone Number",
    "Address",
    "Apt/Unit#",
    "City",
    "State",
    "Zip Code",
    "DOB",
    "Gender",
    "Payer",
    "Member ID",
    "Claim ID",
    "Claim Amount",
    "Adjusted Amount",
    "Paid Amount",
    "DOS",
    "Status",
    "LTCS ID",
    "B-ID",
    "Acc. No",
    "Submission Date",
    "Assigned To",
    "Paid By",
    "Processed By Secondary Ins",
    "Resubmitted Claims",
    "Crossover Claims",
    "Assign To Me",
    "Billing Company",
    "Claim Note",
    "Claim Reason",
    "Blank",
    "Invoice No",
    "Invoice Status",
    "Processed By",
    "By Client",
    "Ref Provider",
    "Check No",
    "Paid Date",
    "Injury",
  ];

  const fetchPayersFromApi = useCallback(
    debounce(async () => {
      dispatch(fetchInsurancesAsync({ orderByDirection: true }));
    }, 1000),
    []
  );

  useEffect(() => {
    fetchPayersFromApi();
  }, []);

  const [usersPerPage, setUsersPerPage] = useState(PAGE_LIMIT);

  useEffect(() => {
    if (pageNo > 1) {
      dispatch(fetchAllEmployeeClaims(timeFilter, pageNo, filter, false, appContext.users));
    }
    dispatch(fetchAllLocations());
  }, [pageNo]);

  useEffect(() => {
    const newBucketToSelect = filter.status || "draft";
    if (newBucketToSelect !== selectedBucket) {
      // setPersonalize(draggablePersonalizationLocalStorage.get(loginUser, selectedFilter, CLAIM_PERSONALIZATION));
      const employeeClaimFilter = updateEmployeeClaimPersonalization(CLAIM_PERSONALIZATION, newBucketToSelect);
      setPersonalize(employeeClaimFilter);
      setSelectedBucket(newBucketToSelect);
    }
  }, [filteredEmployeeClaims]);

  const filterOrderBy = (order, dir) => {
    const obj = {
      orderBy: order,
      orderByDirection: dir ? "desc" : "asc",
    };

    dispatch(setFilter({ ...filter, ...obj }));
  };

  const sortUsers = (users, sortBy) => {
    if (sortDescending) {
      return [...users].sort((a, b) => {
        let fieldA = a[sortBy];
        let fieldB = b[sortBy];
        let nameA = `${a["pat_name_f"]}${a["pat_name_l"]}`;
        let nameB = `${b["pat_name_f"]}${b["pat_name_l"]}`;
        if (fieldA && fieldB && fieldA == fieldB) {
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        } else {
          return fieldA < fieldB ? -1 : 1;
        }
      });
    } else {
      return [...users].sort((a, b) => {
        let fieldA = a[sortBy];
        let fieldB = b[sortBy];
        let nameA = `${a["pat_name_f"]}${a["pat_name_l"]}`;
        let nameB = `${b["pat_name_f"]}${b["pat_name_l"]}`;
        if (fieldA && fieldB && fieldA == fieldB) {
          return nameB < nameA ? -1 : nameB > nameA ? 1 : 0;
        } else {
          return fieldB < fieldA ? -1 : 1;
        }
      });
    }
  };

  const ErrorFallback = ({ error, resetErrorBoundary }) => {
    // Call resetErrorBoundary() to reset the error boundary and retry the render
    return (
      <ErrorMessageModal
        error={error.message}
        handleClose={() => {
          dispatch(setClaimReSubmissionModal(null));
          setOpenOrderDetail(false);
        }}
      />
    );
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
  };

  const currentUsers = (users) => {
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    return users.slice(indexOfFirstUser, indexOfLastUser);
  };

  let usersToMap = currentUsers(filteredEmployeeClaims);

  const getPageNumbers = (users) => {
    const pageNumbers = users.length > PAGE_LIMIT ? Math.ceil(users.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(employeeClaims);

  // useEffect(() => {
  //   return () => {
  //     dispatch(setFilter({}));
  //   };
  // }, []);

  const getCompanystats = async () => {
    const stats = clients[0].stats;
    const ttlSubmitted =
      stats.submitted + stats.approved + stats.paid + stats.partialProcessed + stats.rejectted + stats.denied;
    const ttlSubmittedAmt =
      stats.submittedAmount +
      stats.approvedAmount +
      stats.paidAmount +
      stats.partialProcessedAmount +
      stats.rejecttedAmount +
      stats.deniedAmount;
    dispatch(setStatsObj({ ...stats, submitted: ttlSubmitted, submittedAmount: ttlSubmittedAmt }));
  };

  const fetchFromApi = useCallback(
    debounce((timeFilter, pageNo, filter, val, users) => {
      dispatch(fetchAllEmployeeClaims(timeFilter, pageNo, filter, val, users));
    }, 1000),
    []
  );

  useEffect(() => {
    fetchFromApi(timeFilter, pageNo, filter, true, appContext.users);
  }, [filter, timeFilter]);

  useEffect(() => {
    const selectedFilter = filter.status || "draft";
    // setPersonalize(draggablePersonalizationLocalStorage.get(loginUser, selectedFilter, CLAIM_PERSONALIZATION));
    setPersonalize(updateEmployeeClaimPersonalization(CLAIM_PERSONALIZATION, selectedFilter));
  }, [setting]);

  if (filteredEmployeeClaims.length > 0) {
    pageNumbers = getPageNumbers(filteredEmployeeClaims);
  }

  const handleChangePage = (page, direction) => {
    dispatch(setCurrentPage(page));
    const currentRecord = filteredEmployeeClaims.length;
    if (direction === "next" && page === pageNumbers && totalRecord > 0 && currentRecord < totalRecord) {
      dispatch(setPageNo(pageNo + 1));
    }
  };

  const flipSort = (by) => {
    const sortOrder = sortBy === by ? !sortDescending : true;

    setSortDescending(sortOrder);
    setSortBy(by);
    filterOrderBy(by, sortOrder);
    // handleChangePage(1);
  };

  const handlePersonalization = async (data) => {
    const selectedFilter = filter.status || "draft";
    const currentPersonalization = data[selectedFilter];
    setPersonalize(currentPersonalization);
    for (const key in data) {
      const personalisationData = draggablePersonalizationLocalStorage.saveAs(data[key], key);
      draggablePersonalizationLocalStorage.save(JSON.stringify(personalisationData));
      await api.udpatePersonalization(loginUser.phone_number, personalisationData);
    }
    setOpenPersonalizationModal(false);
  };

  // const getDuplicateData = async () => {
  //   const emps = filteredEmployeeClaims.filter(
  //     (f, i, arr) =>
  //       arr.findIndex(
  //         (m) => m.proc_array[0].remote_chgid_1 === f.proc_array[0].remote_chgid_1 && f.from_date_1 === m.from_date_1
  //       ) !== i
  //   );
  //   const duplicateData = filteredEmployeeClaims.filter(
  //     (f, i) =>
  //       emps.findIndex(
  //         (m) => m.proc_array[0].remote_chgid_1 === f.proc_array[0].remote_chgid_1 && f.from_date_1 === m.from_date_1
  //       ) !== -1
  //   );

  //   await downloadDataAsCSV(duplicateData, "SevantDuplicateEmployees.csv");
  // };

  const handleImport = async (empData, subAgentID, orderMonth, shipmentDate, claimStatus) => {
    if (empData.length === 0) {
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      setOpenImportModal(false);
      return;
    }

    if (!orderMonth) {
      dispatch(setMessage("Please select Claim date", MESSAGE_MODES.error));
      return;
    }
    if (!claimStatus) {
      dispatch(setMessage("Please Select Claim Status", MESSAGE_MODES.error));
      return;
    }

    const subAgent = await api.getSubAgentById(subAgentID || "886a8f16-7d65-4a18-8f8e-51c14e71b8ed");
    const location = await api.getLocationById(subAgent.locationID);
    const client = await api.getClientById(subAgent.clientID);
    setOpenImportModal(false);
    setLoading(true);
    const ttlLength = empData.length;
    let arr = [];
    let updateArr = [];
    let fileData = [];
    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      const emp = { ...data };
      if (!emp.pat_name_f && !emp.pat_dob) continue;
      emp.clientID = client.id;
      emp.clientName = client.name;
      emp.locationID = location.id;
      emp.locationName = location.name;
      emp.subAgentID = subAgent.id;
      emp.subAgentName = subAgent.name;
      emp.payerid = emp.payerid.padStart(5, 0);
      emp.pat_phone = phoneFormatter(emp.pat_phone || "");
      emp.bill_phone = phoneFormatter(emp.bill_phone || "");
      emp.ins_phone = phoneFormatter(emp.ins_phone || "");
      emp.other_payer_phone = phoneFormatter(emp.other_payer_phone || "");
      emp.pat_dob = parseClaimDate(emp.pat_dob || "");
      emp.ins_dob = parseClaimDate(emp.ins_dob || "");
      emp.other_ins_dob = parseClaimDate(emp.other_ins_dob || "");
      emp.createdAt = moment(`${orderMonth} 20:00:00`).toISOString();
      emp.units_1 = parseFloat(emp.units_1);
      emp.status = claimStatus;
      emp.ins_number = RemoveSpaceAndChar(emp.ins_number);
      emp.orderId = emp.remote_claimid.slice(7);
      emp.pat_name_f = getValidDep(emp.pat_name_f || "");
      emp.pat_name_l = getValidDep(emp.pat_name_l || "");
      emp.pat_name_m = getValidDep(emp.pat_name_m || "");
      emp.ins_name_f = getValidDep(emp.ins_name_f || "");
      emp.ins_name_l = getValidDep(emp.ins_name_l || "");
      emp.ins_name_m = getValidDep(emp.ins_name_m || "");

      if (claimStatus !== "submitted") {
        const findClaim = employeeClaims.find(
          (f) => f.remote_claimid.trim().toLowerCase() === emp.remote_claimid.trim().toLowerCase()
        );
        if (findClaim) {
          await graphqlApi.updateEmployeeClaimStatusGQL(findClaim, claimStatus);
          updateArr.push(emp);
        }
        continue;
      }
      let proc_array = [];
      const procArr = Object.keys(emp).filter((f) => f.includes("proc_code"));
      procArr.map((key, index) => {
        const i = index + 1;
        const splitDiagRef = emp[`diag_ref_${i}`].split(",");
        const obj = {
          proc_code: emp[`proc_code_${i}`],
          from_date: parseClaimDate(emp[`from_date_${i}`]),
          diag_ref_1: splitDiagRef[0],
          diag_ref_2: splitDiagRef[1],
          diag_ref_3: splitDiagRef[2],
          diag_ref_4: splitDiagRef[3],
          charge: emp[`charge_${i}`],
          units: emp[`units_${i}`],
          place_of_service: emp[`place_of_service_${i}`],
          mod1: emp[`mod1_${i}`],
          mod2: emp[`mod2_${i}`],
          mod3: emp[`mod3_${i}`],
          mod4: emp[`mod4_${i}`],
          [`remote_chgid_${i}`]: emp[`remote_chgid_${i}`],
        };
        proc_array.push(obj);
      });
      emp.proc_array = proc_array;
      const {
        prov_name_f,
        prov_name_l,
        prov_name_m,
        prov_npi,
        prov_taxid,
        prov_taxid_type,
        prov_taxonomy,
        ord_prov_name_f,
        ord_prov_name_l,
        ord_prov_name_m,
        ord_prov_npi,
        ref_name_f,
        ref_name_l,
        ref_name_m,
        ref_npi,
      } = emp;
      const renderProviderID = providers.find(
        (f) => f.lastName.trim().toLowerCase() === prov_name_l.trim().toLowerCase()
      );
      const referingProviderID = providers.find(
        (f) => f.lastName.trim().toLowerCase() === ref_name_l.trim().toLowerCase()
      );
      const orderingProviderID = providers.find(
        (f) => f.lastName.trim().toLowerCase() === ord_prov_name_l.trim().toLowerCase()
      );
      emp.provider = {
        prov_name_f,
        prov_name_l,
        prov_name_m,
        prov_id: renderProviderID?.id || "",
        prov_npi,
        prov_taxid,
        prov_taxid_type,
        prov_taxonomy,
        ord_prov_id: orderingProviderID?.id || "",
        ord_prov_name_f,
        ord_prov_name_l,
        ord_prov_name_m,
        ord_prov_npi,
        ref_id: referingProviderID?.id || "",
        ref_name_f,
        ref_name_l,
        ref_name_m,
        ref_npi,
      };
      emp.employeeID = employees.find((f) => f.schrID === emp.pcn.trim())?.id ?? "";
      emp.updatedByName = loginUser.name;
      emp.updatedBy = loginUser.sub;

      const findClaim = employeeClaims.find(
        (f) => f.remote_claimid.trim().toLowerCase() === emp.remote_claimid.trim().toLowerCase()
      );
      if (findClaim) {
        continue;
      }

      try {
        const res = await graphqlApi.createClaimGQL(emp);
        if (res.data) {
          fileData.push(res.data);
        }
        if (res) arr.push(emp);
      } catch (err) {
        console.log("Error:-", err);
      }
    }

    const fileName = `claim/audit_logs_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
    await api.saveFileOnBucket(fileData, fileName);
    setLoading(false);
    dispatch(fetchAllEmployeeClaims(timeFilter, pageNo, filter, false, appContext.users));
    if (updateArr.length > 0)
      dispatch(setMessage(`${updateArr.length} Claim(s) Updated Successfully!`, MESSAGE_MODES.success));
    if (arr.length > 0) dispatch(setMessage(`${arr.length} Claim(s) Imported Successfully!`, MESSAGE_MODES.success));
  };

  const searchRecord = (firstName, lastName, zip, ins_number, dob) => {
    return employees.find((item) => {
      if (firstName && lastName && ins_number && dob) {
        return (
          item.firstName.toLowerCase() === firstName.toLowerCase() &&
          item.lastName.toLowerCase() === lastName.toLowerCase() &&
          item.insuranceCompany === ins_number &&
          item.dob === dob
        );
      } else if (firstName && lastName && dob) {
        return (
          item.firstName.toLowerCase() === firstName.toLowerCase() &&
          item.lastName.toLowerCase() === lastName.toLowerCase() &&
          item.dob === dob
        );
      } else if (firstName && lastName && ins_number) {
        return (
          item.firstName.toLowerCase() === firstName.toLowerCase() &&
          item.lastName.toLowerCase() === lastName.toLowerCase() &&
          item.insuranceCompany === ins_number
        );
      } else if (firstName && lastName && zip) {
        return (
          item.firstName.toLowerCase() === firstName.toLowerCase() &&
          item.lastName.toLowerCase() === lastName.toLowerCase() &&
          item.zip === zip
        );
      } else if (firstName && lastName) {
        return isMatchString(item.firstName, item.lastName, firstName, lastName);
      }

      // Add more conditions if needed...

      return false; // No match found
    });
  };

  const handleImportClaims = async (empData, subAgentID, orderMonth, shipmentDate, claimStatus) => {
    if (empData.length === 0) {
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      setOpenImportModal(false);
      return;
    }

    if (!orderMonth) {
      dispatch(setMessage("Please select Claim date", MESSAGE_MODES.error));
      return;
    }
    if (!claimStatus) {
      dispatch(setMessage("Please Select Claim Status", MESSAGE_MODES.error));
      return;
    }

    const subAgent = await api.getSubAgentById(subAgentID || "886a8f16-7d65-4a18-8f8e-51c14e71b8ed");
    const location = await api.getLocationById(subAgent.locationID);
    const client = await api.getClientById(subAgent.clientID);
    setOpenImportModal(false);
    const ttlLength = empData.length;
    let arr = [];
    let updateArr = [];
    let fileData = [];

    let newClaims = [];
    let emps = [];
    for (let i = 0; i < ttlLength; i++) {
      const { data } = empData[i];
      const claim = trimObject(data);
      claim.clientID = client.id;
      claim.clientName = client.name;
      claim.locationID = location.id;
      claim.locationName = location.name;
      claim.subAgentID = subAgent.id;
      claim.subAgentName = subAgent.name;
      claim.pat_phone = phoneFormatter(claim.phone || "");
      claim.ins_phone = phoneFormatter(claim.phone || "");
      claim.pat_dob = parseClaimDate(claim.dob || "");
      claim.ins_dob = parseClaimDate(claim.dob || "");
      claim.pat_name_f = getValidDep(claim.firstName || "");
      claim.pat_name_l = getValidDep(claim.lastName || "");
      claim.pat_name_m = getValidDep(claim.middleName || "");
      claim.ins_name_f = getValidDep(claim.firstName || "");
      claim.ins_name_l = getValidDep(claim.lastName || "");
      claim.ins_name_m = getValidDep(claim.middleName || "");
      claim.dos = parseClaimDate(claim.dos);
      const findVal = INSURANCE_PROVIDER.find(
        (f) => f.label.toLowerCase().replace(/\s/g, "") === claim.ins_name.toLowerCase().replace(/\s/g, "")
      );
      claim.payer_name = findVal?.label || "";
      claim.payerid = findVal?.value || "";
      claim.updatedByName = loginUser.name;
      claim.updatedBy = loginUser.sub;

      const existEmp = searchRecord(claim.firstName, claim.lastName, claim.zip, claim.ins_number, claim.dob);
      if (!existEmp) {
        existEmp = searchRecord(claim.firstName, claim.lastName, claim.zip, null, claim.dob);
      }

      if (!existEmp) {
        existEmp = searchRecord(claim.firstName, claim.lastName, claim.zip, claim.ins_number, null);
      }

      if (!existEmp) {
        existEmp = searchRecord(claim.firstName, claim.lastName, claim.zip);
      }
      if (!existEmp) {
        existEmp = searchRecord(claim.firstName, claim.lastName);
      }

      if (existEmp) {
        claim.employeeID = existEmp.id;
        emps.push({ employee: existEmp, claim });
      } else {
        newClaims.push(claim);
      }
    }
  };

  const handleRejcetedImport = async (empData, subAgentID, orderMonth, shipmentDate, claimStatus) => {
    if (empData.length === 0) {
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      setOpenImportModal(false);
      return;
    }

    if (!orderMonth) {
      dispatch(setMessage("Please select Claim date", MESSAGE_MODES.error));
      return;
    }
    if (!claimStatus) {
      dispatch(setMessage("Please Select Claim Status", MESSAGE_MODES.error));
      return;
    }
    setOpenImportModal(false);
    setLoading(true);
    const ttlLength = empData.length;
    let arr = [];
    let updateArr = [];

    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      const emp = { ...data };

      const findClaim = employeeClaims.find(
        (f) => f.remote_claimid.trim().toLowerCase() === emp.remote_claimid.trim().toLowerCase()
      );
      if (findClaim) {
        const logStatus = emp.messages.includes("ERA DENIAL") ? "denied" : "rejected";
        // await graphqlApi.updateEmployeeClaimStatusGQL(findClaim, logStatus,emp.messages);
        updateArr.push({
          ...findClaim,
          message: emp.messages,
          status: logStatus,
          local_status_time: emp.local_status_time,
        });
      }
    }

    // const findClaim = employeeClaims.map((f)=>{
    //   return {...f , message: 'Submit for Bill', status: 'submitted', local_status_time: f.createdAt }
    // });

    const fileName = `claim/audit_logs_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
    await api.saveFileOnBucket(updateArr, fileName);

    setLoading(false);
    dispatch(fetchAllEmployeeClaims(timeFilter, pageNo, filter, false, appContext.users));
    if (updateArr.length > 0)
      dispatch(setMessage(`${updateArr.length} Claim(s) Updated Successfully!`, MESSAGE_MODES.success));
    if (arr.length > 0) dispatch(setMessage(`${arr.length} Claim(s) Imported Successfully!`, MESSAGE_MODES.success));
  };

  const handleDownloadExportCsv = async () => {
    if (usersToMap.length === 0) return;
    setExportLoading(true);

    const selectedColumns = personalize.reduce((accumulator, item) => {
      if (item.isCheck && item.itemKey) {
        accumulator.push({ itemKey: item.itemKey, title: item.title, type: item.type });
      }
      return accumulator;
    }, []);

    try {
      // selectedColumns.push({ itemKey: "note", title: "note", type: "text" });

      await dispatch(exportClaimDataToExcel(selectedColumns));
    } catch (error) {
      console.log("Erorr", error);
    } finally {
      setExportLoading(false);
    }
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? filteredEmployeeClaims.map((t) => t.id) : []);
  };

  const handleConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);

    if (!isConfirm) {
      setUserToDelete(null);
      return;
    }
    const empsFilter = filteredEmployeeClaims.filter(
      (f) => checkboxes.includes(f.id) && (f.status === CLAIM_SUBMIT_STATUS.draft || CUSTOM_INS.includes(f.payerid))
    );

    let ttl = empsFilter.length;

    if (
      ttl === 0 &&
      userToDelete?.status !== CLAIM_SUBMIT_STATUS.draft &&
      !CUSTOM_INS.includes(userToDelete?.payerid) &&
      loginUser?.phone_number !== usersNo.ibrahim
    ) {
      dispatch(setMessage("Only draft claims are able to be cancel", MESSAGE_MODES.error));
      setCheckboxes([]);
      return;
    }
    try {
      const newDate = moment().format("YYYY-MM-DDTHH:mm");
      dispatch(
        updateStatusEmployeeClaimAsync({
          claimsList: userToDelete ? [userToDelete] : empsFilter,
          newDate,
          newStatus: { value: CLAIM_SUBMIT_STATUS.cancelled },
        })
      );
      // dispatch(deleteEmployeeClaim(userToDelete, empsFilter));
      setUserToDelete(null);
      setCheckboxes([]);
      setAllSelected(false);
      // getEmployeeClaims(timeFilter, pageNo, filter);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleBulkSubmitClaims = async (isConfirm) => {
    setOpenClaimSubmitConfirmation(false);
    if (!isConfirm) {
      return;
    }
    const claimDraft = filteredEmployeeClaims.filter(
      (f) => checkboxes.includes(f.id) && f.status === CLAIM_SUBMIT_STATUS.draft
    );
    const checkStatus = {
      rejected: "rejected",
      denied: "denied",
      sent: "sent",
      submitted: "submitted",
    };
    const claimRecjectesAndDenied = filteredEmployeeClaims.filter(
      (f) => checkboxes.includes(f.id) && checkStatus[f.status]
    );
    if (claimDraft.length > 0) {
      await dispatch(
        updateBulkSubmitEmployeeClaimAsync({
          claimSubmissions: claimDraft,
          claimStatus: "submitted",
          claimtype: "claim",
        })
      );
    }
    if (claimRecjectesAndDenied.length > 0) {
      await dispatch(
        updateBulkSubmitEmployeeClaimAsync({
          claimSubmissions: claimRecjectesAndDenied,
          claimStatus: "submitted",
          claimtype: "reclaim",
        })
      );
    }
    setCheckboxes([]);
    setAllSelected(false);
  };

  const tdFormat = (item, row) => {
    return row[item];
  };
  const tdFormatTitle = (item, row) => {
    return row[item];
  };

  const tdStatus = (item, row) => {
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={`${row.message || ""}${row.crossover_carrier ? `\n ${row.crossover_carrier}` : ""}`}
        onClick={() => handleCellClick(item.itemKey, row)}
      >
        <div
          className="statusPill custom-ellipsis d-block"
          style={{
            backgroundColor: item.colorObj[row[item.itemKey]],
          }}
        >
          {item.type === TABLE_COLUMN_TYPES.text
            ? CapsFirstLetter(row[item.itemKey])
            : StatusFormatter(row[item.itemKey])}
        </div>
      </td>
    );
  };

  const onRestoreEmpClaims = async (record) => {
    if (!record) return;

    try {
      const newDate = moment().format("YYYY-MM-DDTHH:mm");
      const { additional = {} } = record;

      const prevStatus = additional.prevStatus || CLAIM_SUBMIT_STATUS.draft;

      dispatch(
        updateStatusEmployeeClaimAsync({
          claimsList: [record],
          newDate,
          newStatus: { value: prevStatus },
        })
      );
      setAllSelected(false);
    } catch (err) {
      console.log("Error:-", err);
    }
  };

  const onHandleConfirmation = (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) {
      return;
    }
    switch (type) {
      case CONFIRMATION_TYPE.RESTORE_CLAIM:
        onRestoreEmpClaims(openConfirmation.row);
        break;
    }
  };

  const renderTd = (item, row) => {
    if (item.itemKey === "status" || item.itemKey === "invoiceStatus") return tdStatus(item, row);
    if (item.itemKey === "ref_provider") {
      return (
        <td
          key={item.id}
          className="ellipsis"
          style={{
            textAlign: item.textAlign,
            textOverflow: item.textOverflow,
          }}
        >
          {toTitleCase(row?.provider?.ref_name) || ""}
        </td>
      );
    }

    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormatTitle(item.itemKey, row)}
      >
        {tdFormat(item.itemKey, row)}
      </td>
    );
  };

  const handleCreateClaim = async () => {
    if (checkboxes.length === 1) {
      let res = filteredEmployeeClaims.find((f) => checkboxes.includes(f.id));
      dispatch(setClaimReSubmissionModal([{ employeeId: res.employeeID }]));
    } else {
      dispatch(setClaimReSubmissionModal([]));
    }
  };

  const handleOpenEraFile = async (user) => {
    try {
      const eraid = user.eraIds.split(",").pop();
      let path = await api.getPatientERA({ ...user, eraid: eraid });
      if (!path) {
        window.open(`https://medflow-images.s3.eu-west-1.amazonaws.com/era_view.pdf`);
        return;
      }
      const res = await Storage.get(path, {
        bucket: CONFIG.eligibilityBucket,
      });

      window.open(`${res}`);
    } catch (err) {
      console.log("Error:-", err.message);
    }
  };

  const handleAssignClaim = (userToAssign, message) => {
    let claimsToAssign = filteredEmployeeClaims.filter((f) => checkboxes.indexOf(f.id) !== -1);
    setShowAssignModal(false);

    try {
      const updatedClaimsArr = claimsToAssign.map((claim) => ({
        id: claim.id,
        version: claim.version,
      }));

      const params = {
        items: updatedClaimsArr,
        assignDetails: {
          message,
          userId: userToAssign.id,
          name: `${userToAssign.firstName} ${userToAssign.lastName}`,
        },
        user: {
          updatedBy: loginUser.sub,
          updatedByName: loginUser.name,
        },
      };

      dispatch(assignEmployeeClaimAsync(params));
      setCheckboxes([]);
    } catch (error) {
      console.log(error);
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
    }
  };

  const handelViewInvoice = (data) => {
    console.log("DataInInvoice", data);
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;

      case TABLE_QUICK_TOOLS.edit:
        dispatch(setClaimReSubmissionModal([{ ...row, status: CLAIM_EDIT_STATUS[row.status] }]));
        if (
          (row.status === CLAIM_SUBMIT_STATUS.denied || row.status === CLAIM_SUBMIT_STATUS.rejected) &&
          !row.acknowledgeBy
        ) {
          dispatch(acknowledgeClaim(row, loginUser));
        }
        break;

      case TABLE_QUICK_TOOLS.notes:
        !appContext.isReadOnly() && setClaimNote(row);
        break;

      case "status":
        row.eraIds && setOpenEraModal(row);
        break;

      case "eraIds":
        row.eraIds && handleOpenEraFile(row);
        break;
      case "invoiceID":
        handelViewInvoice(row);
        break;

      default:
        break;
    }
  };

  const customDropDownOptsForRow = (row) => {
    const options = [{ value: "logs", title: "Audit Trail" }];

    const status = row?.status?.toLowerCase();

    if (["00001", "00002"].includes(row.payerid) && permission.claiminvoice.generateInvoices) {
      options.push({ value: "sendInvoice", title: "View Invoice" });
    }

    if (status === "cancelled") {
      options.push({
        value: "restore",
        title: "Restore",
      });
    } else if (
      (status === "draft" || (status === "denied" && getFloatVal(row.total_paid) <= 0)) &&
      permission[EMPLOYEE_CLAIM]?.delete
    ) {
      options.push({
        value: "delete",
        title: "Cancel",
      });
    }

    return options;
  };

  const showClaimCount = () => {
    if (filter?.assignToMe) {
      return filteredEmployeeClaims.length || 0;
    }
    return totalRecord ?? totalRecord;
  };

  const getLabClients = (ids) => {
    const claimClients = locations.filter((loc) => ids.indexOf(loc.id) !== -1);

    return claimClients.reduce((accumulator, currentObject) => {
      accumulator[currentObject.id] = currentObject;
      return accumulator;
    }, {});
  };

  const handleInvoice = async (row) => {
    try {
      setLoading(true);

      const setting = await getCompanySetting();

      const selectedUsersClaims = parseInvoiceData([row], loginUser, locations, setting);

      dispatch(addSelectedInvoice({ selectedInvoice: selectedUsersClaims[0], isBulk: false }));
    } catch (error) {
      console.log("Error", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // Send Bulk Invoices Code Here

  const sendBulkInvoices = () => {
    setBulkInvoiceConfirmation(true);
  };

  const mergeEmployeeClaims = (employeeClaimData) => {
    const mergedClaims = {};

    employeeClaimData.forEach((employee) => {
      const { employeeID, payer_name, clientID, details, claimID, firstName, lastName, ...otherInfo } = employee;

      const enrichedDetails = details.map((detail) => ({
        ...detail,
        firstName,
        lastName,
        claimID,
        employeeID,
      }));

      let key, uniqueInfo;
      if (payer_name === "Bill Client") {
        key = clientID;

        const userExist = locations.find((item) => item.clientID === clientID);

        const { firstName: clientFistName, lastName: clientLastName } = splitClientName(userExist?.name);

        uniqueInfo = { employeeID, clientID, payer_name, firstName: clientFistName, lastName: clientLastName };
      } else if (payer_name === "Bill Patient") {
        key = employeeID;
        uniqueInfo = { employeeID, clientID, payer_name, firstName, lastName };
      }

      if (!mergedClaims[key]) {
        mergedClaims[key] = { ...uniqueInfo, details: enrichedDetails, ...otherInfo };
      } else {
        mergedClaims[key].details = [...mergedClaims[key].details, ...enrichedDetails];
        if (payer_name === "Bill Patient") {
          mergedClaims[key].payer_name = payer_name;
          mergedClaims[key].clientID = clientID;
        }
      }
    });

    Object.keys(mergedClaims).forEach((key) => {
      mergedClaims[key].details.sort((a, b) => a.firstName.localeCompare(b.firstName));
    });

    return Object.values(mergedClaims);
  };

  const uniqueEmployeeAndClient = (arr) => {
    const seen = {
      employeeID: {},
      clientID: {},
    };
    return arr.filter((item) => {
      if (item.payer_name === "Bill Patient") {
        if (!seen.employeeID[item.employeeID]) {
          seen.employeeID[item.employeeID] = true;
          return true;
        }
        return false;
      } else if (item.payer_name === "Bill Client") {
        if (!seen.clientID[item.clientID]) {
          seen.clientID[item.clientID] = true;
          return true;
        }
        return false;
      } else {
        return true;
      }
    });
  };

  const getClaimDetails = async (dataArr) => {
    const promises = dataArr.map((item) => {
      const isClient = item.payer_name === "Bill Client";
      const payload = {
        id: isClient ? item.companyID : item.employeeID,
        type: isClient ? "client" : "employee",
      };

      return api.getInvoiceDetails(payload).then((result) => {
        return { ...item, details: result.records };
      });
    });

    const finalArr = await Promise.all(promises);
    return finalArr;
  };

  const handleClaimImport = async (fileData) => {
    setOpenClaimImport(false);
    try {
      if (fileData.length === 0) return;
      dispatch(setLoader(true));
      let arr = [];
      const ttlLength = fileData.length;
      const res = await api.getCompanySetting();
      for (let index = 0; index < ttlLength; index++) {
        const { data } = fileData[index];
        arr.push({
          ...data,
          settingID: res.id,
          clientID: userCompanyID.get(),
          requestedBy: loginUser.sub,
          requestedByName: loginUser.name,
        });
      }

      const fileName = `claim_import/claims_import${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
      console.log("import Array", arr);
      await api.saveFileOnBucket(arr, fileName);
      dispatch(setLoader(false));
      dispatch(setMessage(`${arr.length} claims has been uploaded for creation`, MESSAGE_MODES.success));
    } catch (err) {
      dispatch(setMessage(err, MESSAGE_MODES.error));
      dispatch(setLoader(false));
    }
  };

  const confirmSendBulkInvoice = async (isConfirm) => {
    setBulkInvoiceConfirmation(false);
    if (!isConfirm) return;
    setLoading(true);
    const billPatientOrClient = ["00001", "00002"];

    const filteredRows = filteredEmployeeClaims.filter(
      (item) => checkboxes.includes(item.id) && billPatientOrClient.includes(item.payerid)
    );
    const setting = await getCompanySetting();

    const selectedUsersClaims = parseInvoiceData(filteredRows, loginUser, locations, setting);

    if (selectedUsersClaims.length === 1) {
      dispatch(addSelectedInvoice({ selectedInvoice: selectedUsersClaims[0], isBulk: false }));
      setLoading(false);
      return;
    }

    setLoading(false);
    dispatch(addSelectedClaim(selectedUsersClaims));
  };

  const handleRemoveAssignTo = async () => {
    const result = filteredEmployeeClaims.filter((item) => checkboxes.includes(item.id) && item.assign_details);
    if (result.length === 0) return;

    try {
      const params = result.map((e) => ({ id: e.id, version: e.version }));
      const res = await api.removeAssignTo({ items: params });
      setCheckboxes([]);
      dispatch(
        setMessage(
          `Successfully Removed the Assigned to on ${params.length} claim${params.length > 1 ? "s" : ""}`,
          MESSAGE_MODES.success
        )
      );
    } catch (ex) {
      dispatch(setMessage("Fail to remove Assigned To", MESSAGE_MODES.error));
    }
  };

  const showRemoveAssignTo = () => {
    if (checkboxes.length === 0) return;
    const result = filteredEmployeeClaims.filter((item) => checkboxes.includes(item.id) && item.assign_details);
    return result.length > 0;
  };

  const personalisationKeyStatus = useRef("draft");

  useEffect(() => {
    const newStatus = filter?.status || "draft";
    if (personalisationKeyStatus.current !== newStatus) {
      personalisationKeyStatus.current = newStatus;
    }
  }, [filter.status]);

  const handleDownloadERA = async () => {
    const employeeClaimsList = filteredEmployeeClaims.filter((item) => checkboxes.includes(item.id) && item.eraIds);

    const eraIds = getERAUniqueIds(employeeClaimsList);

    const selectedEraIds = allSelected ? [] : eraIds;

    if (!allSelected && eraIds.length === 0) {
      setCheckboxes([]);
      setAllSelected(false);
      return;
    }

    await api.downloadClaimERA({ params: { ...timeFilter, ...filter, ids: selectedEraIds } });

    setCheckboxes([]);
    setAllSelected(false);
  };

  const handleConfirmCancelClaim = async (isConfirm) => {
    setOpenDeleteConfirmation(false);

    if (!isConfirm) {
      setUserToDelete(null);
      return;
    }
    const empsFilter = filteredEmployeeClaims.filter(
      (f) => checkboxes.includes(f.id) && f.status.toLowerCase() !== "paid" && f.status.toLowerCase() !== "partial"
    );

    const employeesFilterList = userToDelete ? [userToDelete] : empsFilter;

    const eraCancel = employeesFilterList.map((item) => {
      return { id: item.id, version: item.version };
    });

    if (filter?.status === "paid") {
      dispatch(setMessage("Paid Status Claim Can't be cancelled", MESSAGE_MODES.error));
      return;
    }

    if (allSelected && Object.keys(filter).length === 0) {
      dispatch(setMessage("Select the filter", MESSAGE_MODES.error));
      return;
    }

    if (!allSelected && eraCancel.length === 0) {
      dispatch(setMessage("No Claim to Process as Cancel", MESSAGE_MODES.error));
      return;
    }

    const eraCancelClaimsList = allSelected ? [] : eraCancel;

    const selectedFilter = allSelected ? { ...filter } : filter;

    const newDate = moment().format("YYYY-MM-DDTHH:mm");

    dispatch(
      updateStatusEmployeeClaimAsync({
        claimsList: employeesFilterList,
        eraCancel: eraCancelClaimsList,
        newDate,
        filter: selectedFilter,
        timeFilter,
        newStatus: { value: CLAIM_SUBMIT_STATUS.cancelled },
        isNewCall: true,
      })
    );
    setCheckboxes([]);
    setAllSelected(false);
  };

  const cancelClaimCount = () => {
    let count = showClaimCount();

    if (!allSelected) {
      const empsFilter = filteredEmployeeClaims.filter(
        (f) => checkboxes.includes(f.id) && f.status.toLowerCase() !== "paid" && f.status.toLowerCase() !== "partial"
      );
      count = empsFilter.length;
    }
    if (userToDelete) count = 1;
    return `Are you sure, you want to cancel ${count} claim?`;
  };

  return (
    <div style={{ flex: 1 }}>
      {exportLoading && <Loader />}
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  {t("employeeClaim")} ({formatNumber(showClaimCount())})
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />

                  <Icon
                    handleClick={() => {
                      if (filteredEmployeeClaims && filteredEmployeeClaims.length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                    }}
                    label={!allSelected ? "Select All" : "Deselect All"}
                    title={!allSelected ? "Select All" : "Deselect All"}
                    iconType={"selectAllIcon"}
                  />

                  <FilterSelect setTimeFilter={(obj) => dispatch(setTimeFilter(obj))} />
                  {(permission[EMPLOYEE_CLAIM]?.delete || !CONFIG.isLabType) && (
                    <Icon
                      handleClick={() => setOpenDeleteConfirmation(true)}
                      label="Cancel"
                      title="Cancel"
                      disabled={
                        filteredEmployeeClaims.filter(
                          (f) => checkboxes.includes(f.id) && f.status !== CLAIM_SUBMIT_STATUS.cancelled
                        ).length === 0
                      }
                      iconType={"binIcon"}
                    />
                  )}

                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    label={t("personalize")}
                    iconType={"personalizeIcon"}
                  />

                  <Icon
                    handleClick={handleDownloadExportCsv}
                    title={"Export Claim"}
                    label={"Export Data"}
                    iconType={"XLSIcon"}
                  />

                  <Icon
                    handleClick={handleDownloadERA}
                    title={"Export ERA"}
                    label={"Export ERA"}
                    iconType={"messageIcon"}
                    className="position-relative"
                  />

                  <Icon
                    handleClick={() => {
                      setOpenClaimImport(true);
                    }}
                    title={"Import Claim"}
                    iconType="importIcon"
                    label="Import"
                  />

                  {/* <ExportToExcel
                    selectedItem={
                      personalize.length > 0 && [
                        ...personalize.filter((m) => m.isCheck && m.itemKey),
                        { itemKey: "note" },
                      ]
                    }
                    items={filteredEmployeeClaims.map((e) => {
                      return {
                        ...e,
                        from_date_1: moment(e.from_date_1).format("MM-DD-YYYY"),
                        reSubmissionDate: moment(e.reSubmissionDate).format("MM-DD-YYYY"),
                        paymentCheckNo: e.paymentCheckNo || "",
                        total_charge: formatCurrency(e.total_charge),
                        paidAmount: formatCurrency(e.paidAmount),
                        ...(e.checkDate && { checkDate: moment(e.checkDate).format("MM-DD-YYYY") }),
                      };
                    })}
                    filter={filter}
                    title={"Claim Listing"}
                    sortBy={sortBy}
                  /> */}

                  {permission?.claims.createDraftClaims === true && (
                    <Icon
                      title={t("createClaim")}
                      label={t("createClaim")}
                      iconType={"acknowledgedPositiveIcon"}
                      handleClick={() => handleCreateClaim()}
                    />
                  )}

                  {/* <Icon
                    handleClick={() => {
                      setOpenImportModal(true);
                    }}
                    title={t("loadOrderData")}
                    label={"Import"}
                    iconType={"importIcon"}
                  /> */}

                  <Icon
                    title={t("updateClaimStatus")}
                    label={t("updateClaimStatus")}
                    iconType={"acknowledgedPositiveIcon"}
                    disabled={checkboxes.length === 0}
                    handleClick={() => setChangeClaimStatusModal(true)}
                  />

                  {permission?.claims.submitClaims === true && (
                    <Icon
                      title={t("sendClaim")}
                      label={t("sendClaim")}
                      iconType={"acknowledgedPositiveIcon"}
                      disabled={
                        filteredEmployeeClaims.filter(
                          (f) => checkboxes.includes(f.id) && STATUS_KEYS_RE_SUBMIT[f.status]
                        ).length === 0
                      }
                      handleClick={() => setOpenClaimSubmitConfirmation(true)}
                    />
                  )}

                  {permission[USERS]?.access && (
                    <Icon
                      title={t("assignTo")}
                      label={t("assignTo")}
                      iconType={"addUserIcon"}
                      disabled={checkboxes.length === 0}
                      handleClick={() => setShowAssignModal(true)}
                    />
                  )}
                  {permission[USERS]?.access && showRemoveAssignTo() && (
                    <Icon
                      title={t("removeAssignTo")}
                      label={t("removeAssignTo")}
                      iconType={"addUserIcon"}
                      disabled={checkboxes.length === 0}
                      handleClick={handleRemoveAssignTo}
                    />
                  )}
                  {permission?.claims.editEra === true && (
                    <Icon
                      handleClick={() => {
                        dispatch(setOpenPaymentProcess(true));
                        dispatch(setShowFromBarCode(false));
                        dispatch(setShowFromClientSetting(false));
                        const claims = employeeClaims.filter((item) => checkboxes.some((id) => item.id === id));
                        dispatch(setClaimsIDs(claims));
                        setCheckboxes([]);
                      }}
                      title={t("postPayment")}
                      label={t("postPayment")}
                      iconType={"personalizeIcon"}
                      className="position-relative"
                      exclamationMark={!!LSProcessPaymentData.get()}
                      // disabled={!LSProcessPaymentData.get() && checkboxes.length === 0}
                    />
                  )}
                  {permission?.claiminvoice.sendInvoices === true && (
                    <Icon
                      handleClick={sendBulkInvoices}
                      title={"Send Invoice"}
                      label={"Send Invoice"}
                      iconType={"messageIcon"}
                      className="position-relative"
                      // exclamationMark={!!LSProcessPaymentData.get()}
                      disabled={checkboxes.length === 0}
                    />
                  )}

                  <Icon
                    handleClick={() => setOpenReportModal(true)}
                    title={"Report"}
                    label={"Report"}
                    iconType={"messageIcon"}
                    className="position-relative"
                  />
                </div>

                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={(obj) => dispatch(setFilter({ ...obj }))}
                    filter={filter}
                    isUsersTable={true}
                    employeeClaim={true}
                    personalisationData={DEFAULT_PERSONALIZE}
                  />
                )}

                <div className="dashboard-wrapper">
                  <div className="d-flex w-100">
                    <SingleStatsCard
                      title={"Draft"}
                      totalCounts={statsObj.draft}
                      amount={currencyformatter.format(statsObj.draftAmount || 0)}
                      filterKey="status"
                      value="draft"
                      handelClick={(obj) => dispatch(setFilter({ ...filter, ...obj }))}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Sent"}
                      totalCounts={statsObj.submitted}
                      amount={currencyformatter.format(statsObj.submittedAmount || 0)}
                      filterKey="status"
                      value="submitted"
                      handelClick={(obj) => dispatch(setFilter({ ...filter, ...obj }))}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Rejected"}
                      totalCounts={statsObj.rejectted}
                      amount={currencyformatter.format(statsObj.rejecttedAmount || 0)}
                      filterKey="status"
                      value="rejected"
                      handelClick={(obj) => dispatch(setFilter({ ...filter, ...obj }))}
                      color="black"
                    />
                    {/* <SingleStatsCard
                      title={"Approved"}
                      totalCounts={statsObj.approved}
                      amount={currencyformatter.format(statsObj.approvedAmount || 0)}
                      filterKey="status"
                      value="approved"
                      handelClick={(obj) => dispatch(setFilter({ ...filter, ...obj }))}
                      color="black"
                    /> */}
                    {/* <SingleStatsCard
                      title={"Partially Processed"}
                      totalCounts={statsObj.partialProcessed}
                      amount={currencyformatter.format(statsObj.partialProcessedAmount || 0)}
                      filterKey="status"
                      value="partial"
                      handelClick={(obj) => dispatch(setFilter({ ...filter, ...obj }))}
                      color="black"
                    /> */}
                    <SingleStatsCard
                      title={"Paid"}
                      totalCounts={statsObj.paid}
                      amount={currencyformatter.format(statsObj.paidAmount || 0)}
                      filterKey="status"
                      value="paid"
                      handelClick={(obj) => dispatch(setFilter({ ...filter, ...obj }))}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Denied"}
                      totalCounts={statsObj.denied}
                      amount={currencyformatter.format(statsObj.deniedAmount || 0)}
                      filterKey="status"
                      value="denied"
                      handelClick={(obj) => dispatch(setFilter({ ...filter, ...obj }))}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Cancelled"}
                      totalCounts={statsObj.cancelled}
                      amount={currencyformatter.format(statsObj.cancelledAmount || 0)}
                      filterKey="status"
                      value="cancelled"
                      handelClick={(obj) => dispatch(setFilter({ ...filter, ...obj }))}
                      color="black"
                    />
                    <SingleStatsCard
                      title={"Patient/Client"}
                      totalCounts={statsObj.patientClient}
                      amount={currencyformatter.format(statsObj.patientClientAmount || 0)}
                      filterKey="status"
                      value="patientClient"
                      handelClick={(obj) => dispatch(setFilter({ ...filter, ...obj }))}
                      color="black"
                    />
                  </div>
                </div>
                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={pageNumbers}
                  totalRecord={totalRecord}
                  filter={filter}
                  showSelectedRecord
                  totalSelected={checkboxes.length}
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <MainTable
                  individualRowCssClass={(row) =>
                    row.status &&
                    !row.acknowledgeBy &&
                    (row.status === CLAIM_SUBMIT_STATUS.rejected || row.status === CLAIM_SUBMIT_STATUS.denied) &&
                    "isClaimFalse"
                  }
                  columns={personalize}
                  rows={usersToMap}
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  draggable
                  resizable
                  personalisationKey={personalisationKeyStatus.current}
                  handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                  widthToSkip={155}
                  tools={quickTools}
                  customDropDownOptsForRow={customDropDownOptsForRow}
                  handleDropDownClick={async (type, row) => {
                    if (type === "logs") {
                      setOpenOrderDetail(true);
                      setSelectedOrder(row);
                    }
                    if (type === "delete") {
                      if (row.status === CLAIM_SUBMIT_STATUS.cancelled) return;
                      setOpenDeleteConfirmation(true);
                      setUserToDelete(row);
                    }
                    if (type === "restore") {
                      if (row.status !== CLAIM_SUBMIT_STATUS.cancelled) {
                        dispatch(setMessage("Only Cancelled claim will be restore", MESSAGE_MODES.info));
                        return;
                      }
                      setOpenConfirmation({
                        isShow: true,
                        actionType: CONFIRMATION_TYPE.RESTORE_CLAIM,
                        title: "Claim Restore",
                        message: "Are you Sure? to want to restore this claim",
                        row,
                      });
                    }
                    if (type === "sendInvoice") {
                      try {
                        handleInvoice(row);
                      } catch (error) {
                        console.log("error in sending invoice", error);
                      }
                    }
                  }}
                  selectedRows={checkboxes}
                  handleCellClick={handleCellClick}
                  customColumnCellRenderer={renderTd}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}

      {openPersonalizationModal && (
        // <PersonalizationModal
        //   data={JSON.stringify(personalize)}
        //   handleChange={handlePersonalization}
        //   show={openPersonalizationModal}
        //   handleClose={() => setOpenPersonalizationModal(false)}
        // />
        <PersonalizationModalEmployeeClaim
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
          status={filter?.status || "draft"}
        />
      )}

      {changeClaimStatusModal && (
        <ChangeClaimStatusModal
          selectedClaimIds={checkboxes || []}
          cssClass={"seperateModal"}
          handleClose={(val) => {
            if (val) {
              dispatch(fetchAllEmployeeClaims(timeFilter, pageNo, filter, false, appContext.users));
            }
            setChangeClaimStatusModal(false);
            setCheckboxes([]);
          }}
        />
      )}
      {openEraModal && <EraModal user={openEraModal} handleClose={() => setOpenEraModal(null)} />}
      {openImportModal && (
        <OrderImportModal
          handleClose={() => setOpenImportModal(false)}
          handleImport={handleImportClaims}
          claim
          title="Import Claim Data"
        />
      )}
      {openClaimImport && (
        <ClaimImportModal
          handleClose={() => setOpenClaimImport(false)}
          handleImport={handleClaimImport}
          title="Import Claim Data"
        />
      )}

      {openOrderDetail && (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <OrderLogsModal
            order={selectedOrder}
            show={openOrderDetail}
            user={loginUser}
            handleClose={() => {
              setOpenOrderDetail(false);
              setSelectedOrder("");
            }}
            claim
          />
        </ErrorBoundary>
      )}

      {claimNote && (
        <NotesModal
          handleClose={(obj, level) => {
            setClaimNote(null);
            if (obj) {
              obj.note
                ? dispatch(setMessage("add Notes Successfully", MESSAGE_MODES.success))
                : dispatch(setMessage("Remove Notes Successfully", MESSAGE_MODES.success));
              dispatch(updateNotes(obj));
              if (level === "patient") dispatch(fetchAllEmployees());
            }
          }}
          data={claimNote}
          claim={true}
          user={loginUser}
        />
      )}

      {openReportModal && (
        <ReportFilterModal
          handleClose={() => setOpenReportModal(false)}
          saveFilter={(obj) => {
            setOpenReportModal(false);
          }}
        />
      )}

      {showAssignModal && (
        <AssignClaimModal handleClose={() => setShowAssignModal(false)} handleSave={handleAssignClaim} />
      )}

      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Cancel Claim"
          message={cancelClaimCount()}
          handleConfirm={handleConfirmCancelClaim}
        />
      )}

      {openClaimSubmitConfirmation && (
        <ConfirmationModal
          show={openClaimSubmitConfirmation}
          title="Send Claim"
          message="Are you sure, you want to send selected claim?"
          handleConfirm={handleBulkSubmitClaims}
        />
      )}
      {bulkInvoiceConfirmation && (
        <ConfirmationModal
          show={bulkInvoiceConfirmation}
          title="Bulk Invoices"
          message="Are you sure, you want to review the invoices?"
          handleConfirm={confirmSendBulkInvoice}
          handleClose={() => setBulkInvoiceConfirmation(false)}
        />
      )}
      {openConfirmation.isShow && (
        <ConfirmationModal
          show={openConfirmation.isShow}
          actionType={openConfirmation.actionType}
          title={openConfirmation.title}
          message={openConfirmation.message}
          handleConfirm={onHandleConfirmation}
          closeBtn
        />
      )}
    </div>
  );
};

export default EmployeeClaimAPI;
