import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Row, Col } from "react-bootstrap";
import { CSVReader } from "react-papaparse";
import Select from "react-select";
import DatePicker from "react-date-picker";
import { userCompanyID } from "utils";
import moment from "moment";
import { LAB_CLAIM_STATUS_OPT } from "constant";
import RadioButton from "components/RadioButton/RadioButton";
import { useDispatch, useSelector } from "react-redux";
import { selectedSubAgent } from "store/features/subAgents/subAgentsSelectors";
import { authSelector, selectLoginUser } from "store/features/authentication/authenticationSelectors";

const ClaimImportModal = (props) => {
  const { handleClose, handleImport, title } = props;

  const handleOnDrop = (data) => {
    handleImport(data);
  };
  const handleOnError = (err) => {
    console.log({ err });
  };

  const handleOnRemoveFile = (data) => {
    console.log("Remove Data", data);
  };

  return (
    <Modal show animation={true} backdrop="static" onHide={() => handleClose()} centered size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="form-group-wrapper d-block">
          <div className="col-lg-12 col-12 mb-3">
            <label className="modalLineHeaders mt-0">Upload File Below</label>
          </div>
          <CSVReader
            onDrop={handleOnDrop}
            onError={handleOnError}
            addRemoveButton
            config={{
              header: true,
              skipEmptyLines: true,
            }}
            onRemoveFile={handleOnRemoveFile}
          >
            <span>Drop CSV file here or click to upload.</span>
          </CSVReader>
        </div>
      </Modal.Body>

      <Modal.Footer className="flex-row-reverse">
        <Button variant="secondary" className="headerButton btn-fill" onClick={() => handleClose()}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClaimImportModal;
