import React, { useState, useMemo, useContext, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { MEDICATRE_IDS } from "constant";
import { convertToUpper } from "utils";
import { CUSTOM_INS, CUSTOM_INS_ADV_PLAN, IGNORE_INS } from "constant";
import { isValidIDNumber } from "utils";
import ErrorMessage from "components/Message/ErrorMessage";
import InputField from "components/InputField/InputField";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import api from "api";
import { AppContext } from "context/app-context";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { getStartAndEndDateFromEligibility } from "utils";
import CurrencyInput from "components/CurrencyInput/CurrencyInput";
import { toTitleCase } from "utils";
import { useSelector } from "react-redux";
import { payerSelector } from "store/features/insurances/insuranceSelectors";

const InsuranceDetailsModal = (props) => {
  const { handleClose, handleUpdateIns, title, type, insuranceDetails, insuranceList, user } = props;
  const [insuranceData, setInsuranceData] = useState(insuranceDetails);
  const [showError, setShowError] = useState("");

  const [isEligibiltyChecked, setIsEligibiltyChecked] = useState(false);

  const [eligibleResId, setEligibleResId] = useState("");

  useEffect(() => {
    if (insuranceDetails.isNew === false) {
      setIsEligibiltyChecked(true);
    }
  }, [insuranceDetails]);

  const [loading, setLoading] = useState(false);

  const appContext = useContext(AppContext);

  const loginUser = appContext.user;

  const payerList = useSelector(payerSelector);

  const alreadyExistIns = (ins) => {
    return (
      insuranceList?.some((f) => {
        const isSameCompanyAndMedicalNo = f.insuranceCompany === ins.insuranceCompany && f.medicalNo === ins.medicalNo;
        const isDifferentId = f.id !== insuranceData.id;
        return insuranceData.isNew ? isSameCompanyAndMedicalNo : isSameCompanyAndMedicalNo && isDifferentId;
      }) || false
    );
  };

  const handleInsValidation = (insuranceData) => {
    if (!insuranceData.insuranceCompany) {
      setShowError("Payer is required");
      return;
    }

    if (!IGNORE_INS.includes(insuranceData.insuranceCompany)) {
      if (!insuranceData.medicalNo || !insuranceData.medicalNo.trim()) {
        setShowError("Member ID is required");
        return;
      }

      if (!isValidIDNumber(insuranceData.medicalNo)) {
        setShowError("Member ID is invalid");
        return;
      }

      if (insuranceData.isMedicare === "Y" && !insuranceData.medicareNo) {
        setShowError("Member ID should not be Empty");
        return;
      }
      if (insuranceData.isMedicare === "Y" && !isValidIDNumber(insuranceData.medicareNo)) {
        setShowError("Member ID is invalid");
        return;
      }
      if (alreadyExistIns(insuranceData)) {
        setShowError("Payer is already in Added!");
        return;
      }
    }
    return true;
  };

  // const handleSave = () => {
  //   if (!handleInsValidation(insuranceData)) return;
  //   const insData = { ...insuranceData };

  //   if (insuranceData.isNew) {
  //     Object.assign(insData, { id: "prefix_" + Math.random().toString(36).slice(2, 9) });
  //     handleUpdateIns(insData);
  //   } else {
  //     handleUpdateIns(insData);
  //   }
  //   handleClose();
  // };

  const copyInsuranceObject = (eligibiltyResponse) => {
    const insurance = {
      copay: 0,
      deductible: 0,
      isActive: true,
      medicalNo: "",
      medicareNo: "",
    };
    return IGNORE_INS.includes(insuranceData.insuranceCompany)
      ? { ...insuranceData, ...insurance }
      : {
          ...insuranceData,
          ...eligibiltyResponse,
        };
  };

  const formatEligibilityResponseData = (eligbRes) => {
    const { data, apiData, message, planDates } = eligbRes;

    const { startDate, endDate } = getStartAndEndDateFromEligibility(
      planDates || data?.plan_begin_date || data?.planDates
    );

    return {
      copay: data?.copay || 0,
      deductible: data?.deductible || 0,
      coinsurnace: data?.coinsurnace || 0,
      out_coinsurnace: data?.out_coinsurnace || 0,
      out_copay: data?.out_copay || 0,
      out_copay_specialist: data?.out_copay_specialist || 0,
      copay_specialist: data?.copay_specialist || 0,
      effectiveStartDate: startDate || "",
      effectiveEndDate: endDate || "",
      apiData: apiData || "",
      additionalPayer: data?.additionalPayer || null,
      ipa: data?.ipa || null,
      message,
    };
  };
  const handleSave = async () => {
    let eligibiltyResponse = {};

    if (!handleInsValidation(insuranceData)) return;

    // if (!CUSTOM_INS.includes(insuranceData.insuranceCompany) && !user.isNew) {
    if (!IGNORE_INS.includes(insuranceData.insuranceCompany)) {
      const modifiedEmp = {
        ...user,
        insuranceCompany: insuranceData.insuranceCompany,
        insurance_number: insuranceData.medicalNo,
        medicalNo: insuranceData.medicalNo,
        eEligibility: insuranceData?.eEligibility || false,
        insurance_name: insuranceData?.insuranceCompanyCode,
        insuranceCompanyCode: insuranceData?.insuranceCompany,
        mdID: user.id,
      };

      // return;
      if (!modifiedEmp.eEligibility && (!insuranceData.copay || !insuranceData?.deductible) && !isEligibiltyChecked) {
        setShowError(`You can add deductible and copay manualy.`);
        setIsEligibiltyChecked(true);
        return;
      }

      if (!isEligibiltyChecked) {
        try {
          setLoading(true);

          const eligbRes = await api.checkManualEligibility(modifiedEmp, loginUser);

          // Ensure the id is added to eligibiltyResponse
          if (eligbRes?.id) {
            setEligibleResId(eligbRes?.id);
          }

          const { data, error = null, message, status, id } = eligbRes;
          setLoading(false);
          setIsEligibiltyChecked(true);
          if (error) {
            setShowError(`${error.error_mesg}. You can add deductible and copay manualy.`);
            return;
          } else if (status && status === "Ineligibile") {
            setShowError(message);
            return;
          }
          if (data) {
            eligibiltyResponse = formatEligibilityResponseData(eligbRes);

            setInsuranceData({
              ...insuranceData,
              ...eligibiltyResponse,
            });
          }
        } catch (error) {
          console.log("error", { error });
          setLoading(false);
        }
      }
    }

    const insuranceCopy = copyInsuranceObject(eligibiltyResponse);

    const insData = { ...insuranceCopy, id: eligibleResId };

    handleUpdateIns(insData);

    handleClose();
  };

  const handelAdvantatePlan = (e) => {
    const isChecked = e.target.checked;
    const label = isChecked ? "Y" : "N";
    setInsuranceData({
      ...insuranceData,
      isMedicare: label,
    });
  };

  return (
    <>
      <Modal
        animation={true}
        onHide={() => {
          handleClose();
        }}
        className="seperateModal"
        show={true}
        style={{ paddingLeft: "0" }}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          {loading && <Loader />}

          <div className="testAdmin createClientsWrapper d-block">
            <InputField
              type="dropDown"
              groupWrapper="form-group-wrapper me-2 mt-4"
              inputStyle="w-100"
              labelStyle="mt-0 modalLineHeaders text-capitalize"
              label="Payer"
              options={payerList}
              value={payerList.find((item) => item.value === insuranceData.insuranceCompany) || null}
              index="insuranceCompany"
              placeholder="Select Payer"
              handleChange={(e) => {
                setInsuranceData({
                  ...insuranceData,
                  insuranceCompany: e.value,
                  insuranceCompanyCode: e.label,
                  insuranceType: e.payer_type,
                  insuranceGroupId: "",
                  isMedicare: "N",
                  eEligibility: e?.eEligibility || false,
                });
                setIsEligibiltyChecked(false);
              }}
              required
            />

            {!IGNORE_INS.includes(insuranceData.insuranceCompany) &&
              insuranceData.insuranceCompany &&
              MEDICATRE_IDS.indexOf(insuranceData.insuranceCompany) === -1 && (
                <>
                  <InputField
                    type="text"
                    groupWrapper="form-group-wrapper me-2"
                    inputStyle="modalInput"
                    labelStyle="mt-0 modalLineHeaders text-capitalize"
                    label="Member ID"
                    value={insuranceData.medicalNo}
                    index="medicalNo"
                    maxLength={50}
                    placeholder="Enter Member ID"
                    handleChange={(e) => {
                      setInsuranceData({
                        ...insuranceData,
                        medicalNo: convertToUpper(e.target.value),
                      });
                      setIsEligibiltyChecked(false);
                    }}
                  />

                  <InputField
                    type="text"
                    groupWrapper="form-group-wrapper me-2"
                    inputStyle="modalInput"
                    labelStyle="mt-0 modalLineHeaders text-capitalize"
                    label="Group ID"
                    value={insuranceData.insuranceGroupId}
                    index="insuranceGroupId"
                    maxLength={50}
                    placeholder="Enter Group ID"
                    handleChange={(e) =>
                      setInsuranceData({
                        ...insuranceData,
                        insuranceGroupId: convertToUpper(e.target.value),
                      })
                    }
                  />

                  {/* <Form.Group className="form-group-wrapper">
                  <label className="modalLieHeaders emp-gender-mb text-capitalize">
                    Is the Insurance Information Entered an Advantage Plan?
                  </label>
                  <div className="d-flex justify-content-between w-100 flex-wrap">
                    <RadioButton
                      name="secondaryMedicare"
                      id="syes"
                      label="Yes"
                      checked={insuranceData.isMedicare === "Y" ? true : false}
                      handleRadioButtonChange={(e) =>
                        setInsuranceData({
                          ...insuranceData,
                          isMedicare: e.target.checked ? "Y" : "",
                        })
                      }
                    />
                    <RadioButton
                      name="secondaryMedicare"
                      id="sno"
                      checked={insuranceData?.isMedicare === "N" ? true : false}
                      label="No"
                      handleRadioButtonChange={(e) =>
                        setInsuranceData({
                          ...insuranceData,
                          isMedicare: e.target.checked ? "N" : "",
                          medicareNo: e.target.checked ? "" : insuranceData.medicareNo,
                        })
                      }
                    />
                  </div>
                </Form.Group> */}
                </>
              )}

            {!IGNORE_INS.includes(insuranceData.insuranceCompany) && insuranceData.isMedicare === "Y" && (
              <InputField
                type="text"
                groupWrapper="form-group-wrapper me-2"
                inputStyle="modalInput"
                labelStyle="mt-0 modalLineHeaders text-capitalize"
                label="Medicare Number"
                value={insuranceData.medicareNo}
                index="medicareNo"
                maxLength={50}
                placeholder="Enter Medicare Number"
                handleChange={(e) =>
                  setInsuranceData({
                    ...insuranceData,
                    medicareNo: convertToUpper(e.target.value),
                  })
                }
              />
            )}

            {!IGNORE_INS.includes(insuranceData.insuranceCompany) &&
              insuranceData.insuranceCompany &&
              MEDICATRE_IDS.indexOf(insuranceData.insuranceCompany) !== -1 && (
                <InputField
                  type="text"
                  groupWrapper="form-group-wrapper me-2"
                  inputStyle="modalInput"
                  labelStyle="mt-0 modalLineHeaders text-capitalize"
                  label="Medicare Number"
                  value={insuranceData.medicareNo}
                  index="medicareNo"
                  maxLength={50}
                  placeholder="Enter Medicare Number"
                  handleChange={(e) =>
                    setInsuranceData({
                      ...insuranceData,
                      medicalNo: convertToUpper(e.target.value),
                      medicareNo: convertToUpper(e.target.value),
                    })
                  }
                />
              )}

            {!IGNORE_INS.includes(insuranceData.insuranceCompany) && insuranceData.insuranceCompany && (
              <>
                <div className="d-flex justify-content-between w-100" style={{ gap: "10px" }}>
                  <div className="w-50">
                    <CurrencyInput
                      value={insuranceData.copay}
                      placeholder="Enter Copay"
                      onChange={(e) => {
                        setInsuranceData({ ...insuranceData, copay: e });
                      }}
                      label="Copay"
                    />
                  </div>
                  <div className="w-50">
                    <CurrencyInput
                      value={insuranceData.deductible}
                      placeholder="Enter Deductible"
                      onChange={(e) => {
                        setInsuranceData({ ...insuranceData, deductible: e });
                      }}
                      label="Deductible"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between w-100" style={{ gap: "10px" }}>
                  <div className="w-50">
                    <InputField
                      type="datePicker"
                      inputStyle="w-100 orderTestDob"
                      labelStyle="modalLineHeaders mt-0 text-capitalize"
                      label="Effective From"
                      value={
                        insuranceData?.effectiveStartDate &&
                        new Date(insuranceData.effectiveStartDate) != "Invalid Date"
                          ? moment(insuranceData.effectiveStartDate, "YYYY-MM-DD").toDate()
                          : null
                      }
                      index="dob"
                      placeholder="Effective From"
                      handleChange={(e) =>
                        setInsuranceData({
                          ...insuranceData,
                          effectiveStartDate:
                            moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                        })
                      }
                      isFutureDateAllowed
                    />
                  </div>
                  <div className="w-50">
                    <InputField
                      type="datePicker"
                      inputStyle="w-100 orderTestDob"
                      labelStyle="modalLineHeaders mt-0 text-capitalize"
                      label="Effective To"
                      value={
                        insuranceData?.effectiveEndDate && new Date(insuranceData.effectiveEndDate) != "Invalid Date"
                          ? moment(insuranceData.effectiveEndDate, "YYYY-MM-DD").toDate()
                          : null
                      }
                      index="dob"
                      placeholder="Effective To"
                      handleChange={(e) =>
                        setInsuranceData({
                          ...insuranceData,
                          effectiveEndDate:
                            moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                        })
                      }
                      isFutureDateAllowed
                    />
                  </div>
                </div>
              </>
            )}
            <div className="profile-detail-payer-info">
              {!IGNORE_INS.includes(insuranceData?.insuranceCompany) && (
                <>
                  <div className="d-flex align-items-center mb-2">
                    <label className="label-text">Additional Payer:</label>
                    <span>{toTitleCase(insuranceData?.additionalPayer?.payerName) || "-"}</span>
                  </div>

                  <div className="d-flex align-items-center profile-detail mb-2">
                    <label className="label-text">IPA:</label>
                    <span>{toTitleCase(insuranceData?.ipa?.payerName) || "-"}</span>
                  </div>
                </>
              )}

              {insuranceData.insuranceCompany !== "" &&
                !CUSTOM_INS_ADV_PLAN.includes(insuranceData.insuranceCompany) && (
                  <div className="d-flex align-items-center  profile-detail mb-2">
                    <label className="label-text">Advantage Plan: </label>

                    <SwitchToggle
                      checked={insuranceData.isMedicare === "Y" ? true : false}
                      handleChange={handelAdvantatePlan}
                      color={"blue"}
                    />
                  </div>
                )}

              <div className="d-flex align-items-center profile-detail mb-2 ">
                <label className="label-text">Primary: </label>
                <SwitchToggle
                  checked={insuranceData.isPrimary}
                  handleChange={(e) =>
                    !insuranceDetails.isPrimary &&
                    setInsuranceData({
                      ...insuranceData,
                      isPrimary: e.target.checked,
                    })
                  }
                  color={"blue"}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="px-4 my-2" style={{ height: "2vh" }}>
          {showError && <ErrorMessage error={showError} handleChange={() => setShowError("")} />}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleSave();
            }}
          >
            {insuranceDetails.isNew ? "Add" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InsuranceDetailsModal;
