import InputField from "components/InputField/InputField";
import InputSelectDropDown from "components/InputField/InputSelectDropDown";
import InputCPTCodeDropDown from "components/InputField/InputCPTCodeDropDown";
import ErrorMessage from "components/Message/ErrorMessage";
import { DIAGNOSIS_ITEMS, PARSE_ALPHA_INTO_NUM, POS_OPT, PROCEDURE_CODE_STATES } from "constant";
import { useEffect, useMemo, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { convertToUpper, getIntVal, getTotalCharge } from "utils";
import moment from "moment";
import { parseStringIntoFloat } from "utils";
import { isJSONString } from "utils";
import { getDiagonosisInternalCodeValue } from "utils";
import { v4 as uuidv4 } from "uuid";

const ProcedureModefierModal = (props) => {
  const {
    show,
    handleClose,
    isHospice,
    hospiceCode,
    prevClaims,
    handleChange,
    data,
    handleAddData,
    handleUpdate,
    cssClass,
    claimData,
    dataList
  } = props;
  const [obj, setObj] = useState({});
  const [error, setError] = useState("");
  
  const DIAG_OPT = useMemo(() => {
    const Opt = [];
    DIAGNOSIS_ITEMS.forEach((item) => {
      if (claimData[`diag_${PARSE_ALPHA_INTO_NUM[item]}`]) {
        Opt.push({
          value: item,
          label: item,
        });
      }
    });
    return Opt;
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (Object.keys(data).length > 0 && isMounted) {
      setObj(data);
    } else if (isMounted) {
      setDefaultObject();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const getDefaultDOS = (dos) => {

    const firstCPT = claimData?.proc_array?.[0];
    const from_date = firstCPT?.from_date;

    // add this code to manage the date when fresh claim is being when claim obj is empty 
    // then dataList is being managed for CPT
    const dateItem = dataList && Array.isArray(dataList) && dataList.length > 0 ? dataList[dataList.length-1].from_date : moment().format("YYYY-MM-DD");
    
    // dos is first CPT object selected dos
    return  from_date || claimData?.from_date_1 || dos || dateItem || moment().format("YYYY-MM-DD");
  };

  const setDefaultObject = (dos) => {
    const firstDiagCode = DIAG_OPT[0]?.value || "A";
    setObj({
      id: "",
      mod1: hospiceCode || "",
      mod2: "",
      mod3: "",
      mod4: "",
      place_of_service: "81",
      units: "1",
      diag_ref_1: firstDiagCode,
      from_date: getDefaultDOS(dos),
      proc_code: "",
      isClaimProcess: false,
      charge: "",
    });
  };

  const handleSubmit = (actionState) => {
    if (!obj.from_date) {
      setError("Date of services is Required");
      return;
    }
    if (!obj.proc_code || !obj.proc_code.trim()) {
      setError("Procedure Code is required");
      return;
    }
    if (!obj.place_of_service || !obj.place_of_service.trim()) {
      setError("Place of services is required");
      return;
    }
    if (!obj.diag_ref_1) {
      setError("Link Diagnosis Code 1 is required");
      return;
    }
    if (!obj.charge) {
      setError("Charge is required");
      return;
    }
    if (!obj.units) {
      setError("Units is required");
      return;
    }
    const id = uuidv4();

    if (actionState === PROCEDURE_CODE_STATES.PLUS || actionState === PROCEDURE_CODE_STATES.ADD) {
      // check if patient is hospice and have a modifier in any previous claim specific to that procedure code add modifier
      if (!hospiceCode && isHospice && prevClaims && prevClaims.length > 0) {
        const matchedPrevProCodes = prevClaims.flatMap((claim) =>
          claim.proc_array.filter((proc) => proc.proc_code === obj.proc_code)
        );
        if (matchedPrevProCodes.length > 0) {
          const prevPC = matchedPrevProCodes[0];
          if (!obj.mod1 && prevPC.mod1 && (prevPC.mod1 === "GW" || prevPC.mod1 === "GV")) obj.mod1 = prevPC.mod1;
          if (!obj.mod2 && prevPC.mod2 && (prevPC.mod1 === "GW" || prevPC.mod1 === "GV")) obj.mod1 = prevPC.mod2;
          if (!obj.mod3 && prevPC.mod3 && (prevPC.mod1 === "GW" || prevPC.mod1 === "GV")) obj.mod3 = prevPC.mod3;
          if (!obj.mod4 && prevPC.mod4 && (prevPC.mod1 === "GW" || prevPC.mod1 === "GV")) obj.mod4 = prevPC.mod4;
        }
      }
      obj.id = id;
      
      handleAddData({ ...obj }, actionState);

      if (actionState === PROCEDURE_CODE_STATES.PLUS) {
        setDefaultObject(obj.from_date);
      }
    } else if (obj.id) {
      handleUpdate(obj);
    } else {
      obj.id = id;
      // return the response to main modal
      handleAddData({ ...obj }, actionState);
    }
  };
  const handleChangeData = (e) => {};

  const formatOptionLabel = (data) => {
    const concatenatedLabel = `${data.value} - ${data.label}`;
    return <div>{data.label === concatenatedLabel ? data.label : concatenatedLabel}</div>;
  };

  return (
    <Modal
      show={true}
      backdrop="static"
      className={`${cssClass}`}
      animation={true}
      onHide={() => handleClose()}
      centered
      size={"xl"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Procedure Code
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="createClientsWrapper crew-profile-complete crew-profile-again">
          {DIAGNOSIS_ITEMS.map((item, index) => {
            return (
              <InputCPTCodeDropDown
                key={index}
                type="text"
                inputStyle="modalInput"
                labelStyle="text-capitalize"
                label={item}
                placeholder={item}
                optionList={[]}
                value={getDiagonosisInternalCodeValue(claimData[`diag_${PARSE_ALPHA_INTO_NUM[item]}`])}
                // handleChange={(opt) => setNewUser({ ...newUser, [`diag_${PARSE_ALPHA_INTO_NUM[item]}`]: opt.value })}
                readOnly
                searchtype="ict"
              />
            );
          })}
        </div>
        <div className="crew-form-list crew-profile-again flex-direction row11">
          <div className="createClientsWrapper w-100">
            <Form.Group className="form-group-wrapper w-100">
              <Form.Label className="mt-0 text-capitalize">DATE OF SERVICES</Form.Label>
              <Form.Control
                className={`modalInput`}
                onChange={(e) => {
                  setObj({
                    ...obj,
                    from_date: e.target.value,
                  });
                }}
                value={obj?.from_date}
                placeholder="DATE OF SERVICES"
                maxLength={10}
                type="Date"
              />
            </Form.Group>
            <div className="w-100 createClientsWrapper">
              <InputCPTCodeDropDown
                type="text"
                inputStyle="modalInput"
                labelStyle=" text-capitalize"
                groupWrapper="mb-0 form-group-wrapper"
                label={"PROCEDURE"}
                placeholder={"PROCEDURE"}
                optionList={[]}
                value={obj.internalCode || obj.proc_code || ""}
                handleChange={(opt) => {
                  setObj({
                    ...obj,
                    proc_code: opt.value,
                    charge: parseStringIntoFloat(opt.charges),
                    internalCode: opt.internalCode,
                    proc_desc: opt.description,
                    proc_name: opt.description,
                  });
                }}
                searchtype="cpt"
              />
            </div>
            <Form.Group className="form-group-wrapper w-100">
              <Form.Label className="mt-0 text-capitalize">CHARGES</Form.Label>
              <Form.Control
                className={`modalInput`}
                maxLength={65}
                onChange={(e) => setObj({ ...obj, charge: parseStringIntoFloat(e.target.value) })}
                value={obj?.charge}
                placeholder="Enter Charges"
                type="text"
              />
            </Form.Group>
            <Form.Group className="w-100 form-group-wrapper ">
              <Form.Label className="mt-0 text-capitalize">UNITS</Form.Label>
              <Form.Control
                className={`modalInput`}
                maxLength={65}
                min={0}
                onChange={(e) => setObj({ ...obj, units: Number(e.target.value) })}
                value={obj?.units}
                placeholder="Enter UNITS"
                type="number"
              />
            </Form.Group>
            <Form.Group className="w-100 form-group-wrapper ">
              <Form.Label className="mt-0 text-capitalize">TOTAL CPT CHARGES</Form.Label>
              <Form.Control
                className={`modalInput`}
                maxLength={65}
                value={getTotalCharge(obj).toFixed(2)}
                placeholder="Total Charge"
                disabled={true}
                type="number"
              />
            </Form.Group>
          </div>

          <div className="createClientsWrapper w-100">
            <Form.Group className="form-group-wrapper w-100">
              <Form.Label className="mt-0 text-capitalize">Place Of Services</Form.Label>
              <Select
                options={POS_OPT}
                blurInputOnSelect={true}
                formatOptionLabel={(data) => formatOptionLabel(data)}
                menuPlacement="auto"
                placeholder="Place Of Services"
                value={obj?.place_of_service ? POS_OPT.find((f) => f.value === obj?.place_of_service) : null}
                onChange={(e) => {
                  setObj({ ...obj, place_of_service: e.value });
                }}
              />
            </Form.Group>
          </div>

          {/* <Form.Group className="form-group-wrapper w-100">
            <label className="mt-0  text-capitalize">PROCEDURE</label>
            <Form.Control
              readOnly={true}
              className={`${!true ? "modalInput" : ""}`}
              maxLength={65}
              onChange={(e) => setNewUser({ ...newUser, city: e.target.value })}
              value={newUser.city}
              placeholder="Enter City"
              type="text"
            />
          </Form.Group> */}
          <div className="form-group-wrapper createClientsWrapper modifier w-100">
            <InputField
              type="text"
              inputStyle="modalInput"
              labelStyle=""
              label="MODIFIER 1"
              groupWrapper="w-50"
              value={obj?.mod1 || ""}
              maxlength="2"
              placeholder="MODIFIER 1"
              handleChange={(e) =>
                setObj({
                  ...obj,
                  mod1: convertToUpper(e.target.value),
                })
              }
            />
            <InputField
              type="text"
              inputStyle="modalInput"
              labelStyle=""
              label="MODIFIER 2"
              groupWrapper="w-50"
              value={obj?.mod2 || ""}
              maxlength="2"
              placeholder="MODIFIER 2"
              handleChange={(e) =>
                setObj({
                  ...obj,
                  mod2: convertToUpper(e.target.value),
                })
              }
            />
            <InputField
              type="text"
              inputStyle="modalInput"
              labelStyle=""
              label="MODIFIER 3"
              groupWrapper="w-50"
              value={obj?.mod3 || ""}
              maxlength="2"
              placeholder="MODIFIER 3"
              handleChange={(e) =>
                setObj({
                  ...obj,
                  mod3: convertToUpper(e.target.value),
                })
              }
            />
            <InputField
              type="text"
              inputStyle="modalInput"
              labelStyle=""
              label="MODIFIER 4"
              groupWrapper="w-50"
              value={obj?.mod4 || ""}
              maxlength="2"
              placeholder="MODIFIER 4"
              handleChange={(e) =>
                setObj({
                  ...obj,
                  mod4: convertToUpper(e.target.value),
                })
              }
            />
          </div>

          <div className="createClientsWrapper modifier w-100">
            <Form.Group className="form-group-wrapper">
              <Form.Label>Link Diagnosis Code 1</Form.Label>
              <Select
                options={DIAG_OPT}
                blurInputOnSelect={true}
                isClearable
                menuPlacement="auto"
                placeholder="Link Diagnosis Code"
                value={
                  obj?.diag_ref_1
                    ? {
                        label: obj?.diag_ref_1,
                        value: obj?.diag_ref_1,
                      }
                    : null
                }
                onChange={(e) => {
                  setObj({ ...obj, diag_ref_1: e?.value || "" });
                }}
              />
            </Form.Group>
            <Form.Group className="form-group-wrapper">
              <Form.Label>Link Diagnosis Code 2</Form.Label>
              <Select
                options={DIAG_OPT}
                blurInputOnSelect={true}
                isClearable
                menuPlacement="auto"
                placeholder="Link Diagnosis Code"
                value={
                  obj?.diag_ref_2
                    ? {
                        label: obj?.diag_ref_2,
                        value: obj?.diag_ref_2,
                      }
                    : null
                }
                onChange={(e) => {
                  setObj({ ...obj, diag_ref_2: e?.value || "" });
                }}
              />
            </Form.Group>
          </div>
          <div className="createClientsWrapper w-100">
            <Form.Group className="form-group-wrapper">
              <Form.Label>Link Diagnosis Code 3</Form.Label>
              <Select
                options={DIAG_OPT}
                blurInputOnSelect={true}
                menuPlacement="auto"
                isClearable
                placeholder="Link Diagnosis Code"
                value={
                  obj?.diag_ref_3
                    ? {
                        label: obj?.diag_ref_3,
                        value: obj?.diag_ref_3,
                      }
                    : null
                }
                onChange={(e) => {
                  setObj({ ...obj, diag_ref_3: e?.value || "" });
                }}
              />
            </Form.Group>
            <Form.Group className="form-group-wrapper">
              <Form.Label>Link Diagnosis Code 4</Form.Label>
              <Select
                options={DIAG_OPT}
                blurInputOnSelect={true}
                menuPlacement="auto"
                isClearable
                placeholder="Link Diagnosis Code"
                value={
                  obj?.diag_ref_4
                    ? {
                        label: obj?.diag_ref_4,
                        value: obj?.diag_ref_4,
                      }
                    : null
                }
                onChange={(e) => {
                  setObj({ ...obj, diag_ref_4: e?.value || "" });
                }}
              />
            </Form.Group>
          </div>
        </div>
      </Modal.Body>
      <div className="px-4 my-2" style={{ height: "2vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>

      <Modal.Footer>
        <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
          Close
        </Button>
        <Button
          variant="secondary"
          className="headerButton btn-fill mx-3 ms-auto"
          onClick={() => handleSubmit(PROCEDURE_CODE_STATES.PLUS)}
        >
          Add New
        </Button>
        {Object.keys(data).length === 0 ? (
          <Button
            variant="secondary"
            className="headerButton btn-fill"
            onClick={() => handleSubmit(PROCEDURE_CODE_STATES.ADD)}
          >
            Add
          </Button>
        ) : (
          <Button
            variant="secondary"
            className="headerButton btn-fill"
            onClick={() => handleSubmit(PROCEDURE_CODE_STATES.UPDATE)}
          >
            Update
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ProcedureModefierModal;
