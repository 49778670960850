import React, { useState } from "react";
import { useAccordionButton } from "react-bootstrap";
import { customIsEmpty } from "util/customLodash";
import PersonalizationItem from "components/Personalization/PersonalizationItem";

const TabItem = ({ type, personalize, handleCheckBox }) => {
  return (
    <div className="mb-3 d-flex flex-wrap">
      {!customIsEmpty(personalize) &&
        personalize[type].map((ek, index) => (
          <PersonalizationItem
            key={`${type}_${index}`}
            handleCheck={(event) => handleCheckBox(event, type)}
            item={ek}
            checkKey={`${type}_${index}`}
          />
        ))}
    </div>
  );
};

export default TabItem;
