export const COMPANY_SETTING = "companySetting";

export const COMPANY_SETTING_OBJ = "companySetting/setting";

export const COMPANY_SETTING_FETCH = "companySetting/fetchApiData";

export const COMPANY_SETTING_UPDATE = "companySetting/updateCompanySetting";

export const COMPANY_SETTING_UPDATE_CLI_LIST = "companySetting/updateCompanySettingCLIList";

export const COMPANY_SETTING_UPDATE_ALERT = "companySetting/updateCompanyAlertSetting";

export const COMPANY_SETTING_RENDERING_PROVIDERS = "companySetting/createRenderingProviders";
