import { useRef } from "react";
import API from "api";
import ErrorMessage from "components/Message/ErrorMessage";
import ProviderSearchRecord from "components/ProviderSearchRecord";
import { useEffect, useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../store/features/general/generalAction";
import { MESSAGE_MODES, NPI_TYPE_OPTIONS } from "../../constant";
import InputField from "components/InputField/InputField";
import { formatPhoneNumber } from "utils";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import searchIcon from "../../assets/img/search.svg";
import { createRenderingProviders } from "store/features/companySetting/companySettingSlice";
import { renderingProviderSettingData } from "store/features/companySetting/companySettingSelectors";

const RenderingProviderModal = (props) => {
  const { show, user, handleClose } = props;
  const renderingProviderSettingInfo = useSelector(renderingProviderSettingData);

  const dispatch = useDispatch();

  const [newUser, setNewUser] = useState(user);

  const [error, setError] = useState("");

  useEffect(() => {
    setNewUser(user);
  }, [user]);

  const onHandleSave = () => {
    const objToSave = { ...newUser };
    if (!newUser.firstName || !newUser.firstName.trim()) {
      setError("First Name is required");
      return;
    }

    if (!newUser.lastName || !newUser.lastName.trim()) {
      setError("Last Name is required");
      return;
    }

    if (!newUser.npi || !newUser.npi.trim()) {
      setError("NPI is required");
      return;
    }

    // Find Existing NPI Number
    const findProvider = renderingProviderSettingInfo.find((f) => f.npi === newUser.npi);

    if (findProvider && (!user.isUpdate || (user.isUpdate && newUser.npi !== user.npi))) {
      setError("NPI is already exist");
      return;
    }

    Object.assign(objToSave, {
      name: `${newUser.firstName.trim()} ${newUser?.middleName?.trim() || ""} ${newUser?.lastName.trim()}`,
      npiType: 'NPI-1'
    });

    if (user.isUpdate) {
      dispatch(createRenderingProviders({ params: objToSave, operationType: "update" }));
    } else {
      dispatch(createRenderingProviders({ params: objToSave, operationType: "add" }));
    }
    handleClose();
  };

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        animation={true}
        onHide={() => {
          setNewUser("");
          handleClose();
        }}
        style={{ paddingLeft: "0" }}
        size={"2xl"}
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Create Rendering Provider
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="">
            <div>
              <div style={{ display: "flex", width: "100%", justifyContent: "space-between", gap: "10px" }}>
                <div style={{ display: "flex", width: "100%" }}>
                  <div style={{ display: "flex", width: "90%" }}>
                    <InputField
                      type="text"
                      groupWrapper={"form-group-wrapper"}
                      inputStyle="modalInput"
                      labelStyle="text-capitalize"
                      label="NPI:"
                      value={newUser.npi}
                      index="npi"
                      maxLength={20}
                      placeholder="NPI Number"
                      handleChange={(e) => setNewUser({ ...newUser, npi: e.target.value })}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
                  <div className={" crew-profile-row4 crew-profile-again"}>
                    <InputField
                      type="text"
                      inputStyle="modalInput w-100"
                      labelStyle="text-capitalize"
                      label="First Name"
                      value={newUser.firstName}
                      index="firstName"
                      maxLength={30}
                      placeholder="First Name"
                      handleChange={(e) => setNewUser({ ...newUser, firstName: e.target.value })}
                    />

                    <InputField
                      type="text"
                      inputStyle="modalInput w-100"
                      labelStyle="text-capitalize"
                      label="Middle Name"
                      value={newUser.middleName}
                      index="middleName"
                      maxLength={30}
                      placeholder="Middle Name"
                      handleChange={(e) => setNewUser({ ...newUser, middleName: e.target.value })}
                    />

                    <InputField
                      type="text"
                      inputStyle="modalInput w-100"
                      labelStyle="text-capitalize"
                      label="Last Name"
                      value={newUser.lastName}
                      index="lastName"
                      maxLength={30}
                      placeholder="Last Name"
                      handleChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })}
                    />
                  </div>
                </div>
                <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
                  <div className={" crew-profile-row4 crew-profile-again"}>
                    <InputField
                      type="text"
                      inputStyle="modalInput w-100"
                      labelStyle="text-capitalize"
                      label="Street"
                      value={newUser.street}
                      index="Street"
                      maxLength={65}
                      placeholder="Street"
                      handleChange={(e) => setNewUser({ ...newUser, street: e.target.value })}
                    />
                    <InputField
                      type="text"
                      inputStyle="modalInput w-100"
                      labelStyle="text-capitalize"
                      label="Street 2"
                      value={newUser.street2}
                      index="street2"
                      maxLength={30}
                      placeholder="Street 2"
                      handleChange={(e) => setNewUser({ ...newUser, street2: e.target.value })}
                    />
                    <InputField
                      type="text"
                      inputStyle="modalInput w-100"
                      labelStyle="text-capitalize"
                      label="City"
                      value={newUser.city}
                      index="city"
                      maxLength={20}
                      placeholder="City"
                      handleChange={(e) => setNewUser({ ...newUser, city: e.target.value })}
                    />
                  </div>
                </div>

                <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
                  <div className={" crew-profile-row4 crew-profile-again"}>
                    <InputField
                      type="text"
                      inputStyle="modalInput"
                      labelStyle="text-capitalize"
                      label="Zip Code"
                      value={newUser.zip}
                      index="zip"
                      maxLength={20}
                      placeholder="Zip Code"
                      handleChange={(e) => setNewUser({ ...newUser, zip: e.target.value })}
                    />

                    <InputField
                      type="phone"
                      inputStyle="modalInput"
                      labelStyle="text-capitalize"
                      label="Phone Number"
                      value={newUser.phoneNumber}
                      index="phoneNumber"
                      maxLength={20}
                      placeholder="Enter Phone"
                      handleChange={(e) => setNewUser({ ...newUser, phoneNumber: e })}
                    />

                    <InputField
                      type="phone"
                      inputStyle="modalInput"
                      labelStyle="text-capitalize"
                      label="Fax"
                      value={newUser.fax}
                      index="fax"
                      maxLength={20}
                      placeholder="Enter Fax"
                      handleChange={(e) => setNewUser({ ...newUser, fax: e })}
                    />
                  </div>
                </div>

                <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
                  <div className={" crew-profile-row4 crew-profile-again"}>
                    <InputField
                      type="dropDown"
                      options={NPI_TYPE_OPTIONS}
                      inputStyle=""
                      labelStyle="text-capitalize"
                      label="NPI Type:"
                      isClearable={true}
                      value={NPI_TYPE_OPTIONS.find((f) => f.value === newUser.npiType) || ""}
                      index="npiType"
                      placeholder="NPI"
                      handleChange={(e) => setNewUser({ ...newUser, npiType: e?.value })}
                    />

                    <InputField
                      type="text"
                      inputStyle="modalInput"
                      labelStyle="text-capitalize"
                      label="Taxonomy ID:"
                      value={newUser.taxid}
                      index="taxid"
                      maxLength={20}
                      placeholder="Taxonomy ID"
                      handleChange={(e) => setNewUser({ ...newUser, taxid: e.target.value })}
                    />
                    <InputField
                      type="text"
                      label="Speciality:"
                      inputStyle="modalInput"
                      labelStyle="text-capitalize"
                      value={newUser.speciality || ""}
                      placeholder="Enter Speciality"
                      index="specialitypi"
                      handleChange={(e) => setNewUser({ ...newUser, speciality: e.target.value })}
                    />
                    <InputField
                      type="text"
                      inputStyle="modalInput"
                      labelStyle="text-capitalize"
                      label="State"
                      value={newUser.state}
                      index="state"
                      maxLength={20}
                      placeholder="State"
                      handleChange={(e) => setNewUser({ ...newUser, state: e.target.value })}
                    />
                  </div>
                </div>

                <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
                  <div className={" crew-profile-column2 crew-profile-again"}>
                    <div
                      className="d-flex align-items-center column-gap-10 profile-
                          detail mb-3"
                    >
                      <span className="section-title">Active: </span>
                      <SwitchToggle
                        checked={newUser.isActive}
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            isActive: e.target.checked,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
                  <div className={" crew-profile-column2 crew-profile-again"}>
                    <div
                      className="d-flex align-items-center column-gap-10 profile-
                          detail mb-3"
                    >
                      <span className="section-title">Is Default: </span>
                      <SwitchToggle
                        checked={newUser.isDefault}
                        handleChange={(e) =>
                          setNewUser({
                            ...newUser,
                            isDefault: e.target.checked,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="px-4" style={{ minHeight: "3vh" }}>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>

          <Button
            onClick={() => onHandleSave()}
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill"
          >
            {user.isUpdate ? "Update" : "Create"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RenderingProviderModal;
