import api from "api";
import "bootstrap-daterangepicker/daterangepicker.css";
import ErrorMessage from "components/Message/ErrorMessage";
import PreviousNotes from "components/Note/PreviousNotes";
import RadioButton from "components/RadioButton/RadioButton";
import Loader from "components/Loader/Loader";
import { NOTES_MAX_CHARACTER_LENGTH, PAGE_LIMIT } from "constant";
import graphqlApi from "graphqlApi";
import moment from "moment";
import { useState, useEffect } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateProviderNoteAsync } from "store/features/providers/providersSlice";
import { updateLabClientNotes } from "store/features/locations/locationsSlice";
import MainTable from "components/Table/MainTable";
import { personalizationLocalStorage, CapsFirstLetter, formatCurrency } from "utils";
import { providerList } from "store/features/providers/providersSelectors";
import { PROVIDER_PERSONALIZE } from "constants/personalization";
import { TABLE_QUICK_TOOLS } from "constant";
import RedirectLink from "components/RedirectLink/RedirectLink";

const ClientProviderModal = (props) => {
  const { data, handleClose, user, cssClass, setDefaultProvider } = props;

  const [personalize, setPersonalize] = useState([]);
  const [defaultProviderID, setDefaultProviderID] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [associatedProvider, setAssociatedProviders] = useState([]);

  useEffect(() => {
    loadAssociatedProviders();
  }, []);

  const loadAssociatedProviders = async () => {
    const apiRes = await api.fetchClientAssociatedProviders({ labClientID: data.id });

    if (apiRes?.rows) {
      setAssociatedProviders(apiRes?.rows.map((a) => a.providerID));
    }
  };
  // const usersToMap = [];

  //console.log('providerList',useSelector(providerList), associatedProvider);

  const usersToMap = useSelector(providerList).filter((provider) => {
    return associatedProvider.indexOf(provider.id) !== -1;
  });
  const quickTools = [TABLE_QUICK_TOOLS.checkbox];

  const onHandleSave = async (selectedOpt) => {
    setDefaultProvider(selectedOpt);
    handleClose();
  };
  useEffect(() => {
    setPersonalize(personalizationLocalStorage.get(user, "provider", PROVIDER_PERSONALIZE));
  }, []);

  const handleCellClick = (key, row, event) => {
    if (key === TABLE_QUICK_TOOLS.checkbox && setDefaultProvider) {
      if (event.target.checked) {
        setDefaultProviderID(row);
      } else {
        setDefaultProviderID("");
      }
    }
  };

  const customRenderTD = (item, row, index) => {
    
    if (item.itemKey === "name") {
      return (
        <td
          className="ellipsis"
          style={{
            textAlign: item.textAlign,
            textOverflow: item.textOverflow,
          }}
        >
          <RedirectLink
            pathname="/admin/employeeclaim"
            state={{
              name: row.npi,
              term: "ref_npi",
            }}
          >
            {CapsFirstLetter(row?.name.toString())}
          </RedirectLink>
        </td>
      );
    }
    if (item.itemKey === "claimAmount") {
      return (
        <td
          className="ellipsis"
          style={{
            textAlign: item.textAlign,
            textOverflow: item.textOverflow,
          }}
        >
          <RedirectLink
            pathname="/admin/employeeclaim"
            state={{
              name: row.npi,
              term: "ref_npi",
              filterTerm: "status",
              filterName: "billed",
            }}
          >
            {formatCurrency(row.claimAmount)}
          </RedirectLink>
        </td>
      );
    }
    if (item.itemKey === "openAmount") {
      return (
        <td
          className="ellipsis"
          style={{
            textAlign: item.textAlign,
            textOverflow: item.textOverflow,
          }}
        >
          <RedirectLink
            pathname="/admin/employeeclaim"
            state={{
              name: row.npi,
              term: "ref_npi",
              filterTerm: "status",
              filterName: "open",
            }}
          >
            {formatCurrency(row.openAmount)}
          </RedirectLink>
        </td>
      );
    }
    if (item.itemKey === "paid") {
      return (
        <td
          className="ellipsis"
          style={{
            textAlign: item.textAlign,
            textOverflow: item.textOverflow,
          }}
        >
          <RedirectLink
            pathname="/admin/employeeclaim"
            state={{
              name: row.npi,
              term: "ref_npi",
              filterTerm: "status",
              filterName: "paid",
            }}
          >
            {formatCurrency(row.paid)}
          </RedirectLink>
        </td>
      );
    }
  };

  return (
    <>
      <Modal show backdrop="static" onHide={handleClose} animation={true} size={"3xl"} scrollable className={cssClass}>
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Assoicated Providers </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row textAreaMessageInput">
            <div className="col-12 mb-3">
              <MainTable
                columns={personalize.filter((p)=> p.isCheck)}
                rows={usersToMap}
                // flipSort={flipSort}
                // sortBy={sortBy}
                // sortDescending={sortDescending}
                widthToSkip={160}
                selectedRows={[defaultProviderID?.id]}
                // selectedRows={checkboxes}
                tools={setDefaultProvider ? quickTools : []}
                handleCellClick={handleCellClick}
                customColumnCellRenderer={customRenderTD}
              />
            </div>
          </div>
          {/* <PreviousNotes id={order || claim ? data.employeeID : data.id} user={user} /> */}
          <div style={{ minHeight: "3vh" }}>
            {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>

          {setDefaultProvider && (
            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              disabled={!defaultProviderID}
              className="modalButtons headerButton btn-fill"
              onClick={() => onHandleSave(defaultProviderID)}
            >
              Set as a Primary
            </Button>
          )}

          {loading && <Loader />}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClientProviderModal;
