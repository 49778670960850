import MainTable from "components/Table/MainTable";
import { TABLE_QUICK_TOOLS } from "constant";
import { CODING_RULE_PERSONALIZE, CPT_CODES_PERSONALIZE } from "constants/personalization";
import React, { useState, useEffect } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { showConfirmationModal } from "store/features/general/generalAction";
import Select from "react-select";

import { userCompanyID } from "utils";
import api from "api";
import { customCloneDeep } from "util/customLodash";
import Loader from "components/Loader/Loader";
import InputCPTCodeDropDown from "components/InputField/InputCPTCodeDropDown";
import { parseStringIntoFloat } from "utils";

const CodingRules = () => {
  const dispatch = useDispatch();
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [panelOptions, setPanelOptions] = useState([]);
  const [panelList, setPanelList] = useState([]);
  const [obj, setObj] = useState({});
  const [bOBJ, setbOBJ] = useState({});
  const quickTools = [TABLE_QUICK_TOOLS.delete];
console.log('client',client);
  const fetchPanelList = async () => {
    const { data } = await api.getLabClientTests();
    if (data) {
      setPanelList(data.sort((a, b) => a.name.localeCompare(b.name)));
    }
  };

  useEffect(() => {
    fetchPanelList();
  }, []);

  useEffect(() => {
    const fetchClientData = async () => {
      if (userCompanyID) {
        const fetchedClient = await api.getClientById(userCompanyID.get());
        setClient(fetchedClient);
      }
    };
    fetchClientData();
  }, [userCompanyID]);

  useEffect(() => {
    const fetchAllTests = async () => {
      if (!client || !panelList.length) return;
      setLoading(true);

      const existingIds = client?.mdSetting?.panelList?.map((panel) => panel.id) || [];

      const testList =
        panelList
          ?.filter((item) => !existingIds.includes(item.id))
          .map((item) => ({
            ...item,
            label: item.name,
            value: item.id,
          })) || [];

      setPanelOptions(testList);
      setLoading(false);
    };
    fetchAllTests();
  }, [client, panelList]);

  const deletePanel = async (row) => {
    const cloneClient = customCloneDeep(client);

    cloneClient.mdSetting = cloneClient.mdSetting || {};
    cloneClient.mdSetting.panelList = cloneClient.mdSetting.panelList || [];

    cloneClient.mdSetting.panelList = cloneClient.mdSetting.panelList.filter((item) => item.id !== row.id);
    setClient(cloneClient);

    setPanelOptions((prevOptions) => [...prevOptions, { label: row.name, value: row.id }]);

    await api.updateClient(cloneClient);
  };

  const handleCellClick = (key, row) => {
    if (key === TABLE_QUICK_TOOLS.delete) {
      const confirmationModalData = {
        showModal: true,
        onConfirm: () => deletePanel(row),
        title: "Remove Panel",
        message: "Are you sure you want to remove this panel?",
      };
      dispatch(showConfirmationModal(confirmationModalData));
    }
  };

  const panelData = client?.mdSetting?.panelList || [];

  const handleAddPanel = async (e) => {
    const cloneClient = customCloneDeep(client);

    cloneClient.mdSetting = cloneClient.mdSetting || {};
    cloneClient.mdSetting.panelList = cloneClient.mdSetting.panelList || [];

    cloneClient.mdSetting.panelList.push({ id: e.id, name: e.name });
    setClient(cloneClient);

    setPanelOptions((prevOptions) => prevOptions.filter((option) => option.value !== e.id));

    await api.updateClient(cloneClient);
  };

  const cptTableData = [
    {
      a: obj.proc_code || "",
      b: bOBJ.proc_code || "",
    },
  ];

  console.log("obj", obj, bOBJ);
  return (
    <Row>
      <Col md={12}>
        {loading && <Loader />}
        <Card className="strpied-tabled-with-hover">
          <Accordion defaultActiveKey="0" className="coding-rules-wrapper ">
            <div className="p-3 px-4 d-flex justify-content-end">
              <i
                className="fas fa-plus-circle fa-lg cursor-pointer"
                alt="addCli"
                title="add"
                // onClick={() => setOpenCreateModal(true)}
              />
            </div>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="bg-transparent coding-rules-wrapper ">
                <Card.Header
                  onClick={(e) => e.stopPropagation()}
                  className="p-0 border-0 rounded-0 bg-transparent w-100 cursor-default"
                >
                  <div className="d-flex justify-content-between align-items-center pe-4">
                    <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                      Separate Claim For Panel
                    </Card.Title>
                    <div style={{ width: "40%", cursor: "pointer" }}>
                      <Select
                        options={panelOptions}
                        blurInputOnSelect={true}
                        menuPlacement="auto"
                        placeholder={"Select panel to add in list"}
                        className="w-100"
                        onChange={handleAddPanel}
                      />
                    </div>
                  </div>
                </Card.Header>
              </Accordion.Header>
              <Accordion.Body className="p-0">
                <Card.Body className="table-full-width desktop-noScroll">
                  <MainTable
                    columns={CODING_RULE_PERSONALIZE}
                    rows={panelData}
                    widthToSkip={200}
                    handleCellClick={handleCellClick}
                    tools={quickTools}
                  />
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="">
              <Accordion.Header className="bg-transparent">
                <Card.Header
                  onClick={(e) => e.stopPropagation()}
                  className="p-0 border-0 rounded-0 bg-transparent w-100 cursor-default"
                >
                  <div className="d-flex justify-content-between align-items-center pe-4">
                    <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                      CPT Codes
                    </Card.Title>
                  </div>
                </Card.Header>
              </Accordion.Header>
              <Accordion.Body className="p-0">
                <Card.Body>
                  <div className="d-flex justify-content-start gap-3 px-3">
                    <InputCPTCodeDropDown
                      type="text"
                      inputStyle="modalInput"
                      labelStyle=" text-capitalize"
                      groupWrapper="mb-0 w-30"
                      label={"CPT A"}
                      placeholder={"CPT A"}
                      optionList={[]}
                      value={obj.internalCode || obj.proc_code || ""}
                      handleChange={(opt) => {
                        setObj({
                          ...obj,
                          proc_code: opt.value,
                          charge: parseStringIntoFloat(opt.charges),
                          internalCode: opt.internalCode,
                          proc_desc: opt.description,
                          proc_name: opt.description,
                        });
                      }}
                      searchtype="cpt"
                    />
                    <InputCPTCodeDropDown
                      type="text"
                      inputStyle="modalInput"
                      labelStyle=" text-capitalize"
                      groupWrapper="mb-0 w-30"
                      label={"CPT B"}
                      placeholder={"CPT B"}
                      optionList={[]}
                      value={bOBJ.internalCode || bOBJ.proc_code || ""}
                      handleChange={(opt) => {
                        setbOBJ({
                          ...bOBJ,
                          proc_code: opt.value,
                          charge: parseStringIntoFloat(opt.charges),
                          internalCode: opt.internalCode,
                          proc_desc: opt.description,
                          proc_name: opt.description,
                        });
                      }}
                      searchtype="cpt"
                    />
                  </div>
                  <div className="table-full-width desktop-noScroll">
                    <MainTable
                      columns={CPT_CODES_PERSONALIZE}
                      rows={cptTableData}
                      widthToSkip={200}
                      handleCellClick={(key, row) => {
                        if (key === TABLE_QUICK_TOOLS.delete) {
                          dispatch(
                            showConfirmationModal({
                              showModal: true,
                              onConfirm: () => {
                                if (row.dropdownLabel === "A") setObj({});
                                else setbOBJ({});
                              },
                              title: "Remove Item",
                              message: `Are you sure you want to remove ${row.proc_name}?`,
                            })
                          );
                        }
                      }}
                      tools={quickTools}
                    />
                  </div>
                </Card.Body>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card>
      </Col>
    </Row>
  );
};

export default CodingRules;
