import React, { useState, useEffect, memo, useMemo, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateInvoiceDetails } from "store/features/invoicePreview/invoicePreviewAction";
import { Table, Column, AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";
import { validateNumber, formatCurrency, isObjectCompletelyEmpty, nestedFilter, vhToPixels } from "utils";
import Filter from "components/Filter";
import { INVOICE_DETAILS_FILTER_PERSONALISATION } from "constants/personalization";
import Icon from "components/Icon";
import headerRenderer from "./HeaderRendererEditable";
import { InvoiceEditDetailsSearch } from "constant";
import {
  CurrencyEditableCell,
  renderEditableCell,
  renderActionCell,
  nameCellRenderer,
  refferingCellRenderer,
} from "./CellRenderer";
import DiscountInput from "./DiscountInput";
import BillingInformation from "./BillingInformation";

const EditDetailsInvoiceModal = ({ show, handleClose, selectedInvoice, isInvoiceEdit, isBulk }) => {
  const [editedDetails, setEditedDetails] = useState([]);

  const [filteredDetails, setFilteredDetails] = useState([]);

  const [showFilter, setShowFilter] = useState(false);

  const [individualDiscountType, setIndividualDiscoutnType] = useState(false);
  const [invoiceDiscountType, setInvoiceDiscountType] = useState("amount");

  const [individualDiscountAmount, setIndividualDiscountAmount] = useState(0);

  const [discountAmount, setDiscountAmount] = useState(0);

  useEffect(() => {
    if (selectedInvoice?.discount) {
      setDiscountAmount(selectedInvoice.discount);
    }
    if (selectedInvoice.hasOwnProperty("discountType")) {
      setInvoiceDiscountType(selectedInvoice.discountType);
    }
  }, []);

  const totals = useMemo(() => {
    const result = editedDetails.reduce(
      (accumulator, currentObject) => {
        accumulator.chargeTotal += parseFloat(currentObject.charge);
        accumulator.mwOffTotal += parseFloat(currentObject.discount);
        return accumulator;
      },
      { chargeTotal: 0, mwOffTotal: 0 }
    );

    return { ...result, billedToPlan: result.chargeTotal - result.mwOffTotal };
  }, [editedDetails]);

  const overAllDiscount = useMemo(() => {
    const discountedAmount =
      invoiceDiscountType === "percentage"
        ? (parseFloat(discountAmount) / 100) * parseFloat(totals.billedToPlan)
        : parseFloat(discountAmount);
    const invoiceTotal = parseFloat(totals.billedToPlan) - parseFloat(discountedAmount);
    return { discountedAmount, invoiceTotal };
  }, [discountAmount, editedDetails, invoiceDiscountType]);

  const [filter, setFilter] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (show) {
      setEditedDetails(selectedInvoice.details);
    }
  }, [show]);

  useEffect(() => {
    setFilteredDetails(nestedFilter(editedDetails, filter));
  }, [filter]);

  const handleDetailChange = (index, value, field) => {
    if ((field === "charge" || field === "discount") && !validateNumber(value)) {
      return;
    }
    const updateDetails = (details) => details.map((item, idx) => (idx === index ? { ...item, [field]: value } : item));

    const updatedDetails = updateDetails(editedDetails);

    setEditedDetails(updatedDetails);

    if (field === "discount") {
      const updatedFilteredDetails = updateDetails(filteredDetails);
      setFilteredDetails(updatedFilteredDetails);
    }

    isInvoiceEdit(true);
  };

  const handleSaveDetails = async () => {
    console.log("invoiceDiscountType", invoiceDiscountType);
    await dispatch(
      updateInvoiceDetails({
        details: editedDetails,
        isBulk,
        selectedInvoiceClaimId: selectedInvoice.claimID,
        discount: discountAmount,
        discountType: invoiceDiscountType,
      })
    );
    handleClose();
  };

  const deleteDetailRecord = (delItem) => {
    const filteredItemsEdited = editedDetails.filter((item) => item.id !== delItem.id);
    const filteredItemsDetails = filteredDetails.filter((item) => item.id !== delItem.id);
    setEditedDetails(filteredItemsEdited);
    setFilteredDetails(filteredItemsDetails);
    isInvoiceEdit(true);
  };

  const handelChangeDiscountValue = (event) => {
    const value = parseFloat(event.target.value);
    if (isNaN(value)) {
      setDiscountAmount(0);
      return;
    }
    if (!validateNumber(value) || (invoiceDiscountType === "percentage" && value > 100)) {
      return;
    }
    setDiscountAmount(+value);
  };

  const handleChangeIndividualDiscount = (event) => {
    const value = parseFloat(event.target.value);
    if (isNaN(value)) {
      setIndividualDiscountAmount(0);
      return;
    }
    if (!validateNumber(value) || (individualDiscountType === true && value > 100)) {
      return;
    }
    setIndividualDiscountAmount(value);
  };

  const handleUpdateIndividualDiscount = (action) => {
    let updateDiscount = (item) => ({ ...item, discount: 0 });

    if (action === "add") {
      updateDiscount = (item) => ({
        ...item,
        discount: individualDiscountType
          ? parseFloat(item.charge) * (parseFloat(individualDiscountAmount) / 100)
          : parseFloat(individualDiscountAmount),
      });
    }

    const updatedFilteredDetails = filteredDetails.map(updateDiscount);
    let updatedEditedDetails;
    if (filteredDetails.length > 0) {
      updatedEditedDetails = editedDetails.map((originalItem) => {
        const found = updatedFilteredDetails.find(({ id }) => id === originalItem.id);
        return found || originalItem;
      });
    } else {
      updatedEditedDetails = editedDetails.map(updateDiscount);
    }

    setFilteredDetails(updatedFilteredDetails);
    setEditedDetails(updatedEditedDetails);

    isInvoiceEdit(true);
  };

  const handleChangeIndividualDiscountType = () => {
    setIndividualDiscoutnType(!individualDiscountType);
    if (!individualDiscountType && individualDiscountAmount > 100) {
      setIndividualDiscountAmount(100);
    }
  };

  const handleChangeInvoiceDiscountType = () => {
    setInvoiceDiscountType((currentType) => (currentType === "amount" ? "percentage" : "amount"));
    if (invoiceDiscountType === "amount" && discountAmount > 100) {
      setDiscountAmount(100);
    }
  };

  const DataToShow = isObjectCompletelyEmpty(filter) ? editedDetails : filteredDetails;

  const tableHeight = useMemo(() => vhToPixels(45), []);

  const TotalDataShow = useMemo(() => {
    return [{ total: "Total", amount: totals.chargeTotal, discount: totals.mwOffTotal, action: "" }];
  }, [totals]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={true}
      size={"3xl"}
      scrollable
      centered
      className="seperateModal1 "
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0">
          Edit Invoice Details - {selectedInvoice.toData.firstName + " " + selectedInvoice.toData.lastName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!showFilter && (
          <div style={{ position: "relative", top: "-30px" }}>
            <Icon
              handleClick={() => setShowFilter(!showFilter)}
              title={"Filter"}
              label={"Filter"}
              iconType={"filter"}
            />
          </div>
        )}
        {showFilter && (
          <div style={{ position: "relative", top: "-50px" }}>
            <Filter
              filterTerms={InvoiceEditDetailsSearch}
              setFilter={setFilter}
              filter={filter}
              personalisationData={INVOICE_DETAILS_FILTER_PERSONALISATION}
              setShowFilter={setShowFilter}
            />
          </div>
        )}

        <DiscountInput
          label="Line item only:"
          discountType={individualDiscountType}
          discountAmount={individualDiscountAmount}
          onDiscountTypeChange={handleChangeIndividualDiscountType}
          onDiscountAmountChange={handleChangeIndividualDiscount}
          onUpdateDiscount={() => handleUpdateIndividualDiscount("add")}
        />

        <AutoSizer disableHeight>
          {({ width }) => {
            const columnWidths = {
              fromDate: width * 0.1,
              accessionNo: width * 0.1,
              patientName: selectedInvoice.sendTo === "Client" ? width * 0.15 : 0,
              refferingProvider: width * 0.15,
              cpt: width * 0.05,
              units: width * 0.05,
              description: selectedInvoice.sendTo === "Client" ? width * 0.2 : width * 0.25,
              amount: width * 0.075,
              discount: width * 0.075,
              action: width * 0.05,
            };

            return (
              <Table
                width={width}
                height={tableHeight}
                headerHeight={50}
                rowHeight={50}
                rowCount={DataToShow.length}
                rowGetter={({ index }) => DataToShow[index]}
                rowStyle={{
                  border: "1px solid #DDD",
                }}
                className="main-table-edit-wrapper"
              >
                <Column
                  headerClassName="edit-details-header"
                  label="Service Date"
                  dataKey="from_date"
                  width={columnWidths.fromDate}
                  headerRenderer={(headerProps) => headerRenderer({ ...headerProps, align: "left" })}
                />
                <Column
                  headerClassName="edit-details-header"
                  label="Accession No"
                  dataKey="accession_no"
                  width={columnWidths.accessionNo}
                  headerRenderer={(headerProps) => headerRenderer({ ...headerProps, align: "left" })}
                />
                {selectedInvoice.sendTo === "Client" && (
                  <Column
                    headerClassName="edit-details-header"
                    label="Patient Name"
                    dataKey="first_name"
                    width={columnWidths.patientName}
                    headerRenderer={(headerProps) => headerRenderer({ ...headerProps, align: "left" })}
                    cellRenderer={nameCellRenderer}
                  />
                )}

                <Column
                  headerClassName="edit-details-header"
                  label="Referring Physician"
                  dataKey="referring_provider"
                  width={columnWidths.refferingProvider}
                  headerRenderer={(headerProps) => headerRenderer({ ...headerProps, align: "left" })}
                  cellRenderer={refferingCellRenderer}
                />

                <Column
                  headerClassName="edit-details-header"
                  label="Cpt"
                  dataKey="proc_code"
                  width={columnWidths.cpt}
                  headerRenderer={(headerProps) => headerRenderer({ ...headerProps, align: "left" })}
                />

                <Column
                  headerClassName="edit-details-header"
                  label="Units"
                  dataKey="units"
                  width={columnWidths.units}
                  cellRenderer={renderEditableCell}
                  headerRenderer={(headerProps) => headerRenderer({ ...headerProps, align: "center" })}
                />
                <Column
                  headerClassName="edit-details-header"
                  label="Description"
                  dataKey="proc_desc"
                  width={columnWidths.description}
                  headerRenderer={(headerProps) => headerRenderer({ ...headerProps, align: "left" })}
                />

                <Column
                  headerClassName="edit-details-header amount-details"
                  label="Amount"
                  dataKey="charge"
                  width={columnWidths.amount}
                  cellRenderer={({ cellData, columnData, dataKey, rowData, rowIndex }) => (
                    <CurrencyEditableCell
                      cellData={cellData}
                      columnData={columnData}
                      dataKey={dataKey}
                      rowData={rowData}
                      rowIndex={rowIndex}
                      updateData={handleDetailChange}
                    />
                  )}
                  headerRenderer={(headerProps) => headerRenderer({ ...headerProps, align: "center" })}
                />
                <Column
                  headerClassName="edit-details-header amount-details"
                  label="Discount"
                  dataKey="discount"
                  width={columnWidths.discount}
                  cellRenderer={({ cellData, columnData, dataKey, rowData, rowIndex }) => (
                    <CurrencyEditableCell
                      cellData={cellData}
                      columnData={columnData}
                      dataKey={dataKey}
                      rowData={rowData}
                      rowIndex={rowIndex}
                      updateData={handleDetailChange}
                    />
                  )}
                  headerRenderer={(headerProps) =>
                    headerRenderer({
                      ...headerProps,
                      align: "center",
                      showClearIcon: true,
                      onClear: () => {
                        handleUpdateIndividualDiscount("clear");
                      },
                    })
                  }
                />

                <Column
                  headerClassName="edit-details-header"
                  label="Action"
                  dataKey="delete"
                  width={columnWidths.action}
                  cellRenderer={(cellProps) => renderActionCell({ ...cellProps, onClick: deleteDetailRecord })}
                  headerRenderer={(headerProps) => headerRenderer({ ...headerProps, align: "right" })}
                />
              </Table>
            );
          }}
        </AutoSizer>
      </Modal.Body>
      <Modal.Footer>
        <AutoSizer disableHeight>
          {({ width }) => {
            const columnWidths = {
              total: width * 0.8,
              amount: width * 0.075,
              discount: width * 0.075,
              action: width * 0.05,
            };

            return (
              <Table
                width={width}
                height={50}
                rowHeight={50}
                rowCount={TotalDataShow.length}
                rowGetter={({ index }) => TotalDataShow[index]}
                className="total-table-edit-wrapper"
              >
                <Column
                  dataKey="total"
                  width={columnWidths.total}
                  style={{ textAlign: "right", fontWeight: "800", paddingRight: "20px" }}
                />
                <Column
                  dataKey="amount"
                  width={columnWidths.amount}
                  cellRenderer={({ cellData, columnData, dataKey, rowData, rowIndex }) => (
                    <CurrencyEditableCell
                      cellData={cellData}
                      columnData={columnData}
                      dataKey={dataKey}
                      rowData={rowData}
                      rowIndex={rowIndex}
                      readOnly={true}
                    />
                  )}
                />
                <Column
                  dataKey="discount"
                  width={columnWidths.discount}
                  cellRenderer={({ cellData, columnData, dataKey, rowData, rowIndex }) => (
                    <CurrencyEditableCell
                      cellData={cellData}
                      columnData={columnData}
                      dataKey={dataKey}
                      rowData={rowData}
                      rowIndex={rowIndex}
                      readOnly={true}
                    />
                  )}
                />

                <Column label="Action" dataKey="action" width={columnWidths.action} />
              </Table>
            );
          }}
        </AutoSizer>

        <BillingInformation
          billedToPlan={totals.billedToPlan}
          discountAmount={discountAmount}
          overAllDiscount={overAllDiscount.invoiceTotal}
          handleDiscountChange={handelChangeDiscountValue}
          invoiceDiscountType={invoiceDiscountType}
          changeDiscountType={handleChangeInvoiceDiscountType}
        />

        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={handleClose}
          >
            Close
          </Button>

          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={handleSaveDetails}
          >
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(EditDetailsInvoiceModal);
