import React from "react";
import { Link } from "react-router-dom";

const RedirectLink = ({ pathname, state, children }) => {
  return (
    <Link
      style={{ textDecoration: "none", color: "var(--text-black)", cursor: "pointer" }}
      to={{
        pathname,
        state,
      }}
    >
      {children}
    </Link>
  );
};

export default RedirectLink;
