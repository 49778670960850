import React from "react";
import { Modal, Button, Table, Card, Row, Col } from "react-bootstrap";
import { objTypes, AdditionalKeys, fieldLabel, applyCustomOrder } from "constant";
import { insuranceProviderFormatter } from "utils";

const OrderLogsCollapseTable = (props) => {
  const { open } = props;

  const data = applyCustomOrder(Object.keys(open.newRecord), Object.keys(fieldLabel)).filter(
    (f) =>
      !AdditionalKeys.includes(f) &&
      !f.includes("mod") &&
      !f.includes("units") &&
      !f.includes("ins_") &&
      !f.includes("thru_date") &&
      !f.includes("remote_chgid")
  );

  function formatDate(dateString) {
    if (!dateString) return dateString;
    const numericString = dateString.replace(/\D/g, "");
    const year = numericString.substring(0, 4);
    const month = numericString.substring(4, 6);
    const day = numericString.substring(6, 8);
    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  }

  const TableCollapse = ({ objKeys, newRecord, oldRecord }) => {
    // Check if objKeys ends with a number
    const endsWithNumber = /\d$/.test(objKeys);

    // Modify the key and get the numeric suffix
    const modifiedObjKeys = endsWithNumber ? objKeys.replace(/_\d+$/, "") : objKeys;
    const numericSuffix = endsWithNumber ? objKeys.match(/\d$/) : null;

    // Get the modified fieldLabel using modifiedObjKeys
    const modifiedFieldLabel = fieldLabel[modifiedObjKeys] || "Value";

    const shouldRenderRow =
      (oldRecord?.[objKeys] || newRecord?.[objKeys]) &&
      newRecord?.[objKeys]?.[objTypes[objKeys] || "S"] !== oldRecord?.[objKeys]?.[objTypes[objKeys] || "S"] &&
      (newRecord?.[objKeys]?.[objTypes[objKeys] || "S"] || oldRecord?.[objKeys]?.[objTypes[objKeys] || "S"]);

    return shouldRenderRow ? (
      <tr className="expended-row">
        <td
          className="ellipsis w-10"
          style={{ textAlign: "center" }}
          title={`${modifiedFieldLabel} ${(numericSuffix && numericSuffix) || ""} `}
        >
          {`${modifiedFieldLabel} ${(numericSuffix && numericSuffix) || ""}`}
        </td>
        <td style={{ textAlign: "center" }}>
          {objKeys === "insuranceCompany"
            ? insuranceProviderFormatter(oldRecord?.[objKeys]?.[objTypes[objKeys] || "S"])
            : objKeys === "eligibilityStatus"
            ? oldRecord?.[objKeys]?.["M"]?.["message"]?.["S"]
            : objKeys === "from_date" || objKeys === "orderDate"
            ? formatDate(oldRecord?.[objKeys]?.[objTypes[objKeys] || "S"])
            : oldRecord?.[objKeys]?.[objTypes[objKeys] || "S"]}
        </td>
        <td style={{ textAlign: "center" }}>
          {objKeys === "insuranceCompany"
            ? insuranceProviderFormatter(newRecord?.[objKeys]?.[objTypes[objKeys] || "S"])
            : objKeys === "eligibilityStatus"
            ? newRecord?.[objKeys]?.["M"]?.["message"]?.["S"]
            : objKeys === "from_date" || objKeys === "orderDate"
            ? formatDate(oldRecord?.[objKeys]?.[objTypes[objKeys] || "S"])
            : newRecord?.[objKeys]?.[objTypes[objKeys] || "S"]}
        </td>
      </tr>
    ) : null;
  };

  return (
    <>
      {data && data.length > 0 && (
        <tr>
          <td colSpan={"12"} className="expended-table-child">
            <Card className="striped-table-card">
              <Card.Body>
                <div className="form-group-wrapper d-block">
                  <div className="table-responsive overFlow-y-hidden">
                    <Table className="table expandedTableChild">
                      <thead>
                        <tr>
                          <th className="fw-bold" style={{ textAlign: "center" }}>
                            Field
                          </th>
                          <th style={{ textAlign: "center" }}>Before</th>
                          <th style={{ textAlign: "center" }}>After</th>
                        </tr>
                      </thead>
                      <tbody>
                        {open.newRecord &&
                          open.oldRecord &&
                          data.map((m, i) => {
                            return (
                              <TableCollapse
                                key={i}
                                objKeys={m}
                                oldRecord={open.oldRecord}
                                newRecord={open.newRecord}
                              />
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </td>
        </tr>
      )}
    </>
  );
};

export default OrderLogsCollapseTable;
