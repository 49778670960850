import React from "react";
import Status from "components/Status";

const TDStatus = ({ row, item, handleClick }) => {

  const isActive = row[item];

  const onHandleClick = () => {
    handleClick(row);
  };
  if(isActive)
  return  (
    <div className="star-icon-wrapper" onClick={onHandleClick}>
      <Status type="circle" size="md" color="green" tickIcon />
    </div>
  ) 
  
  return (
    <div className="star-icon-wrapper" onClick={onHandleClick}>
      <Status type="circle" size="md" color="maroon" crossIcon />
    </div>
  );
};

export default TDStatus;
