import { useEffect, useState } from "react";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import logoImage from "../../assets/img/login_logo.png";
import Loader from "components/Loader/Loader";
import { useHistory } from "react-router-dom";
import API from "api";
import ShowAlertMessage from "components/ShowAlertMessage";
import { useSelector } from "react-redux";
import { authSelector } from "store/features/authentication/authenticationSelectors";
import { getUserName } from "utils";

const SendOTPUi = () => {
  const [disableOTPbtn, setDisableOTPBtn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [phone, setPhone] = useState("+1");
  const { companies } = useSelector(authSelector);

  useEffect(() => {
    if (phone && phone.length > 6) {
      setDisableOTPBtn(false);
    } else {
      setDisableOTPBtn(true);
    }
  }, [phone]);

  let history = useHistory();

  const handleError = () => {
    setPhoneError("This Company has been InActive");
    setDisableOTPBtn(false);
  };

  const handleForgotPassword = async () => {
    if (phone && phone.length > 6) {
      try {
        setLoading(true);
        const username = await getUserName(phone, companies, handleError);
        const path = "/user/sendCode";
        const response = await API.callPublicAPI({ path, payload: { phone: username } });
        if (response && response.send) {
          if (history) history.push("/reset-pass", { username });
        } else {
          setPhoneError("Record Not Found");
        }
      } catch (error) {
        setPhoneError(error?.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="loginWrapper">
        <div className="login-left-side">
          <div className="text-center">{<img src={logoImage} alt="logo" className="logo-width" />}</div>
        </div>
        {phoneError && <ShowAlertMessage message={phoneError} handleClose={() => setPhoneError("")} error />}
        {loading && <Loader />}
        <div className="login-right-side max-w-100 text-center">
          <h3 className="mt-0">Forgot Your Password?</h3>
          <p className="tag-line mt-3">
            Enter the phone number associated with your account and We'll send a code to your email
            address to reset your password.
          </p>
          <div className="login-inner-right-side mt-4">
            <div className="form-group text-left">
              <label className="login-input-label mb-2">Phone Number</label>
              <PhoneNoInput handleChange={(e) => setPhone(e)} value={phone} />
            </div>
            <div className="mt-4 w-100">
              <button
                type="submit"
                disabled={disableOTPbtn}
                style={{ maxWidth: "100%" }}
                className="text-uppercase w-100 btn signin-btn"
                onClick={handleForgotPassword}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendOTPUi;
