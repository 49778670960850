import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import api from "api";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import { CLI_ACTIONS } from "constant";
import { NEW_CLI_ITEM } from "constant";
import { CLI_DEFAULT_OPTIONS } from "constant";
import { Button, Form, Modal } from "react-bootstrap";
import { t } from "stringConstants";

const AddGeneralSettingsModal = (props) => {
  const { handleClose, selectedItem, handleSave } = props;
  const [newItem, setNewItem] = useState(
    selectedItem ? { action: CLI_ACTIONS.update, ...selectedItem } : { action: CLI_ACTIONS.add, ...NEW_CLI_ITEM }
  );
  const [error, setError] = useState("");
  const [prevCliData, setPrevCliData] = useState([]);

  useEffect(() => {
    getPreviousList();
  }, []);

  const getPreviousList = async () => {
    const res = await api.getCompanySetting();
    setPrevCliData(res.cliaNumber || []);
  };

  const onHandleSave = async (obj) => {
    if (!obj.cliCode) {
      setError("Please fill CLI Code!");
      return;
    }

    let alreadyExist = false;
    if (obj.action === CLI_ACTIONS.add) {
      const findVal = prevCliData.findIndex((item) => item.cliCode === obj.cliCode);
      if (findVal !== -1) alreadyExist = true;
    } else if (obj.action === CLI_ACTIONS.update) {
      let finds = 0;
      prevCliData.forEach((item) => item.cliCode === obj.cliCode && finds++);
      if (finds > 1) alreadyExist = true;
    }

    if (alreadyExist) {
      setError("CLI Code already exists!");
      return;
    }

    handleSave(obj);
  };

  return (
    <>
      <Modal show backdrop="static" animation={true} onHide={() => handleClose()} centered size={"md"}>
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {newItem.action === CLI_ACTIONS.add ? `${t("create")}` : `${t("update")}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: 0 }}>
          <div className="d-flex justify-content-around">
            <div>
              <Form.Group className="w-100">
                <label className="mt-0 modalLineHeaders text-capitalize">CLIA Code: </label>
                <Form.Control
                  className={"modalInput"}
                  onChange={(e) =>
                    setNewItem({
                      ...newItem,
                      cliCode: e.target.value.toUpperCase(),
                    })
                  }
                  maxLength={10}
                  value={newItem.cliCode}
                  placeholder="Enter CLI Code"
                  type="text"
                />
              </Form.Group>
            </div>

            <div
              className="d-flex flex-column align-items-center justify-content-between profile-
                          detail mb-3"
            >
              <span className="section-title">Is Default: </span>
              <SwitchToggle
                checked={newItem.isDefault === CLI_DEFAULT_OPTIONS.yes}
                handleChange={(e) =>
                  setNewItem({
                    ...newItem,
                    isDefault: e.target.checked ? CLI_DEFAULT_OPTIONS.yes : CLI_DEFAULT_OPTIONS.no,
                  })
                }
              />
            </div>
          </div>
        </Modal.Body>
        <div className="px-4 my-2" style={{ height: "2vh" }}>
          {error && <strong style={{ color: "Red" }}>{error}</strong>}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            onClick={() => onHandleSave(newItem)}
          >
            {newItem.action === CLI_ACTIONS.add ? "Create" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddGeneralSettingsModal;
