import React, { useState } from "react";
import { Button, Modal, Card, Table, Row, Col, Collapse } from "react-bootstrap";
import { formatDateMDY, formatDateMDYTime, parseUserName, toTitleCase } from "utils";
import api from "api";
import { Audit_Types, Zone_Audit_Types, AdditionalKeys, applyCustomOrder, fieldLabel } from "constant";
import { Audit_Parse } from "constant";
import OrderLogsCollapseTable from "./Order/components/OrderLogsCollapseTable";
import { tdRowDropdown } from "utils";
import { objTypes } from "constant";

const AuditTrailEntries = (props) => {
  const { item, auditTrails, user, handleOpenDetailModal } = props;
  const [show, setShow] = useState(false);
  const [items, setItems] = useState([]);
  console.log("item", items);
  const handleExpand = () => {
    const sortList = [...auditTrails].sort((a, b) =>
      b.createdAt > a.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
    );
    let logs = sortList.filter((f) => Audit_Types[f.eventType] === item);
    if (item === "Claim" || item === "Member Profile Edited") {
      const editLogslist = logs.filter(
        (item) =>
          item.changeObject &&
          item.changeObject.newRecord &&
          applyCustomOrder(Object.keys(item.changeObject.newRecord), Object.keys(fieldLabel)).filter((f) => {
            const shouldRenderRow =
              (item.changeObject.oldRecord?.[f] || item.changeObject.newRecord?.[f]) &&
              item.changeObject.newRecord?.[f]?.[objTypes[f] || "S"] !==
                item.changeObject.oldRecord?.[f]?.[objTypes[f] || "S"] &&
              (item.changeObject.newRecord?.[f]?.[objTypes[f] || "S"] ||
                item.changeObject.oldRecord?.[f]?.[objTypes[f] || "S"]);
            return (
              !AdditionalKeys.includes(f) &&
              !f.includes("mod") &&
              !f.includes("units") &&
              !f.includes("ins_") &&
              !f.includes("thru_date") &&
              !f.includes("remote_chgid") &&
              shouldRenderRow
            );
          }).length > 0
      );

      const logsFilter = logs.filter((f) => f.action !== "Updated");
      if (editLogslist.length > 0) {
        logsFilter.push(...editLogslist);
      }
      logsFilter.sort((a, b) => {
        const testerB = b.createdAt;
        const testerA = a.createdAt;
        return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
      });

      logs = logsFilter;
    }
    setItems(logs);
    setShow(!show);
  };

  const TableRow = ({ log, index }) => {
    const [open, setOpen] = useState(null);
    return (
      <>
        <tr>
          {tdRowDropdown(log.changeObject, open, () => setOpen(!open ? log.changeObject : null))}
          <td>{items.length - index}</td>
          <td>{formatDateMDY(log.createdAt)}</td>
          <td
            style={{
              textAlign: "center",
              cursor: log.eventType === "Eligibility" ? "pointer" : "",
            }}
            onClick={() => log.eventType === "Eligibility" && handleOpenDetailModal(log)}
          >
            {Audit_Parse[log.eventType]}
          </td>
          <td className="ellipsis" style={{ textAlign: "center" }}>
            {log.result || log.action || log.reason || (log.eventData && (Audit_Parse[log.eventData] || log.eventData))}
          </td>
          <td style={{ textAlign: "center" }}>{parseUserName(log)}</td>
          {/* <td
            onClick={async () => {
              await api.deleteLogRecord(log.id);
            }}
          >
            <i className="fas fa-trash cursor-pointer" />
          </td> */}
        </tr>
        {open && <OrderLogsCollapseTable open={open} />}
      </>
    );
  };
  return (
    <>
      <tr
        onClick={handleExpand}
        onMouseOver={(e) => {
          e.target.style.cursor = "pointer";
        }}
      >
        {tdRowDropdown(true, show)}
        <td>{item}</td>
      </tr>
      {items.length > 0 && show && (
        <tr className="subTableRow">
          <td colSpan={"12"} className="expended-table-wrapper">
            <Card className="striped-table-card">
              <Card.Body>
                <div className="form-group-wrapper d-block">
                  <div className="table-responsive overFlow-y-hidden">
                    <Table className="table expandedTable">
                      <thead>
                        <tr>
                          <th />
                          <th style={{ width: "10%" }}>Sr#</th>
                          <th>Date</th>
                          <th className="border-0 centered">Event</th>
                          <th className="border-0 centered w-25">Action</th>
                          {/* <th className="border-0 centered">Detail</th> */}
                          <th className="border-0 centered">User</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.length > 0 &&
                          items.map((item, i) => {
                            return <TableRow key={i} index={i} log={item} />;
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </td>
        </tr>
      )}
    </>
  );
};

export default AuditTrailEntries;
