import React, { useState, useEffect, useMemo } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import api from "api";
import { useSelector, useDispatch } from "react-redux";
import { INSURANCE_PROVIDER, BILL_TO_OPTIONS, CLAIM_INSURANCE_TYPE } from "constant";
import { Storage } from "aws-amplify";
import ProcedureInfoSection from "./components/ProcedureInfoSection/ProcedureInfoSection";
import { saveAs } from "file-saver";
import PdfViewer from "../PdfViewer";
import Loader from "components/Loader/Loader";
import { employeesSelector } from "store/features/employees/employeesSelectors";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import Select from "react-select";
import InputField from "components/InputField/InputField";
import Dropdown from "react-bootstrap/Dropdown";
import { locationsSelectors } from "store/features/locations/locationsSelectors";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import API from "api";
import moment from "moment";
import { ErrorBoundary } from "react-error-boundary";
import OrderLogsModal from "components/Modal/Order/OrderLogsModal";
import { updateEmployeeClaim } from "store/features/employeeClaims/employeeClaimsActions";
import { fetchAllLocations } from "store/features/locations/locationsSlice";
import { fetchAllEmployees } from "store/features/employees/employeesSlice";
import { CLAIMS_KEYS, CUSTOM_INS, DIAGNOSIS_ITEMS, CONFIG } from "constant";
import { PARSE_ALPHA_INTO_NUM, MESSAGE_MODES } from "constant";
import {
  getDiagonosisCode,
  formatCurrency,
  parseInvoiceData,
  formatEmployeeClaim,
  removeKeysFromObject,
  getTotalCharge,
} from "utils";
import { setMessage } from "store/features/general/generalAction";
import { addSelectedInvoice } from "store/features/invoicePreview/invoicePreviewAction";
import { getFloatVal } from "utils";
import AppealModal from "./AppealModal";
import { customUniqBy } from "util/customLodash";
import { fetchDocuemntTypesAsync } from "store/features/documents/documentSlice";

const EraModal = (props) => {
  const { user, handleClose, cssClass } = props;
  const [show, setShow] = useState("");
  const [claim, setClaim] = useState({ ...user });
  const [eraList, setEraList] = useState([]);
  const [openPdf, setOpenPdf] = useState("");
  const [loading, setLoading] = useState(false);

  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [employee, setEmployee] = useState({});
  const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [selectedERA, setSelectedERA] = useState([]);

  const labLocations = useSelector(locationsSelectors);
  const loginUser = useSelector(selectLoginUser);
  const dispatch = useDispatch();

  const [appealData, setAppealData] = useState(null);
  const [totalChargeAmount, setTotalChargeAmount] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setShow("");
    }, 4000);
  }, [show]);
  useEffect(() => {
    getCliData();
    dispatch(fetchAllLocations());
  }, []);

  useEffect(() => {
    dispatch(fetchDocuemntTypesAsync({ title: "" }));
  }, []);

  const handleOpenEraFile = async (eraData) => {
    try {
      if (eraData.files && eraData.files.length > 0) {
        for (const file of eraData.files) {
          const res = await Storage.get(file.fileName, {
            bucket: CONFIG.eligibilityBucket,
            download: true,
          });

          // Use file-saver to save the file
          saveAs(res.Body, file.fileName);
        }
      } else {
        const path = await api.getPatientERA(eraData);
        const res = await Storage.get(path, {
          bucket: CONFIG.eligibilityBucket,
        });
        window.open(`${res}`);
      }

      // setOpenPdf(res);

      // const baseUrl = res.split("?")[0];
      // setOpenPdf(baseUrl);
    } catch (err) {
      console.log("Error:-", err.message);
    }
  };

  const getCliData = async () => {
    try {
      setLoading(true);
      const era = await api.getClaimERA(user);
      // setEraList(formatEraListView(era, user));
      if (era.length > 0) {
        era.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      }
      setEraList(era);
      if (era?.length > 0) {
        setEmployee(era[0]?.employee);
      }
      setLoading(false);
    } catch (error) {
      console.log("error in getCliData off", error.message);
    }
  };

  const handleWriteOff = async (claimStatus, claimtype, note) => {
    try {
      let claimWriteOff = { ...claim };
      let writeOffAmount = 0;

      claimWriteOff = {
        ...claimWriteOff,
        status: "paid",
        proc_array: claim?.proc_array?.map((item) => ({
          ...item,
          write_off: parseFloat(item.charge) - parseFloat(item.allowed || 0),
          mw_off: parseFloat(item.charge) - parseFloat(item.paid || 0),
        })),
      };
      setClaim(claimWriteOff);
      setEraList((prevEra) => {
        return prevEra.map((item) => ({
          ...item,
          charge: item?.charge?.map((item1) => ({
            ...item1,
            write_off: Number(item1.charge) - Number(item1.allowed || 0),
          })),
        }));
      });
      const modifiedObj = claimWriteOff ? { ...claimWriteOff } : {};

      claimWriteOff.proc_array.map((item) => {
        writeOffAmount += getFloatVal(item.mw_off);
      });
      const mergedObj = Object.assign(modifiedObj, {
        status: claimStatus || "submitted",
        updatedBy: loginUser.sub,
        updatedByName: loginUser.name,
      });
      await dispatch(updateEmployeeClaim(mergedObj, claimStatus, claimtype));
      const logDate = moment().toISOString();
      const logObj = {
        firstName: employee.firstName,
        lastName: employee.lastName,
        dob: employee.dob,
        empID: employee.id,
        medicalNo: employee.medicalNo,
        locationID: employee.companyID,
        clientID: employee.clientID,
        subAgentID: employee.subAgent,
        userID: employee.subID,
        payerid: claim.payerid,
        payerName: claim.payer_name,
        claimID: claim.id,
        userName: loginUser.name,
        message: `Remaining Balance of $${writeOffAmount} Invoiced to Client. \n${note}`,
        eventType: "ClaimWriteOff",
        eventData: "ClaimWriteOff",
        logDate,
      };
      await API.saveLogs(logObj);
      handleClose();
    } catch (error) {
      console.log("error in write off", error.message);
    }
  };

  const isAlreadySend = () => {
    const toData = claim?.toData ?? [];
    const formattedData = toData && typeof toData === "string" ? JSON.parse(toData) : toData;
    const isInvoiceRecord = formattedData?.filter((f) => f.invoiceNo);
    return isInvoiceRecord && isInvoiceRecord.length > 0;
  };

  const calculateAmount = useMemo(() => {
    let totalBill = 0;
    let totalAllowed = 0;
    let totalWriteOff = 0; // eraList.reduce((ttl, { total_write_off }) => ttl + getFloatVal(total_write_off), 0);
    let totalManualWriteOff = 0;
    let totalPaid = eraList.reduce((ttl, { total_paid }) => ttl + getFloatVal(total_paid), 0);

    claim &&
      claim.proc_array.map((item) => {
        const prAmount =
          parseFloat(item.co_insurance || 0) + parseFloat(item.copay || 0) + parseFloat(item.deductible || 0);
        const allowedAmount = prAmount ? prAmount + parseFloat(item.paid || 0) : parseFloat(item.allowed || 0);
        totalBill += getTotalCharge(item);
        // totalPaid += parseFloat(item.paid || 0); // Use the ERA Paid Details
        totalAllowed += allowedAmount;
        totalWriteOff += parseFloat(item.write_off || 0);
        totalManualWriteOff += parseFloat(item.mw_off || 0);
      });

    return {
      totalBill: +parseFloat(totalBill).toFixed(2),
      totalAllowed: +parseFloat(totalAllowed).toFixed(2),
      totalPaid: +parseFloat(totalPaid).toFixed(2),
      totalWriteOff: +parseFloat(totalWriteOff).toFixed(2),
      totalManualWriteOff: +parseFloat(totalManualWriteOff).toFixed(2),
      open: +parseFloat(
        totalBill - totalPaid - totalWriteOff - (totalManualWriteOff ? totalManualWriteOff - totalWriteOff : 0)
      ).toFixed(2),
    };
  }, [eraList]);

  const insuranceOptions = useMemo(() => {
    if (employee && employee?.insuranceDetails?.length > 0) {
      const items = employee?.insuranceDetails
        .filter(
          (f) =>
            f &&
            !CUSTOM_INS.includes(f.insuranceCompany) &&
            // TODO:: this check is temporary disabled because user want to send claim to same payer 
           // (claim.toData ? claim.toData.findIndex((i) => i.payerid === f.insuranceCompany) === -1 : true) &&
            claim.payerid !== f.insuranceCompany
        )
        .map((item) => ({
          ...item,
          label: item.insuranceCompanyCode,
          value: item.insuranceCompany,
        }));
      return items;
    }
    return [];
  }, [employee, eraList]);

  const handleInvoice = async (type) => {
    try {
      let insProvider = INSURANCE_PROVIDER.find((item) => item.payer_name === type);
      let claimtoUpdate = claim ? { ...claim } : {};

      const mergedObj = Object.assign(claimtoUpdate, {
        payer_name: insProvider.payer_name,
        payerid: insProvider.payerid,
        ins_number: insProvider.medicalNo || "",
      });

      if (type == "Bill Client") {
        if (!(claim.locationID || claim.provider.ref_id)) {
          dispatch(setMessage("Please select client to pay", MESSAGE_MODES.error));
          return;
        }
      }

      await dispatch(updateEmployeeClaim(mergedObj, "paid", "claim", "noAlert"));
      const invoiceFormatedData = await parseInvoiceData(mergedObj, loginUser);
      dispatch(addSelectedInvoice({ selectedInvoice: invoiceFormatedData, isBulk: false }));
    } catch (error) {
      console.log("error in handle invoice", error.message);
    }
  };

  // Parse Diagnosis Code JSON
  const parseDiagonosisCode = (obj) => {
    for (const item of DIAGNOSIS_ITEMS) {
      obj[`diag_${PARSE_ALPHA_INTO_NUM[item]}`] = getDiagonosisCode(obj[`diag_${PARSE_ALPHA_INTO_NUM[item]}`]);
    }
    return obj;
  };

  const handleConfirm = async (isConfirm, type, note) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) return;
    switch (type) {
      case "writeOff":
        handleWriteOff("paid", "claim", note);
        break;
      case "changeInsurance":
        handleInsuranceChange(openConfirmation.data);
    }
  };

  const handleShowLogs = async (data) => {
    setOpenOrderDetail(true);
  };

  const handleInsuranceChange = async (adIns) => {
    try {
      const modifiedObj = claim
        ? {
            ...claim,
            payer_name: adIns.insuranceCompanyCode,
            payerid: adIns.insuranceCompany,
            status: "submitted",
            ins_number: adIns.medicalNo || "",
          }
        : {};

      const toData = [];

      if (!CUSTOM_INS.includes(claim.payerid)) {
        toData.push({
          payer_name: claim.payer_name,
          payerid: claim.payerid,
          ins_number: claim.ins_number,
          submissionDate: claim.submissionDate?.split(",")[0],
          status_code: 1,
        });
      }

      Object.assign(modifiedObj, {
        ins_name_f: modifiedObj.pat_name_f,
        ins_name_l: modifiedObj.pat_name_l,
        ins_name_m: modifiedObj.pat_name_m,
        ins_sex: modifiedObj.pat_sex,
        ins_addr_1: modifiedObj.pat_addr_1,
        ins_addr_2: modifiedObj.pat_addr_2,
        ins_city: modifiedObj.pat_city,
        ins_state: modifiedObj.pat_state,
        ins_dob: modifiedObj.pat_dob,
        ins_zip: modifiedObj.pat_zip,
        updatedBy: loginUser.sub,
        updatedByName: loginUser.name,
        ...(toData.length > 0 && { toData }),
      });

      const primaryERA = eraList.find((f) => f.payerid === claim.payerid);

      const setting = await api.getCompanySetting();
      const claimSubmission = formatEmployeeClaim(modifiedObj, setting, false, true, primaryERA);
      let claims = parseDiagonosisCode(claimSubmission);
      //await downloadDataAsCSV([claims], "Secondary Claim Submit");
      await dispatch(updateEmployeeClaim(claims, "submitted", "claim"));
      handleClose();
    } catch (error) {
      console.log("error in handle insurance change", error);
    }
  };

  const hanleAppealModalClose = () => {};

  const handelShowAppealModal = () => {
    const extractedData = [];
    let chargeAmount = 0;
    eraList.forEach((obj) => {
      obj.charge.forEach((chargeObj) => {
        if (selectedERA.includes(chargeObj.chgid)) {
          const isProcCodeExists = extractedData.some((item) => item.proc_code === chargeObj.proc_code);
          if (!isProcCodeExists) {
            extractedData.push({
              proc_code: chargeObj.proc_code,
              payerid: obj.payerid,
              payer_name: obj.payer_name,
            });
          }
          chargeAmount = chargeAmount + chargeObj.charge;
        }
      });
    });
    setAppealData(extractedData);
    setTotalChargeAmount(chargeAmount);
  };

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
  };
  const ErrorFallback = ({ error, resetErrorBoundary }) => {
    return (
      <ErrorMessageModal
        error={error.message}
        handleClose={() => {
          dispatch(setClaimReSubmissionModal(null));
          setOpenOrderDetail(false);
        }}
      />
    );
  };
  return (
    <>
      <Modal
        show
        backdrop="static"
        animation={true}
        onHide={() => handleClose()}
        centered
        size={"2xl"}
        className={cssClass}
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0 text-capitalize " id="contained-modal-title-vcenter" style={{}}>
            <span style={{ marginRight: "20px" }}>{`ERA Detail ${
              user.pat_name_f ? `(${user.pat_name_f} ${user.pat_name_l})` : ""
            }`}</span>
            <span className="fw-bold" style={{ marginRight: "20px" }}>{`Total Bill: $${
              calculateAmount.totalBill
            } Total Paid: $${calculateAmount.totalPaid || 0.0}`}</span>
            <span className="fw-bold">{`Outstanding: $${calculateAmount.open}`}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: 0 }}>
          {appealData && (
            <AppealModal
              cssClass={cssClass}
              handleClose={() => setAppealData(null)}
              appealData={appealData}
              claimData={claim}
              chargeAmount={totalChargeAmount}
            />
          )}

          <div className="crew-member-profile">
            <div className="crew-member-profile-inner">
              <Row>
                <Col md={8}></Col>
                <Col md={4} style={{ marginBottom: "-40px" }}>
                  <div>
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Open Party: </span>
                      <Select
                        styles={style}
                        className="w-60"
                        options={CLAIM_INSURANCE_TYPE}
                        blurInputOnSelect={true}
                        defaultValue={null}
                        value={CLAIM_INSURANCE_TYPE.find((item) => item.value == claim?.openCompany)}
                        menuPlacement="auto"
                        placeholder="Select option"
                        onChange={(e) => {
                          setClaim({ ...claim, openParty: e.value });
                        }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="d-md-flex justify-content-between">
                <Col md="12">
                  <ProcedureInfoSection
                    eraList={eraList}
                    setEraList={setEraList}
                    setSelectedERA={setSelectedERA}
                    handleOpenEraFile={handleOpenEraFile}
                    claim={claim}
                    isNotEditAble={!!calculateAmount.totalManualWriteOff}
                    setClaim={setClaim}
                    handleShowLogs={handleShowLogs}
                  />
                </Col>
              </div>
            </div>
          </div>
          {loading && <Loader />}
        </Modal.Body>
        {!!calculateAmount.totalManualWriteOff && (
          <div className="mx-5" style={{ color: "red" }}>
            <h6>{`Note :  Claim Has been Settled of Manual Write Off Amount ${formatCurrency(
              calculateAmount.totalManualWriteOff
            )}.`}</h6>
          </div>
        )}
        <Modal.Footer>
          <Button
            variant="primary"
            className="modalButtons headerButton btn-fill mb-2"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            variant="secondary"
            className="modalButtons headerButton btn-fill mb-2"
            disabled={parseInt(calculateAmount.open) === 0}
            onClick={() =>
              setOpenConfirmation({
                isShow: true,
                message: `Are you sure, you want to write off payment?`,
                actionType: "writeOff",
                title: "Write Off",
                note: { label: "Add Write off Note:" },
              })
            }
          >
            Write Off
          </Button>
          <Button
            variant="secondary"
            className="modalButtons headerButton btn-fill mb-2"
            disabled={selectedERA.length === 0}
            onClick={handelShowAppealModal}
          >
            Appeal
          </Button>
          <Dropdown>
            <Dropdown.Toggle
              variant="dark"
              size="md"
              disabled={parseInt(calculateAmount.open) === 0 || isAlreadySend()}
              id="dropdown-basic"
              style={{ backgroundColor: "#231F20", marginBottom: "6px", borderRadius: "0", height: "40px" }}
            >
              Generate Invoice
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleInvoice("Bill Client")}>Bill Client</Dropdown.Item>
              <Dropdown.Item onClick={() => handleInvoice("Bill Patient")}>Bill Patient</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <InputField
            type="dropDown"
            groupWrapper="form-group-wrapper me-2"
            inputStyle="w-100"
            labelStyle="mt-0 mx-3 modalLineHeaders text-capitalize"
            label="Additional Insurance:"
            options={insuranceOptions}
            value={insuranceOptions.find((item) => item.insuranceCompany == claim.payerid)}
            index="billTo"
            placeholder="Select Option"
            disabled={insuranceOptions.length === 0 || parseInt(calculateAmount.open) === 0}
            handleChange={(e) => {
              setOpenConfirmation({
                isShow: true,
                message: `Are you sure, you want to submit the claim  to secondary insurance?`,
                actionType: "changeInsurance",
                title: "Submit Claim",
                data: e,
              });
            }}
          />
        </Modal.Footer>
        {openPdf && <PdfViewer url={openPdf} />}
        {openConfirmation.isShow && (
          <ConfirmationModal
            show={openConfirmation.isShow}
            actionType={openConfirmation.actionType}
            title={openConfirmation.title}
            message={openConfirmation.message}
            note={openConfirmation.note}
            handleConfirm={handleConfirm}
            cssClass="seperateModal"
          />
        )}
        {openOrderDetail && (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <OrderLogsModal
              order={claim}
              searchByPayerId={true}
              show={openOrderDetail}
              user={loginUser}
              cssClass={"seperateModal"}
              handleClose={() => {
                setOpenOrderDetail(false);
              }}
              claim
            />
          </ErrorBoundary>
        )}
      </Modal>
    </>
  );
};

export default EraModal;
