import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../api";
import {
  COMPANY_SETTING,
  COMPANY_SETTING_FETCH,
  COMPANY_SETTING_UPDATE,
  COMPANY_SETTING_UPDATE_CLI_LIST,
  COMPANY_SETTING_UPDATE_ALERT,
  COMPANY_SETTING_RENDERING_PROVIDERS,
} from "./companySettingConstants";

export const fetchCompanySetting = createAsyncThunk(COMPANY_SETTING_FETCH, async () => {
  const response = await api.getCompanySetting(); // Your API call logic here
  return response;
});

export const updateCompanySetting = createAsyncThunk(COMPANY_SETTING_UPDATE, async (defaultSetting) => {
  const response = await api.updateCompnayDefaultSetting(defaultSetting); // Your API call logic here
  return response;
});
export const updateCompanySettingCLIList = createAsyncThunk(COMPANY_SETTING_UPDATE_CLI_LIST, async (defaultSetting) => {
  const response = await api.updateCliItem(defaultSetting);
  return response;
});

export const updateCompanyAlertSetting = createAsyncThunk(COMPANY_SETTING_UPDATE_ALERT, async (defaultSetting) => {
  const response = await api.updateCompanyAlertSetting(defaultSetting);
  return response;
});

export const createRenderingProviders = createAsyncThunk(
  COMPANY_SETTING_RENDERING_PROVIDERS,
  async (renderProviderSettings) => {
    const response = await api.addRenderingProvider(
      renderProviderSettings.params,
      renderProviderSettings.operationType
    );
    return response;
  }
);

const companyUserSlice = createSlice({
  name: COMPANY_SETTING,
  initialState: { setting: null },
  reducers: {
    setCliList: (state, action) => {
      state.setting["cliaNumber"] = action.payload;
    },
    setRenderProviderList: (state, action) => {
      state.setting["renderingProvider"] = action.payload;
    },
    setAlertSetting: (state, action) => {
      state.setting["alertSetting"] = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanySetting.fulfilled, (state, action) => {
        state.setting = action.payload;
      })
      .addCase(updateCompanySetting.fulfilled, (state, action) => {
        state.setting = action.payload;
      })
      .addCase(updateCompanySettingCLIList.fulfilled, (state, action) => {
        state.setting = action.payload;
      })
      .addCase(createRenderingProviders.fulfilled, (state, action) => {
        state.setting = action.payload;
      })
      .addCase(updateCompanyAlertSetting.fulfilled, (state, action) => {
        state.setting = action.payload;
      });
  },
});

export const { setCliList, setAlertSetting, setRenderProviderList } = companyUserSlice.actions;

export default companyUserSlice.reducer;
