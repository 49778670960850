import React, { useState, useEffect, useMemo } from "react";
import logoImage from "../../assets/img/ltc_logo.png";
import { useHistory, Redirect } from "react-router-dom";
import { phoneFormatter, setPhoneNo, medFlowInLocalStorage, personalizationLocalStorage } from "utils";
import { Auth } from "aws-amplify";
import API from "api";
import { INSURANCE_PROVIDER } from "constant";
import { userCompanyID } from "utils";
import SweetAlert from "react-bootstrap-sweetalert";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import { NEW_PERSONALIZE } from "constant";
import Select from "react-select";
import { MEDICATRE_IDS } from "constant";
import { PERSONALIZATION } from "constant";
import { loggedInUser } from "utils";
import { convertToUpper } from "utils";

const UserLogin = () => {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [insuranceCompany, setInsuranceCompany] = useState("");
  const [insuranceGroupId, setInsuranceGroupId] = useState("");

  let history = useHistory();
  let url = window.location.href;

  useEffect(() => {
    const getUSer = async () => {
      const user = await API.getCurrentUser();
      if (user) {
        redirectToAdmin();
      }
    };
    getUSer();
  }, []);

  const insuranceObject = useMemo(() => {
    if (insuranceCompany) {
      const item = INSURANCE_PROVIDER.find((i) => i.value === insuranceCompany);
      if (item) return item;
    }
    return "";
  }, [insuranceCompany]);

  const handleSubmit = async () => {
    if (!insuranceCompany) {
      setPhoneError("Please Select Insurance Provide");
      return;
    }
    if (!insuranceGroupId && insuranceCompany && MEDICATRE_IDS.indexOf(insuranceCompany) === -1) {
      setPhoneError("Please enter Group ID");
      return;
    }
    if (!phone) {
      setPhoneError("please enter the ID");
      return;
    }
    if (!password) {
      setPasswordError("please enter the password");
      return;
    }

    try {
      setDisableBtn(true);
      let medicalNo = phone.replace(/ /g, "");
      console.log("items", medicalNo);
      const item = await API.getPatientData(medicalNo, insuranceGroupId, insuranceCompany);
      if (item.employee && item.employee.loginID) {
        medicalNo = item.employee.loginID;
      }
      console.log("item", item);
      if (
        (item.employee && item.employee?.insuranceCompany !== insuranceCompany) ||
        (item.employee?.insuranceGroupId && item.employee?.insuranceGroupId !== insuranceGroupId)
      ) {
        setPhoneError("Incorrect Insurance Provider");
        setDisableBtn(false);
        return;
      }
      const user = await Auth.signIn(medicalNo, password);
      const labID = user.attributes["custom:labID"] || null;
      if (labID) {
        userCompanyID.save(user.attributes["custom:labID"]);
      }
      medFlowInLocalStorage.save();
      loggedInUser.save({
        name: `${item.employee.firstName} ${item.employee.lastName}`.trim(),
      });
      personalizationLocalStorage.save(JSON.stringify(PERSONALIZATION));
      redirectToAdmin();
    } catch (error) {
      setDisableBtn(false);
      console.log("error", error);
      setPhoneError(error.message);
    }
  };

  const handleKeyDownEvent = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const redirectToAdmin = () => {
    if (history) history.push("/admin/orders");
    else window.location.reload();
  };

  const isLoggedIn = medFlowInLocalStorage.get();
  if (isLoggedIn) redirectToAdmin();
  return (
    <div className="loginWrapper">
      <div className="login-left-side">
        <div className="text-center">{<img src={logoImage} alt="logo" className="logo-width" />}</div>
      </div>
      <div className="login-right-side">
        <h3 className="mt-0">Login</h3>
        <p className="tag-line">Sign in to your account</p>
        <div className="form-group-wrapper">
          <Select
            options={INSURANCE_PROVIDER}
            blurInputOnSelect={true}
            menuPlacement="auto"
            placeholder="Select Insuraance Provider"
            className="w-100"
            value={insuranceObject}
            onChange={(e) => setInsuranceCompany(e.value)}
          />
        </div>
        {insuranceCompany && MEDICATRE_IDS.indexOf(insuranceCompany) === -1 && (
          <>
            <div className="form-group">
              <input
                value={insuranceGroupId}
                className="form-control"
                maxLength={16}
                placeholder="Enter Group ID"
                style={{
                  width: "100%",
                }}
                onChange={(e) => setInsuranceGroupId(convertToUpper(e.target.value))}
              />
            </div>
            <div className="form-group mt-3">
              <input
                value={phone}
                className="form-control"
                maxLength={16}
                placeholder="Enter Insurance ID"
                style={{
                  width: "100%",
                }}
                onChange={(e) => setPhone(convertToUpper(e.target.value))}
              />
            </div>
          </>
        )}

        {insuranceCompany && MEDICATRE_IDS.indexOf(insuranceCompany) !== -1 && (
          <div className="form-group">
            <input
              maxLength={16}
              type="text"
              className="form-control"
              value={phone}
              placeholder="Medicare/Medicaid Number"
              onChange={(e) => setPhone(convertToUpper(e.target.value))}
            />
          </div>
        )}
        <div className="input-group group-wrapper mt-3">
          <span className="input-group-text">
            <i className="fa fa-lock" />
          </span>
          <input
            className="form-control"
            placeholder="Enter your password"
            minLength={8}
            value={password}
            type="password"
            onKeyDown={handleKeyDownEvent}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="text-right w-100">
          <button
            type="submit"
            disabled={disableBtn}
            className="text-uppercase w-100 btn signin-btn"
            onClick={handleSubmit}
          >
            Sign In
          </button>
        </div>
        {phoneError && (
          <SweetAlert show={phoneError ? true : false} error title="Error" onConfirm={() => setPhoneError("")}>
            {phoneError}
          </SweetAlert>
        )}
      </div>
    </div>
  );
};

export default UserLogin;
