import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_SELECTED_INVOICES,
  RESET_SELECTED_INVOICES,
  UPDATE_SELECTED_INVOICE_DETAILS,
} from "./invoicePreviewConstant";
import api from "api";
import { updateClaimMessage } from "../bulkInvoices/bulkInvoicesAction";

export const addSelectedInvoice = createAsyncThunk(
  ADD_SELECTED_INVOICES,
  async ({ isBulk, selectedInvoice }, thunkAPI) => {
    let payload = {};
    if ((!selectedInvoice.details || selectedInvoice.details.length === 0) && !selectedInvoice.id) {
      const isClient = selectedInvoice.payer_name === "Bill Client";
      payload = {
        id: isClient ? selectedInvoice.companyID : selectedInvoice.employeeID,
        type: isClient ? "client" : "employee",
      };
    } else if (selectedInvoice.id) {
      payload = {
        id: selectedInvoice.id,
        type: "invoice",
      };
    }

    if (Object.keys(payload).length !== 0) {
      const response = await api.getInvoiceDetails(payload);
      if ((!selectedInvoice.details || selectedInvoice.details.length === 0) && !selectedInvoice.id) {
        selectedInvoice = { ...selectedInvoice, details: response.records };
        thunkAPI.dispatch(updateClaimMessage({ id: selectedInvoice.claimID, details: response.records }));
      } else {
        selectedInvoice = response;
      }
    }
    return { isBulk, selectedInvoice };
  }
);

export const resetInvoice = createAsyncThunk(RESET_SELECTED_INVOICES, async () => {
  return;
});

export const updateInvoiceDetails = createAsyncThunk(
  UPDATE_SELECTED_INVOICE_DETAILS,
  async ({ isBulk, selectedInvoiceClaimId, details, discount, discountType, totalBilled }, thunkAPI) => {
    if (isBulk) {
      thunkAPI.dispatch(
        updateClaimMessage({ id: selectedInvoiceClaimId, details, discount, discountType, totalBilled, localDiscount })
      );
    }
    return { selectedInvoiceClaimId, details, isBulk, discount, discountType, totalBilled };
  }
);
