import api from "api";
import { Storage } from "aws-amplify";
import EmployeeModal from "components/Employee/EmployeeModal";
import ExportToExcel from "components/ExportToExcel";
import Icon from "components/Icon";
import Loader from "components/Loader/Loader";
import EligibilityCheckListViewModal from "components/Modal/CheckEligibility/EligibilityCheckListViewModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import EmployeeImportModal from "components/Modal/EmployeeImportModal";
import EmployeeOrderHistory from "components/Modal/EmployeeOrderHistory";
import ErrorMessageModal from "components/Modal/ErrorMessageModal";
import ImportErrorModal from "components/Modal/ImportErrorModal";
import MergeProfileModal from "components/Modal/MergeProfileModal";
import NotesModal from "components/Modal/NotesModal";
import OrderDetailModal from "components/Modal/Order/OrderDetailModal";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import TestQuantityModal from "components/Modal/TestQuantityModal";
import MFPagination from "components/Pagination/MFPagination";
import Status from "components/Status";
import MainTable from "components/Table/MainTable";
import UserPasswordModal from "components/Users/UserPasswordModal";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { ErrorBoundary } from "react-error-boundary";
import { jsonToCSV } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { authSelector, selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { sendEmailToDevAsync } from "store/features/authentication/authenticationSlice";
import {
  openEligibilityDetailModalAsync,
  setEligibilityDetailsData,
} from "store/features/checkEligibility/checkEligibilitySlice";
import { selectedCompanySetting } from "store/features/companySetting/companySettingSelectors";
import { setClaimReSubmissionModal } from "store/features/employeeClaims/employeeClaimsSlice";
import {
  createEmployee,
  deleteEmployee,
  fetchAllEmployeesApi,
  updateEmployee,
  exportDataToExcel,
} from "store/features/employees/employeesAction";
import {
  employeesFilterSelector,
  employeesSelector,
  newUserSelector,
  openCreatorSelector,
} from "store/features/employees/employeesSelectors";
import {
  setCurrentPage,
  setEmployeesFilter as setFilter,
  setFilteredEmployees,
  setNewUser,
  setOpenCreator,
  setPageNo,
  updateNotes,
  updateTestAvailableQty,
} from "store/features/employees/employeesSlice";
import { setMessage } from "store/features/general/generalAction";
import { selectedLocation } from "store/features/locations/locationsSelectors";
import { selectedProvider } from "store/features/providers/providersSelectors";
import { selectedSubAgent } from "store/features/subAgents/subAgentsSelectors";
import {
  CapsFirstLetter,
  RemoveSpaceAndChar,
  changeSort,
  currentDate,
  downloadDataAsCSV,
  draggablePersonalizationLocalStorage,
  emailMsgToCreatedMember,
  formatNumber,
  formatTimeZone,
  getPhoneNumber,
  getStateLength,
  getValidDep,
  getValidGender,
  isValidDobField,
  isValidEmail,
  isValidIDNumber,
  isValidName,
  isValidZipCode,
  parseInsDetails,
  setPhoneNo,
  sortList,
  sortingFilterInLC,
  validatePhone,
  CapitalizeFirstLetterOfWords,
} from "utils";
import { v4 as uuidv4 } from "uuid";
import "../../src/tableStyling.css";
import Filter from "../components/Filter";
import {
  ADMIN_USERS,
  CLAIM_SUBMIT_STATUS,
  CONFIG,
  CONFIRMATION_TYPE,
  CUSTOM_INS,
  EMPLOYEE,
  EMPLOYEE_HEADERS,
  EmptyUserObj,
  INSURANCE_PROVIDER,
  MESSAGE_MODES,
  MIN_TEST_QUANTITY,
  PAGE_LIMIT,
  PARSE_FILTER_KEY_NAME,
  PATIENTS_DROPDOWN_OPTIONS,
  TABLE_QUICK_TOOLS,
  countryListAllIsoData,
  usersNo,
} from "../constant";
import { LAB_MEMBER_PERSONALIZE, NEW_PERSONALIZE } from "../constants/personalization";
import { AppContext } from "../context/app-context";
import { fetchAllEmployeeClaims } from "../store/features/employeeClaims/employeeClaimsActions";
import { t } from "../stringConstants";
import ViewClaimList from "./EmployeeClaims/ViewClaimList";
import { debounce } from "debounce";
import { nameToTitleCase } from "utils";

const Employees = (props) => {
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [userToDelete, setUserToDelete] = useState("");
  const [timeFilter, setTimeFilter] = useState("All Users");
  const [showFilter, setShowFilter] = useState(false);
  const appContext = useContext(AppContext);
  const [count, setCount] = useState(0);
  const [fullscreen, setFullscreen] = useState(true);
  const [title, setTitle] = useState("");
  const [assignProgram, setAssignProgram] = useState([]);
  const [assignProgramSuccessMsg, setAssignProgramSuccessMsg] = useState("");
  const [orderModal, setOrderModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [triggerFilter, setTriggerFilter] = useState();
  // const [currentPage, setCurrentPage] = useState(1);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [programMessage, setProgramMessage] = useState("");
  const [openErrModal, setOpenErrModal] = useState(false);
  const [openProgram, setOpenProgram] = useState(false);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [successData, setsuccessData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [personalize, setPersonalize] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [employeesForOrder, setEmployeesForOrder] = useState(null);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [noteModal, setNoteModal] = useState(null);
  const [showMergeProfileModal, setShowMergeProfileModal] = useState(false);
  const [openTestQuantityModal, setOpenTestQuantityModal] = useState(false);
  const [claimList, setClaimList] = useState([]);
  const [updatePatient, setUpdatePatient] = useState(null);
  const [eligibilityCheckListView, setEligibilityCheckListView] = useState([]);
  const setting = useSelector(selectedCompanySetting);
  const { providers } = useSelector(selectedProvider);
  const { locations, userLocation } = useSelector(selectedLocation);
  const { subAgents, userSubAgent } = useSelector(selectedSubAgent);
  const loginUser = useSelector(selectLoginUser);
  const { permission } = useSelector(authSelector);

  const { filteredEmployees, error, employees, pageNo, currentPage, totalRecord } = useSelector(employeesSelector);

  const filter = useSelector(employeesFilterSelector);

  const dispatch = useDispatch();

  const newUser = useSelector(newUserSelector);
  const openCreator = useSelector(openCreatorSelector);

  const quickTools = [
    TABLE_QUICK_TOOLS.checkbox,
    !appContext.isReadOnly() && (permission[EMPLOYEE]?.editPatient || !CONFIG.isLabType) && TABLE_QUICK_TOOLS.edit,
    (permission[EMPLOYEE]?.editPatient || !CONFIG.isLabType) && TABLE_QUICK_TOOLS.notes,
  ];

  const searchTerms = [
    "First Name",
    "Last Name",
    "Email",
    "Phone",
    "Gender",
    "Primary Payer",
    "Lab Client",
    "Sub Agent",
    "LTCS ID",
    "Primary Payer Number",
    "Additional Payer",
    "Additional Payer Number",
    "Test Ordered",
    "Test Available",
    "Auto Ship",
    "Updated At",
    "Eligibility",
    "Note",
    "Status",
    "Eligibility Status",
  ];

  const altaSearchTerms = [
    "First Name",
    "Last Name",
    "Email",
    "Phone",
    "Gender",
    "Primary Payer",
    "LTCS ID",
    "Primary Payer Number",
    "Additional Payer",
    "Additional Payer Number",
    "Updated At",
    "Eligibility",
    "Note",
    "Status",
    "Blank",
  ];

  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      const filterTerm = location.state.term;
      let filterTermName = location.state.name;

      if (filterTerm === "createdAt")
        filterTermName = {
          startDate: moment(location.state.name.startDate),
          endDate: moment(location.state.name.endDate),
        };
      if (filterTerm && filterTermName) {
        dispatch(setFilter({ ...filter, [filterTerm]: filterTermName }));
      }
    }
  }, [location.state]);

  const handleUpdateInsurance = async () => {
    const arr = INSURANCE_PROVIDER.filter(
      (f) => f.label.toLowerCase().includes("medicare") && f.payerid !== "01192"
    ).map((m) => m.payerid);

    const newInsurance = INSURANCE_PROVIDER.filter((f) => !arr.includes(f.value));
    return;
    await api.addJSON(newInsurance);
  };

  useEffect(() => {
    const sortLS = sortingFilterInLC.get();
    if (sortLS.employees && sortLS.employees.sortBy) {
      setSortBy(sortLS.employees.sortBy);
      setSortDescending(sortLS.employees.sortDescending);
    }
    // if (sortLS.employees && sortLS.employees.filter) {
    //   setFilter(sortLS.employees.filter);
    // }
    setPersonalize(
      draggablePersonalizationLocalStorage.get(
        loginUser,
        "member",
        CONFIG.isLabType ? LAB_MEMBER_PERSONALIZE : NEW_PERSONALIZE
      )
    );
  }, [setting]);

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length && !Object.keys(filters[key]).length) {
          return true;
        }
        if (key === "updatedAt" || key === "createdAt") {
          return new Date(obj[key]) > filters[key].startDate._d && new Date(obj[key]) < filters[key].endDate._d;
        }
        if (key === "First Name") {
          return obj.firstName && obj.firstName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "Last Name") {
          return obj.lastName && obj.lastName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "scheduleID") {
          return obj.scheduleID && (obj.scheduleID === filters[key] || obj.scheduleLinked.indexOf(filters[key]) !== -1);
        }
        if (key === "department") {
          return obj.department && obj.department === filters[key];
        }
        if (key === "isSchedule") {
          return (obj.isSchedule ? obj.isSchedule : 0) == filters[key];
        }
        if (key === "isVaccinated") {
          return (obj.isVaccinated ? 1 : 0).toString() === filter[key];
        }
        if (key === "qaDone") {
          return obj.qaDone === filters[key];
        }
        if (key === "autoShipment") {
          return obj.autoShipment == filters[key];
        }
        if (key === "testDone") {
          return obj.testDone === filter[key];
        }
        if (key === "note") {
          if (filters[key] === "1" && obj.note) return true;
          if (filters[key] === "0") return true;
        }
        if (key === "eligibilityStatus") {
          const val = obj[key]?.message === "Eligibile" ? "eligibile" : obj[key] ? "invalid" : "not checked";
          return val && val.toString() === filters[key];
        }
        if (key === "testOrdered" || key === "testAvailable") {
          return obj[key].toString() === filters[key];
        }
        if (key === "empTZ") {
          return formatTimeZone(obj[key]).toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "checkIn") {
          return obj.checkIn === filter[key];
          // return new Date(obj.checkIn) > filters[key].startDate._d && new Date(obj.checkIn) < filters[key].endDate._d;
        }
        if (key === "payerId") {
          return filter[key].includes(obj.insuranceCompany);
        }
        if (key === "secondaryInsurance") {
          return filter[key].includes(obj.secondaryInsurance);
        }
        if ((key === "programName" || key === "zoneColor") && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }
        if (key === "status") {
          return filter[key] === obj.status;
        }
        if (key === "Blank" && filter[key].length > 0) {
          return filter[key].some((f) => !obj[PARSE_FILTER_KEY_NAME[f]]);
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
    if (e.target.checked && user.scheduleID) {
      setAssignProgram((a) =>
        a.length > 0 ? (a[0].scheduleID === user.scheduleID ? [...a, user] : [...a]) : [...a, user]
      );
    } else {
      setAssignProgram(assignProgram.filter((f) => f.id !== user.id));
    }
  };
  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? filteredEmployees.map((t) => t.id) : []);
    const values = val ? filteredEmployees.filter((f) => f.isSchedule === 2 && f) : [];
    let assignVal = [];
    if (values.length > 0) {
      const reduceVal = values.reduce((result, value) => {
        if (value.scheduleLinked && value.scheduleLinked.length > 0) {
          result[value.scheduleLinked] = (result[value.scheduleLinked] || 0) + 1;
        }
        return result;
      }, {});

      const maxVal = Object.keys(reduceVal).reduce((a, b) => (reduceVal[a] > reduceVal[b] ? a : b));
      const arrVal = maxVal.split(",");
      assignVal = values.filter((f) => JSON.stringify(f.scheduleLinked) === JSON.stringify(arrVal));
    }
    setAssignProgram(assignVal);
  };
  const sendEmailToMember = async (newUser) => {
    try {
      const message = emailMsgToCreatedMember(newUser.firstName, newUser.medicalNo, newUser.password);

      await api.sendEmail([{ email: newUser.email, subject: "SafeCamp LTC Team", msg: message }]);
    } catch (error) {
      console.log("ERROR createUser: ", error);
    }
  };

  const replaceInsurance = async () => {
    const members = filteredEmployees.filter((f) => f.insuranceCompany !== "01192" && f.medicareNo);

    const updateMembers = members.map((m) => {
      return {
        ...m,
        insuranceCompany: "01192",
        insuranceCompanyCode: "Medicare",
        medicalNo: m.medicareNo,
        insuranceDetails: {
          insuranceCompany: m.insuranceCompany,
          insuranceCompanyCode: m.insuranceCompanyCode,
          insuranceGroupId: m.insuranceGroupId,
          medicalNo: m.medicalNo,
        },
      };
    });

    if (updateMembers.length > 0) {
      await api.updateMember(updateMembers);
    }
  };

  const createMember = async (user) => {
    try {
      const phoneNo = setPhoneNo(user.phoneNumber);

      if (user.isNew) {
        dispatch(createEmployee(user));
      } else {
        let refProvider = null;
        let ordProvider = null;
        let provider = null;
        if (user.referringProvider) {
          refProvider = providers.find((f) => f.id === user.referringProvider);
        }
        if (user.orderingProvider) {
          ordProvider = providers.find((f) => f.id === user.orderingProvider);
        }
        if (user.renderingProvider) {
          provider = providers.find((f) => f.id === user.renderingProvider);
        }
        dispatch(updateEmployee(user));

        if (loginUser?.phone_number === usersNo.ibrahim || CONFIG.isLabType) {
          // const claims = await api.fetchPatientClaim(user.id);
          const claims = await api.getEmployeeClaimsAPI(user.id);
          setUpdatePatient({ user, provider, refProvider, ordProvider });
          setClaimList(
            claims.filter(
              (f) =>
                f.status === CLAIM_SUBMIT_STATUS.denied ||
                f.status === CLAIM_SUBMIT_STATUS.rejected ||
                f.status === CLAIM_SUBMIT_STATUS.draft
            )
          );
        }
        // if (result && user.password) {
        //   await api.createSystemUser(user, result);
        //   await sendEmailToMember(user);
        // }
      }
      // await appContext.resetEmployees();
      setLoading(false);
    } catch (error) {
      console.log("ERROR:User-", error);
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
      dispatch(setOpenCreator(true));
    }
  };

  const handleImportLabMember = async (empData, subAgentID) => {
    if (empData.length === 0) {
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      setOpenImportModal(false);
      return;
    }

    if (!subAgentID) {
      dispatch(setMessage("Sub Agent was not selected", MESSAGE_MODES.error));
      return;
    }

    const client = await api.getSubAgentById(subAgentID);
    const companyObj = await api.getCompanySetting();
    let allEmployess = companyObj.employeeCounter;
    const programName = [];
    const arr = [];
    const arrdata = [];
    const empList = [...employees];
    const newEmpIds = [];
    const ttlLength = empData.length;
    // const filterheader = EMPLOYEE_HEADERS.every((c) => Object.keys(empData[0].data).includes(c));
    // if (!filterheader) {
    //   dispatch(setMessage("data not matched", MESSAGE_MODES.error));
    //   setOpenImportModal(false);
    //   return;
    // }
    setOpenImportModal(false);
    setLoading(true);

    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      const emp = { ...data, countryCode: "" };
      if (!emp.firstName && !emp.dob) continue;

      emp.firstName = getValidDep(emp.firstName || "").trim();
      emp.lastName = getValidDep(emp.lastName || "").trim();
      emp.middleName = getValidDep(emp.middleName || "").trim();
      emp.phoneNumber = getPhoneNumber(emp.phoneNumber || "");
      emp.companyID = client.locationID;
      emp.clientID = client.clientID;
      const iso = countryListAllIsoData.find((f) => f.name === emp.country) || {
        code: "US",
      };
      if (emp.insuranceCompany === "#N/A") {
        emp.insuranceCompany = "";
      }
      emp.isoCode = iso.code;
      emp.subAgentID = subAgentID;
      emp.subAgent = { ...client, label: client.name, value: client.id };
      emp.testAvailable = client.testLimit;
      emp.testOrdered = parseInt(emp.Total || 0);
      emp.medicalNo = RemoveSpaceAndChar(emp.medicalNo)?.toUpperCase();
      emp.insuranceGroupId = emp.groupId?.trim().toUpperCase();
      emp.insuranceCompany = emp.insuranceCompany ? emp.insuranceCompany.padStart(5, 0) : "";
      emp.email = emp.email?.trim().toLowerCase().replace(/\s/g, "") || "";
      emp.city = emp.city?.trim() || "";
      emp.localNo = emp.accountNo?.trim() || "";
      emp.autoShipment = (emp["Automatic Monthly"] || "No") === "Yes";
      if (emp.address) {
        emp.street = emp.address;
      }

      if (emp.address2) {
        emp.street2 = emp.address2;
      }
      if (emp.gender) {
        emp.gender = getValidGender(emp.gender);
      }

      emp.dob = moment(emp.dob, "MM/DD/YYYY").format("YYYY-MM-DD");
      if (emp.insuranceCompany === "01192") {
        emp.medicareNo = emp.medicalNo;
      }

      if (emp.renderingProviderNpi) {
        emp.renderingProvider = providers.find((f) => f.npi === emp.renderingProviderNpi)?.id || "";
      }
      if (emp.referringProviderNpi) {
        emp.referringProvider = providers.find((f) => f.npi === emp.referringProviderNpi)?.id || "";
      }
      if (emp.orderingProviderNpi) {
        emp.orderingProvider = providers.find((f) => f.npi === emp.orderingProviderNpi)?.id || "";
      }

      if (emp.insuranceCompany) {
        let findVal = INSURANCE_PROVIDER.find((f) => f.value === emp.insuranceCompany.replace(/\s/g, ""));
        if (!findVal) {
          findVal = INSURANCE_PROVIDER.find(
            (f) => f.label.toLowerCase().replace(/\s/g, "") === emp.insuranceCompany.toLowerCase().replace(/\s/g, "")
          );
        }
        if (!findVal) {
          arr.push({
            message: `Inusrance is Invalid ${index + 2}.`,
            data: emp,
          });

          continue;
        }
        emp.insuranceCompanyCode = findVal.label;
        emp.insuranceCompany = findVal.payerid;
        emp.insuranceDetails = parseInsDetails(emp.insuranceDetails, emp);
      }

      if (!emp.firstName?.trim()) {
        arr.push({
          message: `First Name Required ${index + 2}.`,
          data: emp,
        });

        continue;
      }

      if (!isValidName(emp.firstName)) {
        arr.push({
          message: `First Name is invalid must contains at least 2 letters and Name should be realistic ${index + 2}.`,
          data: emp,
        });

        continue;
      }

      if (!emp.lastName?.trim()) {
        arr.push({
          message: `Last Name Required ${index + 2}.`,
          data: emp,
        });

        continue;
      }

      if (!isValidName(emp.lastName)) {
        arr.push({
          message: `Last Name is invalid must contains at least 2 letters and Name should be realistic ${index + 2}.`,
          data: emp,
        });

        continue;
      }

      if (!emp.dob) {
        arr.push({
          message: `Date of Birth is required on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      if (emp.state.length > getStateLength(emp)) {
        arr.push({
          message: `State must be 2 characters on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      if (!emp.insuranceCompany) {
        arr.push({
          message: `Inusrance is required ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      if (!emp.medicalNo) {
        arr.push({
          message: `medicalNo is required ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      if (emp.renderingProviderNpi && !emp.renderingProvider) {
        arr.push({
          message: `Rendering Provider NPI isn't in provider management list ${index + 2}.`,
          data: emp,
        });

        continue;
      }

      if (emp.referringProviderNpi && !emp.referringProvider) {
        arr.push({
          message: `Referring Provider NPI isn't in provider management list ${index + 2}.`,
          data: emp,
        });

        continue;
      }

      if (emp.orderingProviderNpi && !emp.orderingProvider) {
        arr.push({
          message: `Ordering Provider NPI isn't in provider management list ${index + 2}.`,
          data: emp,
        });

        continue;
      }

      const isRecordExists = await api.getUserEmployeeID(emp);
      if (isRecordExists) {
        arr.push({
          message: `${t("clientAlreadyExistOnRow")} ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      allEmployess = allEmployess + 1;
      console.log("Employee", emp);
      const resultID = await api.newEmployee(emp, allEmployess);
      if (resultID) newEmpIds.push(resultID.id);

      if (resultID.res) {
        await api.newPatientCreateAPI(resultID.res);
      }

      arrdata.push("Success");
    }

    if (arrdata.length == 0 && arr.length === 0) {
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      setLoading(false);
      return;
    }

    await api.updateCompnayEmployeeNo(allEmployess);
    dispatch(fetchAllEmployeesApi());
    setLoading(false);
    if (arr.length > 0 || arrdata.length > 0) {
      setErrorData(arr);
      setTitle(t("client"));
      setCount(allEmployess);
      setsuccessData(arrdata);
      setOpenErrModal(true);
    }
  };

  const handleClose = () => {
    dispatch(setOpenCreator(false));
    setOpenConfirmation({ isShow: false });
    fetchFromApi(pageNo, filter, true);
  };

  const ErrorFallback = ({ error, resetErrorBoundary }) => {
    // Call resetErrorBoundary() to reset the error boundary and retry the rend

    return (
      <ErrorMessageModal
        error={error.message}
        handleClose={() => {
          dispatch(setOpenCreator(false));
          setClaimList([]);
          setSelectedEmployee(null);
        }}
      />
    );
  };

  const handleOpenDetailModal = async (item, employee) => {
    if (item?.message !== "Eligibile") return;

    if (item.apiData) {
      const data = JSON.parse(item.apiData);
      if (data.fileName) {
        try {
          let res = await Storage.get(`${data.fileName}`, {
            bucket: CONFIG.eligibilityBucket,
            download: true,
          });

          res.Body.text().then((string) => {
            // handle the String data return String
            const apidata = JSON.parse(string);
            setEligibilityDetailsData({ ...item, ...apidata?.elig, payerId: employee.insuranceCompany, employee });
          });
        } catch (err) {
          console.log("Error:-", err.message);
          dispatch(setMessage(err.message, MESSAGE_MODES.error));
        }
      } else {
        setEligibilityDetailsData({ ...item, ...data?.elig, payerId: employee.insuranceCompany });
      }
    } else {
      setEligibilityDetailsData({ ...item, payerId: employee.insuranceCompany });
    }
  };

  const handleLogError = (error, info) => {
    console.log("Error", error);
    const errorDetails = {
      message: error.message,
      stack: error.stack,
      // any other details you want to send
    };
    dispatch(sendEmailToDevAsync(JSON.stringify(errorDetails)));
  };

  const getDuplicateData = async () => {
    const emps = filteredEmployees.filter(
      (f, i, arr) =>
        arr.findIndex((m) => m.phoneNumber === f.phoneNumber && f.dob === m.dob && f.medicalNo === m.medicalNo) !== i
    );
    const duplicateData = filteredEmployees.filter(
      (f, i) =>
        emps.findIndex((m) => m.phoneNumber === f.phoneNumber && f.dob === m.dob && f.medicalNo === m.medicalNo) !== -1
    );

    await downloadDataAsCSV(duplicateData, "patientDuplicate.csv");
  };

  const updateSubId = async () => {
    filteredEmployees.map(async (emp) => {
      let searchFilter = `preferred_username ^=\  "${emp.loginID}\"`;
      const employees = await api.fetchAllUsers(searchFilter);
      if (employees.Users.length > 0) {
        await api.updateSignUp(emp);
        console.log("employees", employees);
      }
    });
  };

  const handleChangePage = (page, direction) => {
    dispatch(setCurrentPage(page));
    const currentRecord = filteredEmployees.length;
    if (direction === "next" && page === pageNumbers && totalRecord > 0 && currentRecord < totalRecord) {
      dispatch(setPageNo(pageNo + 1));
    }
  };

  const currentUsers = (users) => {
    const indexOfLastUser = currentPage * PAGE_LIMIT;
    const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
    return users.slice(indexOfFirstUser, indexOfLastUser);
  };

  let usersToMap = currentUsers(filteredEmployees);

  const getPageNumbers = (employees) => {
    const pageNumbers = employees.length > PAGE_LIMIT ? Math.ceil(employees.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(employees);

  useEffect(() => {
    if (pageNo > 1) dispatch(fetchAllEmployeesApi(null, pageNo, filter, false));
  }, [pageNo]);

  const fetchFromApi = useCallback(
    debounce((pageNo, filter, isNewCall) => {
      dispatch(fetchAllEmployeesApi(null, pageNo, filter, isNewCall));
    }, 1000),
    []
  );

  useEffect(() => {
    // dispatch(setFilteredEmployees(sortList(sortBy, sortDescending, nestedFilter(employees, filter))));
    fetchFromApi(pageNo, filter, true);
  }, [filter]);

  if (filteredEmployees.length > 0) {
    pageNumbers = getPageNumbers(filteredEmployees);
  }

  const flipSort = (by) => {
    const sortOrder = sortBy === by ? !sortDescending : true;

    setSortDescending(sortOrder);
    setSortBy(by);
    filterOrderBy(by, sortOrder);
  };

  const handlePasswordClose = () => {
    setOpenPasswordModal(false);
  };

  const handleResetPassword = (obj) => {
    console.log("Password Obj", obj);
  };

  const handleEditUser = (user) => {
    const obj = { ...user, isNew: false, username: user.loginID };
    dispatch(setNewUser(obj));
    dispatch(setOpenCreator(true));
  };

  const onDeleteConfirm = async () => {
    let ttl = checkboxes.length;
    if (userToDelete || ttl > 0) {
      try {
        let deleted = [];
        if (userToDelete) {
          await dispatch(deleteEmployee(userToDelete.id));
          deleted.push(true);

          // if (userToDelete.loginID && !CONFIG.isLabType) await api.deleteUser(userToDelete.loginID);
        } else if (ttl > 0) {
          for (let i = 0; i < ttl; i++) {
            // const emp = employees.find((f) => f.id === checkboxes[i]);
            await dispatch(deleteEmployee(checkboxes[i]));
            deleted.push(true);

            // if (emp.loginID && !CONFIG.isLabType) await api.deleteUser(emp.loginID);
          }
        }
        if (deleted.length > 0) {
          dispatch(setMessage(`${t("clientDeletedSuccessfully")}${deleted > 1 ? "s" : ""}`, MESSAGE_MODES.success));
        } else {
          dispatch(setMessage(t("clientHaveClaims"), MESSAGE_MODES.error));
        }

        setUserToDelete(null);
        setCheckboxes([]);
        setAssignProgram([]);
        setAllSelected(false);
        setLoading(false);
        // appContext.resetEmployees();
      } catch (error) {
        console.log("Error:-", error.message);
        dispatch(setMessage(error.message, MESSAGE_MODES.error));
      }
    }
    setLoading(false);
  };

  const formatDOB = (dob) => {
    if (!dob) return "";
    const arr = dob.split("/");
    return `${arr[0].padStart(2, 0)}/${arr[1].padStart(2, 0)}/${arr[2]}`;
  };

  const parseDateOfBirth = (dob) => {
    if (!dob) return "";
    const arr = dob.split("/");
    return `${arr[2]}-${arr[0].padStart(2, 0)}-${arr[1].padStart(2, 0)}`;
  };

  const handleImport = async (empData, subAgentID) => {
    if (empData.length === 0) {
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      setOpenImportModal(false);
      return;
    }

    if (!subAgentID) {
      dispatch(setMessage("Sub Agent was not selected", MESSAGE_MODES.error));
      return;
    }

    const client = await api.getSubAgentById(subAgentID);
    const companyObj = await api.getCompanySetting();
    let allEmployess = companyObj.employeeCounter;
    const programName = [];
    const arr = [];
    const arrdata = [];
    const empList = [...employees];
    const newEmpIds = [];
    const ttlLength = empData.length;
    const filterheader = EMPLOYEE_HEADERS.every((c) => Object.keys(empData[0].data).includes(c));
    if (!filterheader) {
      dispatch(setMessage("Data not matched", MESSAGE_MODES.error));
      setOpenImportModal(false);
      return;
    }
    setOpenImportModal(false);
    setLoading(true);

    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      const emp = { ...data, countryCode: "" };
      if (!emp.firstName && !emp.lastName && !emp.phone && !emp.email) continue;

      emp.firstName = getValidDep(emp.firstName || "");
      emp.lastName = getValidDep(emp.lastName || "");
      emp.phoneNumber = getPhoneNumber(emp.phoneNumber || "");
      emp.companyID = client.locationID;
      emp.clientID = client.clientID;
      const iso = countryListAllIsoData.find((f) => f.name === emp.country) || {
        code: "US",
      };
      emp.isoCode = iso.code;
      emp.subAgentID = subAgentID;
      emp.subAgent = { ...client, label: client.name, value: client.id };
      emp.testAvailable = client.testLimit;
      emp.testOrdered = parseInt(emp.Total);
      emp.medicalNo = emp.primaryInsuranceNo?.trim().toUpperCase();
      emp.insuranceGroupId = emp.groupId?.trim().toUpperCase();
      emp.autoShipment = (emp["Automatic Monthly"] || "No") === "Yes";
      if (emp.address) {
        emp.street = emp.address;
      }
      if (emp.address2) {
        emp.street2 = emp.address2;
      }
      if (emp.gender) {
        emp.gender = getValidGender(emp.gender);
      }

      const dob = moment(emp.dob, "MM/DD/YYYY").format("MM/DD/YYYY");
      emp.dob = moment(emp.dob, "MM/DD/YYYY").format("YYYY-MM-DD");

      if (!emp.firstName?.trim()) {
        arr.push({
          message: `First Name Required ${index + 2}.`,
          data: emp,
        });

        continue;
      }
      if (!isValidName(emp.firstName)) {
        arr.push({
          message: `First Name is invalid must contains at least 2 letters and Name should be realistic ${index + 2}.`,
          data: emp,
        });

        continue;
      }

      if (!emp.lastName?.trim()) {
        arr.push({
          message: `Last Name Required ${index + 2}.`,
          data: emp,
        });

        continue;
      }

      if (!isValidName(emp.lastName)) {
        arr.push({
          message: `Last Name is invalid must contains at least 2 letters and Name should be realistic ${index + 2}.`,
          data: emp,
        });

        continue;
      }

      if (!emp.primaryInsuranceName) {
        arr.push({
          message: `primary insurance name is Required`,
          data: emp,
        });
        continue;
      }
      if (!emp.primaryInsuranceNo) {
        arr.push({
          message: `primary insurance number is Required`,
          data: emp,
        });
        continue;
      }

      if (!isValidIDNumber(emp.primaryInsuranceNo)) {
        arr.push({
          message: `primary insurance number is invalid`,
          data: emp,
        });
        continue;
      }

      if (emp.primaryInsuranceNo) {
        emp.medicalNo = emp.primaryInsuranceNo;
      }
      if (emp.primaryAdvantageMedicareNo) {
        emp.medicareNo = emp.primaryAdvantageMedicareNo;
      }
      if (emp?.primaryGroupId) {
        emp["insuranceGroupId"] = emp.primaryGroupId;
      }
      const findVal = INSURANCE_PROVIDER.find(
        (f) => f.label.toLowerCase().replace(/\s/g, "") === emp.primaryInsuranceName.toLowerCase().replace(/\s/g, "")
      );
      if (!findVal) {
        arr.push({
          message: `insurance provider is Invalid on row ${index + 2}.`,
          data: emp,
        });

        continue;
      } else {
        emp.insuranceCompany = findVal.payerid;
        emp.medicareAgent = findVal.label;
        emp.insuranceCompanyCode = findVal.label;
        if (findVal.payerid === "01192") {
          emp.medicalNo = emp.primaryInsuranceNo;
          emp.medicareNo = emp.primaryInsuranceNo;
        }
      }
      if (!emp.dob) {
        arr.push({
          message: `Date of Birth is required on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }
      if (
        emp.dob &&
        (!isValidDobField(dob, "MM/DD/YYYY") || !moment(dob, "MM/DD/YYYY").isBetween("01/01/1900", currentDate()))
      ) {
        arr.push({
          message: `Invalid (${dob}) Date of Birth format is (MM/DD/YYYY)  on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      if (!emp.gender) {
        arr.push({
          message: `Gender is required on row ${index + 2}`,
          data: emp,
        });
        continue;
      }

      if (!emp.primaryAdvantageMedicareNo) {
        arr.push({
          message: `Medicare ID is Required`,
          data: emp,
        });
        continue;
      }
      if (emp.secondaryInsuranceName && emp.secondaryInsuranceNo) {
        emp["insuranceDetails"] = {};
        if (emp.secondaryInsuranceName.toLowerCase() === primaryInsuranceName.toLowerCase()) {
          delete emp["insuranceDetails"];
        } else {
          if (emp.secondaryGroupId) {
            emp["insuranceDetails"]["insuranceGroupId"] = emp.secodaryInsuranceNo;
          }
          emp["insuranceDetails"]["medicalNo"] = emp.secondaryInsuranceNo;

          const findVal = INSURANCE_PROVIDER.find(
            (f) =>
              f.label.toLowerCase().replace(/\s/g, "") === emp.secondaryInsuranceName.toLowerCase().replace(/\s/g, "")
          );
          if (!findVal) {
            arr.push({
              message: `insurance provider is Invalid on row ${index + 2}.`,
              data: emp,
            });

            continue;
          } else {
            emp["insuranceDetails"]["insuranceCompany"] = findVal.payerid;
            emp["insuranceDetails"]["insuranceCompanyCode"] = findVal.label;
          }
        }
      }

      // if (!emp.medicareAgent) {
      //   arr.push({
      //     message: `insurance provider is Required on row ${index + 2}.`,
      //     data: emp,
      //   });
      //   continue;
      // }
      if (!emp.phoneNumber || !emp.phoneNumber.trim()) {
        arr.push({
          message: `phone number is required on row ${index + 2}`,
          data: emp,
        });
        continue;
      }
      if (emp.phoneNumber && !validatePhone(emp.phoneNumber)) {
        arr.push({
          message: `phone number is not correct ${emp.phone} on row ${index + 2}`,
          data: emp,
        });
        continue;
      }
      if (!emp.email || !emp.email.trim()) {
        arr.push({
          message: `email is required on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      if (emp.email && !isValidEmail(emp.email)) {
        arr.push({
          message: `Invalid email ${emp.email} on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }
      if (!emp.street || !emp.street.trim()) {
        arr.push({
          message: `Address is required on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }
      if (!emp.city || !emp.city.trim()) {
        arr.push({
          message: `City is required on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      if (!emp.state || !emp.state.trim()) {
        arr.push({
          message: `State is required on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      if (emp.state.length > getStateLength(emp)) {
        arr.push({
          message: `State must be 2 characters on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      if (!emp.zip || !emp.zip.trim()) {
        arr.push({
          message: `Zip Code is required on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      if (!isValidZipCode(emp.isoCode || "US", emp.zip)) {
        arr.push({
          message: `Zip Code is invalid on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }
      const isRecordExists = await api.getUserEmployeeID(emp);
      if (isRecordExists) {
        arr.push({
          message: `${t("clientAlreadyExistOnRow")} ${index + 2}.`,
          data: emp,
        });
        continue;
      }

      allEmployess = allEmployess + 1;

      const resultID = await api.newEmployee(emp, allEmployess);
      if (resultID) newEmpIds.push(resultID.id);

      arrdata.push("Success");
    }

    if (arrdata.length == 0 && arr.length === 0) {
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      setLoading(false);
      return;
    }

    await api.updateCompnayEmployeeNo(allEmployess);
    await dispatch(fetchAllEmployeesApi());
    setLoading(false);
    if (arr.length > 0 || arrdata.length > 0) {
      setErrorData(arr);
      setTitle(t("client"));
      setCount(allEmployess);
      setsuccessData(arrdata);
      setOpenErrModal(true);
    }
  };

  const handleImportEmployee = async (empData) => {
    if (empData.length === 0) return;

    const ttlLength = empData.length;
    const likeIds = [];
    const ids = [];
    let i = 0;
    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      if (data.name) {
        const arr = data.name.split(" ");

        const firstName = arr[0].toLowerCase().trim();
        const lastName = (arr[1] || "").trim().toLowerCase();

        const emp = employees.filter(
          (e) =>
            (firstName.indexOf(e.firstName.toLowerCase()) !== -1 &&
              lastName.indexOf(e.lastName.toLowerCase()) !== -1) ||
            (e.firstName.toLowerCase().indexOf(firstName) !== -1 && e.lastName.toLowerCase().indexOf(lastName) !== -1)
        );
        if (emp.length === 0) {
          ids.push({
            firstName: firstName,
            lastName: lastName,
            hrFirst: "",
            hrLast: "",
            zone: data.programName,
          });
        } else {
          likeIds.push({
            id: emp[0].id,
            firstName: firstName,
            lastName: lastName,
            hrFirst: emp[0].firstName,
            hrLast: emp[0].lastName,
            zone: data.programName,
          });
        }
      }
    }

    likeIds.forEach(async (e) => {
      await api.updateEmployeeDefault(e.id, e.zone);
    });

    const content = jsonToCSV(ids);
    const fileContent = new Blob([content], { type: "csv" });
    // await FileSaver.saveAs(fileContent, "houston_zone_22_07_22.csv");
  };
  const handleImportEmployeeOld = async (empData) => {
    if (empData.length === 0) return;
    const ttlLength = empData.length;
    const likeIds = [];
    const ids = [];
    let i = 0;
    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      if (data.firstName) {
        const firstName = data.firstName.toLowerCase();
        const lastName = data.lastName.toLowerCase();
        const firstLike = data.firstName.toLowerCase().substring(0, 2);
        const lastLike = data.lastName.toLowerCase().substring(0, 2);
        const emp = employees.filter(
          (e) =>
            (firstName.indexOf(e.firstName.toLowerCase()) !== -1 &&
              lastName.indexOf(e.lastName.toLowerCase()) !== -1) ||
            (e.firstName.toLowerCase().indexOf(firstName) !== -1 && e.lastName.toLowerCase().indexOf(lastName) !== -1)
        );
        if (emp.length === 0) {
          // ids.push({ firstName, lastName });
          const emp1 = employees.filter(
            (e) =>
              (firstLike === e.firstName.toLowerCase().substring(0, 2) &&
                lastName.indexOf(e.lastName.toLowerCase()) !== -1) ||
              (firstName.indexOf(e.firstName.toLowerCase()) !== -1 &&
                lastLike === e.lastName.toLowerCase().substring(0, 2))
          );
          if (emp1.length === 0) {
            likeIds.push({
              firstName: data.firstName,
              lastName: data.lastName,
              hrFirst: "",
              hrLast: "",
            });
          } else {
            likeIds.push({
              firstName: data.firstName,
              lastName: data.lastName,
              hrFirst: emp1[0].firstName,
              hrLast: emp1[0].lastName,
            });
          }
        } else {
          likeIds.push({
            firstName: data.firstName,
            lastName: data.lastName,
            hrFirst: emp[0].firstName,
            hrLast: emp[0].lastName,
          });
        }
      }
    }

    const content = jsonToCSV(likeIds);
    const fileContent = new Blob([content], { type: "csv" });
    //  await FileSaver.saveAs(fileContent, "houston_data_exists_20_07_22.csv");
  };

  const onHandleConfirmation = (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) {
      switch (type) {
        case CONFIRMATION_TYPE.DELETE:
          setCheckboxes([]);
          setAllSelected(false);
          setAssignProgram([]);
          setUserToDelete(null);
          break;
      }
      return;
    }
    switch (type) {
      case CONFIRMATION_TYPE.DELETE:
        onDeleteConfirm();
        break;
    }
  };

  const handleCreateClaim = async () => {
    if (checkboxes.length === 1) {
      let res = filteredEmployees.filter((f) => checkboxes.indexOf(f.id) !== -1);
      dispatch(setClaimReSubmissionModal(res));
      setCheckboxes([]);
    } else {
      dispatch(setMessage(t("selectOneClientError"), MESSAGE_MODES.error));
    }
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    const personalisationData = draggablePersonalizationLocalStorage.saveAs(data, "member");
    setOpenPersonalizationModal(false);
    draggablePersonalizationLocalStorage.save(JSON.stringify(personalisationData));
    await api.udpatePersonalization(loginUser.phone_number, personalisationData);
  };

  const updateTestQty = async (testQty) => {
    setOpenTestQuantityModal(false);
    try {
      setLoading(true);
      await api.updateTestAvailable(checkboxes, testQty);
      dispatch(setMessage(t("clientTestAvailableUpdate", { total: checkboxes.length }), MESSAGE_MODES.success));
      dispatch(updateTestAvailableQty({ Ids: checkboxes, testQty }));
      setLoading(false);
      setCheckboxes([]);

      setAllSelected(false);
    } catch (err) {
      console.log("Error:-", err.message);
      setLoading(false);
      dispatch(setMessage(err.message, MESSAGE_MODES.error));
    }
  };

  const insuranceProviderFormatter = (insuranceCompany) => {
    if (!insuranceCompany) return "-";
    const item = INSURANCE_PROVIDER.find((i) => i.value === insuranceCompany);
    if (!item) return "-";
    return item?.label;
  };

  const handleEligibilityCheck = async (emp) => {
    try {
      let empList = employees.filter((e) => checkboxes.includes(e.id));
      if (emp) empList = [emp];
      setLoading(true);
      const [resStatus, response] = await api.checkManualBulkEligibility(empList, loginUser);

      if (response && response.length === 1) {
        if (response[0]?.res && !CUSTOM_INS.includes(empList[0].insuranceCompany)) {
          dispatch(setEligibilityDetailsData({ ...response[0].res, employee: empList[0] }));
        } else {
          dispatch(setMessage("Failed Eligibility Check", MESSAGE_MODES.error));
        }
      } else if (response && response.length > 1) {
        setEligibilityCheckListView(response);
      }

      if (resStatus.length === 1) {
        if (resStatus.includes("Failed")) {
          dispatch(setMessage("Failed Eligibility Check", MESSAGE_MODES.error));
        } else {
          dispatch(setMessage("Passed Eligibility Check", MESSAGE_MODES.success));
        }
      } else if (resStatus.length > 0 && resStatus.filter((e) => e === "Failed").length === 0) {
        dispatch(setMessage("Passed Eligibility Check", MESSAGE_MODES.success));
      } else if (resStatus.length > 0) {
        if (resStatus.every((e) => e === "Failed")) {
          dispatch(setMessage("Failed Eligibility Check", MESSAGE_MODES.error));
        } else {
          dispatch(setMessage("Only Few Passed Eligibility Check", MESSAGE_MODES.error));
        }
      }
    } catch (err) {
      console.log("Error", err);
      dispatch(setMessage("Fail to Check the Elibility", MESSAGE_MODES.error));
    }
    setLoading(false);
    setCheckboxes([]);
  };

  const tdFormat = (item, row) => {
    if (item === "firstName") {
      return (
        <Link
          style={{ textDecoration: "none", color: "var(--text-black)", cursor: "pointer" }}
          to={{
            pathname: CONFIG.isLabType ? "/admin/employeeclaim" : "/admin/orders",
            state: {
              name: row.id,
              term: "employeeID",
            },
          }}
        >
          {nameToTitleCase(row.firstName)}
        </Link>
      );
    }
    if (item === "lastName") {
      return (
        <Link
          style={{ textDecoration: "none", color: "var(--text-black)", cursor: "pointer" }}
          to={{
            pathname: CONFIG.isLabType ? "/admin/employeeclaim" : "/admin/orders",
            state: {
              name: row.id,
              term: "employeeID",
            },
          }}
        >
          {nameToTitleCase(row.lastName)}
        </Link>
      );
    }
    if (item === "eligibilityStatus") {
      return (
        <div
          className="star-icon-wrapper cursor-pointer"
          onDoubleClick={() =>
            row[item]?.message === "Eligibile" &&
            dispatch(openEligibilityDetailModalAsync({ item: row[item], employee: row }))
          }
        >
          <Status
            type="circle"
            size="md"
            color={row[item]?.message === "Eligibile" || CUSTOM_INS.includes(row.insuranceCompany) ? "green" : "maroon"}
            crossIcon={row[item] || CUSTOM_INS.includes(row.insuranceCompany)}
            questionIcon={!row[item] && !CUSTOM_INS.includes(row.insuranceCompany)}
          />
        </div>
      );
    }
    if (item === "status") {
      return (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color={row[item] === "inactive" ? "maroon" : "green"} crossIcon={row[item]} />
        </div>
      );
    }
    return row[item];
  };

  const tdFormatTitle = (item, row) => {
    if (item !== "eligibilityStatus") {
      return row[item];
    }

    return CUSTOM_INS.includes(row.insuranceCompany)
      ? "Eligible"
      : row[item] === null
      ? "Not Eligible"
      : row[item]?.message;
  };

  const renderTd = (item, row) => {
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormatTitle(item.itemKey, row)}
      >
        {tdFormat(item.itemKey, row)}
      </td>
    );
  };

  const updateMemberStatus = async () => {
    const members = filteredEmployees.filter(
      (f) => f.eligibilityStatus && f.eligibilityStatus?.message !== "Eligibile"
    );
  };

  const openDeleteConfirmation = (user) => {
    setOpenConfirmation({
      isShow: true,
      actionType: CONFIRMATION_TYPE.DELETE,
      title: `${t("deleteClient")}`,
      message: `${t("requestClientRemoval", { total: user ? 1 : checkboxes.length })}${
        checkboxes.length > 1 ? "s" : ""
      }?`,
    });
    if (user) setUserToDelete(user);
  };

  const handleOrderClick = () => {
    const empList = employees
      .filter((e) => checkboxes.includes(e.id) && e.testAvailable > 0 && e.status === "active")
      .map((e) => {
        const minTestToOrder =
          e.subAgent?.minTestOrderQty || e.location?.minTestOrderQty || e.client?.minTestOrderQty || MIN_TEST_QUANTITY;

        return {
          ...e,
          testSendQty: e.testAvailable,
          minTestToOrder,
        };
      });
    if (empList.length === 0) {
      const text = "Please select the employee having a valid available tests limit or Member Status should be active";
      dispatch(setMessage(text, MESSAGE_MODES.error));
      return;
    }
    setEmployeesForOrder(empList);
    setOrderModal(true);
  };

  const filterOrderBy = (order, dir) => {
    const obj = {
      orderBy: order,
      orderByDirection: dir ? "desc" : "asc",
    };

    dispatch(setFilter({ ...filter, ...obj }));
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;

      case TABLE_QUICK_TOOLS.edit:
        handleEditUser(row);
        break;

      case TABLE_QUICK_TOOLS.notes:
        !appContext.isReadOnly() && setNoteModal(row);
        break;

      default:
        break;
    }
  };

  const handleDownloadExportCsv = async () => {
    if (usersToMap.length === 0) return;

    const selectedColumns = personalize.reduce((accumulator, item) => {
      if (item.isCheck && item.itemKey) {
        accumulator.push({ itemKey: item.itemKey, title: item.title, type: item.type });
      }
      return accumulator;
    }, []);

    try {
      await dispatch(exportDataToExcel(selectedColumns));
    } catch (error) {
      console.log("Erorr", error);
    }
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {`${t("clients")} (${formatNumber(Number(totalRecord))})`}
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />

                  <Icon
                    handleClick={async () => {
                      if (filteredEmployees && filteredEmployees.length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                      // await api.updateClientMdSettingInfo();
                    }}
                    label={!allSelected ? "Select All" : "Deselect All"}
                    title={!allSelected ? "Select All" : "Deselect All"}
                    iconType={"selectAllIcon"}
                  />
                  {!appContext.isReadOnly() && (
                    <>
                      {permission[EMPLOYEE].archivePatients && (
                        <Icon
                          handleClick={() => openDeleteConfirmation()}
                          label="Archive"
                          title="Archive"
                          disabled={checkboxes.length === 0}
                          iconType={"binIcon"}
                        />
                      )}
                      <Icon
                        handleClick={() => setOpenPersonalizationModal(true)}
                        title={t("personalize")}
                        label={t("personalize")}
                        iconType={"personalizeIcon"}
                      />
                      {(permission[EMPLOYEE]?.createPatient || !CONFIG.isLabType) && (
                        <>
                          {!appContext.isReadOnly() &&
                            (ADMIN_USERS.includes(loginUser?.phone_number) || CONFIG.isLabType) && (
                              <Icon
                                handleClick={handleEligibilityCheck}
                                title={"Check Eligibility"}
                                disabled={checkboxes.length === 0}
                                iconType="acknowledgedPositiveIcon"
                                label="Eligibility"
                              />
                            )}

                          <Icon
                            handleClick={() => {
                              if (subAgents?.length === 0) {
                                dispatch(setMessage("First Create Sub Agent", MESSAGE_MODES.error));
                                return;
                              }
                              const obj = { ...EmptyUserObj, id: uuidv4() };
                              if (CONFIG.isLabType && locations.length === 1) {
                                Object.assign(obj, {
                                  companyID: locations[0]?.id,
                                  clientID: locations[0]?.clientID,
                                  location: {
                                    ...locations[0],
                                    value: locations[0].id,
                                    label: locations[0].name,
                                  },
                                });
                              }
                              if (!loginUser.isAdmin() && !loginUser?.isClient() && !loginUser?.isSite()) {
                                Object.assign(obj, {
                                  subAgentID: subAgents[0]?.id,
                                  companyID: subAgents[0]?.locationID,
                                  subAgent: subAgents[0],
                                  clientID: subAgents[0]?.clientID,
                                });
                              }
                              dispatch(setNewUser(obj));
                              dispatch(setOpenCreator(true));
                            }}
                            label={t("createMember")}
                            title={t("createClient")}
                            iconType={"addUserIcon"}
                          />

                          {permission[EMPLOYEE]?.createPatient === true && (
                            <Icon
                              handleClick={() => {
                                setOpenImportModal(true);
                              }}
                              title={t("loadClientData")}
                              iconType="loadCrewIcon"
                              label={t("loadClient")}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                  <Icon
                    handleClick={handleDownloadExportCsv}
                    title={"Export Data"}
                    label={"Export Data"}
                    iconType={"XLSIcon"}
                  />

                  {!appContext.isReadOnly() &&
                    (ADMIN_USERS.includes(loginUser?.phone_number) || CONFIG.isLabType) &&
                    (permission[EMPLOYEE]?.createPatient || !CONFIG.isLabType) && (
                      <>
                        {!CONFIG.isLabType && (
                          <>
                            <Icon
                              handleClick={handleOrderClick}
                              title={t("orderTest")}
                              label={t("orderTest")}
                              iconType="assignZoneIcon"
                              disabled={
                                usersToMap.filter((user) => checkboxes.includes(user.id) && user.testAvailable > 0)
                                  .length <= 0
                              }
                            />

                            <Icon
                              handleClick={() => setOpenTestQuantityModal(true)}
                              title={"Update Test Available"}
                              disabled={checkboxes.length === 0}
                              iconType="assignZoneIcon"
                              label="UTA"
                            />
                          </>
                        )}

                        {permission?.claims.createDraftClaims === true && (
                          <Icon
                            title={t("createClaim")}
                            label={t("createClaim")}
                            iconType={"acknowledgedPositiveIcon"}
                            handleClick={() => {
                              handleCreateClaim();
                            }}
                          />
                        )}
                        {/* <Icon
                          handleClick={() => setShowMergeProfileModal(true)}
                          title={"Combine Profile"}
                          label="Combine"
                          iconType="assignZoneIcon"
                          disabled={checkboxes.length < 2}
                        /> */}
                      </>
                    )}
                </div>

                {showFilter && (
                  <Filter
                    grouped
                    filterTerms={CONFIG.isLabType ? altaSearchTerms : searchTerms}
                    setFilter={(obj) => dispatch(setFilter({ ...obj }))}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                    member
                  />
                )}

                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={pageNumbers}
                  showSelectedRecord
                  totalSelected={checkboxes.length}
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <MainTable
                  cssClass={filteredEmployees.length === 0 && "overFlow-y-hidden"}
                  columns={personalize}
                  rows={usersToMap}
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  draggable
                  resizable
                  personalisationKey={"member"}
                  handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                  widthToSkip={130}
                  selectedRows={checkboxes}
                  tools={quickTools}
                  dropDownOptions={PATIENTS_DROPDOWN_OPTIONS}
                  handleDropDownClick={(type, row) => {
                    if (type === "logs") {
                      setSelectedEmployee(row);
                    }
                    if (type === "delete") {
                      openDeleteConfirmation(row);
                    }
                  }}
                  handleCellClick={handleCellClick}
                  customColumnCellRenderer={renderTd}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
      {showAlert && (
        <SweetAlert
          btnSize="md"
          info
          onConfirm={() => {
            setShowAlert(false);
            setAssignProgramSuccessMsg("");
          }}
        >
          {t("scheduleNotAssigned")}
        </SweetAlert>
      )}
      {openCreator && (
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleLogError}>
          <EmployeeModal
            user={{
              ...newUser,
              ...(userSubAgent && {
                subAgentID: userSubAgent.id,
                companyID: userSubAgent.locationID,
                clientID: userSubAgent.clientID,
              }),
            }}
            handleClose={handleClose}
            handleSave={createMember}
            resetCall={setOpenPasswordModal}
            handleEligibilityCheck={handleEligibilityCheck}
          />
        </ErrorBoundary>
      )}
      {noteModal && (
        <NotesModal
          handleClose={(obj) => {
            if (obj) {
              dispatch(updateNotes(obj));
              obj.note
                ? dispatch(setMessage("add Notes Successfully", MESSAGE_MODES.success))
                : dispatch(setMessage("Remove Notes Successfully", MESSAGE_MODES.success));
            }
            setNoteModal(null);
          }}
          emp
          user={loginUser}
          data={noteModal}
        />
      )}
      {openPasswordModal && (
        <UserPasswordModal user={newUser} handleClose={handlePasswordClose} handleSave={handleResetPassword} />
      )}

      {openConfirmation.isShow && (
        <ConfirmationModal
          show={openConfirmation.isShow}
          actionType={openConfirmation.actionType}
          title={openConfirmation.title}
          message={openConfirmation.message}
          handleConfirm={onHandleConfirmation}
          closeBtn
        />
      )}

      {openImportModal && (
        <EmployeeImportModal
          handleClose={() => setOpenImportModal(false)}
          handleImport={CONFIG.isLabType ? handleImportLabMember : handleImport}
          title={t("importClientDataTitle")}
        />
      )}

      {openErrModal && (
        <ImportErrorModal
          member
          title={title}
          errData={errorData}
          count={count}
          successData={successData}
          handleClose={async () => {
            setOpenErrModal(false);
            setErrorData([]);
            setsuccessData([]);
            setTitle("");
            await dispatch(fetchAllEmployeesApi());
          }}
        />
      )}

      {openPersonalizationModal && (
        <PersonalizationModal
          data={JSON.stringify(personalize)}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}
      {selectedEmployee && (
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleLogError}>
          <EmployeeOrderHistory user={selectedEmployee} handleClose={() => setSelectedEmployee(null)} />
        </ErrorBoundary>
      )}
      {openTestQuantityModal && (
        <TestQuantityModal handleConfirm={updateTestQty} handleClose={() => setOpenTestQuantityModal(false)} />
      )}
      {orderModal && (
        <OrderDetailModal
          handleClose={(isProcess, limitExccedEmployees) => {
            setOrderModal(false);
            setCheckboxes([]);
            setAllSelected(false);
            if (isProcess) {
              if (limitExccedEmployees && limitExccedEmployees.length > 0) {
                const text = `${limitExccedEmployees.length} order request not processed because of limit excceed`;
                dispatch(setMessage(text, MESSAGE_MODES.success));
              } else {
                dispatch(setMessage("Order created Successfully", MESSAGE_MODES.success));
              }
              dispatch(fetchAllEmployeesApi());
            }
          }}
          employees={employeesForOrder}
          loginUser={loginUser}
        />
      )}
      {showMergeProfileModal && (
        <MergeProfileModal
          handleClose={(val) => {
            setShowMergeProfileModal(false);
            if (val) {
              dispatch(setMessage("Successfully Combine Profiles", MESSAGE_MODES.success));
              setCheckboxes([]);
              dispatch(fetchAllEmployeesApi());
            }
          }}
          employees={filteredEmployees.filter((f) => checkboxes.includes(f.id))}
          userName={loginUser.name}
        />
      )}

      {eligibilityCheckListView.length > 0 && (
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleLogError}>
          <EligibilityCheckListViewModal
            handleClose={() => setEligibilityCheckListView([])}
            lists={eligibilityCheckListView}
            setEligibilityDetailsData={(item) => dispatch(setEligibilityDetailsData(item))}
          />
        </ErrorBoundary>
      )}

      {claimList && claimList.length > 0 && (
        <ViewClaimList
          empClaimsList={claimList}
          patient={updatePatient}
          handleClose={(val) => {
            if (val) {
              dispatch(fetchAllEmployeeClaims(null, 1, {}, true));
              dispatch(setMessage("Updated Claims Successfully", MESSAGE_MODES.success));
            }
            setClaimList([]);
          }}
          cssClass={"seperateModal"}
        />
      )}
    </div>
  );
};

export default Employees;
