import React from "react";
import { Table, Card } from "react-bootstrap";
import { toTitleCase } from "utils";

const SettingLogsCollapseTable = (props) => {
  const { data } = props;

  const TableCollapse = ({ data }) => {
    const { name, oldResult, newResult } = data;
    return (
      <tr className="expended-row">
        <td style={{ textAlign: "center" }}>{name}</td>
        <td style={{ textAlign: "center" }}>{toTitleCase(oldResult)}</td>
        <td style={{ textAlign: "center" }}>{toTitleCase(newResult)}</td>
      </tr>
    );
  };

  return (
    <>
      {data && data.length > 0 && (
        <tr>
          <td colSpan={"12"} className="expended-table-child">
            <Card className="striped-table-card">
              <Card.Body>
                <div className="form-group-wrapper d-block">
                  <div className="table-responsive overFlow-y-hidden">
                    <Table className="table expandedTableChild">
                      <thead>
                        <tr>
                          <th className="fw-bold" style={{ textAlign: "center" }}>
                            Field
                          </th>
                          <th style={{ textAlign: "center" }}>Before</th>
                          <th style={{ textAlign: "center" }}>After</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((m, i) => {
                          return <TableCollapse key={i} data={m} />;
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </td>
        </tr>
      )}
    </>
  );
};
export default SettingLogsCollapseTable;
