import { TABLE_COLUMN_TYPES, claimSubmitStatusColorOpts } from "constant";

export const INSURANCE_PERSONALIZE = [
  {
    itemKey: "Name",
    title: "Name",
    textAlign: "",
    isCheck: true,
    textOverflow: "none",
    id: 1,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
  {
    itemKey: "shortName",
    title: "Alias",
    textAlign: "left",
    isCheck: true,
    textOverflow: "none",
    id: 2,
    type: TABLE_COLUMN_TYPES.text,
    customCellRenderer: true,
  },
  {
    itemKey: "street",
    title: "Address",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 3,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "city",
    title: "City",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 4,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "state",
    title: "State",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 5,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "zip",
    title: "Zip",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 6,
    type: TABLE_COLUMN_TYPES.text,
  },
  {
    itemKey: "phone",
    title: "Phone Number",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 7,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: "fax",
    title: "Fax",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 8,
    type: TABLE_COLUMN_TYPES.phone,
  },
  {
    itemKey: "claims",
    title: "Total Claims",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 9,
    customCellRenderer: true,
  },
  {
    itemKey: "claimAmount",
    title: "Total Claims Amount",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 10,
    customCellRenderer: true,
  },
  {
    itemKey: "openAmount",
    title: "Total Claims Open",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 11,
    customCellRenderer: true,
  },
  {
    itemKey: "paid",
    title: "Total Claims Paid",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 12,
    customCellRenderer: true,
  },
  {
    itemKey: "totalAppeal",
    title: "Total Claims Appeal",
    textAlign: "center",
    isCheck: true,
    textOverflow: "none",
    id: 13,
    customCellRenderer: true,
  },
  {
    itemKey: "avgTime",
    title: "Average Time Open",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 14,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: "lastClaim",
    title: "Last Claim",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 15,
    type: TABLE_COLUMN_TYPES.shortDate,
  },

  {
    itemKey: "days30",
    title: "Days (30)",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 16,
    type: TABLE_COLUMN_TYPES.number,
  },

  {
    itemKey: "days60",
    title: "Days (60)",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 17,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: "days90",
    title: "Days (90)",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 18,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: "older",
    title: "Older",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 19,
    type: TABLE_COLUMN_TYPES.number,
  },
  {
    itemKey: "isActive",
    title: "Active",
    textAlign: "center",
    isCheck: true,
    textOverflow: "visible",
    id: 20,
    colorObj: claimSubmitStatusColorOpts,
    customCellRenderer: true,
  },
];
