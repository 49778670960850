import React from "react";
import Styles from "components/CheckBox/CheckBox.module.css";

const CheckBox = (props) => {
  const { id, label, value, name, checked, handleChange, disabled, customStyle, customWidth, labelStyle } = props;
  return (
    <div className={`${customWidth ? customWidth : ""} ${customStyle ? Styles.customStyle : Styles.customCheckBox}`}>
      <input
        type="checkbox"
        name={name}
        id={id}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={(e) => handleChange(e)}
      />
      {label && (
        <label htmlFor={id} className={`${customStyle ? "mb-0" : ""} ${labelStyle}`}>
          {label}
        </label>
      )}
    </div>
  );
};
export default CheckBox;
