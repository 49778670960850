import Icon from "components/Icon";
import { useMemo } from "react";
import Status from "components/Status";
import { useCallback, useEffect, useRef, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../../../src/tableStyling.css";
import TDStatus from "components/Table/TDStatus";
import {
  ICTCodeSelector,
  openCreateICTModalSelector,
  selectedICTItemSelector,
  totalIctRecordsSelector,
} from "../../store/features/cptCodes/cptCodeSelectors";
import { t } from "../../stringConstants";
import { PAGE_LIMIT, TABLE_QUICK_TOOLS } from "../../constant";
import Filter from "components/Filter";
import { DIAG_CODE_PERSONALIZE, DIAGNOSIS_CODE_PERSONALIZE } from "constants/personalization";

import MFPagination from "components/Pagination/MFPagination";
import { draggablePersonalizationLocalStorage, formatNumber, numberWithCommas } from "../../utils";
import {
  fetchICTCodeAsync,
  setOpenCreateICTModal,
  setDiagnosisList,
  setSelectedICTItem,
} from "../../store/features/cptCodes/cptCodeSlice";
import { debounce } from "debounce";
import AddDiagnosisCodeSettingsModal from "../../components/GeneralSettings/AddDiagnosisCodeSettingsModal";
import MainTable from "components/Table/MainTable";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import api from "api";

const DiagnosisCodeSettings = (props) => {
  const [sortBy, setSortBy] = useState("");
  const diagnosisList = useSelector(ICTCodeSelector);
  const [sortDescending, setSortDescending] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});

  const dispatch = useDispatch();
  const selectedICTItem = useSelector(selectedICTItemSelector);
  const totalIptRecords = useSelector(totalIctRecordsSelector);
  const openCreateICTModal = useSelector(openCreateICTModalSelector);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const searchTerms = ["Diagnosis Code", "Alias", "Diagnosis Code Desc", "Active"];
  const [pageNo, setPageNo] = useState(1);
  const [filterDiagnosisList, setFilterDiagnosisList] = useState([]);
  const user = useSelector(selectLoginUser);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);

  const [personalize, setPersonalize] = useState([]);

  const quickTools = [TABLE_QUICK_TOOLS.edit];

  useEffect(() => {
    const response = draggablePersonalizationLocalStorage.get(user, "icdCode", DIAG_CODE_PERSONALIZE);

    setPersonalize(response);
  }, []);

  const diagnosisCodesToMap = useMemo(() => {
    const list = filterDiagnosisList ? [...filterDiagnosisList] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (list.length < PAGE_LIMIT || currentPage > ttlPages) {
      setCurrentPage(1);
    }
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filterDiagnosisList, currentPage]);

  const handleChangePage = (page, direction) => {
    setCurrentPage(page);
    const currentRecord = filterDiagnosisList.length;
    if (direction === "next" && page === totalPages && totalIptRecords > 0 && currentRecord < totalIptRecords) {
      setPageNo(pageNo + 1);
    }
  };

  const fetchFromApi = useCallback(
    debounce(async (filter, sortBy, sortDescending, page) => {
      dispatch(
        fetchICTCodeAsync({
          ...(Object.keys(filter).length > 0 ? filter : {}),
          orderBy: sortBy,
          orderByDirection: sortDescending,
          page: page,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (pageNo > 1) {
      fetchFromApi(filter, sortBy, sortDescending, pageNo);
    }
  }, [pageNo]);

  useEffect(() => {
    fetchFromApi(filter, sortBy, sortDescending);
  }, [filter, sortBy, sortDescending]);

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  useEffect(() => {
    if (pageNo > 1) {
      const concatResult = filterDiagnosisList.concat(diagnosisList);
      setFilterDiagnosisList(concatResult);
    } else {
      setFilterDiagnosisList(diagnosisList);
    }
  }, [diagnosisList]);

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);

    const personalisationData = draggablePersonalizationLocalStorage.saveAs(data, "icdCode");
    setOpenPersonalizationModal(false);
    draggablePersonalizationLocalStorage.save(JSON.stringify(personalisationData));

    await api.udpatePersonalization(user.phone_number, personalisationData);
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        dispatch(setSelectedICTItem({ item: row }));
        dispatch(setOpenCreateICTModal(true));
        break;
      default:
        break;
    }
  };

  const tdFormat = (item, row) => {
    if (item === "isActive") return <TDStatus row={row} item={item} />;
    return row[item];
  };

  const renderTd = (item, row) => {
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
      >
        {tdFormat(item.itemKey, row)}
      </td>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col>
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{
                  marginBottom: 10,
                  fontWeight: "bold",
                }}
              >
                {`${t("diagnosisCodes")} (${numberWithCommas(totalIptRecords)})`}
              </Card.Title>
              <div className="buttonHeader">
                <Icon
                  handleClick={() => setShowFilter(!showFilter)}
                  title={"Filter"}
                  label={"Filter"}
                  iconType={"filter"}
                />
                <Icon
                  handleClick={() => setOpenPersonalizationModal(true)}
                  title={t("personalize")}
                  label={t("personalize")}
                  iconType={"personalizeIcon"}
                />
                <Icon
                  handleClick={() => dispatch(setOpenCreateICTModal(true))}
                  title={"Create Diagnosis"}
                  label={"Create"}
                  iconType={"createIcon"}
                />
              </div>
              {showFilter && (
                <Filter
                  filterTerms={searchTerms}
                  setFilter={setFilter}
                  filter={filter}
                  isUsersTable={true}
                  personalisationData={DIAGNOSIS_CODE_PERSONALIZE}
                />
              )}
            </Card.Header>

            <Card.Body className="table-full-width desktop-noScroll">
              <div className="table-responsive pendingReleaseTable">
                <Card.Header className="shows-screen-wrapper">
                  <Row className="pb-4">
                    <MFPagination
                      currentPage={currentPage}
                      handleChangePage={handleChangePage}
                      totalPages={totalPages}
                    />
                  </Row>
                </Card.Header>

                <MainTable
                  columns={personalize}
                  rows={diagnosisCodesToMap || []}
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  draggable
                  resizable
                  personalisationKey={"icdCode"}
                  handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                  widthToSkip={155}
                  tools={quickTools}
                  handleCellClick={handleCellClick}
                  customColumnCellRenderer={renderTd}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>

        {openPersonalizationModal && (
          <PersonalizationModal
            data={JSON.stringify(personalize)}
            handleChange={handlePersonalization}
            show={openPersonalizationModal}
            handleClose={() => setOpenPersonalizationModal(false)}
          />
        )}

        {openCreateICTModal && (
          <AddDiagnosisCodeSettingsModal
            handleClose={() => {
              dispatch(setOpenCreateICTModal(false));
              dispatch(setSelectedICTItem(null));
            }}
            selectedItem={selectedICTItem?.item || null}
          />
        )}
      </Row>
    </div>
  );
};

export default DiagnosisCodeSettings;
