import Loader from "components/Loader/Loader";
import ErrorMessage from "components/Message/ErrorMessage";
import React, { useContext } from "react";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const ClaimResolveReasonModal = (props) => {
  const { cssClass, handleClose, handleCancel } = props;
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (!reason || !reason?.trim()) {
      setError("Please add Reason!");
      return;
    }
    handleClose(reason);
  };

  return (
    <Modal
      show
      backdrop="static"
      animation={true}
      onHide={() => handleCancel()}
      className={`${cssClass}`}
      centered
      size={"md"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 text-capitalize" id="contained-modal-title-vcenter">
          Re-Submit Claim
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <>
          <h4 className="profile-title">Reason</h4>
          <div className="mb-3">
            <textarea
              className="form-control reason-textArea"
              value={reason}
              placeholder="(Optional)"
              onChange={(e) => setReason(e.target.value)}
            ></textarea>
          </div>
        </>
      </Modal.Body>
      <div className="px-4 my-2" style={{ height: "2vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>

      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        <Button
          style={{ marginBottom: 10 }}
          variant="secondary"
          className="modalButtons headerButton btn-fill"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClaimResolveReasonModal;
