import api from "api";
import InputField from "components/InputField/InputField";
import { MESSAGE_MODES, currencyformatter, CONFIG, INSURANCE_PROVIDER, BCC_VALUE } from "constant";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { Button, Card, Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { employeesListSelector } from "store/features/employees/employeesSelectors";
import ErrorMessage from "../../../components/Message/ErrorMessage";
import { setMessage } from "store/features/general/generalAction";
import { t } from "../../../stringConstants";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import {
  convertYYYYMMDDToDate,
  formatPhoneNumber,
  isSimilarPayer,
  formatCurrency,
  checkValidity,
  userCompanyID,
  getValidDep,
  parseInsDetails,
  convertToUpper,
  showElementsByClassName,
  hideElementsByClassName,
  getInsuranceNumberWithPrefix,
} from "../../../utils";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import HealthBenefitPlan from "./HealthBenefitPlan";
import PayerInformation from "./PayerInformation";
import PayerInformationList from "./PayerInformationList";
import EligibitlityDetailsCollapsableTable from "./EligibitlityDetailsCollapsableTable";
import PlanItem from "./PlanItem";
import { getDeductible, getElgibilityLineItem, planDates } from "./common";
import HealthBenefitTabularForm from "./HealthBenefitTabularForm";
import EligibilitySearchRecord from "./EligibilitySearchRecord";
import { CONFIRMATION_TYPE } from "constant";
import ConfirmationModal from "../ConfirmationModal";
import { saveAs } from "file-saver";
import { setLoader } from "store/features/general/generalSlice";
import { createEmployeeAsync } from "store/features/employees/employeesSlice";
import { MEDICARE_ID } from "constant";
import PayerInformationUI from "./PayerInformationUI";

const options = {
  componentRestrictions: { country: "us" },
  fields: ["address_components", "name", "formatted_address"],
  strictBounds: false,
  types: [],
};
const EligibilityDetailsModal = (props) => {
  const { handleClose, data, selectedEligibility, cssClass } = props;
  const [newUser, setNewUser] = useState(data);
  const [selectedUser, setSelectedUser] = useState(null);
  const [error, setError] = useState("");
  const [globalSearch, setGlobalSearch] = useState("");
  const [jumpToSection, setJumpToSection] = useState(null);
  const employeesList = useSelector(employeesListSelector);
  const loginUser = useSelector(selectLoginUser);
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [selectSearchData, setSelectSearchData] = useState(null);
  const [collapsable, setCollapsable] = useState(false);
  const [openTable, setOpentTable] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });

  const dispatch = useDispatch();
  const componentRef = useRef();

  const personalization = [
    // {
    //   itemKey: "benefit_code",
    //   title: "Benefit Code",
    //   textAlign: "center",
    // },
    {
      itemKey: "benefit_description",
      title: "Benefit Description",
      textAlign: "center",
    },
    {
      itemKey: "benefit_coverage_description",
      title: "Benefit Coverage Description",
      textAlign: "center",
    },
    {
      itemKey: "plan_date",
      title: "Plan Date",
      textAlign: "center",
    },
    {
      itemKey: "benefit_amount",
      title: "Benefit Amount",
      textAlign: "center",
    },
  ];
  // check Health Benefit is active
  const ELIG_BASE_CONDITION = (f) => f.benefit_code === "30" && f.benefit_coverage_code === "1";

  // in case of medicare check that benefit is comes from Medicare part b
  // when not medicare check that it comes from individual value
  const MEDICARE_ELIG_CONDITION = (f) =>
    newUser.payerId === "01192" ? ELIG_BASE_CONDITION(f) && f.insurance_type_code === "MB" : ELIG_BASE_CONDITION(f);

  const formatList = (items, eligData) => {
    if (!items) return [];

    const filteredItems = items
      .map((item) => {
        const entityName = Array.isArray(item.entity_name) ? item.entity_name[0] : item.entity_name;
        const benefitNotes = Array.isArray(item.benefit_notes) ? item.benefit_notes[0] : item.benefit_notes;

        let title = item.insurance_type_description || item.insurance_plan || benefitNotes;
        if (entityName) {
          title = entityName;
        }

        return {
          medicareid: eligData?.medicareid,
          title,
          description: benefitNotes || "",
          ...(title !== benefitNotes && {
            benefit_notes: benefitNotes,
          }),
          ...(item.benefit_coverage_description === "Managed Care Coordinator" && {
            benefit_coverage_description: item.benefit_coverage_description,
          }),
        };
      })
      .filter((f) => f.title);

    return filteredItems;
  };

  // Function find the Additional payer and IPA or could be any other requirement
  const additionalPayers = (eligData, benefits, itemToSearch) => {
    if (!benefits) return null;

    let filteredData = benefits.filter(
      (f) =>
        f.benefit_code !== "88" &&
        f.benefit_notes !== "CALL FOR HCP INFORMATION" &&
        f.benefit_notes !== "CALL FOR HCP INFORAMTION" &&
        itemToSearch.includes(f.benefit_coverage_description)
    );

    if (filteredData.length === 0) return [];

    return formatList(filteredData, eligData);
  };

  const primaryPayersList = (eligData, benefits) => {
    if (!benefits) return null;

    let filteredData = benefits.filter(
      (f) =>
        f.benefit_code !== "88" &&
        f.insurance_plan !== "CA QMB Plan" &&
        f.benefit_notes !== "CALL FOR HCP INFORMATION" &&
        f.benefit_notes !== "CALL FOR HCP INFORAMTION" &&
        f.benefit_coverage_description === "Other or Additional Payor"
    );

    if (filteredData.length === 0) return [];

    return formatList(filteredData, eligData);
  };

  // Function find the Additional payer and IPA or could be any other requirement
  const findBenefitCode = (benefits, itemToSearch) => {
    if (!benefits) return null;

    let filteredData = benefits.filter((f) => f.benefit_code === "30" && f.entity_description?.includes(itemToSearch));

    if (filteredData.length === 0) {
      filteredData = benefits.filter((f) => f.entity_description?.includes(itemToSearch));
    }

    if (filteredData.length === 0) return null;

    // Sort based on "primary_care_provider" key.
    filteredData.sort((a, b) => {
      const aValue = a.primary_care_provider ? a.primary_care_provider.split("-")[1] : "00000000"; // the end date
      const bValue = b.primary_care_provider ? b.primary_care_provider.split("-")[1] : "00000000"; // the end date
      return bValue.localeCompare(aValue);
    });

    const {
      benefit_code,
      entity_description,
      entity_name,
      entity_addr_1,
      entity_city,
      entity_state,
      entity_zip,
      entity_phone,
      primary_care_provider,
      coordination_of_benefits,
    } = filteredData[0];

    const index = entity_description?.indexOf(itemToSearch);
    if (index !== -1) {
      let additionalPayerId;
      const payerName = entity_name[index];
      const additionalPayerElgibility = INSURANCE_PROVIDER.find((provider) => isSimilarPayer(provider, payerName));
      if (additionalPayerElgibility?.value) {
        additionalPayerId = additionalPayerElgibility.value;
      }

      const dates = planDates(primary_care_provider || coordination_of_benefits);

      return {
        ...filteredData[0],
        ...dates,
        additionalPayerId,
        title: entity_name[index],
        address: entity_addr_1?.[index],
        city: entity_city?.[index],
        state: entity_state?.[index],
        zip: entity_zip?.[index],
        phoneNumber: entity_phone?.[index],
      };
    }
    return null;
  };

  useEffect(() => {
    parseEligibiltyData(data);
  }, [data]);

  useEffect(() => {
    setGlobalSearch(jumpToSection?.label || "");
  }, [jumpToSection]);

  useEffect(() => {
    if (data)
      setSelectedUser(
        employeesList.find(
          (emp) =>
            (checkValidity(emp.firstName) === checkValidity(data.ins_name_f) &&
              checkValidity(emp.lastName) === checkValidity(data.ins_name_l) &&
              emp.dob === convertYYYYMMDDToDate(data.ins_dob, "YYYY-MM-DD")) ||
            emp.id === data.employeeID ||
            emp.id === data.employee?.id
        )
      );
  }, [employeesList, data]);

  const parseEligibiltyData = (objParams) => {
    if (!objParams) return;

    try {
      const eligData = { ...objParams };
      // Extract error messages and set them if they exist
      if (eligData.error?.length) {
        const errorMessage = eligData.error.map(({ error_mesg }) => error_mesg).join(",");
        setError(errorMessage);
      } else if (eligData?.error?.error_mesg) {
        setError(eligData?.error?.error_mesg);
      }

      // Process benefits
      const sortedBenefits = [];
      let additionalPayerName;
      let primaryPayers = [];
      let additionalPayersList = [];
      let ipa;

      // get part b, deductiable and co insurance object from health benefit
      let partB = getElgibilityLineItem(eligData, MEDICARE_ELIG_CONDITION);

      // get All Benefits
      let healthBenefitPlans = eligData.benefit?.filter(
        (f) => f.benefit_code === "30" && f.benefit_coverage_code === "1"
      );

      const { benefit, medicareid } = eligData;
      eligData.benefit?.forEach((benefit) => {
        const { benefit_code } = benefit;

        if (benefit_code === "30") {
          sortedBenefits.unshift(benefit);
        } else {
          sortedBenefits.push(benefit);
        }
      });

      additionalPayersList = additionalPayers(eligData, benefit, ["Managed Care Coordinator", "Third Party Liability"]);

      primaryPayers = primaryPayersList(eligData, benefit);

      // set additional payers if any
      let res = findBenefitCode(benefit, "Primary Payer");
      if (res) additionalPayerName = res;

      // set IPA
      let ipaRes = findBenefitCode(benefit, "Independent Physicians Association (IPA)");
      if (ipaRes) ipa = ipaRes;

      const dates = planDates(partB?.plan_date || eligData.plan_begin_date || eligData.plan_date || []);

      // Create the newUser object

      const deductibleObj = getDeductible(eligData, BCC_VALUE.DEDUCTIBLE, "Y");

      if (partB) {
        Object.assign(partB, { deductible: deductibleObj });
      }

      const payerName = INSURANCE_PROVIDER.find((f) => f.value === eligData.payerId)?.label;

      const newUser = {
        ...eligData,
        healthBenefitPlans,
        additionalPayerName,
        benefit: sortedBenefits,
        ipa,
        partB,
        payerName,
        ...dates,
        primaryPayers,
        additionalPayersList,
      };

      setNewUser(newUser);
    } catch (ex) {
      console.log("parseEligibiltyData", ex);
    }
  };

  const searchData = () => {};

  const handleUpdatePatient = async () => {
    // const { payerId } = selectedEligibility;
    const { ins_name_f, ins_name_l, ins_dob, ins_number, employee } = data;

    if (employee) {
      return populate(employee);
    }

    const filteredArray = employeesList.filter(
      (item) =>
        (checkValidity(item.firstName) === checkValidity(ins_name_f) &&
          checkValidity(item.lastName) === checkValidity(ins_name_l) &&
          item.dob === convertYYYYMMDDToDate(ins_dob, "YYYY-MM-DD")) ||
        item.id === selectedUser.id
    );
    if (filteredArray.length === 0) {
      dispatch(setMessage("No Patient Found", MESSAGE_MODES.info));
      return;
    }

    if (filteredArray.length === 1) {
      return populate(filteredArray[0]);
    }

    setRecordData(filteredArray);

    // if (validateFieldsAreSimilar()) return;

    // try {
    //   const updatedEmp = {
    //     ...selectedUser,
    //     firstName: ins_name_f,
    //     lastName: ins_name_l,
    //     dob: convertYYYYMMDDToDate(ins_dob, "YYYY-MM-DD"),
    //     medicalNo: ins_number,
    //     medicareNo: payerId === "01192" ? ins_number : emp.medicareNo,
    //   };
    //   await api.updateEmployeePersonals(updatedEmp);
    //   dispatch(setMessage("Updated successfully.", MESSAGE_MODES.success));
    // } catch (error) {
    //   console.log(error);
    //   dispatch(setMessage(error.message, MESSAGE_MODES.error));
    // }
  };

  const validateFieldsAreSimilar = (selectedUser) => {
    if (!selectedUser) return true;
    const { insuranceDetails } = selectedUser;
    const insData = [...parseInsDetails(insuranceDetails, selectedUser)];
    const findIns = insData.find((f) => f.insuranceCompany === data.payerId);
    if (!findIns) return false;
    if (data.ins_sex && selectedUser.sex.toLowerCase() !== data.ins_sex.toLowerCase()) return false;
    if (data.ins_name_f && selectedUser.firstName.toLowerCase() !== data.ins_name_f.toLowerCase()) return false;
    if (data.ins_name_l && selectedUser.lastName.toLowerCase() !== data.ins_name_l.toLowerCase()) return false;
    if (data.ins_dob && moment(selectedUser.dob).format("YYYYMMDD") !== convertYYYYMMDDToDate(data.ins_dob, "YYYYMMDD"))
      return false;
    if (data.ins_number && findIns?.medicalNo?.toLowerCase() !== data.ins_number.toLowerCase()) return false;
    return true;
  };

  const isActive = () => {
    if (MEDICARE_ID.includes(newUser.payerId)) {
      return !!newUser?.benefit?.some(MEDICARE_ELIG_CONDITION);
    }
    return !!newUser?.benefit?.some(ELIG_BASE_CONDITION);
  };

  const handleAddToProfile = async (isAddInProfile, benefitData) => {
    setLoading(true);
    let dataResponse = null;
    let payload = null;
    // check the additional payer eligibility without medical no

    // check the additional payer eligibility with medical no
    let { exception, status, data, error, obj } = await isEligibileWithAdditionalPayer(benefitData, newUser.medicalNo);
    dataResponse = data;
    payload = obj;
    if (exception) {
      setLoading(false);
      return;
    }

    if (error) {
      dispatch(setMessage(error, MESSAGE_MODES.error));
      return;
    }

    setLoading(false);
    dispatch(setMessage(`Eligibility Passed`, MESSAGE_MODES.success));
    parseEligibiltyData({ ...dataResponse, payerId: payload.payerId });

    if (data && isAddInProfile) {
      const { ins_number } = dataResponse;
      const { payerId } = newUser;
      if (selectedUser) {
        const { insuranceDetails } = selectedUser;
        const insData = [...parseInsDetails(insuranceDetails, selectedUser)];

        const index = insData.findIndex((f) => f.insuranceCompany === benefitData.additionalPayerId);
        let ins = {
          id: "prefix_" + Math.random().toString(36).slice(2, 9),
          insuranceCompany: benefitData.additionalPayerId,
          insuranceCompanyCode: benefitData.title,
          medicalNo: ins_number,
          isActive: true,
          ...(MEDICARE_ID.includes(payerId) && { medicareNo: ins_number }),
        };
        if (index !== -1) {
          const findIns = insData[index];
          insData.splice(index, 1, {
            ...findIns,
            ...ins,
          });
        } else {
          insData.push(ins);
        }

        const objToUpdate = {
          id: selectedUser.id,
          insuranceDetails: insData,
          ...(index === 0 && {
            medicalNo: ins_number,
            insuranceCompany: benefitData.additionalPayerId,
            insuranceCompanyCode: benefitData.title,
          }),
        };
        await api.updateEmployeeInsurance(objToUpdate, loginUser);
      }
    }
    if (data && !isAddInProfile && !!newUser.employee) {
      const { ins_number } = data;
      dispatch(setMessage(`Eligibility Passed, Please note the Insurnace Number ${ins_number}`, MESSAGE_MODES.success));
    }
  };

  const isEligibileWithAdditionalPayer = async (benefitData, medicalNo) => {
    let insNo = [medicalNo];
    let defaultPayerId = [benefitData.additionalPayerId];
    let defaultPayerName = [benefitData.title];
    if (medicalNo) insNo = [getInsuranceNumberWithPrefix(benefitData.additionalPayerId, medicalNo)];
    if (insNo && insNo != "" && !insNo.includes(medicalNo)) {
      insNo.unshift(medicalNo);
      defaultPayerId.unshift(benefitData.additionalPayerId);
      defaultPayerName.unshift(benefitData.title);
    }
    try {
      const obj = {
        clientID: userCompanyID.get(),
        locationID: CONFIG.generalFacility,
        subAgentID: CONFIG.generalSubAgent,
        firstName: getValidDep(newUser.ins_name_f),
        lastName: getValidDep(newUser.ins_name_l),
        middleName: getValidDep(newUser.ins_name_m),
        fdos: moment().format("YYYYMMDD"),
        userID: loginUser.sub,
        ...(insNo && insNo != "" && { medicalNo: insNo }),
        userName: loginUser.name,
        subAgentName: newUser.subAgentName,
        locationName: newUser.clientName,
        clientName: newUser.clientName,
        insuranceCompany: benefitData.additionalPayerId,
        dob: newUser.ins_dob,
        insuranceProvider: benefitData.title,
        street: newUser.ins_addr_1,
        city: newUser.ins_city,
        state: newUser.ins_state,
        zip: newUser.ins_zip,
        countryCode: "+1",
        empID: selectedUser?.id || "",
        country: "US",
        payerids: defaultPayerId,
        payerNames: defaultPayerName,
      };

      const res = await api.checkBRMEligibility(obj, loginUser);

      const { data, error } = res;

      if (error) {
        return { exception: false, status: false, error: error.error_mesg, obj };
      }

      if (data) {
        const isEligible = !!data?.benefit?.some(ELIG_BASE_CONDITION);
        if (isEligible) {
          return { exception: false, status: true, data: data, obj };
        }
      }
    } catch (e) {
      console.log("E", e);
      dispatch(setMessage("Fail to Process the request", MESSAGE_MODES.error));
    }
    return { exception: true };
  };

  const onHandleConfirmation = (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) return;

    switch (type) {
      case CONFIRMATION_TYPE.UPDATE_PATIENT:
        updateDemoGraphics();
        break;
      case CONFIRMATION_TYPE.CREATE_PATIENT:
        createNewEmployee();
        break;
    }
  };
  const handlePrints = async () => {
    const element = componentRef.current;
    hideElementsByClassName("noPrint");
    try {
      const canvas = await html2canvas(element);
      const pdf = new jsPDF("p", "mm", "a4"); // 'p' for portrait, 'mm' for units, 'a4' for page size

      // Define header text
      const headerText = "Eligibility Details";
      // Add header with custom font style and size
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(12);
      // Add header
      pdf.text(headerText, 10, 7); //// Customize the header text and position

      const imgWidth = pdf.internal.pageSize.getWidth(); // Get page width
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio
      pdf.addImage(canvas.toDataURL("image/jpeg"), "JPEG", 0, 10, imgWidth, imgHeight); // Adjust the image position (e.g., 10 units down)

      // Define footer text
      const footerText = "Page ";

      // Add footer
      pdf.setFontSize(10);
      pdf.text(
        `${footerText}${pdf.internal.getCurrentPageInfo().pageNumber}`,
        pdf.internal.pageSize.getWidth() / 2,
        pdf.internal.pageSize.getHeight() - 10,
        {
          align: "center",
        }
      ); // Adjust the footer text and position
      componentRef.current = element;
      handlePrint();

      // pdf.save(`Eligibility-Details.pdf`);
      // showElementsByClassName("noPrint");
    } catch (err) {
      console.log("Error-", err);
    }
  };

  const handleDownload = async (print) => {
    const element = componentRef.current;
    hideElementsByClassName("noPrint");
    try {
      const canvas = await html2canvas(element);
      const pdf = new jsPDF("p", "mm", "a4"); // 'p' for portrait, 'mm' for units, 'a4' for page size

      // Define header text
      const headerText = "Eligibility Details";
      // Add header with custom font style and size
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(12);
      const leftMargin = 10; // Adjust the left margin as needed
      const rightMargin = 10;
      // Add header
      pdf.text(headerText, leftMargin, 7); //// Customize the header text and position

      const imgWidth = pdf.internal.pageSize.getWidth() - rightMargin - leftMargin; // Get page width
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio
      pdf.addImage(canvas.toDataURL("image/jpeg"), "JPEG", leftMargin, 10, imgWidth, imgHeight); // Adjust the image position (e.g., 10 units down)

      // Define footer text
      const footerText = "Page ";

      // Add footer
      pdf.setFontSize(10);
      pdf.text(
        `${footerText}${pdf.internal.getCurrentPageInfo().pageNumber}`,
        pdf.internal.pageSize.getWidth() / 2,
        pdf.internal.pageSize.getHeight() - 10,
        {
          align: "center",
        }
      ); // Adjust the footer text and position'

      // Open a new window or tab with the PDF content for printing
      if (print) {
        const pdfDataUri = pdf.output("datauristring");
        const newWindow = window.open("", "_blank");
        newWindow.document.write('<iframe width="100%" height="100%" src="' + pdfDataUri + '"></iframe>');
        newWindow.document.close();
      } else {
        pdf.save(`Eligibility-Details.pdf`);
      }

      // Trigger the print dialog
      // setTimeout(() => {
      //   newWindow.print();
      // }, 500);

      showElementsByClassName("noPrint");
    } catch (err) {
      console.log("Error-", err);
    }
  };

  const handleSingleFieldUpdate = async (key, value) => {
    try {
      dispatch(setLoader(true));
      const res = await api.updateEmpSingleField(key, value, selectedUser);
      setSelectedUser(res);
      dispatch(setLoader(false));
    } catch (err) {
      console.log("Error ;-", err);
      dispatch(setLoader(false));
    }
  };

  const compareStrings = (empKey, key) => {
    if (!isActive()) return null;
    if (empKey === "medicalNo" || empKey === "copay" || empKey === "deductible") {
      if (selectedUser) {
        const { insuranceDetails } = selectedUser;
        const insData = [...parseInsDetails(insuranceDetails, selectedUser)];

        const index = insData.findIndex((f) => f.insuranceCompany === newUser["payerId"]);
        const findIns = insData[index];

        const { partB } = newUser;
        if (findIns && (empKey === "copay" || empKey === "deductible")) {
          const value = partB.deductible[key]?.benefit_amount || 0;

          if (value != 0 && findIns[empKey] !== value) {
            return (
              <span
                className="linkedText text-lowercase textUnderLine"
                onClick={() => {
                  let insDetails = [...insData];

                  if (index !== -1) {
                    insDetails.splice(index, 1, {
                      ...findIns,
                      [empKey]: value || 0,
                    });
                  }
                  handleSingleFieldUpdate("insuranceDetails", insDetails);
                }}
              >
                update
              </span>
            );
          }
          return null;
        }
        if (
          findIns &&
          (empKey !== "copay" || empKey !== "deductible") &&
          findIns[empKey]?.toString().toLowerCase() !== newUser[key]?.toLowerCase()
        ) {
          return (
            <span
              className="linkedText text-lowercase textUnderLine"
              onClick={() => {
                let insDetails = [...insData];

                if (index !== -1) {
                  insDetails.splice(index, 1, {
                    ...findIns,
                    [empKey]: newUser[key],
                    isActive: true,
                    ...(empKey === "medicalNo" && newUser["payerId"] === "01192" && { medicareNo: newUser[key] }),
                  });
                }
                handleSingleFieldUpdate("insuranceDetails", insDetails);
              }}
            >
              update
            </span>
          );
        }
      }
      return null;
    }

    if (empKey === "dob") {
      if (
        selectedUser &&
        newUser[key] &&
        selectedUser?.[empKey] !== convertYYYYMMDDToDate(newUser[key], "YYYY-MM-DD")
      ) {
        return (
          <span
            className="linkedText text-lowercase textUnderLine"
            onClick={() => handleSingleFieldUpdate(empKey, convertYYYYMMDDToDate(newUser[key], "YYYY-MM-DD"))}
          >
            update
          </span>
        );
      }
      return null;
    }
    if (selectedUser && selectedUser[empKey]?.toLowerCase() !== newUser[key]?.toLowerCase()) {
      return (
        <span
          className="linkedText text-lowercase textUnderLine"
          onClick={() => handleSingleFieldUpdate(empKey, getValidDep(newUser[key]))}
        >
          update
        </span>
      );
    }
    return null;
  };

  const updateDemoGraphics = async () => {
    const { ins_name_f, ins_name_l, ins_dob, ins_number, payerId, ins_sex, payerName, partB } = newUser;
    try {
      const ins = {
        insuranceCompany: payerId,
        medicalNo: ins_number,
        medicareNo: MEDICARE_ID.includes(data.payerId) ? ins_number : selectedUser.medicareNo || "",
        insuranceCompanyCode: payerName,
        isActive: true,
      };

      const { insuranceDetails } = selectedUser;
      const insData = [...parseInsDetails(insuranceDetails, selectedUser)];

      const index = insData.findIndex((f) => f.insuranceCompany === payerId);
      if (index !== -1) {
        const findIns = insData[index];
        insData.splice(index, 1, {
          ...findIns,
          ...ins,
        });
      } else {
        insData.push({ ...ins, id: "prefix_" + Math.random().toString(36).slice(2, 9) });
      }

      const updatedEmp = {
        ...selectedUser,
        firstName: getValidDep(ins_name_f),
        lastName: getValidDep(ins_name_l),
        dob: convertYYYYMMDDToDate(ins_dob, "YYYY-MM-DD"),
        gender: ins_sex,
        insuranceDetails: insData,
        ...(index === 0 && ins),
      };
      const res = await api.updateEmployeePersonals(updatedEmp);
      setSelectedUser(res);
      dispatch(setMessage("Updated successfully.", MESSAGE_MODES.success));
    } catch (error) {
      console.log(error);
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
    }
  };

  const createNewEmployee = async () => {
    const { ins_name_f, ins_name_l, ins_dob, ins_number, payerId, payerName, ins_sex, id } = newUser;
    try {
      const ins = {
        id: "prefix_" + Math.random().toString(36).slice(2, 9),
        insuranceCompany: payerId,
        medicalNo: convertToUpper(ins_number),
        medicareNo: convertToUpper(MEDICARE_ID.includes(data.payerId) ? ins_number : ""),
        insuranceCompanyCode: payerName,
        insuranceGroupId: "",
        isMedicare: "N",
        isActive: true,
      };
      const newEmp = {
        firstName: getValidDep(ins_name_f),
        lastName: getValidDep(ins_name_l),
        dob: convertYYYYMMDDToDate(ins_dob, "YYYY-MM-DD"),
        insuranceDetails: [ins],
        gender: ins_sex,
        eEligibility: true,
        eligibilityID: id,
        ...ins,
      };
      dispatch(createEmployeeAsync(newEmp));
    } catch (error) {
      console.log(error);
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
    }
  };

  const populate = async (emp) => {
    const resMessage = validateFieldsAreSimilar(emp);
    if (resMessage) {
      dispatch(setMessage("Patient Info is Up-to-date", MESSAGE_MODES.info));
      return;
    }
    setSelectedUser(emp);
    setOpenConfirmation({
      isShow: true,
      actionType: CONFIRMATION_TYPE.UPDATE_PATIENT,
      title: `Update Demograpihics (${emp.schrID})`,
      message: `Demographics info is'nt matched, Would you like to update patient?`,
    });
  };

  const eligibilityCheckDate = () => {
    let date = newUser?.updatedAt;

    if (!date) return "";

    const eligDate = moment(date).format("MM-DD-YYYY");

    return `Eligibility Checked on ${eligDate}`;
  };

  return (
    <>
      <Modal
        show
        animation={true}
        size={"2x1"}
        onHide={() => {
          handleClose();
        }}
        backdrop="static"
        style={{ paddingLeft: "0" }}
        centered
        scrollable
        className={cssClass ?? ""}
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {t("eligibilityDetails")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
            color: "var(--text-black)",
          }}
          className="text-uppercase d-flex column-gap-15"
        >
          <div className="elligibility-details-left-side">
            <div className="testAdmin createClientsWrapper printableArea" id="printableArea" ref={componentRef}>
              <div className="my-2 boxborder">
                <div className="crew-profile-column-equal align-items-center">
                  <label className="modalLineHeaders fw-bold mt-0">{eligibilityCheckDate()}</label>
                  <div className="form-group-eligibity-wrapper w-100">
                    <h3 className={`fw-bold ${isActive() ? "colorGreen" : "colorRed"} `}>
                      {isActive() ? "Active - Eligible" : "InActive - Not Eligible"}
                    </h3>
                  </div>
                </div>
              </div>
            </div>

            <div className="testAdmin createClientsWrapper printableArea" id="printableArea" ref={componentRef}>
              <div className="my-2 boxborder">
                <div className="crew-profile-column-equal align-items-center">
                  <label className="modalLineHeaders fw-bold mt-0">Demographics:</label>
                  <div className="form-group-eligibity-wrapper w-100">
                    {newUser?.date_of_death_date && (
                      <>
                        <label className="modalLineHeaders fw-bold">Expired On:</label>
                        <span className={`fw-bold colorRed `}>
                          {moment(newUser?.date_of_death_date).format("MM-DD-YYYY")}
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div className="crew-profile-column-equal  form-group-eligibity-wrapper">
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className="modalLineHeaders fw-bold">First Name: </label>
                    <span className={compareStrings("firstName", "ins_name_f") ? "error-text" : ""}>
                      {newUser.ins_name_f || newUser.firstName}
                    </span>
                    {compareStrings("firstName", "ins_name_f") || <></>}
                  </div>
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className="modalLineHeaders fw-bold">Last Name: </label>
                    <span className={compareStrings("lastName", "ins_name_l") ? "error-text" : ""}>
                      {newUser.ins_name_l || newUser.lastName}
                    </span>
                    {compareStrings("lastName", "ins_name_l") || <></>}
                  </div>
                </div>
                <div className="crew-profile-column-equal  form-group-eligibity-wrapper">
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className={`modalLineHeaders fw-bold`}>DOB:</label>
                    <span className={compareStrings("dob", "ins_dob") ? "error-text" : ""}>
                      {newUser.ins_dob ? convertYYYYMMDDToDate(newUser.ins_dob || newUser.dob) : "-"}
                    </span>
                    {compareStrings("dob", "ins_dob") || <></>}
                  </div>
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className="modalLineHeaders fw-bold">Gender: </label>
                    <span className={compareStrings("sex", "ins_sex") ? "error-text" : ""}>{newUser.ins_sex}</span>
                    {compareStrings("sex", "ins_sex") || <></>}
                  </div>
                </div>
                <div className="crew-profile-column-equal form-group-eligibity-wrapper">
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className="modalLineHeaders fw-bold">Insurance No: </label>
                    <span className={compareStrings("medicalNo", "ins_number") ? "error-text" : ""}>
                      {newUser.ins_number || newUser.medicalNo}
                    </span>
                    {compareStrings("medicalNo", "ins_number") || <></>}
                  </div>
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className="modalLineHeaders fw-bold">Insurance Name: </label>
                    <span>{newUser.payerName}</span>
                  </div>
                </div>
                {isActive() &&
                  !validateFieldsAreSimilar(selectedUser) &&
                  (newUser.id || newUser.employeeID || newUser.employee) && (
                    <Button
                      style={{ marginBottom: 10 }}
                      variant="secondary"
                      className="modalButtons headerButton btn-fill noPrint"
                      onClick={handleUpdatePatient}
                    >
                      Update Patient
                    </Button>
                  )}
                {isActive() && !selectedUser && (newUser.id || newUser.employeeID || newUser.employee) && (
                  <Button
                    style={{ marginBottom: 10 }}
                    variant="secondary"
                    className="modalButtons headerButton btn-fill noPrint"
                    onClick={() => {
                      setOpenConfirmation({
                        isShow: true,
                        title: "Create Patient",
                        message: "Would you like to create new Patient with this Patient Info?",
                        actionType: CONFIRMATION_TYPE.CREATE_PATIENT,
                      });
                    }}
                  >
                    Create Patient
                  </Button>
                )}
              </div>
              {/* <HealthBenefitPlan
              newUser={newUser}
              label={newUser.payerId !== "01192" ? "In Network Benefit Information" : "Benefit Information"}
              benefitValue={BCC_VALUE.DEDUCTIBLE}
              inNetwork="Y"
            /> */}
              <PlanItem
                item={newUser?.partB}
                handleCollapse={() => setCollapsable(!collapsable)}
                benefitLabel={
                  !MEDICARE_ID.includes(newUser.payerId) ? "In Network Benefit Information" : "Benefit Information"
                }
                title={
                  !MEDICARE_ID.includes(newUser.payerId)
                    ? "Individual - Benefit Details:"
                    : "Part B - Beneficiary Details:"
                }
                newUser={newUser}
                compareStrings={compareStrings}
              ></PlanItem>

              {newUser.payerId !== "01192" &&
                collapsable &&
                newUser.healthBenefitPlans
                  ?.filter((f, i) => i !== 0)
                  ?.map((plan) => (
                    <PlanItem item={plan} title="Individual - Benefit Details:" newUser={newUser}></PlanItem>
                  ))}

              {!MEDICARE_ID.includes(newUser.payerId) && collapsable && (
                <>
                  <HealthBenefitTabularForm newUser={newUser} />
                </>
              )}
            </div>

            {error && <ErrorMessage error={error} handleChange={() => setError("")} timeLess />}
            <div className="d-flex align-items-center justify-content-around my-3 ">
              <div className="form-group-eligibity-wrapper w-75">
                <InputField
                  type="dropDown"
                  options={
                    newUser.benefit &&
                    newUser.benefit.map((benefit) => {
                      return { value: benefit.benefit_code, label: benefit.benefit_description };
                    })
                  }
                  placeholder="Search..."
                  value={jumpToSection}
                  handleChange={(e) => setJumpToSection(e)}
                  groupWrapper="w-100"
                  onInputChange={(text) => {
                    text && setJumpToSection(null);
                  }}
                  isClearable
                />
              </div>
              <div
                className="form-group-eligibity-wrapper  cursor-pointer fw-bold fs-5"
                onClick={() => setOpentTable(!openTable)}
              >
                {openTable ? <i className="fas fa-chevron-down" /> : <i className="fas fa-chevron-up" />}
              </div>
            </div>

            {openTable && (
              <div className="form-group-wrapper d-block">
                <EligibitlityDetailsCollapsableTable
                  columns={personalization}
                  rows={newUser.benefit || []}
                  globalSearch={globalSearch}
                />
              </div>
            )}
          </div>
          <div className="elligibility-details-right-side my-2 boxborder">
            {newUser?.primaryPayers?.some((t) => t.medicareid) && (
              <PayerInformationList
                data={newUser?.primaryPayers}
                label={newUser?.primaryPayers?.some((t) => t.medicareid) ? "Primary Payer" : ""}
                handleAddToProfile={handleAddToProfile}
                loading={loading}
                employee={selectedUser}
              />
            )}

            <PayerInformationUI
              data={newUser.additionalPayerName}
              handleAddToProfile={handleAddToProfile}
              label="Additional Payer:"
              loading={loading}
              employee={selectedUser}
            />

            <PayerInformationList
              data={newUser?.additionalPayersList}
              handleAddToProfile={handleAddToProfile}
              loading={loading}
              employee={selectedUser}
            />
            {!newUser?.primaryPayers?.some((t) => t.medicareid) && (
              <PayerInformationList
                data={newUser?.primaryPayers}
                label={newUser?.primaryPayers?.some((t) => t.medicareid) ? "Primary Payer" : ""}
                handleAddToProfile={handleAddToProfile}
                loading={loading}
                employee={selectedUser}
              />
            )}
            <PayerInformationUI
              data={newUser.ipa}
              handleAddToProfile={handleAddToProfile}
              label="Independent Physicians Association:"
              loading={loading}
              employee={selectedUser}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <div>
            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              className="modalButtons mx-2 headerButton btn-fill"
              onClick={() => handleDownload()}
            >
              Download
            </Button>

            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              className="modalButtons headerButton btn-fill"
              // onClick={() => window.print()}
              onClick={() => handleDownload(true)}
            >
              Print
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {recordData.length > 0 && (
        <EligibilitySearchRecord
          data={recordData}
          handleSelect={populate}
          handleClose={() => {
            setRecordData([]), setSelectSearchData(null);
          }}
          setSelectSearchData={setSelectSearchData}
          selectSearchdata={selectSearchData}
          cssClass={"seperateModal"}
        />
      )}
      {openConfirmation.isShow && (
        <ConfirmationModal
          show={openConfirmation.isShow}
          actionType={openConfirmation.actionType}
          title={openConfirmation.title}
          message={openConfirmation.message}
          handleConfirm={onHandleConfirmation}
          cssClass={"seperateModal"}
          closeBtn
        />
      )}
    </>
  );
};

export default EligibilityDetailsModal;
