import api from "api";
import Loader from "components/Loader/Loader";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import MFPagination from "components/Pagination/MFPagination";
import Status from "components/Status";
import MainTable from "components/Table/MainTable";
import { SALES_PERSON_PERSONALIZE } from "constants/personalization";
import { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { showConfirmationModal } from "store/features/general/generalAction";
import { deleteSalesPerson, setSelectedSalePerson } from "store/features/salePerson/salePersonAction";
import { salesPersonsListSelector } from "store/features/salePerson/salePersonSelector";
import "../../src/tableStyling.css";
import Filter from "../components/Filter";
import Icon from "../components/Icon";
import { PAGE_LIMIT, TABLE_QUICK_TOOLS } from "../constant";
import { AppContext } from "../context/app-context";
import { t } from "../stringConstants";
import { draggablePersonalizationLocalStorage } from "../utils";
import { fetchSalePersons } from "store/features/salePerson/salePersonAction";
import { setSalesPersonAssignDetails } from "store/features/salePerson/salePersonAction";
import { unLinkSalesPerson } from "store/features/salePerson/salePersonAction";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { selectedCompanySetting } from "store/features/companySetting/companySettingSelectors";

const SalesPersonView = (props) => {
  const { clientId } = props;

  const [salesPersons, setSalesPersons] = useState([]);
  const [filteredSalesPersons, setFilteredSalesPersons] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const appContext = useContext(AppContext);
  const [allSelected, setAllSelected] = useState(false);
  const [personalize, setPersonalize] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const salesPersonsList = useSelector(salesPersonsListSelector);
  const setting = useSelector(selectedCompanySetting);

  const dispatch = useDispatch();

  const quickTools = [TABLE_QUICK_TOOLS.checkbox, TABLE_QUICK_TOOLS.edit, TABLE_QUICK_TOOLS.delete];

  const searchTerms = ["First Name", "Last Name", "Email", "Phone", "Practice ID"];

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(PAGE_LIMIT);

  const sortUsers = (users, sortParam) => {
    if (sortDescending) {
      return [...users].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...users].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  const loginUser = useSelector(selectLoginUser);

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;

    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (filters[key] == "" && !Object.keys(filters[key]).length) {
          return true;
        }
        if (key === "reSubmissionDate") {
          return filter[key] && obj[key];
        }
        if (key === "practiceID") {
          return obj[key] && obj[key] === filters[key];
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const currentUsers = (users) => {
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    return users.slice(indexOfFirstUser, indexOfLastUser);
  };

  let salePersonsToMap = currentUsers(filteredSalesPersons);

  const getPageNumbers = (users) => {
    const pageNumbers = users.length > PAGE_LIMIT ? Math.ceil(users.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(salesPersons);

  useEffect(() => getSalesPersonList(), [clientId]);
  useEffect(() => formatSalesPersonList(), [salesPersonsList]);

  const getSalesPersonList = () => {
    const fetchingDetails = {};
    if (clientId) fetchingDetails.siteID = clientId;
    dispatch(fetchSalePersons(fetchingDetails));
  };

  const formatSalesPersonList = () => {
    setSalesPersons(salesPersonsList);
    const filteredClaims = sortUsers(nestedFilter(salesPersonsList, filter), sortBy);
    setFilteredSalesPersons(filteredClaims);
    setPersonalize(draggablePersonalizationLocalStorage.get(loginUser, "salesPersons", SALES_PERSON_PERSONALIZE));
  };

  useEffect(() => {
    if (filteredSalesPersons.length > 0)
      setFilteredSalesPersons(sortUsers(nestedFilter(filteredSalesPersons, filter), sortBy));
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (salesPersons.length > 0) {
      setFilteredSalesPersons(sortUsers(nestedFilter(salesPersons, filter), sortBy));
      setCurrentPage(1);
    }
  }, [filter]);

  if (filteredSalesPersons.length > 0) {
    pageNumbers = getPageNumbers(filteredSalesPersons);
  }

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
    handleChangePage(1);
  };

  useEffect(() => {
    setPersonalize(draggablePersonalizationLocalStorage.get(loginUser, "salesPersons", SALES_PERSON_PERSONALIZE));
  }, []);

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    setOpenPersonalizationModal(false);
    const personalisationData = draggablePersonalizationLocalStorage.saveAs(data, "salesPersons");
    draggablePersonalizationLocalStorage.save(JSON.stringify(personalisationData));
    await api.udpatePersonalization(loginUser.phone_number, personalisationData);
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? filteredSalesPersons.map((t) => t.id) : []);
  };

  const tdFormat = (item, row) => {
    if (item === "isActive")
      return row?.isActive ? (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="green" tickIcon />
        </div>
      ) : (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="maroon" crossIcon />
        </div>
      );
    return row[item];
  };

  const renderTd = (item, row) => {
    return (
      <td
        key={item.id}
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
      >
        {tdFormat(item.itemKey, row)}
      </td>
    );
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;

      case TABLE_QUICK_TOOLS.edit:
        dispatch(setSelectedSalePerson({ ...row, isNew: false }));
        break;

      case TABLE_QUICK_TOOLS.delete:
        if (!clientId) {
          const confirmationModalData = {
            showModal: true,
            onConfirm: () => dispatch(deleteSalesPerson({ ...row })),
            title: "Delete Sale Person",
            message: "Are you sure, you want to delete that sales person?",
          };
          dispatch(showConfirmationModal(confirmationModalData));
        } else {
          const confirmationModalData = {
            showModal: true,
            onConfirm: () => dispatch(unLinkSalesPerson({ siteId: clientId, salePersonId: row.id })),
            title: "Remove Sale Person",
            message: "Are you sure, you want to remove that sales person?",
          };
          dispatch(showConfirmationModal(confirmationModalData));
        }
        break;

      default:
        break;
    }
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {t("salesPerson")}
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />

                  <Icon
                    handleClick={async () => {
                      if ((filteredSalesPersons || []).length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                    }}
                    label={!allSelected ? "Select All" : "Deselect All"}
                    title={!allSelected ? "Select All" : "Deselect All"}
                    iconType={"selectAllIcon"}
                  />

                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    label={t("personalize")}
                    iconType={"personalizeIcon"}
                  />

                  <Icon
                    title={t("createSalesPerson")}
                    label={t("createSalesPerson")}
                    iconType={"acknowledgedPositiveIcon"}
                    handleClick={() => dispatch(setSelectedSalePerson({ isNew: true, siteID: clientId }))}
                  />

                  {!clientId && (
                    <Icon
                      title={t("assignTo")}
                      label={t("assignTo")}
                      iconType={"addUserIcon"}
                      disabled={checkboxes.length === 0}
                      handleClick={() =>
                        dispatch(
                          setSalesPersonAssignDetails({
                            showAssignModal: true,
                            salesPersonListToAssign: filteredSalesPersons.filter((salePerson) =>
                              checkboxes.includes(salePerson.id)
                            ),
                          })
                        )
                      }
                    />
                  )}
                </div>

                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    isUsersTable={true}
                    employeeClaim={true}
                    personalisationData={SALES_PERSON_PERSONALIZE}
                  />
                )}

                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={pageNumbers}
                  showSelectedRecord
                  totalSelected={checkboxes.length}
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <MainTable
                  columns={personalize}
                  rows={salePersonsToMap}
                  draggable
                  resizable
                  flipSort={flipSort}
                  personalisationKey={"salesPersons"}
                  handleSaveDragAndResize={(personalizeArr) => setPersonalize(personalizeArr)}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  widthToSkip={155}
                  tools={quickTools}
                  selectedRows={checkboxes}
                  handleCellClick={handleCellClick}
                  customColumnCellRenderer={renderTd}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}

      {openPersonalizationModal && (
        <PersonalizationModal
          data={JSON.stringify(personalize)}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}
    </div>
  );
};

export default SalesPersonView;
