import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { capitalizeLetter, formatPhoneNumber, formatDateOfBirthDOB } from "utils";
import "./EmployeeStyle.css";

const ProfileListItems = (props) => {
  const { item, handleMakeRelation, relations, options } = props;

  const [selectedOption, setSelectedOption] = useState(null);

  const relation = relations ? relations.find((ele) => ele.id === item.id) : null;
  const initialSelectedOption = relation ? options.find((option) => option.value === relation?.relation) : null;
  const onChangeHandler = (options) => {
    setSelectedOption(options);
  };

  return (
    <tr>
      <td className="genderWidth18 ellipsis">{capitalizeLetter(item.firstName)}</td>
      <td className="genderWidth18 ellipsis">{capitalizeLetter(item.lastName)}</td>
      <td className="tdWidth10 ellipsis">{item.sex}</td>
      <td className="tdWidth12 ellipsis">{formatDateOfBirthDOB(item.dob)}</td>
      <td className="tdWidth18 ellipsis">{formatPhoneNumber(item.phoneNumber, "", true)}</td>
      <td className="relationWidth18">
        <Select
          blurInputOnSelect={true}
          menuPlacement="bottom"
          className="w-100"
          defaultValue={initialSelectedOption}
          onChange={onChangeHandler}
          value={selectedOption}
          options={options}
          styles={{
            // Fixes the overlapping problem of the component
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
        />
      </td>
      <td onClick={() => handleMakeRelation(item, selectedOption?.value)}>
        <button className="headerButton btn-fill btn btn-secondary cursor-pointer profileRelationButton">
          <i className="fa fa-plus" />
        </button>
      </td>
      <td>
        <div className="d-flex flex-wrap gap-3"></div>
      </td>
    </tr>
  );
};

export default ProfileListItems;
