export const EMPLOYEE = "employee";

export const EMPLOYEE_FETCH = "employee/fetchAllEmployees";

export const EMPLOYEE_FETCH_API = "employee/fetchAllEmployeesApi";

export const EMPLOYEE_FETCH_RECORD = "employee/fetchEmployeeRecord";

export const EMPLOYEE_CREATE = "employee/createEmployee";

export const EMPLPLOYEE_UPDATE = "employee/updateEmployee";

export const EMPLOYEE_DELETE = "employee/deleteEmployee";

export const EMPLOYEE_EXPORT = "employee/exportEmployee";
