import React, { useEffect, useMemo } from "react";
import { formatCurrency } from "../../../utils";
import { getElgibilityLineItem } from "./common";
import { formatPhoneNumber } from "../../../utils";
import { Button } from "react-bootstrap";

const PayerInformationList = (props) => {
  const { data, label } = props;
console.log('data',data);
  return (
    <>
      <div className="form-group-eligibity-wrapper">
        <div className="form-group-eligibity-wrapper">
          <div className="d-grid w-100">
            <span className="fw-bold">{label}</span>
            {data &&
              data.map((item) => (
                <>
                  {item.benefit_coverage_description && <span>{item.benefit_coverage_description}</span>}
                  <span className="error-text">{item?.title}</span>
                  
                  {data?.planStartDate && <span>{`${data?.planStartDate}- ${data?.planEndDate}`}</span>}
                  {<span>{item?.benefit_notes}</span>}
                </>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PayerInformationList;
