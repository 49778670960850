import _ from "lodash";

export const customIsEmpty = (value) => {
  return _.isEmpty(value);
};

export const customKeyBy = (array, key) => {
  return _.keyBy(array, key);
};
export const customUniqBy = (array, key) => {
  return _.uniqBy(array, key);
};

export const customCompact = (array) => {
  return _.compact(array);
};
export const customIsEqual = (array1, array2) => {
  return _.isEqual(array1,array2);
};
export const customCloneDeep = (obj) => {
  return _.cloneDeep(obj);
};