import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { setHospiceDetailsData } from "store/features/checkEligibility/checkEligibilitySlice";
import { formatCurrency } from "utils";
import { convertIntoDates, planDates } from "./common";

const DETAIL_TYPES = {
  hospice: {
    name: "hospice",
    code: "45",
    descriptionFilter:  (f) =>f.benefit_description === "Hospice",
  },
  skilledNursing: {
    name: "skilledNursing",
    code: "AG",
    descriptionFilter: (f) => f.admission && f.benefit_description === "Skilled Nursing Care",
  },
};

const PlanItem = (props) => {
  const { item, title, newUser, benefitLabel, handleCollapse, compareStrings } = props;

  const dispatch = useDispatch();

  if (!item) return <></>;

  const showSkilledNursing = useMemo(() => {
    if (!newUser) return {};

    let filteredData = newUser?.benefit?.find((f) => f.admission && f.benefit_description === "Skilled Nursing Care");

    if (!filteredData) {
      return {};
    }

    const dates = planDates(filteredData.admission);
    // const status = filteredData.benefit_coverage_description === "Active Coverage" ? "Active" : "In Active";

    const obj = { ...filteredData, ...dates, status: "" };
    return obj;
  }, [newUser]);

  const showHospice = useMemo(() => {
    if (!newUser) return { status: "" };

    let filteredData = newUser?.benefit?.find((f) => f.benefit_description.toLowerCase() === "hospice");

    if (!filteredData) {
      return { status: "No" };
    }

    const dates = planDates(filteredData.plan_date);

    const activeDates = dates.planStartDate ? `(${dates.planStartDate} - ${dates.planEndDate})` : "";

    const obj = { ...filteredData, ...dates, status: "Yes", activeDates };
    return obj;
  }, [newUser]);

  const handleDetailClick = (detailType) => {
    const selectedDetailType = DETAIL_TYPES[detailType];

    const benefitsList = newUser?.benefit || [];
    const filteredBenefits = [];

    benefitsList.forEach((benefit) => {
      let isRelevant = false;
      const stringToCheck = benefit.benefit_description;
      if (selectedDetailType.descriptionFilter) {
        const descritpionFilter = selectedDetailType.descriptionFilter(benefit);

        if (descritpionFilter) isRelevant = true;
      }

      if (isRelevant)
        filteredBenefits.push({
          ...benefit,
          ...convertIntoDates(benefit?.admission || benefit?.benefit || benefit?.plan_date),
        });
    });

    dispatch(
      setHospiceDetailsData({
        showModal: true,
        modalTitle: detailType === DETAIL_TYPES.hospice.name ? "Hospice Details" : "Skilled Nursing Details",
        detailsList: filteredBenefits || [],
      })
    );
  };

  return (
    <>
      <div
        className={`boxborder testAdmin createClientsWrapper ${handleCollapse && "cursor-pointer"}`}
        onClick={() => handleCollapse && handleCollapse()}
      >
        <label className="modalLineHeaders fw-bold">{title} </label>

        <div className="crew-profile-column-equal form-group-eligibity-wrapper">
          <div className="form-group-eligibity-wrapper w-100">
            <label className="modalLineHeaders fw-bold">Plan Start Date: </label>
            <span>{item?.planStartDate || newUser.planStartDate || "-"}</span>
          </div>
          <div className="form-group-eligibity-wrapper w-100">
            <label className="modalLineHeaders fw-bold">Plan End Date: </label>
            <span>{item?.planEndDate || newUser.planEndDate || "-"}</span>
          </div>
        </div>

        <div className="form-group-eligibity-wrapper crew-profile-column-equal eligibility-columns-full">
          <div className="form-group-eligibity-wrapper w-100">
            <label className="modalLineHeaders fw-bold">Insurance Plan: </label>
            <div className="flex-1">
              {item.insurance_plan && <span>{item.insurance_plan || "-"}</span>}
              <div className="form-group-eligibity-wrapper w-100">{item.insurance_type_description || ""}</div>
            </div>
          </div>
          <div className="form-group-eligibity-wrapper w-100">
            <label className="modalLineHeaders fw-bold w-50">Plan Number: </label>
            <div className="flex-1">
              <span>{item.plan_number || "-"}</span>
            </div>
          </div>
        </div>
        {benefitLabel && (
          <>
            {" "}
            {/* <div className="form-group-eligibity-wrapper crew-profile-column-equal eligibility-columns-full">
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">Start Date: </label>
                <span>{item.deductible.deductibleInYear?.planStartDate || "-"}</span>
              </div>
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">End Date: </label>
                <span>{item.deductible.deductibleInYear?.planEndDate || "-"}</span>
              </div>
            </div> */}
            <div className="crew-profile-column-equal form-group-eligibity-wrapper">
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">Deductible Amount: </label>
                <span className={compareStrings("deductible", "deductibleInYear") ? "error-text" : ""}>
                  {formatCurrency(item.deductible.deductibleInYear?.benefit_amount)}
                </span>
                {compareStrings("deductible", "deductibleInYear")}
              </div>
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">Deductible Remaining: </label>
                <span>{formatCurrency(item.deductible.deductibleRemaning?.benefit_amount)}</span>
              </div>
            </div>
            <div className="crew-profile-column-equal form-group-eligibity-wrapper">
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">CoInsurance%: </label>
                <span>{item.deductible.coInsurance?.benefit_percent || "-"}</span>
              </div>
              <div className="form-group-eligibity-wrapper w-100">
                <label className="modalLineHeaders fw-bold">Co Payment: </label>
                <span className={compareStrings("copay", "coPayment") ? "error-text" : ""}>
                  {formatCurrency(item.deductible.coPayment?.benefit_amount)}
                </span>
                {compareStrings("copay", "coPayment")}
              </div>
            </div>
          </>
        )}
        <div className="crew-profile-column-equal form-group-eligibity-wrapper">
          <div className="form-group-eligibity-wrapper w-100">
            <label className="modalLineHeaders fw-bold">Hospice: </label>
            <span onClick={() => showHospice?.status !== "No" && handleDetailClick(DETAIL_TYPES.hospice.name)}>
              <span className="font-bold">{showHospice?.status}</span>
              <span>{showHospice?.activeDates}</span>
            </span>
          </div>
        </div>
        <div className="crew-profile-column-equal form-group-eligibity-wrapper">
          <div className="form-group-eligibity-wrapper w-100">
            <label className="modalLineHeaders fw-bold">Skilled Nursing Care: </label>
            <span
              onClick={() => {
                if (showSkilledNursing?.planStartDate && showSkilledNursing?.planEndDate)
                  handleDetailClick(DETAIL_TYPES.skilledNursing.name);
              }}
            >
              {showSkilledNursing?.planStartDate} - {showSkilledNursing?.planEndDate}
            </span>
          </div>
        </div>
        <div className="form-group-eligibity-wrapper crew-profile-column-reverse w-100">
          <label className="modalLineHeaders fw-bold w-25">Notes: </label>
          <span>{item?.benefit_notes || item?.insurance_type_description || "-"}</span>
        </div>
      </div>
    </>
  );
};

export default PlanItem;
